import React from "react"
import { EditorProvider } from "contexts/experience-editor"
import { Switch, Route, useRouteMatch } from "react-router-dom"
import EditorPageTitleBar from "./editor-page-title-bar"
import QRCodeList from "./qr-codes"
import QRCodeShow from "./qr-codes/show"
import DesignEditor from "./design-editor"
import Configuration from "./configuration"

const ExperienceEdit = () => {
  const { path } = useRouteMatch()

  /* eslint-disable react/no-multi-comp */
  const Scrollable = ({ children }) => (
    <div className="flex flex-col w-full overflow-y-scroll">
      <EditorPageTitleBar />
      {children}
    </div>
  )

  return (
    <EditorProvider>
      <Switch>
        <Route path={`${path}/editor`}>
          <div className="flex flex-col w-full">
            <EditorPageTitleBar />
            <DesignEditor />
          </div>
        </Route>
        <Route path={`${path}/qrcodes/:activationId`}>
          <Scrollable>
            <QRCodeShow />
          </Scrollable>
        </Route>
        <Route exact path={`${path}/qrcodes`}>
          <Scrollable>
            <QRCodeList />
          </Scrollable>
        </Route>
        <Route path={`${path}/nfctags`}>
          <Scrollable>
            <p>NFC tags here</p>
          </Scrollable>
        </Route>
        <Route path={`${path}/configuration`}>
          <Scrollable>
            <Configuration />
          </Scrollable>
        </Route>
      </Switch>
    </EditorProvider>
  )
}

export default ExperienceEdit
