import React, { useState } from "react"
import { useParams, Link } from "react-router-dom"
import { useEditor } from "contexts/experience-v2-editor"
import { Helmet } from "react-helmet"

import Alert from "components/alert"
import CardEditor from "./card-editor"
import GridEditor from "./grid-editor"
import Activations from "./activations"
import Settings from "./settings"
import Incentives from "./incentives"

const EditorPageTitleBar = () => {
  const {
    currentCardExperience,
    setGridView,
    setCurrentCardOrdinal,
    setIsLaunchCard,
  } = useEditor()
  const params = useParams()

  const returnLinkDestination = `/brands/${params.brandKey}/experiences`
  const title = currentCardExperience.name

  const tabs = {
    left: {
      Grid: <GridEditor />,
      "Card 1": <CardEditor />,
      "Card 2": <CardEditor />,
      "Card 3": <CardEditor />,
      "Card 4": <CardEditor />,
      Launch: <CardEditor />,
    },
    right: {
      Incentives: <Incentives />,
      Activations: <Activations />,
      Settings: <Settings />,
    },
  }

  const [tab, setTab] = useState("Grid")

  const tabContent = tabs.left[tab] || tabs.right[tab]

  const tabTitles = {
    "Card 1": 1,
    "Card 2": 2,
    "Card 3": 3,
    "Card 4": 4,
  }

  const updateLeftTab = (option) => {
    const ordinal = tabTitles[option]

    if (option === "Grid") {
      setGridView(true)
      setIsLaunchCard(false)
    } else if (option === "Launch") {
      setGridView(false)
      setIsLaunchCard(true)
      setCurrentCardOrdinal(null)
    } else {
      setGridView(false)
      setIsLaunchCard(false)
      setCurrentCardOrdinal(ordinal)
    }

    setTab(option)
  }

  const updateRightTab = (option) => setTab(option)

  const showDeletedAlert = currentCardExperience.cardExperienceCards.find(
    (cec) =>
      cec.card?.cardVariations.find(
        (cv) => cv.productVariant.deletedFromShopify
      )
  )

  const {
    brand: { name: brandName },
  } = currentCardExperience

  const getClassesForTab = (currentTab, option) => {
    const basic =
      "whitespace-nowrap py-4 px-1 border-b-2 mr-2 cursor-pointer text-sm"
    return `${basic} ${
      currentTab === option
        ? "border-purple-500 text-purple-600 hover:text-purple-700 font-medium"
        : "border-transparent text-gray-500 hover:text-gray-700"
    }`
  }

  return (
    <>
      <Helmet>
        <title>
          {brandName} | {title}
        </title>
      </Helmet>
      {showDeletedAlert && (
        <Alert message="A card has a deleted variation, please review it." />
      )}
      <nav className="flex flex-col w-full mx-auto bg-white dark:bg-dark-800">
        <div className="flex px-4 py-2">
          <div className="flex items-center flex-shrink-0">
            <Link
              className="text-3xl text-black dark:text-gray-100 hover:text-purple-600"
              to={returnLinkDestination}
            >
              &lsaquo;
            </Link>
          </div>
          <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
            <h1
              className="inline-flex items-center px-1 pt-1 font-semibold text-gray-900 dark:text-gray-100 text-md"
              href="#"
            >
              {title}
            </h1>
          </div>
        </div>
        <div className="flex flex-row justify-between px-4 font-normal border-b border-gray-200 dark:border-dark-700">
          <div>
            {Object.keys(tabs.left).map((option) => (
              <button
                key={option}
                className={getClassesForTab(tab, option)}
                onClick={() => updateLeftTab(option)}
                type="button"
              >
                {option}
              </button>
            ))}
          </div>
          <div>
            {Object.keys(tabs.right).map((option) => (
              <button
                key={option}
                className={`${
                  tab === option
                    ? "border-purple-500 text-purple-600 hover:text-purple-700 font-medium"
                    : "border-transparent text-gray-500 hover:text-gray-700"
                } whitespace-nowrap py-4 px-1 border-b-2 mr-2 cursor-pointer text-sm`}
                onClick={() => updateRightTab(option)}
                type="button"
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      </nav>
      <div className="flex-1 px-4 py-4 overflow-y-scroll dark:text-white">
        {tabContent}
      </div>
    </>
  )
}

export default EditorPageTitleBar
