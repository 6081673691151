import React from "react"
import { Form } from "formik"
import * as Yup from "yup"
import { DeviceMobileIcon } from "@heroicons/react/solid"
import Button from "components/button"
import BatchForm from "components/batch-form"
import Select from "components/forms/select"
import SubmitButton from "components/submit-button"
import SmallFormModal, {
  ModalFooter,
  ModalBody,
} from "components/small-form-modal"
import { useEditor } from "contexts/experience-v2-editor"
import { CREATE_INCENTIVE } from "./queries"

const CreateIncentiveModal = ({ visible, onClose }) => {
  const { currentCardExperience, refetchCardExperience: refetch } = useEditor()
  const formSchema = Yup.object().shape({
    incentiveType: Yup.string().required("Required"),
    cardExperienceId: Yup.string().required("Required"),
  })

  const allIncentiveTypeOptions = {
    free_shipping_timer: "Free Shipping Timer",
    sms_discount: "SMS Discount",
  }
  const existingIncentiveTypes = currentCardExperience.incentives.map(
    (incentive) => incentive.incentiveType
  )

  const incentiveTypeOptions = Object.keys(allIncentiveTypeOptions)
    .filter((key) => !existingIncentiveTypes.includes(key))
    .reduce((acc, key) => {
      acc[key] = allIncentiveTypeOptions[key]
      return acc
    }, {})

  return (
    <SmallFormModal onClose={onClose} visible={visible}>
      {({ close }) => (
        <BatchForm
          initialValues={{
            cardExperienceId: currentCardExperience.id,
            incentiveType: "sms_discount",
          }}
          mutation={CREATE_INCENTIVE}
          onSuccess={() => {
            refetch()
            onClose()
          }}
          validationSchema={formSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody
                icon={
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <DeviceMobileIcon className="w-6 h-6 text-blue-600" />
                  </div>
                }
                title="Create a New Incentive"
              >
                <Select
                  label="Incentive Type"
                  name="incentiveType"
                  options={incentiveTypeOptions}
                />
                <div className="h-4" />
              </ModalBody>
              <ModalFooter>
                <SubmitButton submitting={isSubmitting} />
                <Button className="mr-2" onClick={close}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </BatchForm>
      )}
    </SmallFormModal>
  )
}

export default CreateIncentiveModal
