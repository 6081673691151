import React from "react"
import { formatCurrency } from "utils/money"

const ProductVariantRow = ({ productVariant, chooseProductVariant }) => (
  <tr>
    <td className="text-xs">
      <img
        alt={`${productVariant.standaloneTitle}.`}
        className="w-10"
        src={productVariant.imageSrcLarge}
      />
    </td>
    <td className="py-2 font-semibold text-sm flex flex-col">
      <span className="font-semibold text-xs">
        {productVariant.product.title}
      </span>
      <div className="flex flex-row items-center justify-start">
        <span className="font-normal text-xs text-gray-400 pr-1">
          {productVariant.standaloneTitle}
        </span>{" "}
        &middot;{" "}
        <a
          className="pl-1 text-xs font-medium text-blue-500"
          href={productVariant.product.platformShoppableUrl}
        >
          Link&#8599;
        </a>
      </div>
    </td>
    <td className="text-xs">{productVariant.sku}</td>
    <td className="text-xs">
      {productVariant.platformId?.replace("gid://shopify/ProductVariant/", "")}
    </td>
    <td className="text-xs">{formatCurrency(productVariant.price)}</td>
    <td className="pl-2">
      <button
        className="block cursor-pointer relative text-center text-xs text-purple-600 font-medium"
        onClick={() => chooseProductVariant(productVariant.id)}
        type="button"
      >
        Use for This Experience
      </button>
    </td>
  </tr>
)

export default ProductVariantRow
