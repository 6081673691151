import ActivationAppStoreConnectStatus from "components/activation-app-store-connect-status"
import React from "react"

const ActivationAppStoreConnectMessage = ({ status }) => {
  if (status === "live") {
    return null
  }
  const outstandingContainerStyles =
    "bg-pink-50 border-l-4 border-pink-400 p-4 mb-10"

  const completedContainerStyles =
    "bg-green-50 border-l-4 border-green-400 p-4 mb-10"

  const outstandingMessage = (
    <p className="text-sm text-pink-700">
      This link is not yet submitted to Apple App Store Connect.
    </p>
  )

  const completedMessage = (
    <p className="text-sm text-green-700">
      This link is submitted to Apple App Store Connect.
    </p>
  )

  return (
    <div
      className={
        status === "outstanding"
          ? outstandingContainerStyles
          : completedContainerStyles
      }
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <ActivationAppStoreConnectStatus status={status} />
        </div>
        <div className="ml-3 mt-1">
          {status === "outstanding" ? outstandingMessage : completedMessage}
        </div>
      </div>
    </div>
  )
}

export default ActivationAppStoreConnectMessage
