import React from "react"

import { Switch, Route, Redirect } from "react-router-dom"
import BrandIndex from "./brand-index"
import CoreAppIndex from "./core-app-index"

const AdminRouter = () => (
  <Switch>
    <Route component={BrandIndex} exact path="/admin/brands" />
    <Route component={CoreAppIndex} path="/admin/core-app" requireAuth />
    <Redirect to="/admin/brands" />
  </Switch>
)

export default AdminRouter
