import classNames from "classnames"
import { PrimaryButton } from "components/button"
import Card from "components/card"
import Row from "components/layout/row"
import { Form, useFormikContext } from "formik"
import useClickOutside from "hooks/use-click-outside"
import useDisclosure from "hooks/use-disclosure"
import React, { useCallback, useRef } from "react"
import Loader from "react-loader-spinner"

const ToggleForm = ({ children, title, isSubmitting, className, ...rest }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { submitForm, dirty } = useFormikContext()

  const wrapperRef = useRef(null)

  const onClickOutside = useCallback(() => {
    if (isOpen) {
      onClose()
    }
  }, [onClose, isOpen])

  const handleSubmit = (event) => {
    event.stopPropagation()
    if (dirty) {
      submitForm()
    }
    onClose()
  }

  const handleCancel = (event) => {
    event.stopPropagation()
    onClose()
  }

  useClickOutside(wrapperRef, onClickOutside)

  return (
    <Form>
      <Card
        ref={wrapperRef}
        className={classNames(
          className,
          dirty ? "border-2 border-orange-500" : ""
        )}
        data-testid="card-wrapper"
        onClick={onOpen}
        {...rest}
      >
        <Row className="w-full mb-6">
          <h3 className="flex-1 text-lg font-semibold">{title}</h3>

          {isSubmitting && (
            <Loader color="#518DFF" height={25} type="Puff" width={25} />
          )}

          {!isSubmitting && (
            <Row className="gap-4">
              <button
                className="text-blue-500 text-sm"
                onClick={handleCancel}
                type="button"
              >
                {isOpen ? "Cancel" : "Edit"}
              </button>

              {isOpen && (
                <PrimaryButton onClick={handleSubmit}>Submit</PrimaryButton>
              )}
            </Row>
          )}
        </Row>
        <div className="flex flex-col gap-3">{children({ isOpen })}</div>
      </Card>
    </Form>
  )
}

export default ToggleForm
