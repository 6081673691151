import React from "react"
import { useParams, Link } from "react-router-dom"
import { useEditor } from "contexts/experience-editor"
// import CloneExperienceButton from "./clone-experience-button"

const ExperienceHeader = () => {
  const { currentExperience } = useEditor()
  const params = useParams()

  const returnLinkDestination = `/brands/${params.brandKey}/old-experiences`
  const title = currentExperience.name

  return (
    <div className="w-full border-b mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between h-16 items-center">
        <div className="flex">
          <div className="flex-shrink-0 flex items-center">
            <Link
              className="text-3xl text-black hover:text-purple-600"
              to={returnLinkDestination}
            >
              &lsaquo;
            </Link>
          </div>
          <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
            <h1
              className="text-gray-900 inline-flex items-center px-1 pt-1 text-lg font-bold"
              href="#"
            >
              {title}
            </h1>
          </div>
        </div>
        {/* Hidden for now */}
        {/* <div className="flex items-center">
          <CloneExperienceButton />
        </div> */}
      </div>
    </div>
  )
}

export default ExperienceHeader
