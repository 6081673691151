import React from "react"
import { useEditor } from "contexts/experience-v2-editor"
import Iphone11ProMax from "components/containers/iphone-11-pro-max"
import ExpandedViewPreview from "./expanded-view"
import CollapsedViewPreview from "./collapsed-view"

const Preview = () => {
  const {
    currentCardExperience,
    currentCard,
    currentCardVariation,
    isGridView,
    setGridView,
  } = useEditor()

  if (
    (!isGridView && currentCard === null) ||
    currentCardExperience.cardExperienceCards.length === 0
  ) {
    return (
      <Iphone11ProMax backgroundColor="white">
        <div className="w-full h-full flex items-center justify-center">
          <p className="text-purple-600 font-medium text-lg">
            ← Add or create a card
          </p>
        </div>
      </Iphone11ProMax>
    )
  }

  const { incentives, gridTitle, cardExperienceCards, logoHorizontalSrc } =
    currentCardExperience

  const {
    details: { prePurchaseExplainerTitle, postPurchaseExplainerTitle },
  } = (incentives.size > 0 && incentives[0]) || { details: {} }

  const { screenBackgroundColor, screenForegroundColor } =
    currentCard || currentCardExperience.cardExperienceCards[0].card

  const gridBackgroundColor = `#${screenBackgroundColor}`
  const gridForegroundColor = `#${screenForegroundColor}`

  return (
    <>
      <Iphone11ProMax backgroundColor={gridBackgroundColor}>
        {isGridView ? (
          <CollapsedViewPreview
            cardExperienceCards={cardExperienceCards}
            gridForegroundColor={gridForegroundColor}
            gridTitle={gridTitle}
            logoHorizontalSrc={logoHorizontalSrc}
            postPurchaseMessage={postPurchaseExplainerTitle}
            prePurchaseMessage={prePurchaseExplainerTitle}
            setGridView={setGridView}
          />
        ) : (
          <ExpandedViewPreview
            card={currentCard}
            currentCardVariation={currentCardVariation}
            logoHorizontalSrc={logoHorizontalSrc}
            setGridView={setGridView}
          />
        )}
      </Iphone11ProMax>
    </>
  )
}

export default Preview
