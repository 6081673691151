/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React from "react"
import { Link } from "react-router-dom"
import Table from "components/table"
import formatCurrency from "utils/money"

import CardExperiences, {
  filterCardExperiences,
} from "components/card-experiences"

const ProductSelector = ({
  setSelectedTableRows,
  paginate = false,
  showHeader = false,
  showNumberSelected = false,
  tableData,
}) => (
  // TODO: add a max height so the table scrolls but the bottom buttons are visible
  <Table
    columns={[
      { title: "SKU", field: "sku" },
      {
        title: "Shopify Product Variant ID",
        field: "shopifyId",
        defaultFilter: "",
        customFilterAndSearch: (term, row) => row.shopifyId.includes(term),
      },
      {
        title: "Shopify Product ID",
        field: "shopifyProductId",
        defaultFilter: "",
        customFilterAndSearch: (term, row) =>
          row.shopifyProductId.includes(term),
      },
      {
        title: "Used in Card Experiences",
        field: "cardExperiences",
        render: ({ cardExperiences }) => (
          <CardExperiences cardExperiences={cardExperiences} />
        ),
        customFilterAndSearch: (term, row) =>
          filterCardExperiences(term, row.cardExperiences),
      },
      {
        title: "Product Variant",
        customFilterAndSearch: (term, row) =>
          row.productTitle.toLowerCase().includes(term.toLowerCase()) ||
          row.subtitle.toLowerCase().includes(term.toLowerCase()),
        render: ({ productTitle, subtitle, productVariantUrl }) => (
          <Link
            rel="noopener noreferrer"
            target="_blank"
            to={productVariantUrl}
          >
            <div className="whitespace-nowrap flex flex-row justify-start items-center">
              <div className="ml-4 pr-6 py-2 items-center font-medium text-gray-900">
                <p>{productTitle}</p>
                <p className="text-gray-600 text-sm font-normal">{subtitle}</p>
              </div>
            </div>
          </Link>
        ),
      },
      {
        field: "price",
        title: "Price",
        filtering: false,
        customSort: (a, b) => a.price - b.price,
        render: ({ price }) => <span>{formatCurrency(price)}</span>,
      },
    ]}
    data={tableData}
    onSelectionChange={(rows) => {
      // We actually want to reassign this, it's functioning like a state
      tableData.forEach((row) => {
        // eslint-disable-next-line no-param-reassign
        row.selected = false
      })

      rows.forEach((row) => {
        // eslint-disable-next-line no-param-reassign
        row.selected = true
      })
      setSelectedTableRows(rows)
    }}
    options={{
      emptyRowsWhenPaging: false,
      filtering: showHeader,
      header: showHeader,
      initialPage: 0,
      pageSize: 10,
      pageSizeOptions: [10, 20, 50],
      paging: paginate,
      selection: true,
      toolbar: showNumberSelected,
    }}
  />
)

export default ProductSelector
