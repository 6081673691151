import React from "react"
import { useEditor } from "contexts/experience-editor"
import * as Yup from "yup"
import BatchForm from "components/batch-form"
import { Form } from "formik"

import SubmitButton from "components/submit-button"
import FormikInput from "components/forms/formik-input"
import Textarea from "components/forms/textarea"

import { UPDATE_FEATURED_CONTENT_HEADER_MUTATION } from "./queries"

const ContentHeaderForm = ({ experienceSectionDetails }) => {
  const { refetchExperience } = useEditor()

  const { title, description, id } = experienceSectionDetails

  const formSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
  })

  const additionalValues = {
    experienceSectionFeaturedContentHeaderDetailsId: id,
  }

  return (
    <div className="border-b-2 border-gray-400 pb-4">
      <BatchForm
        additionalValues={additionalValues}
        formSchema={formSchema}
        initialValues={{
          title,
          description,
        }}
        mutation={UPDATE_FEATURED_CONTENT_HEADER_MUTATION}
        onSuccess={() => refetchExperience()}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="grid grid-cols-8">
              <div className="col-span-6 sm:col-span-4 mb-4">
                <FormikInput
                  autoFocus
                  label="Title"
                  name="title"
                  placeholder="Unlock Your Discount"
                  type="text"
                />
              </div>
              <div className="col-span-2" />
              <div className="col-span-6 sm:col-span-6 mb-4">
                <Textarea
                  autoFocus
                  label="description"
                  name="description"
                  placeholder="Get 10% off your order"
                  rows={3}
                  type="text"
                />
              </div>
            </div>
            <SubmitButton submitting={isSubmitting} />
          </Form>
        )}
      </BatchForm>
    </div>
  )
}

export default ContentHeaderForm
