import React from "react"

const HintText = ({ hint }) => {
  if (!hint) return null

  return (
    <p
      className="text-gray-400 ml-1 leading-none mt-1"
      style={{ fontSize: "0.6rem" }}
    >
      {hint}
    </p>
  )
}

export default HintText
