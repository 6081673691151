import React from "react"
import Card from "components/expanded-card-preview"
import Footer from "components/expanded-card-preview/footer"
import Header from "components/expanded-card-preview/header"

const ExpandedViewPreview = ({
  card,
  currentCardVariation,
  logoHorizontalSrc,
  setGridView,
}) => {
  const {
    disableAuxButtons,
    expandedAccentColor,
    screenForegroundColor,
    expandedForegroundOverAccentColor,
  } = card

  const currentCardVariationCard =
    currentCardVariation?.cardVariationCards.find(
      (cvc) =>
        cvc.cardVariation.id === currentCardVariation.id &&
        cvc.card.id === card.id
    )

  return (
    // TODO: move this to another component, use it in the pv preview page
    <div className="flex flex-col space-between items-center h-full w-full">
      <Header
        accentColor={`#${expandedAccentColor}`}
        cardVariationCard={currentCardVariationCard}
        imageSrc={logoHorizontalSrc}
        price={currentCardVariation?.price}
        textColor={`#${screenForegroundColor}`}
      />
      <Card
        card={card}
        cardVariation={currentCardVariation}
        cardVariationCard={currentCardVariationCard}
      />
      <Footer
        accentColor={`#${expandedAccentColor}`}
        disableAuxButtons={disableAuxButtons}
        setGridView={setGridView}
        textColor={`#${screenForegroundColor}`}
        textOverAccentColor={`#${expandedForegroundOverAccentColor}`}
      />
    </div>
  )
}

export default ExpandedViewPreview
