import React from "react"

const ModalBody = ({ children, icon, title, description, inputFields }) => (
  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
    <div className="sm:items-start">
      <div className="flex mb-6 items-center">
        <span className="mr-2">{icon}</span>
        <div>
          <h3
            className="text-lg leading-6 font-medium text-gray-900 whitespace-normal"
            id="modal-headline"
          >
            {title}
          </h3>
          <div>
            <p className="text-sm text-gray-500">{description}</p>
          </div>
        </div>
      </div>

      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        {inputFields && (
          <div className="mb-2">
            {inputFields.map((inputField) => (
              <div key={inputField.key} className="w-72 mt-4">
                {inputField}
              </div>
            ))}
          </div>
        )}
        <div className="mb-2">{children}</div>
      </div>
    </div>
  </div>
)

export default ModalBody
