import gql from "graphql-tag"

export const REMOVE_CATALOG_PRODUCT_VARIANT_MUTATION = gql`
  mutation RemoveItemFromExperienceSectionProductVariantCatalog(
    $input: RemoveItemFromExperienceSectionProductVariantCatalogInput!
  ) {
    removeItemFromExperienceSectionProductVariantCatalog(input: $input) {
      errors
      experience {
        id
      }
    }
  }
`

export const REORDER_PRODUCT_VARIANT_CATALOG_MUTATION = gql`
  mutation ReorderExperienceSectionProductVariantCatalog(
    $input: ReorderExperienceSectionProductVariantCatalogInput!
  ) {
    reorderExperienceSectionProductVariantCatalog(input: $input) {
      errors
      experience {
        id
      }
    }
  }
`

export const GET_PRODUCT_VARIANTS = gql`
  query GetExperienceSectionProductVariantCatalogQuery($id: ID!) {
    experienceSectionProductVariantCatalogDetail(id: $id) {
      id
      productVariants {
        id
        description
        imageSrcSmall
        sku
        standaloneTitle
        subtitle
        price
        deletedFromShopify
      }
    }
  }
`

export const GET_MODAL_PRODUCT_VARIANTS = gql`
  query GetExperienceSectionProductVariantCatalogQuery($id: ID!) {
    experienceSectionProductVariantCatalogDetail(id: $id) {
      id
      productVariants {
        id
        sku
        description
        imageSrcSmall
        standaloneTitle
        subtitle
        price
      }
      excludedProductVariants {
        id
        sku
        description
        imageSrcSmall
        standaloneTitle
        subtitle
        price
      }
    }
  }
`

export const UPDATE_MODAL_PRODUCT_VARIANTS = gql`
  mutation UpdateExperienceSectionProductVariantCatalogDetails(
    $input: UpdateExperienceSectionProductVariantCatalogDetailsInput!
  ) {
    updateExperienceSectionProductVariantCatalogDetails(input: $input) {
      errors
      experience {
        id
        __typename
      }
      __typename
    }
  }
`

export const UPDATE_PRODUCT_VARIANT_MUTATION = gql`
  mutation UpdateProductVariantFromCatalog(
    $input: UpdateProductVariantFromCatalogInput!
  ) {
    updateProductVariantFromCatalog(input: $input) {
      errors
      productVariant {
        id
      }
    }
  }
`

export const UPDATE_CATALOG_TITLE_MUTATION = gql`
  mutation UpdateExperienceSectionProductVariantCatalogTitle(
    $input: UpdateExperienceSectionProductVariantCatalogTitleInput!
  ) {
    updateExperienceSectionProductVariantCatalogTitle(input: $input) {
      errors
      experience {
        id
        __typename
      }
      __typename
    }
  }
`
