import React from "react"
import { XCircleIcon } from "@heroicons/react/solid"

const Alert = ({ message }) => (
  <div className="p-4 rounded-md bg-red-50">
    <div className="flex">
      <div className="flex-shrink-0">
        <XCircleIcon aria-hidden="true" className="w-5 h-5 text-red-400" />
      </div>
      <div className="flex-1 ml-3 md:flex md:justify-between">
        <p className="text-sm text-red-700">{message}</p>
      </div>
    </div>
  </div>
)
export default Alert
