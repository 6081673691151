import React, { useState, useEffect } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { useToasts } from "react-toast-notifications"
import { MinusCircleIcon, PencilIcon } from "@heroicons/react/solid"
import { useMutation } from "@apollo/client"
import { remove } from "lodash"
import * as Yup from "yup"
import { Form } from "formik"

import { formatCurrency } from "utils/money"
import BatchForm from "components/batch-form"
import FormikInput from "components/forms/formik-input"
import SubmitButton from "components/submit-button"
import { ReactComponent as DragIcon } from "assets/icons/drag.svg"
import {
  REMOVE_CATALOG_PRODUCT_VARIANT_MUTATION,
  REORDER_PRODUCT_VARIANT_CATALOG_MUTATION,
  UPDATE_CATALOG_TITLE_MUTATION,
} from "./queries"

import EditProductVariantModal from "./edit-product-variant-modal"

const ProductVariantCatalogSection = ({
  experienceSectionDetails,
  onComplete,
}) => {
  const [items, setItems] = useState(experienceSectionDetails.productVariants)
  const { addToast } = useToasts()

  useEffect(() => {
    setItems(experienceSectionDetails.productVariants)
  }, [experienceSectionDetails.productVariants])

  const [removeItemFromProductVariantCatalog] = useMutation(
    REMOVE_CATALOG_PRODUCT_VARIANT_MUTATION,
    {
      onError: (e) => {
        // eslint-disable-next-line no-console
        console.log("Error", e)
        addToast("Something went wrong, changes did not save!", {
          appearance: "error",
        })
        onComplete()
      },
      onCompleted: () => {
        onComplete()
      },
    }
  )

  const [reorderProductVariantCatalog] = useMutation(
    REORDER_PRODUCT_VARIANT_CATALOG_MUTATION,
    {
      onError: (e) => {
        // eslint-disable-next-line no-console
        console.log("Error", e)
        addToast("Something went wrong, changes did not save!", {
          appearance: "error",
        })
        onComplete()
      },
      onCompleted: () => {
        onComplete()
      },
    }
  )

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const removeItem = (variantId) => {
    const itemsCopy = Array.from(items)
    const newItems = remove(itemsCopy, (el) => el.id !== variantId)

    setItems(newItems)

    removeItemFromProductVariantCatalog({
      variables: {
        input: {
          experienceSectionProductVariantCatalogDetailsId:
            experienceSectionDetails.id,
          variantId,
        },
      },
    })
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    )

    const reorderedItemIds = reorderedItems.map(({ id }) => id)

    setItems(reorderedItems)

    reorderProductVariantCatalog({
      variables: {
        input: {
          experienceSectionProductVariantCatalogDetailsId:
            experienceSectionDetails.id,
          productVariantIds: reorderedItemIds,
        },
      },
    })
  }

  const [modalVisible, setModalVisible] = useState(false)
  const [currentlyEditingVariant, setCurrentlyEditingVariant] = useState(false)

  const showModal = () => setModalVisible(true)
  const onModalClose = () => {
    setModalVisible(false)
    onComplete()
  }

  const showEditModal = (variant) => {
    setCurrentlyEditingVariant(variant)
    showModal()
  }

  const formSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
  })

  return (
    <>
      <EditProductVariantModal
        onClose={onModalClose}
        variant={currentlyEditingVariant}
        visible={modalVisible}
      />
      <hr />
      <BatchForm
        additionalValues={{
          experienceSectionProductVariantCatalogDetailsId:
            experienceSectionDetails.id,
        }}
        formSchema={formSchema}
        initialValues={{
          title: experienceSectionDetails.title,
        }}
        mutation={UPDATE_CATALOG_TITLE_MUTATION}
        onSuccess={() => onComplete()}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="mt-2">
              <FormikInput
                autoFocus
                label="Catalog Title"
                name="title"
                placeholder="Popular Products"
                type="text"
              />
            </div>
            <div className="my-2">
              <SubmitButton cta="Update Title" submitting={isSubmitting} />
            </div>
          </Form>
        )}
      </BatchForm>

      <DragDropContext
        // onBeforeCapture={onBeforeCapture}
        // onBeforeDragStart={onBeforeDragStart}
        // onDragStart={onDragStart}
        // onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
      >
        <div>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <ul
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`${
                  snapshot.isDraggingOver ? "bg-white" : "bg-white"
                }`}
              >
                {items.map((variant, i) => (
                  <Draggable
                    key={variant.id}
                    draggableId={variant.id}
                    index={i}
                  >
                    {(itemProvided, itemSnapshot) => (
                      <li
                        /* eslint-disable react/jsx-props-no-spreading */
                        ref={itemProvided.innerRef}
                        className={`py-3 pr-3 h-100 bg-white border-gray-200 w-100 ${
                          i + 1 === items.length ? "" : "border-b"
                        } ${itemSnapshot.isDragging ? "shadow-lg" : ""}`}
                        style={itemProvided.draggableProps.style}
                        {...itemProvided.draggableProps}
                        {...itemProvided.dragHandleProps}
                      >
                        <div className="flex items-center space-x-4">
                          <DragIcon className="w-3 h-3 text-gray-300" />
                          <div className="flex-shrink-0">
                            <img
                              alt=""
                              className="h-8 w-8"
                              src={variant.imageSrcSmall}
                            />
                          </div>
                          <div className="flex-1 min-w-0">
                            <p className="text-sm font-semibold text-gray-900 truncate">
                              {variant.standaloneTitle}
                            </p>
                            <p className="text-xs text-gray-500 truncate">
                              {variant.sku && (
                                <>
                                  <span className="text-xs font-medium text-gray-600 truncate">
                                    {variant.sku}
                                  </span>
                                  <span
                                    aria-hidden="true"
                                    className="hidden sm:inline sm:mx-1"
                                  >
                                    &middot;
                                  </span>
                                </>
                              )}
                              {variant.subtitle}
                              <span
                                aria-hidden="true"
                                className="hidden sm:inline sm:mx-1"
                              >
                                &middot;
                              </span>
                              {formatCurrency(variant.price)}
                            </p>
                          </div>
                          <div>
                            <button
                              className="cursor-pointer inline-flex items-center text-sm leading-5 font-medium rounded-full text-purple-500 bg-white hover:text-purple-800"
                              onClick={() => showEditModal(variant)}
                              type="button"
                            >
                              <PencilIcon className="w-4 h-4" />
                            </button>
                          </div>
                          <div>
                            <button
                              className="cursor-pointer inline-flex items-center text-sm leading-5 font-medium rounded-full text-purple-500 bg-white hover:text-purple-800"
                              onClick={() => removeItem(variant.id)}
                              type="button"
                            >
                              <MinusCircleIcon className="w-4 h-4" />
                            </button>
                          </div>
                        </div>
                      </li>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    </>
  )
}

export default ProductVariantCatalogSection
