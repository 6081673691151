import gql from "graphql-tag"

const UPDATE_CALENDLY_EVENT = gql`
  mutation UpdateCalendlyEvent($input: UpdateCalendlyEventInput!) {
    updateCalendlyEvent(input: $input) {
      errors
      brand {
        id
      }
    }
  }
`

export { UPDATE_CALENDLY_EVENT }

export default UPDATE_CALENDLY_EVENT
