import gql from "graphql-tag"

const GET_BRAND = gql`
  query GetBrand($key: String!) {
    brand(key: $key) {
      id
      name
      status
      homepage
      key
      shopifyDomain
      logoImageSrc
      termsOfUseUrl
      hasShopifyToken
      privacyPolicyUrl
      supportUrl
      shopifyApiKey
      shopifyApiSecret
      applicationFeeRate
      shopifyMerchantInstallLink
      shopifyMerchantInstallLinkExpiresAtDistance
      stripeConnectAccountId
      stripeInstallLink
      stripeApplePayDomainId
      dropboxFileRequestLink
      publicRefreshToken
      publicRefreshTokenExpiresAt

      ## New fields!!

      ##onboardingStripeSetupComplete
      ##onboardingShopifySetupComplete
      ##onboardingCalendlyComplete

      ## Calendly could use the two following fields available in public name space, if we dont want to have a specific bool for it
      ## field :calendly_event_id, String, null: true
      ## field :calendly_invitee_id, String, null: true

      ##applePayOnWebEnabled
    }
  }
`

export default GET_BRAND

export { GET_BRAND }
