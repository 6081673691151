import React from "react"

const ProfileAvatar = ({ fullName, avatarUrl }) => {
  const initials = fullName
    .match(/\b(\w)/g)
    .join("")
    .toUpperCase()

  return (
    <button
      aria-haspopup="true"
      className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
      id="user-menu"
      type="button"
    >
      <span className="sr-only">Open user menu</span>
      {avatarUrl ? (
        <img alt="" className="h-8 w-8 rounded-full" src={avatarUrl} />
      ) : (
        <span className="font-bold text-xs text-white bg-black h-8 w-8 flex items-center justify-center rounded-full">
          {initials}
        </span>
      )}
    </button>
  )
}

export default ProfileAvatar
