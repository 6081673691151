import React from "react"

import Button from "components/button"
import SubmitButton from "components/submit-button"
import FormikInput from "components/forms/formik-input"
import BatchForm from "components/batch-form"
import SmallFormModal, {
  ModalFooter,
  ModalBody,
} from "components/small-form-modal"
import Label from "components/forms/label"
import { Field, Form } from "formik"
import * as Yup from "yup"
import BatchTagPreview from "components/batch-tag-preview"

const BaseBatchTagModal = ({
  activation,
  migration,
  cardExperience,
  onSuccess,
  onClose,
  show,
  title,
  cta,
  includeCardExperienceId = false,
}) => {
  if (!show) return null

  // name, activationId will be undefined if activation is undefined
  const { name, id: activationId } = activation || {}

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  })

  const {
    batchTagCtaLineOne,
    batchTagCtaLineTwo,
    batchTagShowPaymentIcons,
    batchTagCtaLineHeightMultiplier,
  } = activation || {}

  return (
    <SmallFormModal fullWidth onClose={onClose} visible={show}>
      {({ close }) => (
        <BatchForm
          formSchema={formSchema}
          generateValues={(values) => ({
            ...values,
            batchTagCtaLineOne:
              values.batchTagCtaLineOne === ""
                ? null
                : values.batchTagCtaLineOne,
            batchTagCtaLineTwo:
              values.batchTagCtaLineTwo === ""
                ? null
                : values.batchTagCtaLineTwo,
            // conditionally include cardExperienceId key
            ...(includeCardExperienceId && {
              cardExperienceId: cardExperience.id,
            }),
          })}
          initialValues={{
            activationId,
            name,
            batchTagCtaLineHeightMultiplier:
              batchTagCtaLineHeightMultiplier || 1,
            batchTagCtaLineOne: batchTagCtaLineOne || "Scan Me",
            batchTagCtaLineTwo: batchTagCtaLineTwo || "",
            batchTagShowPaymentIcons: batchTagShowPaymentIcons || false,
          }}
          mutation={migration}
          onSuccess={onSuccess}
          useBrandKey
        >
          {({ isSubmitting, values }) => (
            <Form>
              <ModalBody>
                <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                  {title}
                </h3>
                <p>
                  Points to <b>{cardExperience.name}</b>
                </p>
                <div className="grid grid-cols-2 mt-4">
                  <div className="grid grid-rows-1 gap-3">
                    <FormikInput
                      label="Name (internal)"
                      name="name"
                      type="text"
                    />
                    <FormikInput
                      label="CTA Line One"
                      name="batchTagCtaLineOne"
                      type="text"
                    />
                    <FormikInput
                      label="CTA Line Two"
                      name="batchTagCtaLineTwo"
                      type="text"
                    />
                    {/* TODO: make this into a color selector
                    <FormikInput
                      label="Tag Foreground Color"
                      name="batchTagForegroundColor"
                      type="text"
                    />
                    TODO: make this into a color selector
                    <FormikInput
                      label="Tag Background Color"
                      name="batchTagBackgroundColor"
                      type="text"
                    /> */}
                    <div className="flex">
                      <Field
                        className="mr-2"
                        name="batchTagShowPaymentIcons"
                        type="checkbox"
                      />
                      <Label
                        displayName="Show Payment Icons?"
                        inputName="batchTagShowPaymentIcons"
                      />
                    </div>
                    <hr />
                    <div className="grid grid-rows-1 gap-1 bg-orange-50 p-2 rounded">
                      <Label
                        displayName="Font Size Multiplier"
                        inputName="batchTagCtaLineHeightMultiplier"
                      />
                      <Field
                        max="1.5"
                        min="0.8"
                        name="batchTagCtaLineHeightMultiplier"
                        step="0.01"
                        type="range"
                      />
                      <small>
                        Currently set to:{" "}
                        {values.batchTagCtaLineHeightMultiplier}
                      </small>
                      <small className="text-orange-800">
                        Only use this slider if you have design approval to
                        alter the font size!
                      </small>
                    </div>
                  </div>
                  <div>
                    <BatchTagPreview />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <SubmitButton cta={cta} submitting={isSubmitting} />
                <Button className="mr-2" onClick={close}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </BatchForm>
      )}
    </SmallFormModal>
  )
}

export default BaseBatchTagModal
