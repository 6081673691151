import React from "react"

const Label = ({ displayName, inputName, noLabel }) => {
  if (noLabel) return null

  return (
    <label
      className="block text-xs font-medium text-black capitalize font-bold"
      htmlFor={inputName}
    >
      {displayName}
    </label>
  )
}

export default Label
