import React from "react"
import Drawer from "components/drawer"
import FormikInput from "components/forms/formik-input"
import { Form } from "formik"
import Select from "components/forms/select"
import SubmitButton from "components/submit-button"
import * as Yup from "yup"
import BatchForm from "components/batch-form"
import { useEditor } from "contexts/experience-v2-editor"
import isColorLight from "utils/is-color-light"
import { UPDATE_CARD_VARIATION_DISCOUNT } from "./queries"

const formSchema = Yup.object().shape({
  discount: Yup.number()
    .positive()
    .when("discountMethod", {
      is: "percent",
      then: Yup.number().max(100).min(0),
    }),
})

const EditDiscountDrawer = ({ isOpen, onClose, cardVariationCard }) => {
  const { refetchCardExperience, currentCard } = useEditor()

  // Badge color has to be the opposite of card background for contrast
  const initializeBadgeColor = () =>
    isColorLight(currentCard.expandedBackgroundColor) ? "dark" : "light"

  return (
    <Drawer
      className="w-1/3 p-8"
      isOpen={isOpen}
      onClose={onClose}
      title="Edit discount"
    >
      <BatchForm
        enableReinitialize
        formSchema={formSchema}
        generateValues={(values) => ({
          ...values,
          discount: parseFloat(values.discount),
          cardVariationCardId: cardVariationCard.id,
          discountMethod: values.discountMethod,
        })}
        initialValues={{
          discount: cardVariationCard.discount,
          discountMethod: cardVariationCard.discountMethod,
          discountBadgeColor:
            cardVariationCard.discountBadgeColor || initializeBadgeColor(),
        }}
        mutation={UPDATE_CARD_VARIATION_DISCOUNT}
        onSuccess={() => {
          refetchCardExperience()
          onClose()
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <div className="flex flex-col gap-2">
              <Select
                label="Discount Type"
                name="discountMethod"
                options={{
                  "": "No Discount",
                  percentage: "Percent",
                  fixed_amount: "Dollar",
                }}
              />
              {values.discountMethod === "percentage" ? (
                <FormikInput
                  label="Percentage"
                  name="discount"
                  placeholder="10"
                  step="1"
                  type="number"
                />
              ) : (
                <FormikInput
                  label="Dollar amount"
                  name="discount"
                  placeholder="5.00"
                  step="0.01"
                  type="number"
                />
              )}
              <Select
                label="Badge theme"
                name="discountBadgeColor"
                options={{
                  dark: "Dark",
                  light: "Light",
                }}
              />

              <SubmitButton cta="Save" submitting={isSubmitting} />
            </div>
          </Form>
        )}
      </BatchForm>
    </Drawer>
  )
}

export default EditDiscountDrawer
