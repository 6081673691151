import React, { useEffect, useState } from "react"
import { Form } from "formik"
import * as Yup from "yup"
import { useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"

import BatchForm from "components/batch-form"
import FormikInput from "components/forms/formik-input"
import Input from "components/forms/input"
import Select from "components/forms/select"
import ImagePicker from "components/image-picker"
import SubmitButton from "components/submit-button"

import {
  PrimaryButton,
  RedButton,
  RedButtonWithConfirmation,
  SecondaryButton,
} from "components/button"
import {
  CREATE_OR_UPDATE_ACTIVATION_APPLE_APP_CLIP,
  RESET_ACTIVATION_APPLE_APP_CLIP,
  SYNC_ACTIVATION_APPLE_APP_CLIP,
  UPDATE_ACTIVATION_APPLE_APP_CLIP_STATUS,
} from "./queries"

const convertArrayToObject = (array) =>
  array.reduce((acc, cur) => {
    acc[cur] = cur
    return acc
  }, {})

const offsetDateByHours = (date, offsetHours) => {
  const dateObject = new Date(date)
  return dateObject.setTime(dateObject.getTime() + offsetHours * 60 * 60 * 1000)
}

const convertTimezone = (date) => {
  const tzString = Intl.DateTimeFormat().resolvedOptions().timeZone
  return new Date(date).toLocaleString("en-US", {
    timeZone: tzString,
    timeZoneName: "short",
  })
}

const AppleForm = ({ activation, refetchActivation }) => {
  const MAX_TITLE_LENGTH = 30
  const MAX_SUBTITLE_LENGTH = 56
  const formSchema = Yup.object().shape({
    appleAppClipTitle: Yup.string().max(
      MAX_TITLE_LENGTH,
      "Title can't be over ${max} characters" // eslint-disable-line no-template-curly-in-string
    ),
    appleAppClipSubtitle: Yup.string().max(
      MAX_SUBTITLE_LENGTH,
      "Subtitle can't be over ${max} characters" // eslint-disable-line no-template-curly-in-string
    ),
    appleAppClipAction: Yup.string().required("Required"),
    appleAppClipBusinessCategory: Yup.string().required("Required"),
  })

  const {
    id: activationId,
    url,
    brand: {
      key: brandKey,
      appleAppClipTitle: brandAppleAppClipTitle,
      appleAppClipSubtitle: brandAppleAppClipSubtitle,
      appleAppClipAction: brandAppleAppClipAction,
      appleAppClipBusinessCategory: brandAppleAppClipBusinessCategory,
      appleAppClipImage: brandAppleAppClipImage,
    },
    appleAppClipAllActions,
    appleAppClipAllBusinessCategories,
    appleAppClipAppleId,
    appleAppClipActive,
    appleAppClipSubmitterAdminUser,
    appleAppClipSubmittedAt,
    appleAppClipTitle,
    appleAppClipSubtitle,
    appleAppClipAction,
    appleAppClipBusinessCategory,
    appleAppClipImage,
  } = activation

  // For simplicity, assume that image filename is the last path in the url
  const parseImageFilename = (imageSrc) => imageSrc && imageSrc.split("/").pop()

  const [imageSrc, setImageSrc] = useState(
    appleAppClipImage?.imageSrc || brandAppleAppClipImage.imageSrc
  )
  const [imageFilename, setImageFilename] = useState(
    parseImageFilename(appleAppClipImage?.imageSrc)
  )
  useEffect(() => {
    setImageFilename(parseImageFilename(imageSrc))
  }, [imageSrc])

  const { addToast } = useToasts()
  const [updateActivationAppClipStatus, { loading: updating }] = useMutation(
    UPDATE_ACTIVATION_APPLE_APP_CLIP_STATUS,
    {
      onError: () => {
        addToast("Something went wrong, changes did not save!", {
          appearance: "error",
        })
      },
      onCompleted: () => {
        addToast("Successfully updated App Clip status", {
          appearance: "success",
        })
        refetchActivation()
      },
    }
  )

  const [resetActivationAppClipStatus, { loading: resetting }] = useMutation(
    RESET_ACTIVATION_APPLE_APP_CLIP,
    {
      onError: () => {
        addToast("Something went wrong, changes did not save!", {
          appearance: "error",
        })
      },
      onCompleted: () => {
        addToast("Successfully reset App Clip", {
          appearance: "success",
        })
        refetchActivation()
      },
    }
  )

  const [syncActivationAppClipStatus, { loading: syncing }] = useMutation(
    SYNC_ACTIVATION_APPLE_APP_CLIP,
    {
      onError: () => {
        addToast("Something went wrong, changes did not save!", {
          appearance: "error",
        })
      },
      onCompleted: () => {
        addToast("Successfully synced App Clip", {
          appearance: "success",
        })
        refetchActivation()
      },
    }
  )

  const appClipActionOptions = convertArrayToObject(appleAppClipAllActions)
  const appClipBusinessCategoryOptions = convertArrayToObject(
    appleAppClipAllBusinessCategories
  )

  const qrCodeActiveAt = offsetDateByHours(appleAppClipSubmittedAt, 24)
  const qrCodeActive = qrCodeActiveAt <= new Date()
  const qrCodeActiveAtTextColor = qrCodeActive
    ? "text-green-600"
    : "text-red-600"

  const appClipStatus = appleAppClipActive
    ? "App Clip Active"
    : "App Clip Inactive"
  const appClipStatusColor = appleAppClipActive ? "green-600" : "red-600"
  const qrCodeStatus = qrCodeActive ? "QR Code Active" : "QR Code Pending"
  const qrCodeStatusColor = qrCodeActive ? "green-600" : "indigo-600"

  const deactivateButton = (
    <RedButton
      onClick={() =>
        updateActivationAppClipStatus({
          variables: {
            input: {
              activationId,
              brandKey,
              active: false,
            },
          },
        })
      }
      submitting={updating}
    >
      Deactivate App Clip
    </RedButton>
  )

  const reactivateButton = (
    <PrimaryButton
      onClick={() =>
        updateActivationAppClipStatus({
          variables: {
            input: {
              activationId,
              brandKey,
              active: true,
            },
          },
        })
      }
      submitting={updating}
    >
      Reactivate App Clip
    </PrimaryButton>
  )

  const resetButton = (
    <RedButtonWithConfirmation
      onClick={() =>
        resetActivationAppClipStatus({
          variables: {
            input: {
              activationId,
              brandKey,
            },
          },
        })
      }
      submitting={resetting}
    >
      Reset App Clip
    </RedButtonWithConfirmation>
  )

  const syncButton = (
    <SecondaryButton
      onClick={() =>
        syncActivationAppClipStatus({
          variables: {
            input: {
              activationId,
              brandKey,
            },
          },
        })
      }
      submitting={syncing}
    >
      Sync App Clip
    </SecondaryButton>
  )

  return (
    <BatchForm
      enableReinitialize
      formSchema={formSchema}
      generateValues={(values) => ({
        ...values,
        imageFilename,
        imageSrc,
      })}
      initialValues={{
        activationId,
        appleAppClipTitle: appleAppClipTitle || brandAppleAppClipTitle,
        appleAppClipSubtitle: appleAppClipSubtitle || brandAppleAppClipSubtitle,
        appleAppClipAction: appleAppClipAction || brandAppleAppClipAction,
        appleAppClipBusinessCategory:
          appleAppClipBusinessCategory || brandAppleAppClipBusinessCategory,
      }}
      mutation={CREATE_OR_UPDATE_ACTIVATION_APPLE_APP_CLIP}
      onSuccess={refetchActivation}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col">
              <div className="mb-2">
                <div className="mb-2">
                  <p className="text-xs uppercase">
                    Status:{" "}
                    <span
                      className={`px-2 py-1 mr-1 mb-1 text-white text-md font-medium rounded-md bg-${appClipStatusColor}`}
                    >
                      {appClipStatus}
                    </span>
                    {appleAppClipActive && (
                      <span
                        className={`px-2 py-1 mr-1 mb-1 text-white text-md font-medium rounded-md bg-${qrCodeStatusColor}`}
                      >
                        {qrCodeStatus}
                      </span>
                    )}
                  </p>
                </div>

                <div className="mb-2">
                  {appleAppClipSubmitterAdminUser && (
                    <p className="text-xs text-gray-800">
                      Last Submitted By:{" "}
                      {appleAppClipSubmitterAdminUser.fullName}
                    </p>
                  )}
                  <p className="text-xs text-gray-800">
                    Last Submitted At:{" "}
                    {convertTimezone(appleAppClipSubmittedAt)}
                  </p>
                  <p className="text-xs text-gray-800">
                    QR Code Active At:{" "}
                    <span className={`${qrCodeActiveAtTextColor}`}>
                      {convertTimezone(qrCodeActiveAt)}{" "}
                    </span>
                    (Estimated)
                  </p>
                </div>
              </div>

              {appleAppClipAppleId && (
                <>
                  <div className="mb-2">
                    <a
                      className="text-sm text-blue-600"
                      href={`https://appstoreconnect.apple.com/appclips/ui/app-experience-review/${appleAppClipAppleId}`}
                    >
                      Link to App Store Connect
                    </a>
                  </div>
                  <div className="mb-6 flex justify-between">
                    {syncButton}
                    {resetButton}
                    {appleAppClipActive ? deactivateButton : reactivateButton}
                  </div>
                  <div className="border-t mb-6" />
                </>
              )}
              <div className="mb-2">
                <FormikInput
                  color={appleAppClipTitle && "text-red-600"}
                  label={`App Clip Title ${
                    appleAppClipTitle ? "(OVERRIDDEN)" : "(BRAND DEFAULT)"
                  }`}
                  limit={MAX_TITLE_LENGTH}
                  name="appleAppClipTitle"
                  type="text"
                />
              </div>
              <div className="mb-2">
                <FormikInput
                  color={appleAppClipSubtitle && "text-red-600"}
                  label={`App Clip Subtitle ${
                    appleAppClipSubtitle ? "(OVERRIDDEN)" : "(BRAND DEFAULT)"
                  }`}
                  limit={MAX_SUBTITLE_LENGTH}
                  name="appleAppClipSubtitle"
                  type="text"
                />
              </div>
              <div className="mb-2">
                <Select
                  class={appleAppClipAction && "text-red-600"}
                  label={`App Clip Action ${
                    appleAppClipAction ? "(OVERRIDDEN)" : "(BRAND DEFAULT)"
                  }`}
                  name="appleAppClipAction"
                  options={appClipActionOptions}
                  type="text"
                />
              </div>
              <div className="mb-2">
                <Select
                  class={appleAppClipBusinessCategory && "text-red-600"}
                  label={`App Clip Business Category ${
                    appleAppClipBusinessCategory
                      ? "(OVERRIDDEN)"
                      : "(BRAND DEFAULT)"
                  }`}
                  name="appleAppClipBusinessCategory"
                  options={appClipBusinessCategoryOptions}
                  type="text"
                />
              </div>
              <div className="mb-6">
                <Input
                  disabled
                  label="Activation Url"
                  showCopyButton
                  type="text"
                  value={url}
                />
              </div>

              <div className="border-t mb-6" />

              <ImagePicker
                brandKey={brandKey}
                imageUrl={imageSrc}
                inputName="imageSrc"
                label={`App Clip Card Image ${
                  appleAppClipImage ? "(OVERRIDDEN)" : "(BRAND DEFAULT)"
                }`}
                note="Requirements: 1800 x 1200 pixels, PNG or JPEG without Transparency"
                onClearButtonPressed={() => setImageSrc("")}
                onImageUploadComplete={(newImageUrl) => {
                  setImageSrc(newImageUrl)
                }}
                preview
                showClearButton
                uploadFolder={`activations/${activationId}/apple_app_clip_image_src`}
              />
              <SubmitButton
                cta="Submit to App Store Connect"
                submitting={isSubmitting}
              />
            </div>
          </div>
        </Form>
      )}
    </BatchForm>
  )
}

export default AppleForm
