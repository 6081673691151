import React from "react"
import { ReactComponent as ToggleXIcon } from "assets/icons/toggle-x.svg"
import { ReactComponent as ToggleCheckIcon } from "assets/icons/toggle-check.svg"

const ToggleWithIcon = ({ active, onToggle }) => (
  <button
    aria-pressed="false"
    className={`${
      active ? "bg-indigo-600" : "bg-gray-200"
    } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none`}
    onClick={onToggle}
    type="button"
  >
    <span className="sr-only">Use setting</span>
    <span
      className={`${
        active ? "translate-x-5" : "translate-x-0"
      } relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
    >
      <span
        aria-hidden="true"
        className={`${
          active
            ? "opacity-0 ease-out duration-100"
            : "opacity-100 ease-in duration-200"
        } absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
      >
        <ToggleXIcon className="h-3 w-3 text-gray-400" />
      </span>
      <span
        aria-hidden="true"
        className={`${
          active
            ? "opacity-100 ease-in duration-200"
            : "opacity-0 ease-out duration-100"
        } absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
      >
        <ToggleCheckIcon className="h-3 w-3 text-indigo-600" />
      </span>
    </span>
  </button>
)

export default ToggleWithIcon
