import { DownloadIcon } from "@heroicons/react/outline"
import React from "react"

const generateFilename = (activationName, nbeName) =>
  `${nbeName}--${activationName}`.replace(/[^a-z0-9]/gi, "_").toLowerCase()

const Card = ({ activation, experience }) => {
  const { id, name, url, batchTagSvgUrl, qrCodeSvgUrl, fallbackUrl } =
    activation
  return (
    <li
      key={id}
      className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
    >
      <div className="flex-1 flex flex-col p-8">
        <p className="text-gray-400 text-md font-normal">Activation Name:</p>
        <h3 className="mb-4 text-gray-900 text-lg font-medium">{name}</h3>
        <div className="h-24 w-24 mx-auto">
          <img
            alt={`${name} QR Code URL`}
            className="object-contain w-24"
            src={batchTagSvgUrl || qrCodeSvgUrl}
            style={{ maxHeight: "6rem" }} // h-24
          />
        </div>
        <div className="mt-4">
          <a
            download={generateFilename(name, experience.name)}
            href={batchTagSvgUrl || qrCodeSvgUrl}
          >
            <button
              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              type="button"
            >
              <span className="sr-only">Download QR code.</span>
              <DownloadIcon className="w-4 h-4" />
            </button>
          </a>
        </div>
        <p className="text-sm mt-4">
          <span className="text-gray-500 text-sm mt-1 block">
            Activation URL:
          </span>
          <code className="break-all">{url}</code>
        </p>
        <p className="text-sm mt-4">
          <span className="text-gray-500 text-sm mt-1 block">
            Fallback URL:
          </span>
          <code className="break-all">{fallbackUrl}</code>
        </p>
      </div>
    </li>
  )
}
export default Card
