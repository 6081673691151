import React, { useState } from "react"
import { useMutation } from "@apollo/client"
import { Redirect } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Form } from "formik"
import * as Yup from "yup"

import BatchForm from "components/batch-form"
import FormikInput from "components/forms/formik-input"
import Select from "components/forms/select"
import SubmitButton from "components/submit-button"
import RedButtonWithConfirmation from "components/button/red-button-with-confirmation"

import { UPDATE_ACTIVATION, DELETE_ACTIVATION } from "./queries"
import QrCodes from "./qr-codes"

const ExternalUrlForm = ({
  activation,
  destination,
  refetchActivation,
  setDestination,
}) => {
  const { addToast } = useToasts()
  const [redirectUrl, setRedirectUrl] = useState(undefined)
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    destination: Yup.string().required("Required"),
  })

  const {
    id,
    name,
    brand: { key: brandKey },
    category,
    availableCategories,
    fallbackUrl,
  } = activation

  const [deleteActivationMutation] = useMutation(DELETE_ACTIVATION, {
    variables: {
      input: {
        activationId: activation.id,
        brandKey,
      },
    },
    onCompleted: () => {
      addToast("Successfully deleted activation", { appearance: "success" })
      setRedirectUrl(`/brands/${brandKey}/activations`)
    },
    onError: (error) => {
      addToast(`Could not delete activation: ${error.message}`, {
        appearance: "error",
      })
    },
  })

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />
  }

  return (
    <BatchForm
      enableReinitialize
      formSchema={formSchema}
      generateValues={(values) => ({
        ...values,
        category: values.category === "null" ? null : values.category,
      })}
      initialValues={{
        activationId: id,
        name,
        category,
        fallbackUrl,
        destination,
      }}
      mutation={UPDATE_ACTIVATION}
      onSuccess={refetchActivation}
    >
      {({ isSubmitting }) => (
        <div className="mb-8">
          <Form>
            <div className="grid grid-cols-8">
              <div className="col-span-8 my-4">
                <h2 className="text-xl tracking-tight">General</h2>
              </div>
              <div className="col-span-2 mb-4">
                <Select
                  label="Destination"
                  name="destination"
                  onChange={setDestination}
                  options={{
                    external_url: "External URL",
                    grid: "Grid",
                    card: "Card",
                  }}
                />
              </div>
              <div className="col-span-6" />
              <div className="col-span-4 mb-4">
                <FormikInput label="Title" name="name" type="text" />
              </div>
              <div className="col-span-4" />

              <div className="col-span-2 mb-4">
                <FormikInput
                  label="External Url"
                  name="fallbackUrl"
                  type="text"
                />
              </div>
              <div className="col-span-6" />

              <div className="col-span-4 mb-4">
                <p className="text-sm font-semibold text-gray-900">
                  Current External URL
                </p>
                <a
                  className="text-sm text-blue-600"
                  href={fallbackUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {fallbackUrl}
                </a>
              </div>
              <div className="col-span-4" />

              <div className="col-span-2 mb-4">
                <Select
                  label="Category (Internal)"
                  name="category"
                  options={{
                    null: "(None)",
                    ...availableCategories,
                  }}
                />
              </div>
              <div className="col-span-6" />

              <div className="col-span-8">
                <RedButtonWithConfirmation
                  onClick={deleteActivationMutation}
                  submitting={isSubmitting}
                >
                  Delete
                </RedButtonWithConfirmation>

                <SubmitButton
                  className="ml-2"
                  cta="Save"
                  submitting={isSubmitting}
                />
              </div>
            </div>
            <QrCodes
              activation={activation}
              refetchActivation={refetchActivation}
            />
          </Form>
        </div>
      )}
    </BatchForm>
  )
}

export default ExternalUrlForm
