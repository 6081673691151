import React from "react"
import { Link } from "react-router-dom"
import useQueryParams from "utils/use-query-params"

const BackLink = ({ backTo: passedBackTo, backText: passedBackText }) => {
  const query = useQueryParams()
  const backTo = passedBackTo || query.get("backTo")
  const backText = passedBackText || query.get("backText")

  if (!backTo || !backText) return <div>&nbsp;</div>
  return (
    <Link
      className="font-normal text-xs leading-tight text-blue-500"
      to={backTo}
    >
      &larr; {backText}
    </Link>
  )
}

export default BackLink
