import React from "react"
import { DownloadIcon } from "@heroicons/react/outline"

const QrCodeDownloadLink = ({ filename, assetUrl }) => (
  <a download={filename} href={assetUrl}>
    <button
      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      type="button"
    >
      <span className="sr-only">Download QR code.</span>
      <DownloadIcon className="w-4 h-4" />
    </button>
  </a>
)

export default QrCodeDownloadLink
