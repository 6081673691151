import BrandShow from "app/admin/new-brand-show"
import { useAdminAuthentication } from "contexts/admin-authentication"
import React, { useEffect } from "react"

/* TODO: Remove this file when brand migrations are merged into the admin namespace and we can have a single entrypoint */
const AdminBrandShow = () => {
  const { currentAdminUser, authAndLoadAdminUser } = useAdminAuthentication()

  useEffect(() => {
    authAndLoadAdminUser()
  }, [currentAdminUser, authAndLoadAdminUser])

  if (!currentAdminUser) {
    return null
  }

  return <BrandShow />
}

export default AdminBrandShow
