import gql from "graphql-tag"

const CREATE_ACTIVATION_LINK_MUTATION = gql`
  mutation CreateActivationLink($input: CreateActivationLinkInput!) {
    createActivationLink(input: $input) {
      errors
      activation {
        id
      }
    }
  }
`
export { CREATE_ACTIVATION_LINK_MUTATION }
export default CREATE_ACTIVATION_LINK_MUTATION
