import React from "react"
import { useAdminAuthentication } from "contexts/admin-authentication"

const UserMenu = () => {
  const { logAdminUserOut } = useAdminAuthentication()
  //
  // <a href="#" className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
  //   Your Profile
  // </a>
  //
  // <a href="#" className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
  //   Account Settings
  // </a>
  //

  return (
    <div
      aria-labelledby="user-menu"
      aria-orientation="vertical"
      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
      role="menu"
    >
      <button
        className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
        onClick={logAdminUserOut}
        role="menuitem"
        type="button"
      >
        Sign out
      </button>
    </div>
  )
}
export default UserMenu
