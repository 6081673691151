import React from "react"
import IndexTable from "components/index-table"

import sortAlphabetically from "utils/sort-alphabetically"
import { PrimaryButton } from "components/button"
import DeleteBrandUserButton from "../delete-brand-user-button"

const List = ({ onOpenBrandUserModal, brand, refetchBrandUsers }) => {
  const { brandUsers } = brand

  const tableData = brandUsers.sort(sortAlphabetically)

  return (
    <IndexTable
      columns={[
        {
          title: "Name",
          field: "name",
          cellStyle: { whiteSpace: "nowrap", width: "20%" },
          customFilterAndSearch: (term, row) =>
            (row.firstName &&
              row.firstName.toLowerCase().includes(term.toLowerCase())) ||
            (row.lastName &&
              row.lastName.toLowerCase().includes(term.toLowerCase())),
          render: ({ firstName, lastName }) => (
            <>
              <span>
                {firstName} {lastName}
              </span>
            </>
          ),
        },
        {
          title: "Email ID",
          field: "email",
          cellStyle: { whiteSpace: "nowrap", width: "20%" },
          customFilterAndSearch: (term, row) =>
            row.email.toLowerCase().includes(term.toLowerCase()),
          render: ({ email }) => (
            <>
              <span>{email}</span>
            </>
          ),
        },
        {
          title: "Actions",
          cellStyle: { whiteSpace: "nowrap", width: "10%" },
          disableClick: true,
          render: (brandUser) => (
            <>
              <PrimaryButton
                className="h-6 w-6 mr-2 text-gray-600"
                onClick={() => onOpenBrandUserModal(brandUser)}
              >
                Edit
              </PrimaryButton>

              <DeleteBrandUserButton
                brandUserId={brandUser.id}
                refetchBrandUsers={refetchBrandUsers}
              />
            </>
          ),
        },
      ]}
      ctaText="Create a New User"
      data={tableData}
      onCtaClick={onOpenBrandUserModal}
      title="Users"
    />
  )
}

export default List
