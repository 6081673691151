import React, { useState } from "react"
import { Form } from "formik"
import * as Yup from "yup"
import BatchForm from "components/batch-form"
import SubmitButton from "components/submit-button"
import ImagePicker from "components/image-picker"
import { UPDATE_EXPERIENCE_BACKGROUND_IMAGE_MUTATION } from "./queries"

const BackgroundImageForm = ({ currentExperience, refetchExperience }) => {
  const { backgroundImageSrc, id: experienceId } = currentExperience
  const [imageUrl, setImageUrl] = useState(backgroundImageSrc)

  const onImageUploadComplete = (url) => {
    setImageUrl(url)
  }

  const formSchema = Yup.object().shape({
    backgroundImageSrc: Yup.string().url(),
  })

  return (
    <BatchForm
      formSchema={formSchema}
      generateValues={() => ({
        imageUrl,
        experienceId,
      })}
      initialValues={{
        backgroundImageSrc,
      }}
      mutation={UPDATE_EXPERIENCE_BACKGROUND_IMAGE_MUTATION}
      onSuccess={() => refetchExperience()}
    >
      {({ isSubmitting }) => (
        <Form>
          <ImagePicker
            imageUrl={imageUrl}
            inputName="backgroundImageSrc"
            label="Background Image"
            onImageUploadComplete={onImageUploadComplete}
            uploadFolder={`experiences/${experienceId}/background_image`}
          />
          <SubmitButton submitting={isSubmitting} />
        </Form>
      )}
    </BatchForm>
  )
}

export default BackgroundImageForm
