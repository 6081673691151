import { useFormikContext } from "formik"
import React, { useEffect, useCallback } from "react"
import GRADIENTS from "./gradients"
import Square from "./square"

const GradientSelector = ({
  gradientFieldName = "backgroundGradient",
  textFieldName = "textColor",
}) => {
  const { values, setFieldValue } = useFormikContext()

  const handleSelect = useCallback(
    (props) => {
      setFieldValue(gradientFieldName, props.gradient)
      setFieldValue(textFieldName, props.textColor)
    },
    [gradientFieldName, textFieldName, setFieldValue]
  )

  useEffect(() => {
    if (!values[gradientFieldName].length) {
      const { textColor, gradient } = GRADIENTS[0]
      handleSelect({ textColor, gradient })
    }
  }, [gradientFieldName, textFieldName, values, handleSelect])

  const toStringUppercase = (array) => array.toString().toUpperCase()

  return (
    <div className="flex flex-col">
      <h2 className="block text-xs font-medium text-black capitalize font-bold">
        Gradient color
      </h2>
      <div className="grid grid-rows-2 grid-flow-col max-w-xs">
        {GRADIENTS.map(({ textColor, gradient, key }) => (
          <Square
            key={key}
            gradient={gradient}
            isSelected={
              toStringUppercase(values[gradientFieldName]) ===
              toStringUppercase(gradient)
            }
            onClick={() => handleSelect({ textColor, gradient })}
            textColor={textColor}
          />
        ))}
      </div>
    </div>
  )
}

export default GradientSelector
