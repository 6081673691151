import React from "react"
import { ReactComponent as LoadingIcon } from "assets/icons/button-spinner.svg"
import classnames from "classnames"

const roundingStyles = {
  all: "rounded-md ",
  right: "rounded-r-md rounded-l-none",
  left: "rounded-l-md rounded-r-none",
}

const Button = ({
  onClick,
  order = 1,
  className = "",
  buttonClassName = "",
  type = "button",
  children,
  submitting = false,
  disabled = false,
  rounding = "all",
  ...rest
}) => (
  /* eslint-disable react/button-has-type */
  <span className={`order-${order} sm:order-${order} ${className}`}>
    <button
      className={classnames(
        "shadow-sm inline-flex items-center font-medium focus:outline-none transition duration-150 ease-in-out leading-5",
        roundingStyles[rounding],
        disabled && "cursor-not-allowed",
        buttonClassName
      )}
      disabled={disabled}
      onClick={onClick}
      type={type}
      {...rest}
    >
      {submitting && <LoadingIcon className="w-4 h-4 mr-2 pt-1" />}
      {children}
    </button>
  </span>
)

export default Button
