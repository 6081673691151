import React from "react"
import { ApolloProvider } from "@apollo/client"
import client from "config/apollo-share"
import Container from "components/layout/container"
import ShareRouter from "./share-router"

const Brand = () => (
  <ApolloProvider client={client}>
    <Container>
      <div className="relative">
        <ShareRouter />
      </div>
    </Container>
  </ApolloProvider>
)

export default Brand
