import React from "react"
import FormikInput from "components/forms/formik-input"

const colorInputMask = (value) => {
  let newValue = value || ""
  // Don't allow non-hex values
  newValue = newValue.replace("#", "").replace(/[^a-fA-F0-9]/g, "")
  return newValue.substring(0, 6).toUpperCase() // Force uppercase
}

const ColorSelector = ({
  label,
  name,
  placeholder = "000000",
  color,
  onChange,
  isTextOnly,
  ...rest
}) => (
  <FormikInput
    {...rest}
    isTextOnly={isTextOnly}
    label={label}
    mask={colorInputMask}
    name={name}
    onChange={onChange}
    placeholder={placeholder}
    prefix={
      <span
        className="inline-flex items-center px-3 py-2 rounded-l-md border-r border-gray-300 bg-gray-50 text-gray-500 text-sm"
        style={{
          background: `#${color}`,
        }}
      >
        &nbsp;
      </span>
    }
    type="text"
    value={color}
  />
)

export default ColorSelector
