import React from "react"
import MaterialTable from "material-table"

const Table = (props) => {
  const { options } = props
  return (
    <MaterialTable
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      options={{
        paging: false,
        search: false,
        showTitle: false,
        filtering: true,
        toolbar: false,
        headerStyle: {
          textTransform: "uppercase",
          fontSize: "0.75em",
          lineHeight: "1em",
          letterSpacing: "0.2ch",
          color: "#555",
          fontWeight: "bold",
        },
        ...options,
      }}
    />
  )
}

export default Table
