import gql from "graphql-tag"

// brand is inferred from current user
const GET_EXPERIENCES = gql`
  query GetExperiences($brandKey: String!) {
    brand(brandKey: $brandKey) {
      id
      name
      shopifyDomain
      homepage
      key
      coreAppCardExperience {
        id
        name
      }
      cardExperiences {
        id
        logoHorizontalSrc
        batchForWebEnabled
        notificationsFeature
        name
        hidden
        internalNickname
        activations {
          id
        }
        productVariants {
          deletedFromShopify
        }
        cardExperienceCards {
          ordinal
          card {
            collapsedBackgroundImageSrc
          }
        }
      }
    }
  }
`

const CREATE_BLANK_CARD_EXPERIENCE_MUTATION = gql`
  mutation CreateBlankCardExperience($input: CreateBlankCardExperienceInput!) {
    createBlankCardExperience(input: $input) {
      cardExperience {
        id
      }
      errors
    }
  }
`

const UPDATE_CARD_EXPERIENCE = gql`
  mutation UpdateCardExperience($input: UpdateCardExperienceInput!) {
    updateCardExperience(input: $input) {
      errors
      cardExperience {
        id
      }
    }
  }
`

export {
  GET_EXPERIENCES,
  CREATE_BLANK_CARD_EXPERIENCE_MUTATION,
  UPDATE_CARD_EXPERIENCE,
}
