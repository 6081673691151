import React, { createContext, useContext, useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"

import { GET_CARD_EXPERIENCE } from "./queries"

const EditorExperienceV2Context = createContext(undefined)

const EditorV2Provider = ({ children }) => {
  const { cardExperienceId, brandKey } = useParams()

  const [currentCardExperience, setCurrentCardExperience] = useState(null)

  const [isGridView, setGridView] = useState(true)

  const [currentCard, setCurrentCard] = useState(null)
  const [currentCardOrdinal, setCurrentCardOrdinal] = useState(null)
  const [isLaunchCard, setIsLaunchCard] = useState(false)

  const [currentCardVariation, setCurrentCardVariation] = useState(null)

  const { data, refetch } = useQuery(GET_CARD_EXPERIENCE, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
    variables: { id: cardExperienceId, brandKey },
  })

  const changeCurrentCardVariationWithId = (id) => {
    const newCardVariation = currentCard.cardVariations.find(
      (variation) => variation.id === id
    )
    return newCardVariation && setCurrentCardVariation(newCardVariation)
  }

  useEffect(() => {
    setCurrentCardExperience(data?.cardExperience)

    if (isLaunchCard) {
      setCurrentCard(data?.cardExperience?.launchCard)
    } else {
      const cardAtCurrentOrdinal =
        currentCardOrdinal &&
        data?.cardExperience?.cardExperienceCards.find(
          (cec) => cec.ordinal === currentCardOrdinal
        )?.card

      setCurrentCard(cardAtCurrentOrdinal || null)
    }
  }, [currentCardOrdinal, data, isLaunchCard])

  useEffect(() => {
    setCurrentCardVariation(currentCard?.cardVariations[0])
  }, [currentCard])

  return (
    <EditorExperienceV2Context.Provider
      value={{
        cardExperienceId,
        currentCardExperience,
        currentCard,
        setCurrentCard,
        currentCardOrdinal,
        setCurrentCardOrdinal,
        currentCardVariation,
        changeCurrentCardVariationWithId,
        isGridView,
        setGridView,
        isLaunchCard,
        setIsLaunchCard,
        refetchCardExperience: refetch,
      }}
    >
      {currentCardExperience && children}
    </EditorExperienceV2Context.Provider>
  )
}

const useEditor = () => {
  const context = useContext(EditorExperienceV2Context)

  if (context === undefined) {
    throw new Error("useEditor must be used within a EditorV2Provider")
  }

  return context
}

export { useEditor, EditorV2Provider }
