import { gql } from "@apollo/client"

export const LOGIN_ADMIN_USER_FIELDS = `
  id
  firstName
  lastName
  fullName
  batchAuthToken
  avatarUrl
  email
  phone
`

export const GET_CURRENT_ADMIN_USER_QUERY = gql`
  query GetCurrentAdminUser {
    currentAdminUser {
      ${LOGIN_ADMIN_USER_FIELDS}
    }
  }
`
