import { gql } from "@apollo/client"
import { LOGIN_ADMIN_USER_FIELDS } from "contexts/admin-authentication"

export const LOGOUT_ADMIN_USER_MUTATION = gql`
  mutation Logout($input: LogoutInput!) {
    logout(input: $input) {
      errors
    }
  }
`

export const LOGIN_WITH_PASSWORD_MUTATION = gql`
  mutation LoginWithPassword($input: LoginWithPasswordInput!) {
    loginWithPassword(input: $input) {
      user {
        ${LOGIN_ADMIN_USER_FIELDS}
      }
    }
  }
`
