import React from "react"
import { Form } from "formik"
import * as Yup from "yup"

import BatchForm from "components/batch-form"
import { useEditor } from "contexts/experience-editor"
import SubmitButton from "components/submit-button"
import FormikInput from "components/forms/formik-input"
import Textarea from "components/forms/textarea"
import Select from "components/forms/select"

import { UPDATE_DISCOUNT_SECTION_MUTATION } from "./queries"

const DiscountSection = ({ experienceSectionDetails }) => {
  const { refetchExperience } = useEditor()
  const {
    id,
    discountMethod,
    discountMagnitude,
    subtitle,
    title,
    smsDisclaimer,
  } = experienceSectionDetails

  const formSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    subtitle: Yup.string().required("Required"),
    discountMethod: Yup.string().required("Required"),
    discountMagnitude: Yup.number().required("Required"),
    smsDisclaimer: Yup.string().required("Required"),
  })

  return (
    <BatchForm
      additionalValues={{
        experienceSectionOrderDiscountDetailsId: id,
      }}
      formSchema={formSchema}
      initialValues={{
        title,
        subtitle,
        discountMethod,
        discountMagnitude,
        smsDisclaimer,
      }}
      mutation={UPDATE_DISCOUNT_SECTION_MUTATION}
      onSuccess={() => refetchExperience()}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <div className="grid grid-cols-8">
            <div className="col-span-6 sm:col-span-4 mb-4">
              <FormikInput
                autoFocus
                label="Title"
                name="title"
                placeholder="Unlock Your Discount"
                type="text"
              />
            </div>
            <div className="col-span-2" />
            <div className="col-span-6 mb-4">
              <FormikInput
                autoFocus
                label="Subtitle"
                name="subtitle"
                placeholder="Get 10% off your order"
                type="text"
              />
            </div>
            <div className="col-span-2" />
            <div className="col-span-6 mb-4">
              <Select
                autoFocus
                label="Discount Type"
                name="discountMethod"
                options={{
                  percentage: "Percentage",
                  dollar: "Dollar Amount",
                }}
                placeholder="Percentage"
                type="text"
              />
            </div>
            <div className="col-span-2" />
            <div className="col-span-3 mb-4">
              <FormikInput
                autoFocus
                label="Discount Amount"
                mask={parseInt}
                name="discountMagnitude"
                placeholder={
                  values.discountMethod === "percentage" ? "10" : "5.00"
                }
                suffix="%"
                type="number"
              />
            </div>
            <div className="col-span-2" />
            <div className="col-span-6 mb-4">
              <Textarea
                autoFocus
                label="SMS Disclaimer"
                name="smsDisclaimer"
                placeholder="Legal copy here..."
                type="text"
              />
            </div>
            <div className="col-span-2" />
          </div>
          <SubmitButton submitting={isSubmitting} />
        </Form>
      )}
    </BatchForm>
  )
}

export default DiscountSection
