import React from "react"
import LinkToNewTab from "components/link-to-new-tab"
import DeleteCardButton from "./delete-card-button"

const CardInfo = ({
  currentCard,
  currentCardExperience,
  isLaunchCard,
  refetchCardExperience,
}) => {
  const { id, hidden } = currentCard
  const activationsShowingLaunchCard = currentCardExperience.activations.filter(
    (activation) => activation.showLaunchCard
  )
  const brandKey = currentCardExperience.brand.key

  const visibilityColor = hidden ? "bg-red-100" : "bg-green-100"
  const visibilityLabel = hidden ? "Hidden" : "Visible"

  return (
    <>
      <div className="flex justify-between items-center">
        <p className="text-gray-600 text-sm font-normal">Card ID: {id}</p>
        <DeleteCardButton
          currentCard={currentCard}
          currentCardExperience={currentCardExperience}
          isLaunchCard={isLaunchCard}
          refetchCardExperience={refetchCardExperience}
        />
      </div>
      <span
        className={`px-2 py-1 mr-1 mb-1 text-gray-800 text-xs font-medium rounded-sm ${visibilityColor}`}
      >
        {visibilityLabel}
      </span>
      {isLaunchCard && (
        <>
          <p className="text-red-400 text-xs font-bold uppercase mb-2">
            You must enable launch card in the specific activation.
          </p>
          <p className="text-gray-600 text-xs">
            Currently, {activationsShowingLaunchCard.length} activation(s) are
            showing this launch card.
          </p>
          <ul className="list-disc pl-5 font-mono">
            {activationsShowingLaunchCard.map((activation) => (
              <li key={activation.id} className="text-gray-600 text-xs">
                <LinkToNewTab
                  className="ml-3"
                  to={`/brands/${brandKey}/activations/${activation.id}`}
                >
                  {activation.name}
                </LinkToNewTab>
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  )
}

export default CardInfo
