import React from "react"
import { DownloadIcon } from "@heroicons/react/outline"

const DownloadButton = ({ kind, activationName, qrCodeSvgId }) => {
  const downloadQR = () => {
    const generateFilename = () =>
      `${kind.toUpperCase()}__${activationName}`
        .replace(/[^a-z0-9]/gi, "_")
        .toLowerCase()

    // Downloads the QR code as a svg file
    const svg = document.getElementById(qrCodeSvgId)
    const svgData = new XMLSerializer().serializeToString(svg)

    const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" })
    const svgUrl = URL.createObjectURL(svgBlob)

    const downloadLink = document.createElement("a")
    downloadLink.href = svgUrl
    downloadLink.download = generateFilename()
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <button
      className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={() => downloadQR()}
      type="button"
    >
      <span className="sr-only">Download QR code.</span>
      <DownloadIcon className="w-4 h-4" />
    </button>
  )
}

export default DownloadButton
