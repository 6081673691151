import React, { useRef } from "react"
import useTriggerClick from "utils/use-trigger-click"

const TriggeredDropdownMenu = ({
  triggerRef,
  content,
  position = "belowleft",
  dropdownWidth = 256,
  visible,
  setVisible,
}) => {
  const menuRef = useRef(null)

  const onTriggerClick = () => {
    setVisible((visibility) => !visibility)
  }

  const onOutsideClickWhenOpen = () => {
    setVisible(false)
  }

  useTriggerClick(
    triggerRef,
    menuRef,
    visible,
    onTriggerClick,
    onOutsideClickWhenOpen
  )

  let positionStyles = {
    top: 0,
    left: 0,
  }

  if (position === "belowleft") {
    positionStyles = {
      top:
        triggerRef?.current &&
        triggerRef.current.offsetTop + triggerRef.current.offsetHeight,
      left: triggerRef?.current && triggerRef.current.offsetLeft,
    }
  } else if (position === "belowright") {
    positionStyles = {
      top:
        triggerRef?.current &&
        triggerRef.current.offsetTop + triggerRef.current.offsetHeight,
      left:
        triggerRef?.current &&
        triggerRef.current.offsetLeft +
          triggerRef.current.offsetWidth -
          dropdownWidth, // 16 px per rem
    }
  } else if (position === "sidenav-avatar") {
    positionStyles = {
      bottom: 80,
      left: 12,
    }
  }

  if (!visible) return null

  return (
    <div
      ref={menuRef}
      className={`${
        position === "sidenav-avatar"
          ? "origin-bottom-left origin-bottom"
          : "origin-top-right origin-top"
      } z-10 absolute right-0 left-0 mt-1 rounded-md shadow-lg`}
      style={{
        ...positionStyles,
        width: dropdownWidth,
        zIndex: 100,
      }}
    >
      <div
        aria-labelledby="options-menu"
        aria-orientation="vertical"
        className="rounded-md bg-white shadow-xs"
        role="menu"
      >
        {content}
      </div>
    </div>
  )
}

export default TriggeredDropdownMenu
