import gql from "graphql-tag"

export const UPDATE_APP_CLIP_CARD_MUTATION = gql`
  mutation UpdateAppClipCard($input: UpdateAppClipCardInput!) {
    updateAppClipCard(input: $input) {
      errors
      experience {
        id
        appClipCardTitle
        appClipCardSubtitle
        appClipCardImageSrc
      }
    }
  }
`

export const PRESIGN_UPLOAD_URL_MUTATION = gql`
  mutation PresignPublicAssetUploadUrl(
    $input: PresignPublicAssetUploadUrlInput!
  ) {
    presignPublicAssetUploadUrl(input: $input) {
      url
    }
  }
`
