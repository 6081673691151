import React from "react"
import { useParams } from "react-router-dom"

import {
  CubeIcon,
  CogIcon,
  SparklesIcon,
  DeviceMobileIcon,
  TagIcon,
  UsersIcon,
} from "@heroicons/react/solid"
import Center from "components/layout/center"

import { QrcodeIcon } from "@heroicons/react/outline"
import NavItem from "./nav-item"

const BrandNav = ({ brandName, sharePageSecret }) => {
  const { brandKey } = useParams()

  return (
    <div
      className="flex flex-row justify-start gap-4 px-8 shadow py-1"
      style={{ zIndex: 99 }}
    >
      <Center>
        <h1 className="font-bold text-lg">{brandName}</h1>
      </Center>
      <NavItem exact to={`/brands/${brandKey}`}>
        {{
          title: "General",
          icon: <TagIcon className="h-5 w-5" />,
        }}
      </NavItem>
      <NavItem to={`/brands/${brandKey}/experiences`}>
        {{
          title: "Experiences",
          icon: <DeviceMobileIcon className="h-5 w-5" />,
        }}
      </NavItem>
      <NavItem to={`/brands/${brandKey}/activations`}>
        {{
          title: "Activations / QR Codes",
          icon: <QrcodeIcon className="h-5 w-5" />,
        }}
      </NavItem>
      <NavItem to={`/brands/${brandKey}/product-variants`}>
        {{
          title: "Catalog",
          icon: <CubeIcon className="h-5 w-5" />,
        }}
      </NavItem>
      <NavItem to={`/brands/${brandKey}/users`}>
        {{
          title: "Users",
          icon: <UsersIcon className="h-5 w-5" />,
        }}
      </NavItem>
      <NavItem to={`/brands/${brandKey}/affiliates`}>
        {{
          title: "Affiliates",
          icon: <UsersIcon className="h-5 w-5" />,
        }}
      </NavItem>
      <NavItem openInNewTab to={`/share/${sharePageSecret}/qr`}>
        {{
          title: "Share Activations",
          icon: <SparklesIcon className="h-5 w-5" />,
        }}
      </NavItem>
      <NavItem to={`/brands/${brandKey}/settings`}>
        {{ title: "Settings", icon: <CogIcon className="h-5 w-5" /> }}
      </NavItem>
    </div>
  )
}

export default BrandNav
