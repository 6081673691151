import gql from "graphql-tag"

const GET_BRAND = gql`
  query GetBrand($brandKey: String!) {
    brand(brandKey: $brandKey) {
      id
      name
      status
      homepage
      key
      shopifyDomain
      logoImageSrc
      termsOfUseUrl
      hasShopifyToken
      privacyPolicyUrl
      supportUrl
      shopifyApiKey
      shopifyApiSecret
      applicationFeeRate
      shopifyMerchantInstallLink
      shopifyMerchantInstallLinkExpiresAtDistance
      stripeConnectAccountId
      stripeInstallLink
      stripeApplePayDomainId
      dropboxAssetLink
      dropboxFileRequestLink
      publicRefreshToken
      publicRefreshTokenExpiresAt
      metabaseUrl
    }
  }
`

export default GET_BRAND

export { GET_BRAND }
