import { ReactComponent as ShareIcon } from "assets/icons/footer-icon-share.svg"
import { ReactComponent as ViewGridIcon } from "assets/icons/footer-icon-grid.svg"
import React from "react"
import { LegalText } from "components/containers/iphone-fonts"
import { MEASUREMENTS as Iphone11ProMaxMeasurements } from "components/containers/iphone-11-pro-max"

import { ReactComponent as BuyWithApplePayIcon } from "assets/images/buy-with-apple-pay.svg"

const Footer = ({
  accentColor,
  disableAuxButtons = false,
  textOverAccentColor,
  textColor,
  setGridView,
}) => (
  <div
    className="w-full h-full flex flex-col items-center justify-end"
    style={{
      paddingLeft: Iphone11ProMaxMeasurements.footer.gap,
      paddingRight: Iphone11ProMaxMeasurements.footer.gap,
      height: Iphone11ProMaxMeasurements.footer.height,
      minHeight: Iphone11ProMaxMeasurements.footer.height,
      color: textColor,
    }}
  >
    <div className="h-full flex items-center">
      <p className="text-center" style={{ ...LegalText, opacity: 0.7 }}>
        By purchasing, you agree to the{" "}
        <span className="font-semibold">Terms of Service</span> and{" "}
        <span className="font-semibold">Privacy Policies</span>
      </p>
    </div>
    <div className="w-full text-white flex flex-row justify-between items-center">
      <button
        onClick={() => setGridView(true)}
        style={{
          height: Iphone11ProMaxMeasurements.footer.barHeight,
          width: Iphone11ProMaxMeasurements.footer.barHeight,
        }}
        type="button"
      >
        {disableAuxButtons ? <div className="w-12" /> : <ViewGridIcon />}
      </button>
      <div
        className="flex items-center justify-center"
        style={{
          backgroundColor: accentColor,
          color: textOverAccentColor,
          height: Iphone11ProMaxMeasurements.footer.barHeight,
          borderRadius: Iphone11ProMaxMeasurements.footer.buttonBorderRadius,
          width: "100%",
          marginLeft: Iphone11ProMaxMeasurements.footer.gap,
          marginRight: Iphone11ProMaxMeasurements.footer.gap,
        }}
      >
        <BuyWithApplePayIcon className="h-5" fill={textOverAccentColor} />
      </div>
      <div
        style={{
          height: Iphone11ProMaxMeasurements.footer.barHeight,
          width: Iphone11ProMaxMeasurements.footer.barHeight,
        }}
      >
        {disableAuxButtons ? <div className="w-12" /> : <ShareIcon />}
      </div>
    </div>
  </div>
)

export default Footer
