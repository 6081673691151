import { formatCurrency } from "utils/money"
import React from "react"

const ProductVariantSelectItem = ({ option, toggleSelected }) => {
  const { imageSrcSmall, standaloneTitle, subtitle, price, id, sku } =
    option.data
  return (
    <div className="flex items-center space-x-4 my-2">
      <div>
        <input
          checked={option.status}
          id={id}
          name="productVariantSelect[]"
          onChange={() => toggleSelected(option)}
          type="checkbox"
        />
      </div>
      <label
        className="flex-1 min-w-0 flex items-center space-x-4"
        htmlFor={id}
      >
        <div className="flex-shrink-0">
          <img alt="" className="h-8 w-8" src={imageSrcSmall} />
        </div>
        <div>
          <p className="text-sm font-semibold text-gray-900 wrap">
            {standaloneTitle}
          </p>
          <p className="text-xs text-gray-500 wrap">
            {sku && (
              <>
                <span className="text-xs font-medium text-gray-600 truncate">
                  {sku}
                </span>
                <span aria-hidden="true" className="hidden sm:inline sm:mx-1">
                  &middot;
                </span>
              </>
            )}
            {subtitle}
            <span aria-hidden="true" className="hidden sm:inline sm:mx-1">
              &middot;
            </span>
            {formatCurrency(price)}
          </p>
        </div>
      </label>
    </div>
  )
}

export default ProductVariantSelectItem
