import React from "react"
import { groupBy } from "lodash"

const ShopifyOptions = ({ selectedOptions }) => {
  if (!selectedOptions) return null

  const groupedOptions = groupBy(selectedOptions, "name")

  return (
    <div className=" mt-4  rounded-lg" style={{ maxWidth: 380 }}>
      <b>Selected options:</b>
      {Object.entries(groupedOptions).map(([type, values]) => (
        // eslint-disable-next-line react/jsx-key
        <div className="ml-4">
          <p className="inline-flex mr-2 font-semibold">{type}:</p>
          <span className="text-gray-600">
            {values.map(({ value }) => value).join(", ")}
          </span>
        </div>
      ))}
    </div>
  )
}

export default ShopifyOptions
