import React from "react"
import CloseableConfigSection from "components/closeable-config-section"

import FormComponent from "./form"

const LocationOrderDiscountSection = ({ experienceSectionDetails }) => (
  <CloseableConfigSection
    subtitle="Offer a location discount to your customers."
    title="Discount Section"
  >
    <FormComponent experienceSectionDetails={experienceSectionDetails} />
  </CloseableConfigSection>
)

export default LocationOrderDiscountSection
