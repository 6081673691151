import React from "react"
import { Form } from "formik"
import * as Yup from "yup"
import Button from "components/button"
import BatchForm from "components/batch-form"
import SubmitButton from "components/submit-button"
import FormikInput from "components/forms/formik-input"
import SmallFormModal, {
  ModalFooter,
  ModalBody,
} from "components/small-form-modal"

import { useToasts } from "react-toast-notifications"
import { CREATE_BRAND_USER_MUTATION } from "./queries"

const BrandUserModal = ({ visible, onClose, refetchBrandUsers, userInfo }) => {
  const { addToast } = useToasts()

  const formSchema = Yup.object().shape({
    email: Yup.string().required("Required").email(),
    firstName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
  })

  const onCompleted = (data) => {
    const {
      createOrUpdateBrandUser: { errors },
    } = data

    if (errors[0]) {
      addToast(errors[0], { appearance: "error" })
    } else {
      addToast("Saved Successfully", { appearance: "success" })
      refetchBrandUsers()
      onClose()
    }
  }

  return (
    <SmallFormModal onClose={onClose} visible={visible}>
      {({ close }) => (
        <BatchForm
          initialValues={{
            brandUserId: userInfo.id || null,
            email: userInfo.email || "",
            firstName: userInfo.firstName || "",
            lastName: userInfo.lastName || "",
          }}
          mutation={CREATE_BRAND_USER_MUTATION}
          onCompleted={onCompleted}
          validationSchema={formSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody title={`${userInfo.id ? "Edit" : "Add a new"} User`}>
                <FormikInput
                  autoFocus
                  label="First Name"
                  name="firstName"
                  placeholder="First name"
                  type="text"
                  wrapperProps={{ className: "mb-2" }}
                />
                <FormikInput
                  label="Last Name"
                  name="lastName"
                  placeholder="Last name"
                  type="text"
                  wrapperProps={{ className: "mb-2" }}
                />
                <FormikInput
                  label="Email"
                  name="email"
                  placeholder="Email id"
                  type="text"
                  wrapperProps={{ className: "mb-2" }}
                />
              </ModalBody>
              <ModalFooter>
                <SubmitButton
                  cta={userInfo.id ? "Update" : "Create"}
                  submitting={isSubmitting}
                />
                <Button className="mr-2" onClick={close}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </BatchForm>
      )}
    </SmallFormModal>
  )
}

export default BrandUserModal
