import gql from "graphql-tag"

export const ADD_NEW_BACKGROUND_TO_CARD_VARIATION_MUTATION = gql`
  mutation AddNewBackgroundToCardVariation(
    $input: AddNewBackgroundToCardVariationInput!
  ) {
    addNewBackgroundToCardVariation(input: $input) {
      errors
      cardVariationBackground {
        id
      }
    }
  }
`

export const DELETE_BACKGROUND_FROM_CARD_VARIATION_MUTATION = gql`
  mutation DeleteBackgroundFromCardVariation(
    $input: DeleteBackgroundFromCardVariationInput!
  ) {
    deleteBackgroundFromCardVariation(input: $input) {
      errors
      cardVariationBackground {
        id
      }
    }
  }
`

export const UPDATE_CARD_VARIATION_BACKGROUND_MUTATION = gql`
  mutation UpdateCardVariationBackground(
    $input: UpdateCardVariationBackgroundInput!
  ) {
    updateCardVariationBackground(input: $input) {
      errors
      cardVariationBackground {
        id
        ordinal
      }
    }
  }
`
