import React from "react"

const ActivationPreview = ({ activation, hideHeader = false }) => {
  const { url, qrCodeSvgUrl, experience, fallbackUrl } = activation

  return (
    <>
      {!hideHeader && (
        <div className="flex flex-row justify-between items-center w-full mb-3 px-1">
          <h3 className="text-xl font-semibold leading-tight text-gray-900">
            QR Code
          </h3>
        </div>
      )}
      <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        <div className="bg-white py-6 px-4 sm:p-6 text-center flex flex-col items-center">
          {qrCodeSvgUrl ? (
            <img
              alt="QR Code URL"
              className="h-48 w-48 mb-2"
              src={qrCodeSvgUrl}
            />
          ) : (
            <p className="text-lg font-medium text-gray-800">
              Generating QR Code...
            </p>
          )}
          <p className="text-gray-500 text-xs mb-1">{url}</p>
          {experience && (
            <p className="text-gray-500 text-xs mb-3">
              Android:&nbsp;
              <a
                className="text-blue-500"
                href={fallbackUrl}
                rel="noreferrer"
                target="_blank"
              >
                {fallbackUrl}
              </a>
            </p>
          )}
        </div>
      </div>
    </>
  )
}

export default ActivationPreview
