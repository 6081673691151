import React from "react"
import GetQueryLoader from "components/get-query-loader"
import IndexTable from "components/index-table"
import { useHistory, useLocation } from "react-router-dom"
import useDisclosure from "hooks/use-disclosure"
import CreateExperienceModal from "./create-experience-modal"
import { GET_EXPERIENCES } from "./queries"

const Catalog = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const history = useHistory()
  const location = useLocation()

  const prepareData = (experiences) =>
    experiences.map((experience) => ({
      ...experience,
    }))

  return (
    <GetQueryLoader fetchPolicy="network-only" query={GET_EXPERIENCES}>
      {({ brand: { experiences } }, refetchExperiences) => (
        <main>
          <IndexTable
            columns={[
              {
                title: "Experience",
                field: "name",
              },
              {
                title: "Featuring",
                field: "cartProductVariantTitles",
              },
            ]}
            ctaText="Create New Experience"
            data={prepareData(experiences)}
            onCtaClick={onOpen}
            onRowClick={(_, rowData) => {
              history.push(`${location.pathname}/${rowData.id}/editor`)
            }}
            title="Experiences"
          />
          <CreateExperienceModal
            onClose={onClose}
            refetchExperiences={refetchExperiences}
            visible={isOpen}
          />
        </main>
      )}
    </GetQueryLoader>
  )
}
export default Catalog
