import React, { useEffect, useRef, useState } from "react"
import ProfileAvatar from "components/profile-avatar"
import TriggeredDropdownMenu from "components/triggered-dropdown-menu"
import { useAdminAuthentication } from "contexts/admin-authentication"

import UserMenu from "./user-menu"

const UserMenuDropdown = () => {
  const triggerRef = useRef(null)
  const [menuVisible, setMenuVisible] = useState(false)
  const { currentAdminUser, authAndLoadAdminUser } = useAdminAuthentication()

  useEffect(() => {
    authAndLoadAdminUser()
  }, [currentAdminUser, authAndLoadAdminUser])

  if (!currentAdminUser) return null
  const { avatarUrl, fullName } = currentAdminUser

  return (
    <div
      className="hidden sm:ml-2 sm:flex sm:items-center"
      data-testid="user-menu-dropdown"
    >
      <div className="ml-3 relative">
        <div className="flex flex-row items-center">
          <div ref={triggerRef}>
            <ProfileAvatar avatarUrl={avatarUrl} fullName={fullName} />
          </div>
        </div>
        <TriggeredDropdownMenu
          content={<UserMenu />}
          position="belowright"
          setVisible={setMenuVisible}
          triggerRef={triggerRef}
          visible={menuVisible}
        />
      </div>
    </div>
  )
}

export default UserMenuDropdown
