import React, { useState } from "react"
import classnames from "classnames"
import { Switch } from "@headlessui/react"
import { useMutation } from "@apollo/client"
import { StarIcon } from "@heroicons/react/solid"
import { TOGGLE_STARRED_PRODUCT_VARIANT } from "./queries"

const Star = ({ isStarred: passedIsStarred, id }) => {
  const [isStarred, setStarred] = useState(passedIsStarred)

  const [updateStarred, { loading }] = useMutation(
    TOGGLE_STARRED_PRODUCT_VARIANT
  )

  const toggleStarred = async () => {
    updateStarred({ variables: { input: { id, isStarred: !isStarred } } }).then(
      setStarred(!isStarred)
    )
  }

  return (
    <div className="pr-2 inline-flex items-center justify-center">
      <Switch
        checked={isStarred}
        className="relative inline-flex items-center h-6 w-6 text-center"
        disabled={loading}
        onChange={toggleStarred}
      >
        <span className="sr-only">Star this product variant</span>
        <StarIcon
          className={classnames(
            "h-6 w-6",
            isStarred ? "text-yellow-200" : "text-gray-200"
          )}
        />
      </Switch>
    </div>
  )
}

export default Star
