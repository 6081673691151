import gql from "graphql-tag"

export const UPDATE_CARD_EXPERIENCE = gql`
  mutation UpdateCardExperience($input: UpdateCardExperienceInput!) {
    updateCardExperience(input: $input) {
      errors
      cardExperience {
        id
      }
    }
  }
`

export default UPDATE_CARD_EXPERIENCE
