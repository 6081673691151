import React, { useState } from "react"
import { PrimaryButton } from "components/button"
import EmptyTbody from "components/empty-tbody"
import { sortBy } from "lodash"
import NewSheetModal from "./new-sheet-modal"
import TagSeriesRow from "./tag-series-row"

const thead = (
  <thead className="bg-white">
    <tr>
      <th
        className="px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
        scope="col"
      >
        Generated
      </th>
      <th
        className="px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
        scope="col"
      >
        Quantity
      </th>
      <th
        className="px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
        scope="col"
      >
        Range
      </th>
      <th
        className="px-6 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
        scope="col"
      >
        CSV
      </th>
    </tr>
  </thead>
)

const NFCTagSeries = ({ activation, refetchActivation }) => {
  const { nfcTagSeries } = activation

  const [modalVisible, setModalVisible] = useState(false)

  const showModal = () => setModalVisible(true)
  const hideModal = () => setModalVisible(false)

  let tableContent

  if (nfcTagSeries.length > 0) {
    tableContent = (
      <table className="min-w-full divide-y divide-gray-200">
        {thead}
        <tbody className="bg-white divide-y divide-gray-200">
          {sortBy(nfcTagSeries, ["generatedAt"])
            .reverse()
            .map((serie) => (
              <TagSeriesRow key={serie.id} nfcTagSeries={serie} />
            ))}
        </tbody>
      </table>
    )
  } else {
    tableContent = (
      <>
        <table className="min-w-full divide-y divide-gray-200">{thead}</table>
        <EmptyTbody message="No NFC Tags Yet" />
      </>
    )
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center w-full mb-2 px-1">
        <h3 className="text-xl font-semibold leading-tight text-gray-900">
          NFC Tags
        </h3>
        <PrimaryButton onClick={showModal} slim>
          Generate Next Series CSV
        </PrimaryButton>
      </div>
      <div className="mb-10 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
        {tableContent}
      </div>
      <NewSheetModal
        activation={activation}
        onClose={hideModal}
        refetchActivation={refetchActivation}
        visible={modalVisible}
      />
    </>
  )
}

export default NFCTagSeries
