import React from "react"

const BrandColumn = ({ brand }) => {
  const { name, key, logoImageSrc } = brand

  return (
    <div className="flex flex-row items-center">
      {logoImageSrc ? (
        <img
          alt="logo"
          className="w-5 h-5 mr-2 rounded-full"
          src={logoImageSrc}
        />
      ) : (
        <div className="w-5 h-5 mr-2 rounded-full bg-gray-200" />
      )}
      <div className="flex flex-row font-medium items-center">
        {name}
        <span className="text-gray-400 font-normal ml-2 text-sm pt-1">
          {key}
        </span>
      </div>
    </div>
  )
}

export default BrandColumn
