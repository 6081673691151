import React from "react"
import { MEASUREMENTS as Iphone11ProMaxMeasurements } from "components/containers/iphone-11-pro-max"
import { BodySmall, H1 } from "components/containers/iphone-fonts"
import { formatCurrency, formatCurrencyTruncated } from "utils/money"
import BrandIcon from "components/brand-icon"
import classNames from "classnames"

const Header = ({
  textColor,
  imageSrc,
  accentColor,
  price,
  cardVariationCard,
}) => {
  const discountedPrice = cardVariationCard?.discountedPrice

  const formatPrice = (priceToFormat) =>
    priceToFormat % 100 === 0
      ? formatCurrencyTruncated(priceToFormat)
      : formatCurrency(priceToFormat)

  const originalPriceLabel = formatPrice(price)

  const getPrice = () => {
    if (discountedPrice !== null) {
      return formatPrice(discountedPrice)
    }
    return originalPriceLabel
  }

  return (
    <div
      className="w-full h-full flex flex-row justify-between items-center"
      style={{
        height: Iphone11ProMaxMeasurements.header.height,
        minHeight: Iphone11ProMaxMeasurements.header.height,
        paddingLeft: Iphone11ProMaxMeasurements.header.paddingX,
        paddingRight: Iphone11ProMaxMeasurements.header.paddingX, // iphone 11 pro max from figma
        paddingTop: Iphone11ProMaxMeasurements.header.paddingTop,
        paddingBottom: Iphone11ProMaxMeasurements.header.paddingBottom,
        color: textColor,
      }}
    >
      <BrandIcon imageSrc={imageSrc} textColor={textColor} />
      <div className="flex flex-col text-right">
        <span
          className={classNames("leading-4", {
            "line-through": discountedPrice !== null,
          })}
          style={{
            ...BodySmall,
            color: textColor,
            opacity: 0.6,
          }}
        >
          {discountedPrice !== null ? originalPriceLabel : "Total"}
        </span>

        <span
          className="font-medium text-lg leading-none"
          style={{
            ...H1,
            color: accentColor,
          }}
        >
          {getPrice()}
        </span>
      </div>
    </div>
  )
}
export default Header
