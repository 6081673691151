import gql from "graphql-tag"

export const UPDATE_BRAND_GENERAL_MUTATION = gql`
  mutation UpdateGeneralBrand($input: UpdateBrandInput!) {
    updateGeneralBrand(input: $input) {
      errors
      brand {
        id
      }
    }
  }
`
export const UPDATE_BRAND_LEGAL_MUTATION = gql`
  mutation UpdateLegalBrand($input: UpdateBrandInput!) {
    updateLegalBrand(input: $input) {
      errors
      brand {
        id
      }
    }
  }
`
export const UPDATE_BRAND_SHOPIFY_MUTATION = gql`
  mutation UpdateShopifyBrand($input: UpdateBrandInput!) {
    updateShopifyBrand(input: $input) {
      errors
      brand {
        id
      }
    }
  }
`
export const UPDATE_BRAND_STRIPE_MUTATION = gql`
  mutation UpdateStripeBrand($input: UpdateBrandInput!) {
    updateStripeBrand(input: $input) {
      errors
      brand {
        id
      }
    }
  }
`

export const PRESIGN_UPLOAD_URL_MUTATION = gql`
  mutation PresignPublicAssetUploadUrl(
    $input: PresignPublicAssetUploadUrlInput!
  ) {
    presignPublicAssetUploadUrl(input: $input) {
      url
    }
  }
`
