import { ApolloClient, InMemoryCache } from "@apollo/client"
import { createHttpLink } from "apollo-link-http"
import { ApolloLink } from "apollo-link"
import { setContext } from "apollo-link-context"

const { REACT_APP_GRAPHQL_PUBLIC_URL } = process.env

const setHeaderLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
  },
}))

const httpLink = createHttpLink({
  uri: REACT_APP_GRAPHQL_PUBLIC_URL,
  credentials: "omit",
})

const client = new ApolloClient({
  link: ApolloLink.from([setHeaderLink, httpLink]),
  cache: new InMemoryCache(),
})

export default client
