import classNames from "classnames"
import React from "react"

const Appendix = ({ content, side }) => {
  if (!content) return null

  if (typeof content === "string") {
    return (
      <span
        className={classNames(
          "inline-flex items-center px-3 py-2 border-gray-300 bg-gray-50 text-gray-500",
          {
            "border-l": side === "right",
            "border-r": side === "left",
          }
        )}
      >
        {content}
      </span>
    )
  }

  return content
}

export default Appendix
