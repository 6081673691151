import gql from "graphql-tag"

export const UPDATE_FEATURED_PRODUCT_VARIANT_SECTION = gql`
  mutation UpdateExperienceSectionFeaturedProductVariantDetails(
    $input: UpdateExperienceSectionFeaturedProductVariantDetailsInput!
  ) {
    updateExperienceSectionFeaturedProductVariantDetails(input: $input) {
      errors
      experience {
        id
      }
    }
  }
`

export const PRESIGN_UPLOAD_URL_MUTATION = gql`
  mutation PresignPublicAssetUploadUrl(
    $input: PresignPublicAssetUploadUrlInput!
  ) {
    presignPublicAssetUploadUrl(input: $input) {
      url
    }
  }
`

export const GET_PRODUCT_VARIANTS = gql`
  query GetBrand {
    brand {
      productVariants {
        id
        sku
        description
        imageSrcSmall
        standaloneTitle
        subtitle
        price
      }
    }
  }
`
