import gql from "graphql-tag"
import useInterval from "use-interval"
import { useQuery } from "@apollo/client"

const PollExperience = ({
  currentExperience,
  setProcessing,
  refetchExperience,
}) => {
  const { data, refetch } = useQuery(
    gql`
      query GetExperience($id: ID!) {
        experience(id: $id) {
          backgroundVideoSrc
        }
      }
    `,
    {
      errorPolicy: "all",
      fetchPolicy: "network-only",
      variables: { id: currentExperience?.id },
    }
  )

  useInterval(() => {
    if (
      data?.currentExperience?.backgroundVideoSrc === null ||
      data?.currentExperience?.backgroundVideoSrc === undefined
    ) {
      // Check if the background video value is updated
      refetch()
    } else {
      // Update entire experience, kill polling
      refetchExperience()
      setProcessing(false)
    }
  }, 1000)
  return null
}
export default PollExperience
