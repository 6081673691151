import React, { useEffect } from "react"
import { useEditor } from "contexts/experience-editor"
import { BookmarkIcon, PhotographIcon } from "@heroicons/react/solid"

import {
  Sidebar,
  SidebarGroup,
  SidebarGroupHeader,
  SidebarExperienceSection,
} from "components/sidebar"

import SectionNavItem from "./section-nav-item"
import {
  APP_CLIP_CARD_SECTION,
  APP_CLIP_BACKGROUND_SECTION,
} from "./app-clip-sections"

const SectionNav = () => {
  const { currentExperience, experienceId, currentSection, changeSection } =
    useEditor()

  useEffect(() => {
    changeSection(currentExperience.experienceSections[0].id)
  }, [changeSection, currentExperience, experienceId])

  return (
    <Sidebar>
      <SidebarGroup header={<SidebarGroupHeader header="App Clip Setup" />}>
        <SidebarExperienceSection
          icon={<BookmarkIcon className="w-5 h-5" />}
          isCurrent={
            currentSection?.experienceSectionId === APP_CLIP_CARD_SECTION.id
          }
          onClick={() => changeSection(APP_CLIP_CARD_SECTION.id)}
          subtitle="Customize the native popup card for App Clips."
          title="Preview Card"
        />
        <SidebarExperienceSection
          icon={<PhotographIcon className="w-5 h-5" />}
          isCurrent={
            currentSection?.experienceSectionId ===
            APP_CLIP_BACKGROUND_SECTION.id
          }
          onClick={() => changeSection(APP_CLIP_BACKGROUND_SECTION.id)}
          subtitle="Customize the background visual for App Clips."
          title="Background"
        />
      </SidebarGroup>

      <SidebarGroup
        header={<SidebarGroupHeader header="Experience Sections" />}
      >
        {currentExperience.experienceSections.map((experienceSection) => (
          <SectionNavItem
            key={experienceSection.experienceSectionDetailsId}
            experienceSection={experienceSection}
          />
        ))}
      </SidebarGroup>
    </Sidebar>
  )
}

export default SectionNav
