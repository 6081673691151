import React, { useState } from "react"
import { useParams } from "react-router-dom"
import CardExperienceItem from "./card-experience-item"

export const filterCardExperiences = (term, cardExperiences) =>
  cardExperiences.map((item) => item.id).filter((item) => item.includes(term))
    .length > 0

const CardExperiences = ({ cardExperiences }) => {
  const { brandKey } = useParams()
  const [truncated, setTruncated] = useState(false)

  if (!cardExperiences.length) return null

  const experiences = cardExperiences.slice(
    0,
    truncated ? cardExperiences.length : 3
  )

  const handleClick = (e) => {
    e.stopPropagation()
    setTruncated(!truncated)
  }

  return (
    <>
      {experiences.map((cardExperience) => (
        <CardExperienceItem
          key={cardExperience.id}
          brandKey={brandKey}
          {...cardExperience}
        />
      ))}
      {experiences.length > 2 && (
        <button
          className="text-blue-500 text-sm mt-1"
          onClick={handleClick}
          type="button"
        >
          Show {truncated ? "less" : "more"}
        </button>
      )}
    </>
  )
}

export default CardExperiences
