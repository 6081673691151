import React from "react"
import { ReactComponent as StarIcon } from "assets/icons/star.svg"

const LocationOrderDiscountSectionPreview = ({ experienceSectionDetails }) => {
  const { subtitle, title } = experienceSectionDetails

  return (
    <div className="px-4 bg-white">
      <div
        className="bg-gray-200 flex flex-col px-3 py-3 font-semibold text-xs rounded-lg w-full mt-4 text-white"
        style={{ backgroundColor: "#111111" }}
      >
        <p className="font-bold text-xs">{title}</p>
        <p
          className="font-light text-xs mt-1"
          style={{ fontSize: "0.7rem", lineHeight: "1rem" }}
        >
          {subtitle}
        </p>
        <div className="rounded-2xl w-full border font-bold border-gray-100 bg-white mt-3 px-2 py-2 text-gray-900 flex justify-center">
          <div className="flex items-center">
            <StarIcon className="w-4 mr-2" />
            Unlock Discount
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationOrderDiscountSectionPreview
