import React from "react"
import GetQueryLoader from "components/get-query-loader"
import TitleHeader from "components/title-header"
import GeneralSettingsForm from "./form"
import { GET_BRAND_GENERAL_SETTINGS } from "./queries"

const Settings = () => (
  <>
    <TitleHeader>
      <h1 className="text-3xl font-extrabold leading-tight text-gray-900">
        Settings
      </h1>
    </TitleHeader>
    <main>
      <GetQueryLoader
        fetchPolicy="network-only"
        query={GET_BRAND_GENERAL_SETTINGS}
      >
        {({ brand }) => <GeneralSettingsForm brand={brand} />}
      </GetQueryLoader>
    </main>
  </>
)

export default Settings
