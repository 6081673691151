import React from "react"

const Error = ({ error, touched }) => {
  if (!touched || !error) {
    return null
  }

  return <p className="text-sm text-red-700">{error}</p>
}

export default Error
