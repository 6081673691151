import React from "react"

const BrandColumn = ({ brand, showDeletedAlert }) => {
  const { name, key, logoImageSrc } = brand

  return (
    <div className="flex flex-row items-center">
      {logoImageSrc ? (
        <img
          alt="logo"
          className="w-5 h-5 mr-2 rounded-full"
          src={logoImageSrc}
        />
      ) : (
        <div className="w-5 h-5 mr-2 bg-gray-200 rounded-full" />
      )}
      <div className="flex flex-row items-center font-medium">
        {name}
        <span className="pt-1 ml-2 text-sm font-normal text-gray-400">
          {key}
        </span>
        {showDeletedAlert && (
          <span className="px-2 py-1 mx-4 mt-1 text-xs font-medium text-gray-800 bg-red-100 rounded-sm">
            Products Removed
          </span>
        )}
      </div>
    </div>
  )
}

export default BrandColumn
