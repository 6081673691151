import React, { useEffect, useState } from "react"
import { useEditor } from "contexts/experience-v2-editor"

import CardForm from "./card-form"
import CardVariationConfigurator from "./card-variation-configurator"
import AddOrCreateCard from "./add-or-create-card"
import CardInfo from "./card-info"

const CardEditor = () => {
  const {
    currentCard,
    currentCardExperience,
    currentCardOrdinal,
    isLaunchCard,
    refetchCardExperience,
  } = useEditor()

  const [overrideUrlEnabled, setUrlOverrideEnabled] = useState(
    !!currentCard?.linkOverrideUrl
  )

  useEffect(() => {
    setUrlOverrideEnabled(!!currentCard?.linkOverrideUrl)
  }, [currentCard])

  const allCards = currentCardExperience.cardExperienceCards.map(
    (cardExperienceCard) => cardExperienceCard.card
  )

  if (currentCard === null) {
    return (
      <AddOrCreateCard
        allCards={allCards}
        currentCardExperienceId={currentCardExperience.id}
        currentCardOrdinal={currentCardOrdinal}
        isLaunchCard={isLaunchCard}
        refetchCardExperience={refetchCardExperience}
      />
    )
  }

  return (
    <>
      <CardInfo
        currentCard={currentCard}
        currentCardExperience={currentCardExperience}
        isLaunchCard={isLaunchCard}
        refetchCardExperience={refetchCardExperience}
      />
      <CardForm
        currentCard={currentCard}
        gridVersion={currentCardExperience.gridVersion}
        isLaunchCard={isLaunchCard}
        overrideUrlEnabled={overrideUrlEnabled}
        refetchCardExperience={refetchCardExperience}
        setUrlOverrideEnabled={setUrlOverrideEnabled}
      />
      {!overrideUrlEnabled && (
        <>
          <div className="border mb-8" />
          <CardVariationConfigurator
            currentCard={currentCard}
            overrideUrlEnabled={overrideUrlEnabled}
            refetchCardExperience={refetchCardExperience}
          />
        </>
      )}
    </>
  )
}

export default CardEditor
