import React from "react"
import { useParams } from "react-router-dom"
import GetQueryLoader from "components/get-query-loader"
import BrandEdit from "./brand-edit"
import { GET_BRAND } from "./queries"

const BrandShow = () => {
  const { brandKey } = useParams()

  return (
    <GetQueryLoader
      query={GET_BRAND}
      queryVariables={{
        key: brandKey,
      }}
    >
      {({ brand }, refetch) => (
        <BrandEdit brand={brand} refetchBrand={refetch} />
      )}
    </GetQueryLoader>
  )
}

export default BrandShow
