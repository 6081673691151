import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Form } from "formik"
import * as Yup from "yup"

import BatchForm from "components/batch-form"
import FormikInput from "components/forms/formik-input"
import Select from "components/forms/select"
import Switch from "components/forms/switch"
import ImagePicker from "components/image-picker"
import imageUploadNote from "components/image-upload-note"
import SubmitButton from "components/submit-button"
import Toggle from "components/toggle"

import GradientSelector from "components/gradient-selector"
import AutoForegroundSelector from "./auto-foreground-selector"
import { UPDATE_CARD } from "./queries"
import ModeSelector, { allModes } from "./mode-selector"
import ModalContentForm from "./modal-content-form"

const CardForm = ({
  currentCard,
  isLaunchCard,
  refetchCardExperience,
  overrideUrlEnabled,
  setUrlOverrideEnabled,
  gridVersion,
}) => {
  const [collapsedBackgroundImageSrc, setCollapsedBackgroundImageSrc] =
    useState(currentCard.collapsedBackgroundImageSrc)

  const { brandKey } = useParams()

  useEffect(() => {
    setCollapsedBackgroundImageSrc(currentCard.collapsedBackgroundImageSrc)
  }, [currentCard.collapsedBackgroundImageSrc])

  const {
    collapsedTitle,
    screenBackgroundColor,
    screenForegroundColor,
    expandedBackgroundColor,
    expandedForegroundColor,
    expandedAccentColor,
    expandedForegroundOverAccentColor,
    giftFixedShippingPriceCents,
    giftTheme,
    linkOverrideUrl,
    availableModes,
    defaultMode,
    buyButtonCtaVerb,
    buyConfirmationModalTitle,
    buyConfirmationModalBody,
    buyConfirmationModalButton,
    disableAuxButtons,
    maxQuantity,
    maxQuantityBoughtModalTitle,
    maxQuantityBoughtModalBody,
    maxQuantityBoughtModalButton,
    unitsAvailable,
    noUnitsAvailableModalTitle,
    noUnitsAvailableModalBody,
    noUnitsAvailableModalButton,
    unitsConfirmed,
    collapsedBackgroundGradient,
    collapsedForegroundColor,
  } = currentCard

  const formSchema = Yup.object().shape({
    collapsedTitle: Yup.string().required("Required"),
    defaultMode: Yup.string().oneOf(allModes, "Required"),
    availableModes: Yup.array().min(1, "Required"),
    buyButtonCtaVerb: Yup.string().required("Required"),
    buyConfirmationModalTitle: Yup.string().required("Required"),
    buyConfirmationModalBody: Yup.string().required("Required"),
    buyConfirmationModalButton: Yup.string().required("Required"),
    disableAuxButtons: Yup.boolean().required("Required"),
    limitQuantity: Yup.boolean().required("Required"),
    maxQuantity: Yup.number().when("limitQuantity", {
      is: true,
      then: Yup.number().required("Required"),
      otherwise: Yup.number().notRequired(),
    }),
    limitUnitsAvailable: Yup.boolean().required("Required"),
    unitsAvailable: Yup.number().when("limitUnitsAvailable", {
      is: true,
      then: Yup.number().required("Required"),
      otherwise: Yup.number().notRequired(),
    }),
  })

  const handleOverrideUrlChange = (switchValue, setFieldValue) => {
    // This will clear the linkOverrideUrl field if the switch is turned off
    setUrlOverrideEnabled(switchValue)
    setFieldValue("linkOverrideUrl", null)

    // If switch is turned off, reset to defaults
    if (!switchValue) {
      setFieldValue("collapsedBackgroundGradient", [])
      setFieldValue("collapsedForegroundColor", "111111") // 111111 is the default color in the BE
      setFieldValue("collapsedIconTheme", undefined)
    }
  }

  return (
    <BatchForm
      enableReinitialize
      formSchema={formSchema}
      generateValues={(values) => ({
        ...values,
        collapsedBackgroundImageSrc,
        giftFixedShippingPriceCents: Math.round(
          parseFloat(values.giftFixedShippingPriceCents) * 100
        ),
        limitQuantity: isLaunchCard && values.limitQuantity,
        maxQuantity: Number(values.maxQuantity),
        limitUnitsAvailable: isLaunchCard && values.limitUnitsAvailable,
        unitsAvailable: Number(values.unitsAvailable),
        collapsedIconTheme:
          values.collapsedForegroundColor === "ffffff" ? "light" : "dark",
        collapsedForegroundColor:
          gridVersion === "v2" ? values.collapsedForegroundColor : "000000",
      })}
      initialValues={{
        collapsedBackgroundGradient,
        collapsedForegroundColor,
        giftTheme,
        availableModes,
        defaultMode,
        cardId: currentCard.id,
        collapsedTitle,
        linkOverrideUrl,
        screenBackgroundColor,
        screenForegroundColor,
        expandedBackgroundColor,
        expandedForegroundColor,
        expandedAccentColor,
        expandedForegroundOverAccentColor,
        giftFixedShippingPriceCents: giftFixedShippingPriceCents / 100.0,
        buyButtonCtaVerb,
        buyConfirmationModalTitle,
        buyConfirmationModalBody,
        buyConfirmationModalButton,
        disableAuxButtons,
        limitQuantity: isLaunchCard && !!maxQuantity,
        maxQuantity: maxQuantity || 1,
        maxQuantityBoughtModalTitle,
        maxQuantityBoughtModalBody,
        maxQuantityBoughtModalButton,
        limitUnitsAvailable: isLaunchCard && !!unitsAvailable,
        unitsAvailable: unitsAvailable || 100,
        noUnitsAvailableModalTitle,
        noUnitsAvailableModalBody,
        noUnitsAvailableModalButton,
      }}
      mutation={UPDATE_CARD}
      onSuccess={refetchCardExperience}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <div className="mb-8">
          <Form>
            <div className="grid grid-cols-2 gap-4 mb-8">
              <div className="flex flex-col">
                <h3 className="font-semibold text-lg my-4 border-gray-400 border-b">
                  General
                </h3>
                <div className="mb-2">
                  <FormikInput
                    label="Collapsed Title"
                    name="collapsedTitle"
                    type="text"
                  />
                </div>
                <ImagePicker
                  brandKey={brandKey}
                  imageUrl={collapsedBackgroundImageSrc}
                  inputName="collapsedBackgroundImageSrc"
                  label="Collapsed Background Image"
                  note={imageUploadNote({ isExpanded: false })}
                  onClearButtonPressed={() =>
                    setCollapsedBackgroundImageSrc(null)
                  }
                  onImageUploadComplete={(url) =>
                    setCollapsedBackgroundImageSrc(url)
                  }
                  preview
                  showClearButton
                  uploadFolder={`cards/${currentCard.id}/collapsed_background_image_src`}
                />
                <h3 className="font-semibold text-lg my-4 border-gray-400 border-b">
                  Actions
                </h3>
                <div className="flex flex-col gap-px mb-2">
                  <p className="text-xs font-semibold">Enable URL Action</p>
                  <Toggle
                    data-testid="switch"
                    enabled={overrideUrlEnabled}
                    setEnabled={(switchValue) =>
                      handleOverrideUrlChange(switchValue, setFieldValue)
                    }
                  />
                  <p className="text-xs text-gray-600">
                    This will hide card configuration. Theme will still be
                    saved.
                  </p>
                </div>
                {overrideUrlEnabled && (
                  <>
                    <FormikInput
                      label="Override url"
                      name="linkOverrideUrl"
                      type="text"
                      wrapperProps={{ className: "mb-4" }}
                    />
                    {gridVersion === "v2" && (
                      <>
                        <GradientSelector
                          gradientFieldName="collapsedBackgroundGradient"
                          textFieldName="collapsedForegroundColor"
                        />
                        <p className="text-xs text-gray-600">
                          Gradient will only be used if Collapsed Background
                          Image is empty. The icon is selected automatically
                          based on the url (instagram, youtube, etc)
                        </p>
                      </>
                    )}
                  </>
                )}
                {!overrideUrlEnabled && (
                  <>
                    <h3 className="font-semibold text-lg my-4 border-gray-400 border-b">
                      Theme
                    </h3>
                    <AutoForegroundSelector
                      backgroundColor={values.screenBackgroundColor}
                      backgroundLabel="Background"
                      backgroundName="screenBackgroundColor"
                      foregroundColor={values.screenForegroundColor}
                      foregroundName="screenForegroundColor"
                    />
                    <AutoForegroundSelector
                      backgroundColor={values.expandedBackgroundColor}
                      backgroundLabel="Card"
                      backgroundName="expandedBackgroundColor"
                      foregroundColor={values.expandedForegroundColor}
                      foregroundName="expandedForegroundColor"
                    />
                    <AutoForegroundSelector
                      backgroundColor={values.expandedAccentColor}
                      backgroundLabel="Button"
                      backgroundName="expandedAccentColor"
                      foregroundColor={values.expandedForegroundOverAccentColor}
                      foregroundName="expandedForegroundOverAccentColor"
                    />
                    <Switch
                      hint="Disables the grid and share button on this card"
                      label="Disable aux buttons"
                      name="disableAuxButtons"
                    />
                    <h3 className="font-semibold text-lg my-4 border-gray-400 border-b">
                      Scope (Gift/Buy)
                    </h3>
                    <ModeSelector />
                    {isLaunchCard && (
                      <>
                        <h3 className="font-semibold text-lg my-4 border-gray-400 border-b">
                          Constraints
                        </h3>
                        <div className="mb-2">
                          <Switch
                            hint="Limit the quantity of items that can be purchased from this card by one customer"
                            label="Limit Quantity Per Customer"
                            name="limitQuantity"
                          />
                        </div>
                        {values.limitQuantity && (
                          <>
                            <div className="mb-2">
                              <FormikInput
                                label="Max Quantity Per Customer"
                                min={1}
                                name="maxQuantity"
                                type="number"
                                wrapperProps={{ className: "mb-4" }}
                              />
                            </div>
                            <ModalContentForm
                              label="Max Quantity Bought Modal"
                              namePrefix="maxQuantityBoughtModal"
                            />
                          </>
                        )}
                        <div className="mb-2">
                          <Switch
                            hint="Limit the total number of items that can be purchased from this card by all customers"
                            label="Limit Total Units Available"
                            name="limitUnitsAvailable"
                          />
                        </div>
                        {values.limitUnitsAvailable && (
                          <>
                            <div className="mb-2">
                              <p className="mb-2 text-sm text-red-400">
                                {unitsConfirmed} units have been confirmed from
                                this card.
                              </p>
                              <FormikInput
                                label="Total Units Available"
                                min={1}
                                name="unitsAvailable"
                                type="number"
                                wrapperProps={{ className: "mb-4" }}
                              />
                            </div>
                            <ModalContentForm
                              label="No Units Available Modal"
                              namePrefix="noUnitsAvailableModal"
                            />
                          </>
                        )}
                      </>
                    )}
                    <h3 className="font-semibold text-lg my-4 border-gray-400 border-b">
                      Buying
                    </h3>
                    <div className="mb-4">
                      <Select
                        hint="The verb will be suffixed with the correct payment form, e.g. with  Pay"
                        label="Buy Button CTA Verb"
                        name="buyButtonCtaVerb"
                        options={{
                          Buy: "Buy",
                          Claim: "Claim",
                        }}
                        type="text"
                      />
                    </div>
                    <ModalContentForm
                      label="Buy Confirmation Modal"
                      namePrefix="buyConfirmationModal"
                    />
                    <h3 className="font-semibold text-lg my-4 border-gray-400 border-b">
                      Gifting
                    </h3>
                    <div className="mb-5 flex flex-col gap-2 w-40">
                      <FormikInput
                        label="Fixed gift shipping"
                        min="0"
                        name="giftFixedShippingPriceCents"
                        placeholder="0"
                        step="0.01"
                        suffix="$"
                        type="number"
                      />
                      <Select
                        hint="Dark will use red ribbon, light will use white ribbon"
                        label="Gift Theme"
                        name="giftTheme"
                        options={{
                          dark: "Dark",
                          light: "Light",
                        }}
                        type="text"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <SubmitButton cta="Save" submitting={isSubmitting} />
          </Form>
        </div>
      )}
    </BatchForm>
  )
}

export default CardForm
