import gql from "graphql-tag"

const GET_ACTIVATION = gql`
  query GetActivation($id: ID!) {
    activation(id: $id) {
      id
      name
      url
      demoUrl
      testUrl
      identifier
      qrCodeSvgUrl
      batchTagSvgUrl
      updatedAt
      createdAt
      nextNfcTagNumber
      appStoreConnectStatus
      fallbackUrl

      experience {
        id
        name
      }

      nfcTagSeries {
        id
        medium
        quantity
        seriesRangeString
        generatedAt
        presignedUrl
      }
    }
  }
`

export default GET_ACTIVATION

export { GET_ACTIVATION }
