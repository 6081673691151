import classNames from "classnames"
import React from "react"

const EditorButton = ({ onClick, label, active }) => (
  <button
    className={classNames("rounded-sm mr-2 bg-gray-100 px-2 cursor-pointer", {
      "bg-blue-500": active,
      "text-white": active,
    })}
    onMouseDown={onClick}
    type="button"
  >
    {label}
  </button>
)

export default EditorButton
