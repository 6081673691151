import React from "react"
import MuiDrawer from "@material-ui/core/Drawer"
import Row from "components/layout/row"

const Drawer = ({ children, onClose, isOpen, title, className }) => {
  const renderTitle =
    typeof title === "string" ? (
      <h1 className="text-xl font-semibold">{title}</h1>
    ) : (
      { title }
    )

  return (
    <MuiDrawer
      anchor="right"
      onClose={onClose}
      open={isOpen}
      PaperProps={{ className: className || "w-1/2 p-8" }}
    >
      <div className="overflow-hidden flex flex-col h-full">
        <Row className="mb-12">
          {renderTitle}
          <button
            className="text-blue-500 text-sm"
            onClick={onClose}
            type="button"
          >
            Close
          </button>
        </Row>
        <div className="overflow-auto w-full flex-1">{children}</div>
      </div>
    </MuiDrawer>
  )
}

export default Drawer
