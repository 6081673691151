import gql from "graphql-tag"

const GET_BRANDS = gql`
  query GetBrands($filter: String) {
    brands(filter: $filter) {
      id
      name
      status
      key
      logoImageSrc
      productVariants {
        deletedFromShopify
      }
      platformEnabled
    }
  }
`

export default GET_BRANDS

export { GET_BRANDS }
