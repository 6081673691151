import React from "react"
import { useParams } from "react-router-dom"
import GetQueryLoader from "components/get-query-loader"
import ProductVariantInformation from "./product-variant-information"
import { GET_PRODUCT_VARIANT } from "./queries"

const ProductVariantShow = () => {
  const { productVariantId } = useParams()

  return (
    <GetQueryLoader
      fetchPolicy="network-only"
      query={GET_PRODUCT_VARIANT}
      queryVariables={{
        id: productVariantId,
      }}
    >
      {({ productVariant }, refetch) => (
        <ProductVariantInformation
          productVariant={productVariant}
          productVariantRefetch={refetch}
        />
      )}
    </GetQueryLoader>
  )
}

export default ProductVariantShow
