import React from "react"
import { MEASUREMENTS as Iphone11ProMaxMeasurements } from "components/containers/iphone-11-pro-max"

const BrandIcon = ({ textColor, imageSrc }) => {
  const iconFilterString =
    textColor === "#FFFFFF" ? "brightness(0) invert(1)" : "brightness(0)"

  return (
    <img
      alt=""
      className="w-full"
      src={imageSrc}
      style={{
        filter: iconFilterString,
        msFilter: iconFilterString,
        WebkitFilter: iconFilterString,
        maxWidth: Iphone11ProMaxMeasurements.logo.width,
      }}
    />
  )
}
export default BrandIcon
