import React, { useEffect, useState } from "react"

import ProductVariantHeader from "./header"
import CardVariationPreview from "./preview"
import EditProductVariantInformation from "./edit"
import EditProductVariantBackgrounds from "./backgrounds"

const ProductVariantInformation = ({
  productVariant,
  productVariantRefetch,
}) => {
  const { primaryCardVariation: cardVariation } = productVariant

  const [currentBackgroundOrdinal, setCurrentBackgroundOrdinal] = useState(1)
  const [currentBackground, setCurrentBackground] = useState({})

  useEffect(() => {
    const firstBackground = cardVariation.cardVariationBackgrounds.find(
      (background) => background.ordinal === currentBackgroundOrdinal
    )
    setCurrentBackground(firstBackground || {})
  }, [cardVariation, currentBackgroundOrdinal])

  const tabs = {
    Info: (
      <EditProductVariantInformation
        productVariant={productVariant}
        productVariantRefetch={productVariantRefetch}
      />
    ),
    Backgrounds: (
      <EditProductVariantBackgrounds
        currentBackground={currentBackground}
        currentBackgroundOrdinal={currentBackgroundOrdinal}
        productVariant={productVariant}
        productVariantRefetch={productVariantRefetch}
        setCurrentBackgroundOrdinal={setCurrentBackgroundOrdinal}
      />
    ),
  }
  const [currentTab, setCurrentTab] = useState("Info")
  const tabContent = tabs[currentTab]

  return (
    <div className="grid grid-cols-4">
      <div className="col-span-2 p-10">
        <ProductVariantHeader productVariant={productVariant} />

        <nav className="bg-white dark:bg-dark-800 flex flex-col ml-10">
          <div className="border-b border-gray-200 dark:border-dark-700 px-4 font-normal flex flex-row justify-between">
            <div>
              {Object.keys(tabs).map((option) => (
                <button
                  key={option}
                  className={`${
                    currentTab === option
                      ? "border-purple-500 text-purple-600 hover:text-purple-700 font-medium"
                      : "border-transparent text-gray-500 hover:text-gray-700"
                  } whitespace-nowrap py-4 px-1 border-b-2 mr-2 cursor-pointer text-sm`}
                  onClick={() => setCurrentTab(option)}
                  type="button"
                >
                  {option}
                </button>
              ))}
            </div>
          </div>
        </nav>
        <div className="py-4">{tabContent}</div>
      </div>
      <CardVariationPreview
        brand={productVariant?.brand}
        cardVariation={cardVariation}
        currentCardVariationBackground={currentBackground}
      />
    </div>
  )
}

export default ProductVariantInformation
