import gql from "graphql-tag"

const PRESIGN_UPLOAD_URL_MUTATION = gql`
  mutation PresignPublicAssetUploadUrl(
    $input: PresignPublicAssetUploadUrlInput!
  ) {
    presignPublicAssetUploadUrl(input: $input) {
      url
    }
  }
`

export { PRESIGN_UPLOAD_URL_MUTATION }
export default PRESIGN_UPLOAD_URL_MUTATION
