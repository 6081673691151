const formatHtmlContent = (content) => {
  /**
   * We use a <delete-me> tag to mark the start and end of each line of the
   * rich text content. This function removes the <delete-me> tags and replaces
   * the line breaks with <br> tags.
   */
  const formattedContent = content
    .replace(/<\/delete-me><delete-me>/g, "<br>")
    .replace(/<delete-me><\/delete-me>/g, "<br>")
    .replace(/<delete-me>/g, "")
    .replace(/<\/delete-me>/g, "")

  if (formattedContent === "<br>") {
    return ""
  }
  return formattedContent
}

export default formatHtmlContent
export { formatHtmlContent }
