const GRADIENTS = [
  {
    key: 0,
    textColor: "ffffff",
    gradient: ["7918ac", "ee422e"],
  },
  {
    key: 1,
    textColor: "111111",
    gradient: ["f76b1c", "fbda61"],
  },
  {
    key: 2,
    textColor: "ffffff",
    gradient: ["116672", "83e162"],
  },
  {
    key: 3,
    textColor: "111111",
    gradient: ["2594ff", "a5feca"],
  },
  {
    key: 4,
    textColor: "ffffff",
    gradient: ["2734e1", "7681f3"],
  },
  {
    key: 5,
    textColor: "111111",
    gradient: ["ffacc6", "c7ecff"],
  },
]

export default GRADIENTS
