import React from "react"

import { Switch, Route } from "react-router-dom"
import BrandShare from "./qr-sheet"

const ShareRouter = () => (
  <Switch>
    <Route component={BrandShare} path="/share/:brandSharePageSecret/qr" />
  </Switch>
)

export default ShareRouter
