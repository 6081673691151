import React from "react"
import { MEASUREMENTS as Iphone11ProMaxMeasurements } from "components/containers/iphone-11-pro-max"
import { SelectorText } from "components/containers/iphone-fonts"

const Selector = ({ children, borderColor }) => (
  <div
    className="flex"
    style={{
      border: `3px solid ${borderColor}`,
      borderRadius: Iphone11ProMaxMeasurements.cardSelector.borderRadius,
      paddingLeft: Iphone11ProMaxMeasurements.cardSelector.paddingX,
      paddingRight: Iphone11ProMaxMeasurements.cardSelector.paddingX,
      paddingTop: Iphone11ProMaxMeasurements.cardSelector.paddingY,
      paddingBottom: Iphone11ProMaxMeasurements.cardSelector.paddingY,
      color: borderColor,
      ...SelectorText,
    }}
  >
    {children}
  </div>
)

export default Selector
