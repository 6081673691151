import gql from "graphql-tag"

const UPDATE_FEATURED_CONTENT_HEADER_MUTATION = gql`
  mutation UpdateExperienceSectionFeaturedContentHeaderDetails(
    $input: UpdateExperienceSectionFeaturedContentHeaderDetailsInput!
  ) {
    updateExperienceSectionFeaturedContentHeaderDetails(input: $input) {
      errors
      experience {
        id
      }
    }
  }
`

export { UPDATE_FEATURED_CONTENT_HEADER_MUTATION }

export default UPDATE_FEATURED_CONTENT_HEADER_MUTATION
