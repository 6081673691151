import React from "react"

import { Switch, Redirect } from "react-router-dom"
import Route from "components/route"
import ShopifyThankYou from "app/oauth/shopify-thank-you"
import Admin from "app/admin"
import Brand from "app/brand"
import Share from "app/brand-share"
import Onboarding from "./onboarding"
import StripeConnected from "./onboarding/stripe-connected"
import Login from "./admin/login"

const Router = () => (
  <Switch>
    <Route component={Login} exact path="/login" />

    <Route component={ShopifyThankYou} exact path="/shopify/thank-you" />

    <Route
      component={StripeConnected}
      exact
      path="/onboarding/stripe-connected"
    />
    <Route component={Onboarding} path="/onboarding/:key" />

    <Route component={Admin} path="/admin" requireAuth />
    <Route component={Brand} path="/brands/:brandKey" requireAuth />
    <Route component={Share} path="/share" />

    <Redirect to="/admin/brands" />
  </Switch>
)

export default Router
