import React from "react"
import { ReactComponent as LoadingIcon } from "assets/icons/button-spinner.svg"
import classnames from "classnames"

const SubmitButton = ({
  submitting = false,
  cta = "Save",
  slim = false,
  className = "",
}) => (
  <button
    className={classnames(
      submitting
        ? "bg-gray-600 cursor-not-allowed"
        : "bg-gray-800 hover:bg-gray-900",
      "border border-transparent rounded-md shadow-sm inline-flex justify-center text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900",
      slim
        ? "font-normal px-2.5 py-1.5 text-xs"
        : "px-4 py-2 text-sm font-medium",
      className
    )}
    disabled={submitting}
    style={{ lineHeight: slim && "1.75" }}
    type="submit"
  >
    {submitting && <LoadingIcon className="w-4 h-4 mr-2 pt-1" />}
    {cta}
  </button>
)

export default SubmitButton
