import React, { useMemo } from "react"
import Uppy from "@uppy/core"
import AwsS3 from "@uppy/aws-s3"
import { useToasts } from "react-toast-notifications"
import { useMutation } from "@apollo/client"
import { DashboardModal } from "@uppy/react"
import { PRESIGN_UPLOAD_URL_MUTATION } from "./queries"

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"

const ImageUploadModal = ({
  visible,
  note,
  onClose,
  onImageUploadComplete,
  uploadFolder,
  maxFileSize = 1024 * 1024 * 8, // 8 MB
  allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"],
  brandKey,
}) => {
  const { addToast } = useToasts()

  const [presignUploadUrl, _] = useMutation(PRESIGN_UPLOAD_URL_MUTATION, {
    errorPolicy: "all",
  })

  // if (loading) return <LoadingModal />

  const uppyConfig = useMemo(() => {
    const uppy = new Uppy({
      meta: { type: "test" },
      autoProceed: true,
      // debug: true,
      allowMultipleUploads: false,
      restrictions: {
        maxFileSize,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes,
      },
    })
      .use(AwsS3, {
        async getUploadParameters(file) {
          const {
            data: {
              presignPublicAssetUploadUrl: { url },
            },
          } = await presignUploadUrl({
            variables: {
              input: {
                folder: uploadFolder,
                filename: file.name,
                contentType: file.type,
                brandKey,
              },
            },
          })

          return {
            url,
            method: "PUT",
            fields: [],
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": file.type,
            },
          }
        },
      })
      .on("upload-error", (file, error) => {
        // eslint-disable-next-line no-console
        console.log("upload failed", error)
        addToast("Something went wrong, the file did not upload!", {
          appearance: "error",
        })
      })
      .on("upload-success", (file, response) => {
        onImageUploadComplete(response.body.location)
        onClose()
      })

    return uppy
  }, [
    addToast,
    allowedFileTypes,
    maxFileSize,
    onClose,
    onImageUploadComplete,
    presignUploadUrl,
    uploadFolder,
    brandKey,
  ])
  // .use(XHRUpload, {
  //   endpoint: 'https://storage.googleapis.com/video-storage-us-east1-uploads/g3puffJf1OaJtwt01THGA007W9SLf7jVogXXwyP3OIhUo?Expires=1613499703&GoogleAccessId=mux-direct-upload%40mux-cloud.iam.gserviceaccount.com&Signature=YxaAWqSogFmea0D8luvZsLIhsxPOmPLR1t66QyZnEyAs%2FmKhNTysvKMZQ7lJgmmupYnFj0F7wKSeKIr0jR88CKBuVo251KwwY0%2BxU7%2FJxy%2BYrVQj96EFpaMsseBh8IF9TJvN2AQN%2BM1rnKhGDGG9BEuplKYF1PUzALyse7OBTwR7ytkAXrn4b7%2B%2Fe4rjfvKOkBc5589iuoGKOS%2FJTs3bBSvpCMAzFWz3n5KIOSEBtzGsOTzP9ZZfVQZnE%2F%2BpLsg5pL%2FDycKNV49vC9aJGS3lzWIj1yT5VxJ6CDHDnVkCnbN161a4%2F2nv6ow76grjffp%2F%2BX1FcioUOyAcHk1n6C8a4A%3D%3D&upload_id=ABg5-Uw4IvM_Bvas9v6lxywXzFxH1lanBtH58-g-k2fJN41P6OM6z0qOtcn8l-i3P8kJgZhkntXCtcrSvVMu35N6oWg',
  //   formData: false,
  //   method: 'PUT'
  // })

  return (
    <DashboardModal
      closeModalOnClickOutside
      note={note}
      onRequestClose={onClose}
      open={visible}
      plugins={[]}
      uppy={uppyConfig}
    />
  )
}

export default ImageUploadModal
