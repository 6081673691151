import React from "react"
import useDisclosure from "hooks/use-disclosure"
import { PrimaryButton } from "components/button"
import { useEditor } from "contexts/experience-v2-editor"

import IncentiveForm from "./form"
import CreateIncentiveModal from "./create-incentive-modal"

const Incentives = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { currentCardExperience } = useEditor()

  const { incentives } = currentCardExperience

  return (
    <div>
      {incentives.map((incentive) => (
        <>
          <h3 className="font-bold">
            {incentive.incentiveType.replace(/_/g, " ").toUpperCase()}
          </h3>
          <IncentiveForm key={incentive.id} incentive={incentive} />
          <div className="border-t border-gray-300 my-8" />
        </>
      ))}
      <CreateIncentiveModal onClose={onClose} visible={isOpen} />
      {incentives.length < 2 && (
        <PrimaryButton onClick={onOpen}>Add New Incentive</PrimaryButton>
      )}
    </div>
  )
}

export default Incentives
