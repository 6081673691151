import gql from "graphql-tag"

const UPDATE_CARD_EXPERIENCE = gql`
  mutation UpdateCardExperience($input: UpdateCardExperienceInput!) {
    updateCardExperience(input: $input) {
      errors
      cardExperience {
        id
      }
    }
  }
`

const DELETE_CARD_EXPERIENCE = gql`
  mutation DeleteCardExperience($input: DeleteCardExperienceInput!) {
    deleteCardExperience(input: $input) {
      errors
    }
  }
`

export { UPDATE_CARD_EXPERIENCE, DELETE_CARD_EXPERIENCE }
