import React from "react"
import SidebarGroupHeader from "./sidebar-group-header"
import SidebarExperienceSection from "./sidebar-experience-section"
import SidebarGroup from "./sidebar-group"

const Sidebar = ({ children }) => (
  <div className="col-span-2 bg-white border-r shadow-lg flex flex-col relative overflow-hidden bg-black h-full">
    <div className="overflow-y-scroll shadow-inset pb-80 pt-4">{children}</div>
  </div>
)

export default Sidebar

export { Sidebar, SidebarGroupHeader, SidebarGroup, SidebarExperienceSection }
