import React from "react"
import CloseableConfigSection from "components/closeable-config-section"

import FormComponent from "./form"

const ProductVariantCatalogSection = () => (
  <CloseableConfigSection
    subtitle="Customize the native popup card for App Clips."
    title="App Clip Preview Card"
  >
    <FormComponent />
  </CloseableConfigSection>
)

export default ProductVariantCatalogSection
