import Card from "components/card"
import Center from "components/layout/center"
import React from "react"
import stripe from "assets/images/stripe.svg"
import batch from "assets/images/batch-new-logo-horizontal.svg"
import "./index.css"

const StripeConnected = () => (
  <Center className="h-screen bg-gray-100 flex flex-col pb-16 stripe-background">
    <img alt="stripe-logo" className="w-52 mb-16" src={batch} />
    <Card className="text-center p-10 flex flex-col items-center">
      <img alt="stripe-logo" className="w-44 mb-8" src={stripe} />
      <h1 className="font-semibold text-xl mb-4">
        Stripe Connected Successfully
      </h1>
      <span>You can close this tab now</span>
    </Card>
  </Center>
)

export default StripeConnected
