import React, { useMemo } from "react"
import ReactHlsPlayer from "react-hls-player"

const VideoBackground = ({
  currentCardVariationBackground: {
    backgroundVideoSrc,
    backgroundVideoFirstFrameSrc,
  },
}) => {
  const videoContainer = useMemo(() => {
    if (!backgroundVideoSrc) return null

    if (backgroundVideoSrc.endsWith(".mp4")) {
      return (
        <video
          key={backgroundVideoSrc}
          autoPlay
          className="min-w-full min-h-full object-cover"
          loop
          muted
          style={{
            minHeight: "100%",
            minWidth: "100%",
          }}
        >
          <source src={backgroundVideoSrc} type="video/mp4" />
        </video>
      )
    }

    return (
      <div className="z-0 top-0 absolute right-0 left-0">
        <ReactHlsPlayer autoplay controls url={backgroundVideoSrc} />
      </div>
    )
  }, [backgroundVideoSrc])

  return (
    <div
      className="h-full w-full flex flex-col overflow-hidden flex min-h-full"
      style={{
        backgroundImage: `url(${backgroundVideoFirstFrameSrc})`,
      }}
    >
      {videoContainer}
    </div>
  )
}

export default VideoBackground
