import React from "react"

import { MIGRATE_QR_CODE_TO_BATCH_TAG } from "./queries"
import BaseBatchTagModal from "./base-batch-tag-modal"

const MigrateQRCodeModal = ({
  activation,
  cardExperience,
  onSuccess,
  onClose,
  show,
}) => {
  if (!show) return null

  const { name } = activation

  return (
    <BaseBatchTagModal
      activation={activation}
      cardExperience={cardExperience}
      cta="Migrate"
      migration={MIGRATE_QR_CODE_TO_BATCH_TAG}
      onClose={onClose}
      onSuccess={onSuccess}
      show={show}
      title={
        <>
          Migrate <em>{name}</em> to a Batch Tag
        </>
      }
    />
  )
}

export default MigrateQRCodeModal
