import React from "react"
import { useEditor } from "contexts/experience-v2-editor"

import BatchForm from "components/batch-form"
import SubmitButton from "components/submit-button"
import FormikInput from "components/forms/formik-input"
import { Form } from "formik"
import * as Yup from "yup"

import { UPDATE_CARD_EXPERIENCE } from "./queries"

const GridEditor = () => {
  const { currentCardExperience, refetchCardExperience: refetch } = useEditor()

  const formSchema = Yup.object().shape({
    gridTitle: Yup.string()
      .required("Required")
      .max(80, "Must be 80 characters or less"),
  })

  const { id, gridTitle } = currentCardExperience

  return (
    <BatchForm
      formSchema={formSchema}
      initialValues={{
        cardExperienceId: id,
        gridTitle,
      }}
      mutation={UPDATE_CARD_EXPERIENCE}
      onSuccess={refetch}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="my-4">
            <FormikInput label="Grid Title" name="gridTitle" type="text" />
          </div>

          <SubmitButton cta="Save" submitting={isSubmitting} />
        </Form>
      )}
    </BatchForm>
  )
}

export default GridEditor
