const isColorLight = (color) => {
  const r = parseInt(color.substr(0, 2), 16)
  const g = parseInt(color.substr(2, 2), 16)
  const b = parseInt(color.substr(4, 2), 16)
  // Using relative luminance: https://en.wikipedia.org/wiki/Relative_luminance
  const y = 0.2126 * r + 0.7152 * g + 0.0722 * b
  return y > 127.5
}

export default isColorLight
