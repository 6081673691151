import React from "react"

import { ReactComponent as QuestionCircleIcon } from "assets/icons/question-circle.svg"
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/outline"

const HeadlineStats = () => {
  /* eslint-disable react/no-multi-comp */
  const IncreaseArrow = () => (
    <ArrowSmUpIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500" />
  )
  const DecreaseArrow = () => (
    <ArrowSmDownIcon className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500" />
  )

  return (
    <div>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        Last 30 days
      </h3>
      <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
        <div>
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">
              Total Revenue
            </dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                $71,897
                <span className="ml-2 text-sm font-medium text-gray-500">
                  from $70,946
                </span>
              </div>

              <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                <IncreaseArrow />
                <span className="sr-only">Increased by</span>
                12%
              </div>
            </dd>
          </div>
        </div>

        <div>
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">
              Tap-to-Purchase Conversion
            </dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                58.16%
                <span className="ml-2 text-sm font-medium text-gray-500">
                  from 56.14%
                </span>
              </div>

              <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
                <IncreaseArrow />
                <span className="sr-only">Increased by</span>
                2.02%
              </div>
            </dd>
          </div>
        </div>

        <div>
          <div className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900 flex flex-row">
              APM
              <QuestionCircleIcon className="ml-1 flex-shrink-0 h-4 w-4 mt-1 cursor-hover text-gray-300" />
            </dt>
            <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                24.57
                <span className="ml-2 text-sm font-medium text-gray-500">
                  28.62
                </span>
              </div>

              <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800 md:mt-2 lg:mt-0">
                <DecreaseArrow />
                <span className="sr-only">Decreased by</span>
                4.05%
              </div>
            </dd>
          </div>
        </div>
      </dl>
    </div>
  )
}
export default HeadlineStats
