import React from "react"
import { ToastProvider } from "react-toast-notifications"

import { AdminAuthenticationProvider } from "contexts/admin-authentication"
import { ApolloProvider } from "@apollo/client"
import client from "config/apollo-admin"
import { BrowserRouter } from "react-router-dom"
import DevelopmentWarning from "./development-warning"
import Router from "./router"

const App = () => (
  <ToastProvider
    autoDismiss
    autoDismissTimeout={3000}
    placement="bottom-center"
  >
    <BrowserRouter>
      <ApolloProvider client={client}>
        <AdminAuthenticationProvider>
          <div className="h-screen w-screen flex flex-col">
            <div className="flex flex-col flex-1 w-full overflow-hidden">
              <Router />
            </div>
            <DevelopmentWarning />
          </div>
        </AdminAuthenticationProvider>
      </ApolloProvider>
    </BrowserRouter>
  </ToastProvider>
)

export default App
