import React from "react"
import * as Yup from "yup"
import ToggleForm from "components/toggle-form"
import FormikInput from "components/forms/formik-input"
import BatchForm from "components/batch-form"
import { UPDATE_BRAND_SHOPIFY_MUTATION } from "./queries"

const formSchema = Yup.object().shape({
  shopifyApiKey: Yup.string().nullable(),
  shopifyApiSecret: Yup.string().nullable(),
  shopifyMerchantInstallLink: Yup.string().nullable(),
})

const ShopifyForm = ({
  refetchBrand,
  hasShopifyToken,
  shopifyDomain,
  shopifyApiKey,
  shopifyApiSecret,
  shopifyMerchantInstallLink,
  shopifyMerchantInstallLinkExpiresAtDistance,
}) => (
  <BatchForm
    enableReinitialize
    formSchema={formSchema}
    initialValues={{
      shopifyDomain,
      shopifyApiKey,
      shopifyApiSecret,
      shopifyMerchantInstallLink,
    }}
    mutation={UPDATE_BRAND_SHOPIFY_MUTATION}
    onSuccess={refetchBrand}
  >
    {({ isSubmitting }) => (
      <ToggleForm isSubmitting={isSubmitting} title="Shopify">
        {({ isOpen }) => (
          <>
            <div className="py-1 text-sm text-gray-600 flex flex-col gap-1">
              <p>
                First, you’ll need to set up a Shopify Custom App.
                <a
                  className="pl-1 cursor-pointer text-purple-500 hover:text-purple-600 font-medium"
                  href="https://www.notion.so/getbatch/Custom-App-Setup-in-Shopify-Stripe-64492ac2c76f4f368ba6ccc5f6ed1ec5"
                  rel="noreferrer"
                  target="_blank"
                >
                  &nbsp;Instructions<span>&#8599;</span>
                </a>
                <a
                  className="pl-1 cursor-pointer text-purple-500 hover:text-purple-600 font-medium"
                  href="https://partners.shopify.com/1805929/apps/new"
                  rel="noreferrer"
                  target="_blank"
                >
                  &nbsp;Shopify<span>&#8599;</span>
                </a>
              </p>
              <p>
                Then, add the Api Key, Api Secret, and Install link below. After
                the brand connects, it will update our system automatically.
              </p>
              <p>You can set the brand’s Shopify domain below.</p>
            </div>

            <FormikInput
              disabled={hasShopifyToken}
              isTextOnly={!isOpen}
              label="Shopify Domain"
              name="shopifyDomain"
              placeholder="test"
              suffix=".myshopify.com"
              type="text"
            />
            <FormikInput
              isTextOnly={!isOpen}
              label="Shopify API Key"
              name="shopifyApiKey"
              placeholder="43f480b8f0951b3611f7ed6c797dc501"
              type="text"
            />
            <FormikInput
              isTextOnly={!isOpen}
              label="Shopify API Secret"
              name="shopifyApiSecret"
              placeholder="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"
              type="text"
            />
            <FormikInput
              hint={shopifyMerchantInstallLinkExpiresAtDistance}
              isTextOnly={!isOpen}
              label="Shopify Merchant Install Link"
              name="shopifyMerchantInstallLink"
              placeholder="https://store.myshopify.com/admin/oauth/install_custom_app?client_id=..."
              showCopyButton
              type="text"
            />
          </>
        )}
      </ToggleForm>
    )}
  </BatchForm>
)

export default ShopifyForm
