export const BodySmall = {
  fontSize: 12,
  fontWeight: 500,
  lineHeight: 16 / 12,
  letterSpacing: -0.2,
}

export const SelectorText = {
  fontSize: 14,
  fontWeight: 600,
  lineHeight: 16 / 14,
  height: 16,
  letterSpacing: -0.2,
}

export const LegalText = {
  fontSize: 10,
  fontWeight: 300,
  lineHeight: 14 / 10,
  height: 14,
  letterSpacing: 0.2,
}

export const H1 = {
  fontSize: 28,
  fontWeight: 600,
  lineHeight: 32 / 28,
  height: 32,
  letterSpacing: 0.1,
}

export const H2 = {
  fontSize: 16,
  fontWeight: 600,
  lineHeight: 20 / 16,
  height: 20,
  letterSpacing: 0.07,
}

export const Body = {
  fontSize: 16,
  fontWeight: 400,
  lineHeight: 20 / 16,
  height: 20,
  letterSpacing: -0.04,
}
