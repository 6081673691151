import React from "react"
import { useParams } from "react-router-dom"
import GetQueryLoader from "components/get-query-loader"
import { GET_EXPERIENCE } from "./queries"
import List from "./list"

const QRCodes = () => {
  const { experienceId } = useParams()

  return (
    <div className="flex flex-col px-8">
      <GetQueryLoader
        query={GET_EXPERIENCE}
        queryVariables={{
          id: experienceId,
        }}
      >
        {({ experience }, refetch) => (
          <List experience={experience} experienceRefetch={refetch} />
        )}
      </GetQueryLoader>
    </div>
  )
}

export default QRCodes
