import React from "react"
import { Card } from "@material-ui/core"
import Center from "components/layout/center"
import Row from "components/layout/row"
import { CheckIcon } from "@heroicons/react/outline"
import { PrimaryButton } from "components/button"

const OnboardingIntegrationCard = ({
  image,
  imageWidth = 100,
  alt,
  title,
  description,
  buttonText = "Connect",
  connectedText = "Connected",
  disabled,
  isConnected,
  onConnectClick,
}) => {
  const getButton = () => {
    if (isConnected) {
      return (
        <PrimaryButton
          buttonClassName={{}}
          className="bg-indigo-50 rounded-lg flex p-2 px-4 text-indigo-700 font-semibold cursor-default"
          disabled
        >
          <CheckIcon className="h-6 w-6  mr-2" />
          <span>{connectedText}</span>
        </PrimaryButton>
      )
    }

    return (
      <PrimaryButton
        buttonClassName={{}}
        className="bg-indigo-700 rounded-lg flex p-2 px-4 text-white"
        onClick={onConnectClick}
      >
        {buttonText}
      </PrimaryButton>
    )
  }

  return (
    <Card className="mb-4 p-4 relative">
      <Row>
        <Center className="w-28">
          <img alt={alt} data-testid="logo" src={image} width={imageWidth} />
        </Center>
        <div className="flex-1 ml-4">
          <p className="font-bold">{title}</p>
          <span className="text-gray-500 text-sm">{description}</span>
        </div>
        {getButton()}
      </Row>
      {disabled && (
        <div className="w-full h-full bg-gray-100 absolute top-0 left-0 bg-opacity-50" />
      )}
    </Card>
  )
}

export default OnboardingIntegrationCard
