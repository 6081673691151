import React from "react"
import classNames from "classnames"

const Row = ({ children, className, ...rest }) => (
  <div
    className={classNames(
      "flex flex-row justify-between items-center",
      className
    )}
    {...rest}
  >
    {children}
  </div>
)

export default Row
