import React from "react"
import Time from "react-time-format"
import { useRouteMatch, useHistory } from "react-router-dom"
import ActivatonAppStoreConnectStatus from "components/activation-app-store-connect-status"

const ActivationRow = ({ activation }) => {
  const { url: currentUrl } = useRouteMatch()
  const history = useHistory()

  const { id, name, url, createdAt, appStoreConnectStatus } = activation

  return (
    <tr
      className="hover:bg-gray-100 cursor-pointer"
      onClick={() => history.push(`${currentUrl}/${id}`)}
    >
      <td className="px-2 py-4 whitespace-nowrap">{name}</td>
      <td className="px-2 py-4 whitespace-nowrap text-gray-500 text-xs font-bold ">
        <ActivatonAppStoreConnectStatus status={appStoreConnectStatus} />
        &nbsp;{appStoreConnectStatus.toUpperCase()}
      </td>
      <td className="px-2 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex flex-row">
            <div className="text-sm font-medium text-gray-600 ml-2">{url}</div>
          </div>
        </div>
      </td>
      <td className="px-2 py-4 whitespace-nowrap">
        <Time format="MM/DD/YYYY" value={createdAt} />
      </td>
    </tr>
  )
}

export default ActivationRow
