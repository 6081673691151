import React from "react"

const CutoutBackground = ({
  currentCardVariationBackground: { backgroundCutoutSrc },
}) => (
  <div className="w-full flex items-center h-full">
    <img
      alt="Default variant cutout."
      className="flex-grow animate-cutout"
      src={backgroundCutoutSrc}
    />
  </div>
)

export default CutoutBackground
