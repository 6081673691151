import gql from "graphql-tag"

const UPDATE_DISCOUNT_SECTION_MUTATION = gql`
  mutation UpdateExperienceSectionOrderDiscountDetails(
    $input: UpdateExperienceSectionOrderDiscountDetailsInput!
  ) {
    updateExperienceSectionOrderDiscountDetails(input: $input) {
      errors
      experience {
        id
      }
    }
  }
`

export default UPDATE_DISCOUNT_SECTION_MUTATION

export { UPDATE_DISCOUNT_SECTION_MUTATION }
