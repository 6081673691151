import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import AppleForm from "./apple-form"
import GeneralForm from "./general-form"

const Tabs = ({ activation, refetchActivation }) => {
  const queryParams = new URLSearchParams(useLocation().search)
  const tab = queryParams.get("tab")

  let initialTabState = "General"
  if (tab === "app-clip") {
    initialTabState = "Apple App Clip"
  }
  const [currentTab, setCurrentTab] = useState(initialTabState)

  const tabs = {
    General: (
      <GeneralForm
        activation={activation}
        refetchActivation={refetchActivation}
      />
    ),
    "Apple App Clip": (
      <AppleForm
        activation={activation}
        refetchActivation={refetchActivation}
      />
    ),
  }
  const tabContent = tabs[currentTab]

  return (
    <div>
      <nav className="bg-white dark:bg-dark-800 flex flex-col">
        <div className="border-b border-gray-200 dark:border-dark-700 font-normal flex flex-row justify-between">
          <div>
            {Object.keys(tabs).map((option) => (
              <button
                key={option}
                className={`${
                  currentTab === option
                    ? "border-purple-500 text-purple-600 hover:text-purple-700 font-medium"
                    : "border-transparent text-gray-500 hover:text-gray-700"
                } whitespace-nowrap py-4 px-1 border-b-2 mr-2 cursor-pointer text-sm`}
                onClick={() => setCurrentTab(option)}
                type="button"
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      </nav>
      <div className="py-4">{tabContent}</div>
    </div>
  )
}

export default Tabs
