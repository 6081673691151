import React, { useState } from "react"
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google"
import { useMutation } from "@apollo/client"
import { LOGIN_WITH_GOOGLE_MUTATION } from "./queries"

const { REACT_APP_GOOGLE_OAUTH_CLIENT_ID } = process.env

const GoogleLoginButton = ({ onSuccess }) => {
  const [googleError, setGoogleError] = useState(null)

  const onFailure = () => {
    setGoogleError("Something went wrong, please try again.")
  }

  const onScriptLoadFailure = () => {
    if (window.location.hostname.includes(".netlify.app")) {
      setGoogleError(
        "Google Oauth sign-in not available in Netlify Preview Apps."
      )
    } else if (window.location.hostname.includes("localhost")) {
      setGoogleError("Google Oauth sign-in not available on localhost.")
    } else {
      setGoogleError("Google Oauth sign-in did not load properly.")
    }
  }

  const [loginBackendWithGoogle] = useMutation(LOGIN_WITH_GOOGLE_MUTATION, {
    onError: onFailure,
    onCompleted: ({ loginWithGoogle }) => onSuccess(loginWithGoogle.user),
  })

  const onOauthSuccess = async ({ credential }) => {
    await loginBackendWithGoogle({
      variables: {
        input: {
          googleToken: credential,
        },
      },
    })
  }

  return (
    <div className="flex-col mb-2 flex flex-col items-center">
      <GoogleOAuthProvider
        clientId={REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
        onScriptLoadError={onScriptLoadFailure}
      >
        <GoogleLogin onError={onFailure} onSuccess={onOauthSuccess} />

        {googleError && (
          <p className="mt-2 text-center max-w text-red-700 text-sm">
            {googleError}
          </p>
        )}
      </GoogleOAuthProvider>
    </div>
  )
}

export default GoogleLoginButton
