import React from "react"
import { useParams } from "react-router-dom"
import { Form } from "formik"
import * as Yup from "yup"
import { PlusCircleIcon } from "@heroicons/react/outline"
import Button from "components/button"
import BatchForm from "components/batch-form"
import SubmitButton from "components/submit-button"
import FormikInput from "components/forms/formik-input"
import SmallFormModal, {
  ModalFooter,
  ModalBody,
} from "components/small-form-modal"
import { CREATE_ACTIVATION_LINK_MUTATION } from "./queries"

const NewLinkModal = ({ visible, onClose, experience, experienceRefetch }) => {
  const { experienceId } = useParams()

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  })

  return (
    <SmallFormModal onClose={onClose} visible={visible}>
      {({ close }) => (
        <BatchForm
          additionalValues={{
            experienceId,
          }}
          initialValues={{
            name: "",
          }}
          mutation={CREATE_ACTIVATION_LINK_MUTATION}
          onSuccess={() => {
            experienceRefetch()
            onClose()
          }}
          validationSchema={formSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody
                description={
                  <>
                    Generate a new link to the{" "}
                    <span className="font-semibold">{experience.name}</span>{" "}
                    experience.
                  </>
                }
                icon={
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PlusCircleIcon className="w-6 h-6 text-blue-600" />
                  </div>
                }
                title="New Link"
              >
                <div className="col-span-6 sm:col-span-4">
                  <FormikInput
                    autoFocus
                    label="Link Name"
                    name="name"
                    placeholder="Holiday box QR codes"
                    type="text"
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <SubmitButton submitting={isSubmitting} />
                <Button className="mr-2" onClick={close}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </BatchForm>
      )}
    </SmallFormModal>
  )
}

export default NewLinkModal
