import React, { useState } from "react"
import classnames from "classnames"

import Toggle from "components/toggle"

const SidebarExperienceSection = ({
  onClick,
  isCurrent,
  title,
  subtitle,
  icon,
  toggle = undefined,
  onToggle,
}) => {
  const [enabled, setEnabled] = useState(toggle)

  return (
    <li>
      <button
        className={`${
          isCurrent ? "bg-purple-100" : ""
        } cursor-pointer text-gray-800 flex flex-row items-center w-full justify-between text-left`}
        onClick={onClick}
        type="button"
      >
        <div
          className={classnames(
            isCurrent ? "text-purple-600" : "text-gray-300",
            "pl-3 flex-grow-0"
          )}
        >
          {icon}
        </div>

        <div className="px-4 py-3 flex-grow">
          <h2 className="text-md font-medium leading-tight tracking-tight">
            {title}
          </h2>
          <p className="text-gray-500 text-xs">{subtitle}</p>
        </div>
        {toggle !== undefined && (
          <div className="mr-3">
            <Toggle
              enabled={enabled}
              setEnabled={() => {
                setEnabled((prev) => !prev)
                if (onToggle) {
                  onToggle()
                }
              }}
            />
          </div>
        )}
      </button>
    </li>
  )
}

export default SidebarExperienceSection
