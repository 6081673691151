import React from "react"
import HeadlineStats from "./headline-stats"

const Home = () => (
  <>
    <header>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold leading-tight text-gray-900">
          Dashboard
        </h1>
      </div>
    </header>
    <main>
      <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 pt-10">
        <HeadlineStats />
      </div>
    </main>
  </>
)

export default Home
