import gql from "graphql-tag"

export const GET_CARD_EXPERIENCE = gql`
  query GetCardExperience($id: ID!, $brandKey: String!) {
    cardExperience(id: $id, brandKey: $brandKey) {
      id
      gridTitle
      name
      gridVersion
      hidden
      internalNickname
      incentives {
        incentiveType
        id
        details
        textColor
        backgroundGradient
        priority
      }
      logoHorizontalSrc
      logoHorizontalOverrideSrc
      notificationsFeature
      notificationTitle
      notificationSubtitle
      notificationBody
      notificationDelayMins
      brand {
        id
        key
        name
      }
      batchForWebEnabled
      activations {
        id
        name
        category
        url
        createdAt
        demoUrl
        testUrl
        qrCodeSvgUrl
        kind
        batchTagSvgUrl
        batchTagCtaLineOne
        batchTagCtaLineTwo
        batchTagShowPaymentIcons
        appleAppClipActive
        showLaunchCard
        fallbackUrl
        cardExperience {
          name
        }
      }
      launchCard {
        id
        hidden
        variationLabel
        screenBackgroundColor
        screenForegroundColor
        collapsedTitle
        collapsedBackgroundColor
        collapsedForegroundColor
        collapsedAccentColor
        collapsedForegroundOverAccentColor
        giftable
        giftFixedShippingPriceCents
        giftTheme
        collapsedBackgroundVideoSrc
        collapsedBackgroundType
        collapsedBackgroundImageSrc
        collapsedBackgroundCutoutSrc
        collapsedBackgroundVideoSrc
        collapsedBackgroundVideoFirstFrameSrc
        expandedBackgroundColor
        expandedForegroundColor
        expandedAccentColor
        expandedForegroundOverAccentColor
        linkOverrideUrl
        availableModes
        defaultMode
        buyButtonCtaVerb
        disableAuxButtons
        buyConfirmationModalTitle
        buyConfirmationModalBody
        buyConfirmationModalButton
        maxQuantity
        maxQuantityBoughtModalTitle
        maxQuantityBoughtModalBody
        maxQuantityBoughtModalButton
        unitsAvailable
        unitsConfirmed
        noUnitsAvailableModalTitle
        noUnitsAvailableModalBody
        noUnitsAvailableModalButton
        cardVariations {
          id
          title
          subtitle
          variationValue
          description
          price
          cardVariationBackgrounds {
            id
            ordinal
            backgroundType
            backgroundImageSrc
            backgroundCutoutSrc
            backgroundVideoSrc
            backgroundVideoFirstFrameSrc
          }
          cardVariationCards {
            id
            discount
            discountMethod
            discountBadgeColor
            discountBadgeTextColor
            discountedPrice
            discountBadgeSvg
            discountDisplayValue
            cardVariation {
              id
            }
            card {
              id
            }
          }
          productVariant {
            id
            price
            standaloneTitle
            subtitle
            imageSrcLarge
            imageSrcMedium
            imageSrcSmall
            shopifyVariantIdNumber
            deletedFromShopify
            standaloneTitle
            sku
          }
        }
      }
      cardExperienceCards {
        id
        ordinal
        card {
          id
          hidden
          variationLabel
          screenBackgroundColor
          screenForegroundColor
          collapsedTitle
          collapsedBackgroundColor
          collapsedForegroundColor
          collapsedAccentColor
          collapsedForegroundOverAccentColor
          collapsedBackgroundGradient
          giftable
          giftFixedShippingPriceCents
          giftTheme
          collapsedBackgroundVideoSrc
          collapsedBackgroundType
          collapsedBackgroundImageSrc
          collapsedBackgroundIconSrc
          collapsedBackgroundCutoutSrc
          collapsedBackgroundVideoSrc
          collapsedBackgroundVideoFirstFrameSrc
          expandedBackgroundColor
          expandedForegroundColor
          expandedAccentColor
          expandedForegroundOverAccentColor
          linkOverrideUrl
          availableModes
          defaultMode
          buyButtonCtaVerb
          disableAuxButtons
          buyConfirmationModalTitle
          buyConfirmationModalBody
          buyConfirmationModalButton
          maxQuantity
          maxQuantityBoughtModalTitle
          maxQuantityBoughtModalBody
          maxQuantityBoughtModalButton
          unitsAvailable
          unitsConfirmed
          noUnitsAvailableModalTitle
          noUnitsAvailableModalBody
          noUnitsAvailableModalButton
          cardVariations {
            id
            title
            subtitle
            variationValue
            description
            price
            cardVariationBackgrounds {
              id
              ordinal
              backgroundType
              backgroundImageSrc
              backgroundCutoutSrc
              backgroundVideoSrc
              backgroundVideoFirstFrameSrc
            }
            cardVariationCards {
              id
              discount
              discountMethod
              discountBadgeColor
              discountBadgeTextColor
              discountedPrice
              discountBadgeSvg
              discountDisplayValue
              cardVariation {
                id
              }
              card {
                id
              }
            }
            productVariant {
              id
              price
              standaloneTitle
              subtitle
              imageSrcLarge
              imageSrcMedium
              imageSrcSmall
              shopifyVariantIdNumber
              deletedFromShopify
              standaloneTitle
              sku
            }
          }
        }
      }
    }
  }
`

export default GET_CARD_EXPERIENCE
