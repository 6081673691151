import React, { useState, useEffect } from "react"
import { useEditor } from "contexts/experience-editor"
import ExperiencePreview from "./experience-preview"
import CardPreview from "./card-preview"
import { APP_CLIP_CARD_SECTION } from "../settings/app-clip-sections"

const Workspace = () => {
  const [zoom, setZoom] = useState(1)
  const { currentExperience, currentSection } = useEditor()

  const handleWindowResize = () => {
    const calculatedZoom = Math.min(
      (window.innerHeight -
        130 - // top nav height
        100) / // buffer
        607,
      1.8
    ) // iphone container size

    setZoom(calculatedZoom)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize)
    handleWindowResize() // run once

    return () => {
      // Not sure why this line is throwing a linting error...
      // eslint-disable-next-line no-unused-vars
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [currentExperience])

  if (!currentExperience) return null

  return (
    <main
      className="bg-gray-100 flex flex-col items-center col-span-6 overflow-y-scroll"
      style={
        // height of top nav
        { height: "calc(100vh - 7rem)" }
      }
    >
      <div className="flex flex-row justify-center items-center py-4">
        <p className="text-gray-500 text-xs font-medium">iPhone 12</p>
      </div>
      <div style={{ zoom }}>
        {currentSection === APP_CLIP_CARD_SECTION ? (
          <CardPreview zoom={zoom} />
        ) : (
          <ExperiencePreview zoom={zoom} />
        )}
      </div>
      <div className="w-full flex flex-row justify-center items-center mt-6" />
    </main>
  )
}

export default Workspace
