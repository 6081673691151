import React from "react"
import Drawer from "components/drawer"
import ExperienceCard from "./experience-card"

const SelectExperienceDrawer = ({
  isOpen,
  onClose,
  selectedBrand,
  refetchBrands,
}) => (
  <Drawer
    className="w-1/3 p-8"
    isOpen={isOpen}
    onClose={onClose}
    title="Select experience"
  >
    {(selectedBrand?.cardExperiences || []).map((cardExperience) => (
      <ExperienceCard
        key={cardExperience.id}
        brand={selectedBrand}
        cardExperience={cardExperience}
        closeDrawer={onClose}
        experienceOnCoreApp={selectedBrand?.coreAppCardExperience}
        refetchBrands={refetchBrands}
      />
    ))}
    {!selectedBrand?.cardExperiences.length && (
      <span>No experiences available</span>
    )}
  </Drawer>
)

export default SelectExperienceDrawer
