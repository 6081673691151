import React, { useEffect } from "react"
import { useAdminAuthentication } from "contexts/admin-authentication"
import { Route as ReactRouterDomRoute, useLocation } from "react-router-dom"

const Route = ({ requireAuth, ...rest }) => {
  const { currentAdminUser, authAndLoadAdminUser } = useAdminAuthentication()
  const location = useLocation()

  useEffect(() => {
    if (requireAuth) {
      authAndLoadAdminUser()
    }
  }, [location, requireAuth, authAndLoadAdminUser])

  if (!currentAdminUser && requireAuth) return null

  return <ReactRouterDomRoute {...rest} />
}

export default Route
