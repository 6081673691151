import React from "react"
import { useParams } from "react-router-dom"
import { Form } from "formik"
import * as Yup from "yup"

import { PlusCircleIcon } from "@heroicons/react/outline"
import BatchForm from "components/batch-form"
import Button from "components/button"
import SubmitButton from "components/submit-button"
import FormikInput from "components/forms/formik-input"
import Select from "components/forms/select"
import SmallFormModal, {
  ModalFooter,
  ModalBody,
} from "components/small-form-modal"

import { CREATE_NEXT_NFC_TAG_SERIES } from "./queries"

const NewSheetModal = ({ visible, onClose, activation, refetchActivation }) => {
  const { activationId } = useParams()

  const formSchema = Yup.object().shape({
    quantity: Yup.number()
      .positive("Must be greater than zero")
      .required("Required"),
    medium: Yup.string().required("Required"),
  })

  return (
    <SmallFormModal onClose={onClose} visible={visible}>
      {({ close }) => (
        <BatchForm
          formSchema={formSchema}
          generateValues={(generatedValues) => ({
            activationId,
            quantity: parseInt(generatedValues.quantity),
            medium: generatedValues.medium,
          })}
          initialValues={{
            quantity: 10000,
            medium: "box",
          }}
          mutation={CREATE_NEXT_NFC_TAG_SERIES}
          onSuccess={() => {
            refetchActivation()
            onClose()
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody
                description={
                  <>
                    This sheet will use the same link{" "}
                    <span className="font-semibold">{activation.name}</span> and
                    start at{" "}
                    <span className="font-semibold">
                      Tag Number #{activation.nextNfcTagNumber}
                    </span>
                    .
                  </>
                }
                icon={
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PlusCircleIcon className="h-6 w-6 text-blue-600" />
                  </div>
                }
                title="Next NFC Series Sheet"
              >
                <div className="col-span-6 sm:col-span-4">
                  <FormikInput
                    autoFocus
                    label="Quantity"
                    name="quantity"
                    placeholder="10000"
                    type="number"
                  />
                </div>
                <div className="col-span-6 sm:col-span-4 mt-2">
                  <Select
                    label="Placement"
                    name="medium"
                    options={{
                      sticker: "Sticker",
                      magnet: "Magnet",
                      insert: "Insert",
                      package: "Package ",
                      box_inlay: "Box Inlay",
                      box: "Box",
                      can: "Can",
                      digital: "Digital",
                      billboard: "Billboard",
                    }}
                    placeholder="Box"
                    type="text"
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <SubmitButton submitting={isSubmitting} />
                <Button className="mr-2" onClick={close}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </BatchForm>
      )}
    </SmallFormModal>
  )
}

export default NewSheetModal
