import React from "react"
import { Link } from "react-router-dom"

const ExperienceDescription = ({ brand, cardExperience }) => {
  if (!brand || !cardExperience) {
    return null
  }

  return (
    <div className="flex flex-col">
      <Link
        className="inline-flex text-indigo-500"
        rel="noopener noreferrer"
        target="_blank"
        to={`/brands/${brand.key}/experiences/${cardExperience.id}`}
      >
        <p className="font-semibold">{cardExperience.name}</p>
        <span className="mb-1 ml-1 text-md">&#8599;</span>
      </Link>
      <span className="text-xs text-grey-500">{cardExperience.id}</span>
    </div>
  )
}

export default ExperienceDescription
