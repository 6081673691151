import React from "react"

const ImagePickerPreview = ({ imageUrl, preview = false }) => {
  if (!preview) return null

  if (!imageUrl)
    return <p className="text-sm text-gray-600">No asset available</p>

  return (
    /* eslint-disable jsx-a11y/img-redundant-alt */
    <div className="bg-gray-100 p-4">
      {imageUrl.endsWith(".mp4") ? (
        <video
          alt="Video picker preview."
          autoPlay
          className="mt-1 h-20"
          loop
          muted
          src={imageUrl}
        />
      ) : (
        <img alt="Image picker preview." className="mt-1 h-20" src={imageUrl} />
      )}
    </div>
  )
}
export default ImagePickerPreview
