import gql from "graphql-tag"

const UPDATE_LOCATION_DISCOUNT_SECTION_MUTATION = gql`
  mutation UpdateExperienceSectionLocationOrderDiscountDetails(
    $input: UpdateExperienceSectionLocationOrderDiscountDetailsInput!
  ) {
    updateExperienceSectionLocationOrderDiscountDetails(input: $input) {
      errors
      experience {
        id
      }
    }
  }
`

export default UPDATE_LOCATION_DISCOUNT_SECTION_MUTATION

export { UPDATE_LOCATION_DISCOUNT_SECTION_MUTATION }
