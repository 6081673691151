import React from "react"
import { useField } from "formik"
import Label from "./label"
import Input from "./input"

const FormikInput = (props) => {
  const { name, onChange: passedOnChange, ...rest } = props

  const [field, { touched, error }, { setValue, setTouched }] = useField(name)

  const onChange = (e) => {
    const { value } = e.target

    if (passedOnChange) {
      passedOnChange(e, value)
    }

    setTouched()
    setValue(value)
  }

  return (
    <Input
      error={error}
      name={name}
      onChange={onChange}
      touched={touched}
      value={field.value}
      {...rest}
    />
  )
}

export default FormikInput

export { Label }
