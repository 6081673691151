import React from "react"
import Logo from "assets/images/batch-new-logo-horizontal-dark.svg"
import { NavLink } from "react-router-dom"
import Row from "components/layout/row"

import UserMenuDropdown from "./user-menu-dropdown"

const AppNav = () => {
  const activeNavLinkClassName =
    "bg-indigo-800 border-indigo-600 text-white group border-b-4 font-medium"
  const navLinkClassName =
    "border-transparent text-white hover:text-indigo-100 hover:bg-indigo-700 group pt-4 border-b-4 py-2 px-3 flex items-center text-sm"

  return (
    <Row className="bg-black h-12 items-center px-8 text-white justify-start">
      <img alt="Batch Logo" className="h-6 w-auto" src={Logo} />
      <div className="flex flex-1 flex-row h-full px-8">
        <NavLink
          activeClassName={activeNavLinkClassName}
          className={navLinkClassName}
          to="/admin/brands"
        >
          Brands
        </NavLink>
        <NavLink
          activeClassName={activeNavLinkClassName}
          className={navLinkClassName}
          to="/admin/core-app"
        >
          Core App
        </NavLink>
      </div>

      <UserMenuDropdown />
    </Row>
  )
}
export default AppNav
