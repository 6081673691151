/* eslint-disable import/prefer-default-export */
import gql from "graphql-tag"

export const UPDATE_BRAND_MUTATION = gql`
  mutation UpdateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      brand {
        id
      }
    }
  }
`
