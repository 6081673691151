import React, { useEffect, useState } from "react"

import Row from "components/layout/row"
import shopify from "assets/images/shopify.svg"
import stripe from "assets/images/stripe.svg"
import dropbox from "assets/images/dropbox.svg"
import mixmax from "assets/images/mixmax.svg"
import batchLogo from "assets/images/batch-new-logo-only.svg"
import { Card } from "@material-ui/core"
import Center from "components/layout/center"
import pusher from "utils/pusher"
import OnboardingIntegrationCard, {
  OnboardingIntegrationCardWithModal,
} from "./onboarding-integration-card"
import OnboardingBookmarkCard from "./onboarding-bookmark-card"

const OnboardingIndex = ({ brand }) => {
  const {
    name,
    logoImageSrc,
    stripeInstallLink,
    shopifyConnected,
    stripeConnected,
    dropboxFileRequestLink,
    shopifyMerchantInstallLink,
    publicRefreshToken,
    metabaseUrl,
    sharePageSecret,
  } = brand

  const mixmaxUrl = process.env.REACT_APP_MIXMAX_URL

  const [isStripeConnected, setIsStripeConnected] = useState(stripeConnected)
  const [isShopifyConnected, setIsShopifyConnected] = useState(shopifyConnected)

  useEffect(() => {
    const channel = pusher.subscribe(`onboarding-${publicRefreshToken}`)

    const callback = (eventName) => {
      if (eventName === "stripe_complete") {
        setIsStripeConnected(true)
      }
      if (eventName === "shopify_complete") {
        setIsShopifyConnected(true)
      }
    }
    // bind to all events on the channel
    channel.bind_global(callback)

    return () => pusher.unsubscribe(`onboarding-${publicRefreshToken}`)
  }, [publicRefreshToken])

  const openNewTab = (url) => {
    window.open(url, "_blank")
  }

  return (
    <div className="flex flex-col bg-gray-100 h-screen overflow-auto">
      <Row className="bg-white w-full h-16 shadow px-8">
        <Card className="w-16 h-12 p-1">
          <Center className="w-full h-full">
            <img alt="batch" className="h-full" src={batchLogo} />
          </Center>
        </Card>
        <Card className="w-16 h-12 p-1">
          <Center className="w-full h-full">
            <img alt="brand logo" className="h-full" src={logoImageSrc} />
          </Center>
        </Card>
      </Row>

      <div className="flex-1 w-full py-12">
        <div className="container mx-auto">
          <Row className="mb-8">
            <div>
              <h1 className="font-bold text-3xl pb-2 font-favorit">
                Welcome to Batch!
              </h1>
              <p className="text-gray-500">
                Let’s set up your integrations. The estimated time to complete
                is 20 minutes.
              </p>
            </div>
          </Row>
          <h2 className="font-bold mb-3 text-lg">Integrations</h2>
          <OnboardingIntegrationCard
            alt="shopify"
            description="Allow Batch to view your catalog and place orders into Shopify"
            image={shopify}
            isConnected={isShopifyConnected}
            onConnectClick={() => openNewTab(shopifyMerchantInstallLink)}
            title="Connect Shopify"
          />
          <OnboardingIntegrationCard
            alt="stripe"
            description="Batch will charge customers and deposit funds directly into your Stripe account"
            disabled={!isStripeConnected && !stripeInstallLink}
            image={stripe}
            imageWidth={70}
            isConnected={isStripeConnected}
            onConnectClick={() => openNewTab(stripeInstallLink)}
            title="Connect Stripe"
          />
          <OnboardingIntegrationCard
            alt="dropbox"
            buttonText="Upload"
            description="  Upload images, videos and dielines we can use to design your experience"
            image={dropbox}
            onConnectClick={() => openNewTab(dropboxFileRequestLink)}
            title="Upload Brand Assets"
          />
          <OnboardingIntegrationCardWithModal
            alt="mixmax"
            buttonText="Schedule"
            description="Meet with Batch and discuss your brand's experience, implementation, and timeline on a Zoom call"
            image={mixmax}
            title="Schedule Design Review"
          >
            <iframe
              frameBorder="0"
              src={mixmaxUrl}
              style={{ width: "100%", height: "580px" }}
              title="mixmax"
            />
          </OnboardingIntegrationCardWithModal>
          {/* <Row className="mt-16 mb-4 ">
              <h2 className="font-bold text-lg">QR Codes</h2>
              <PrimaryButton
                buttonClassName={{}}
                className="bg-indigo-700 rounded-lg flex p-2 px-4 text-white"
                onClick={onOpen}
              >
                <PlusIcon className="h-6 w-6 text-white mr-2" />
                <span>Create new QR Code</span>
              </PrimaryButton>
            </Row>
            <Card className="divide-y">
              {[1, 2, 3].map((item) => (
                <OnboardingQRDownload
                  key={item}
                  productLink="https://google.com"
                  qrLink="https://batch-activations-staging.s3.amazonaws.com/b1b8cfc2-7eb0-4509-89fb-e1e3aa501cfd/dtc_booklet__basilisk_breath_collection__amass.svg"
                  title="96oz cold brew hazelnut"
                />
              ))}
            </Card> */}

          <h2 className="font-bold mb-3 text-lg">Bookmarks</h2>
          <OnboardingBookmarkCard
            description="View documentation"
            title="Batch Documentation"
            url="https://docs.getbatch.com/help-center/"
          />
          <OnboardingBookmarkCard
            description="Contact our team for help"
            title="Email Batch"
            url={`mailto:success@getbatch.com?subject=${name}%20-%20Help%20Needed`}
          />
          {isStripeConnected && isShopifyConnected && (
            <>
              <OnboardingBookmarkCard
                description="View and download any of your Batch QR codes"
                title="QR Codes & Links"
                url={`/share/${sharePageSecret}/qr`}
              />
              <OnboardingBookmarkCard
                description="View transaction and status of all gift orders your customers have placed through Batch"
                title="Gift Orders"
                url="https://brands.getbatch.com/dashboard"
              />
              <OnboardingBookmarkCard
                description="Monitor performance of your QR codes and links"
                disabled={!metabaseUrl}
                title="Analytics"
                url={metabaseUrl}
              />
            </>
          )}
        </div>
      </div>
      {/* <NewLinkModal
          experience={{ name: "a" }}
          // eslint-disable-next-line prettier/prettier
        experienceRefetch={() => { }}
          onClose={onClose}
          visible={isOpen}
        /> */}
    </div>
  )
}

export default OnboardingIndex
