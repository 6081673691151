import React, { useEffect, useState } from "react"
import GridForm from "./grid-form"
import ExternalUrlForm from "./external-url-form"

const GeneralForm = ({ activation, refetchActivation }) => {
  const [destination, setDestination] = useState(activation.destination)
  useEffect(() => {
    setDestination(activation.destination)
  }, [activation])

  if (destination === "external_url") {
    return (
      <ExternalUrlForm
        activation={activation}
        destination={destination}
        refetchActivation={refetchActivation}
        setDestination={setDestination}
      />
    )
  }
  return (
    <GridForm
      activation={activation}
      destination={destination}
      refetchActivation={refetchActivation}
      setDestination={setDestination}
    />
  )
}

export default GeneralForm
