import React from "react"
import Header from "./header"
import Cards from "./cards"
import Footer from "./footer"

const CollapsedViewPreview = ({
  cardExperienceCards,
  gridForegroundColor,
  gridTitle,
  prePurchaseMessage,
  postPurchaseMessage,
  logoHorizontalSrc,
  setGridView,
}) => (
  <>
    <Header
      gridTitle={gridTitle}
      imageSrc={logoHorizontalSrc}
      textColor={gridForegroundColor}
    />
    <Cards cardExperienceCards={cardExperienceCards} />
    <Footer
      gridForegroundColor={gridForegroundColor}
      postPurchaseMessage={postPurchaseMessage}
      prePurchaseMessage={prePurchaseMessage}
      setGridView={setGridView}
    />
  </>
)

export default CollapsedViewPreview
