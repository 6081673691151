/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useState } from "react"
import GetQueryLoader from "components/get-query-loader"
import { sortBy } from "lodash"
import CreateBrandDrawer from "components/create-brand-drawer"
import IndexTable from "components/index-table"
import { useHistory } from "react-router-dom"
import Alert from "components/alert"
import { GET_BRANDS } from "./queries"
import BrandColumn from "./brand-column"
import StatusColumn from "./status-column"

const BrandList = ({ onAddBrand, isDrawerOpen, onDrawerClose }) => {
  const history = useHistory()

  const [hasDeletedProductVariantError, setHasDeletedProductVariantError] =
    useState(false)

  const prepareData = (brands) =>
    sortBy(brands, ["name"]).map((brand) => {
      const showDeletedAlert = brand.productVariants.find(
        (pv) => pv.deletedFromShopify
      )
      if (showDeletedAlert) setHasDeletedProductVariantError(true)
      return { brand, showDeletedAlert }
    })

  return (
    <GetQueryLoader query={GET_BRANDS} useBrandKey={false}>
      {({ brands }, refetch) => (
        <>
          <IndexTable
            columns={[
              {
                title: "Name",
                field: "name",
                cellStyle: { whiteSpace: "nowrap" },
                customFilterAndSearch: (term, row) =>
                  row.brand.name.toLowerCase().includes(term.toLowerCase()) ||
                  row.brand.key.toLowerCase().includes(term.toLowerCase()),
                render: (row) => (
                  <BrandColumn
                    brand={row.brand}
                    showDeletedAlert={row.showDeletedAlert}
                  />
                ),
              },
              {
                title: "Platform Enabled",
                field: "platformEnabled",
                cellStyle: { whiteSpace: "nowrap" },
                render: (row) => <StatusColumn brand={row.brand} />,
                filtering: false,
              },
            ]}
            ctaText="Add Brand"
            data={prepareData(brands)}
            deletedProductVariantError={
              hasDeletedProductVariantError && (
                <div className="my-4">
                  <Alert message="Some brands have product variants that has been deleted from shopify and need attention" />
                </div>
              )
            }
            onCtaClick={onAddBrand}
            onRowClick={(_, rowData) => {
              history.push(`/brands/${rowData.brand.key}`)
            }}
            title="Brands"
          />
          <CreateBrandDrawer
            isOpen={isDrawerOpen}
            onClose={onDrawerClose}
            refetchBrands={refetch}
          />
        </>
      )}
    </GetQueryLoader>
  )
}

export default BrandList
