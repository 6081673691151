import React from "react"

import { useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import { PrimaryButton } from "components/button"
import GetQueryLoader from "components/get-query-loader"
import { useParams } from "react-router-dom"
import {
  GET_CARDS,
  ADD_EXISTING_CARD_TO_CARD_EXPERIENCE,
  ADD_NEW_CARD_TO_CARD_EXPERIENCE,
  DELETE_CARD,
} from "./queries"
import CardTable from "./card-table"

const AddOrCreateCard = ({
  allCards: cardsInThisExperience,
  currentCardExperienceId,
  currentCardOrdinal,
  isLaunchCard,
  refetchCardExperience,
}) => {
  const { addToast } = useToasts()
  const { brandKey } = useParams()

  const [addNewCardToCardExperience, _] = useMutation(
    ADD_NEW_CARD_TO_CARD_EXPERIENCE,
    {
      onError: (e) => {
        // eslint-disable-next-line
        console.log("Error", e)
        addToast("Something went wrong, changes did not save!", {
          appearance: "error",
        })
      },
      onCompleted: () => {
        addToast("Successfully Added Card", { appearance: "success" })
        refetchCardExperience()
      },
    }
  )

  const [addExistingCardToCardExperience, _a] = useMutation(
    ADD_EXISTING_CARD_TO_CARD_EXPERIENCE,
    {
      onError: (e) => {
        // eslint-disable-next-line
        console.log("Error", e)
        addToast("Something went wrong, changes did not save!", {
          appearance: "error",
        })
      },
      onCompleted: () => {
        addToast("Successfully Added Card", { appearance: "success" })
        refetchCardExperience()
      },
    }
  )

  const [deleteCard] = useMutation(DELETE_CARD, {
    onError: (e) => {
      // eslint-disable-next-line
        console.log("Error", e)
      addToast("Something went wrong, changes did not save!", {
        appearance: "error",
      })
    },
    onCompleted: () => {
      addToast("Successfully Deleted Card", { appearance: "success" })
      refetchCardExperience()
    },
  })

  return (
    <div>
      <h3 className="text-2xl font-medium leading-tightest text-center">
        Choose or create a card
      </h3>
      <div className="flex justify-center">
        <PrimaryButton
          className="m-8"
          onClick={() =>
            addNewCardToCardExperience({
              variables: {
                input: {
                  cardExperienceId: currentCardExperienceId,
                  ordinal: currentCardOrdinal,
                  brandKey,
                  isLaunchCard,
                },
              },
            })
          }
        >
          Create a new card
        </PrimaryButton>
      </div>
      <h5 className="text-md font-medium leading-tightest text-center">
        Choose an existing card:
      </h5>
      <GetQueryLoader fetchPolicy="network-only" query={GET_CARDS}>
        {({ brand: { cards } }) => {
          const cardIdsInThisExperience = cardsInThisExperience.map(
            (cec) => cec.id
          )
          const filteredCards = cards.filter(
            (cec) => !cardIdsInThisExperience.includes(cec.id)
          )

          if (filteredCards.length === 0) return <p>No other cards found.</p>

          const tableData = filteredCards.map((o) => {
            const { cardVariations, productVariants, id: cardId, hidden } = o
            const backgroundImgSrc =
              cardVariations[0]?.productVariant?.featuredImageSrc
            const skus = productVariants.map((pv) => pv.sku)
            return {
              cardId,
              backgroundImgSrc,
              skus,
              hidden,
            }
          })

          return (
            <CardTable
              addExistingCardToCardExperience={addExistingCardToCardExperience}
              brandKey={brandKey}
              currentCardExperienceId={currentCardExperienceId}
              currentCardOrdinal={currentCardOrdinal}
              deleteCard={deleteCard}
              isLaunchCard={isLaunchCard}
              tableData={tableData}
            />
          )
        }}
      </GetQueryLoader>
    </div>
  )
}

export default AddOrCreateCard
