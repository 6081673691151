import React from "react"

const statusMap = {
  live: {
    label: "Live",
    color: "bg-green-100",
  },
  setup: {
    label: "Hidden",
    color: "bg-red-100",
  },
}

const StatusColumn = ({ brand }) => {
  const hasExperienceOnCoreApp = !!brand.coreAppCardExperience

  const { color, label } = statusMap[hasExperienceOnCoreApp ? "live" : "setup"]

  return (
    <span
      className={`px-2 py-1 mr-1 mb-1 text-gray-800 text-xs font-medium rounded-sm ${color}`}
    >
      {label}
    </span>
  )
}

export default StatusColumn
