import React, { createContext, useContext, useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import {
  APP_CLIP_CARD_SECTION,
  APP_CLIP_BACKGROUND_SECTION,
} from "app/brand/old-experience-edit/design-editor/settings/app-clip-sections"

import { GET_EXPERIENCE } from "./queries"

const EditorExperienceContext = createContext(undefined)

const EditorProvider = ({ children }) => {
  const { experienceId, brandKey } = useParams()
  const [currentExperience, setCurrentExperience] = useState(null)
  const [currentSectionId, setCurrentSectionId] = useState(null)
  const [currentSection, setCurrentSection] = useState(null)

  const changeSection = (sectionId) => {
    setCurrentSectionId(sectionId)
  }

  const { data, refetch } = useQuery(GET_EXPERIENCE, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
    variables: { id: experienceId, brandKey },
  })

  useEffect(() => {
    setCurrentExperience(data?.experience)
  }, [data])

  useEffect(() => {
    const appClipSections = [APP_CLIP_BACKGROUND_SECTION, APP_CLIP_CARD_SECTION]

    let isAppClipSection = false

    // Check if the new section is an app clip section...
    appClipSections.every((appClipSection) => {
      if (currentSectionId === appClipSection.id) {
        setCurrentSection(appClipSection)
        isAppClipSection = true
        return false // exit every
      }
      return true // continue
    })

    if (!isAppClipSection) {
      const section = currentExperience?.experienceSections.find(
        (experienceSection) => experienceSection.id === currentSectionId
      )
      setCurrentSection(section)
    }
  }, [currentSectionId, currentExperience?.experienceSections])

  return (
    <EditorExperienceContext.Provider
      value={{
        experienceId,
        currentExperience,
        currentSection,
        changeSection,
        refetchExperience: refetch,
      }}
    >
      {currentExperience && children}
    </EditorExperienceContext.Provider>
  )
}

const useEditor = () => {
  const context = useContext(EditorExperienceContext)

  if (context === undefined) {
    throw new Error("useEditor must be used within a EditorProvider")
  }

  return context
}

export { useEditor, EditorProvider }
