import classNames from "classnames"
import TextOnly from "components/text-only"
import React from "react"
import { ClipboardCopyIcon } from "@heroicons/react/outline"
import copyToClipboard from "utils/copy-to-clipboard"
import { useToasts } from "react-toast-notifications"
import Label from "../label"
import Error from "../error"
import Counter from "./counter"
import HintText from "./hint-text"
import InputAddon from "./input-addon"

const Input = ({
  noLabel,
  value,
  onChange,
  touched,
  error,
  isTextOnly,
  label,
  name,
  disabled,
  type = "text",
  hint,
  limit,
  suffix,
  prefix,
  wrapperProps,
  mask,
  onBlur,
  showCopyButton,
  color,
  ...inputProps
}) => {
  const { addToast } = useToasts()

  const handleBlur = () => {
    if (typeof value === "string") {
      if (suffix && value.endsWith(suffix)) {
        onChange({ target: { value: value.replace(suffix, "") } })
      }

      if (prefix && value.startsWith(prefix)) {
        onChange({ target: { value: value.replace(prefix, "") } })
      }
    }

    onBlur?.()
  }

  const handleChange = (e) => {
    let updatedValue = e.target.value

    if (mask) {
      updatedValue = mask(updatedValue)
    }

    if (limit) {
      updatedValue = updatedValue.substring(0, limit)
    }

    onChange({ target: { value: updatedValue } })
  }

  return (
    <div className="flex flex-col gap-px" {...wrapperProps}>
      <Label displayName={label || name} inputName={name} noLabel={noLabel} />
      <div className="flex">
        <TextOnly
          color={color}
          isTextOnly={isTextOnly}
          prefix={prefix}
          showCopyButton={showCopyButton}
          suffix={suffix}
          text={value}
        >
          <div
            className={classNames(
              "flex flex-1 rounded-md shadow-sm border focus:ring-gray-900 focus:border-gray-900 sm:text-sm border-gray-300 overflow-hidden",
              {
                "border-red-500": error && touched,
              }
            )}
          >
            <InputAddon content={prefix} side="left" />
            <input
              className={classNames(
                "py-2 px-3 focus:outline-none w-full truncate",
                {
                  "cursor-not-allowed": disabled,
                },
                color
              )}
              disabled={disabled}
              id={name}
              name={name}
              onBlur={handleBlur}
              onChange={handleChange}
              type={type}
              value={value}
              {...inputProps}
            />
            {showCopyButton && (
              <ClipboardCopyIcon
                className="px-2 w-9 h-9 self-center text-blue-500 cursor-pointer"
                onClick={() => copyToClipboard(value, addToast)}
              />
            )}
            <InputAddon content={suffix} side="right" />
          </div>
        </TextOnly>

        <div className="min-w-12">
          {showCopyButton && isTextOnly && (
            <ClipboardCopyIcon
              className="px-2 w-8 h-8 self-center text-blue-500 cursor-pointer"
              onClick={() => copyToClipboard(value, addToast)}
            />
          )}
        </div>
      </div>
      <HintText hint={hint} />
      <Counter limit={limit} value={value} />
      <Error error={error} touched={touched} />
    </div>
  )
}

export default Input
