import gql from "graphql-tag"

export const UPDATE_EXPERIENCE_CONFIGURATION = gql`
  mutation UpdateExperienceConfiguration(
    $input: UpdateExperienceConfigurationInput!
  ) {
    updateExperienceConfiguration(input: $input) {
      errors
      experience {
        id
      }
    }
  }
`
export default UPDATE_EXPERIENCE_CONFIGURATION
