import React from "react"
import { EditorV2Provider } from "contexts/experience-v2-editor"
import Editor from "./editor"
import Preview from "./preview"

const ExperienceEdit = () => (
  <EditorV2Provider>
    <div className="flex overflow-hidden">
      <div className="w-7/12 flex flex-col">
        <Editor />
      </div>
      <div className="w-6/12 border-l border-gray-200 dark:border-dark-700 overflow-y-scroll">
        <Preview />
      </div>
    </div>
  </EditorV2Provider>
)

export default ExperienceEdit
