import gql from "graphql-tag"

// brand is inferred from current user
export const GET_BRAND_GENERAL_SETTINGS = gql`
  query GetBrandGeneralSettings($brandKey: String!) {
    brand(brandKey: $brandKey) {
      id
      name
      key
      termsOfUseUrl
      privacyPolicyUrl
      supportUrl
      logoImageSrc
      logoHorizontalSrc
      sharePageSecret
    }
  }
`

// brand is inferred from current user
export const SAVE_BRAND_GENERAL_SETTINGS = gql`
  mutation SaveBrandGeneralSettings($input: SaveBrandGeneralSettingsInput!) {
    saveBrandGeneralSettings(input: $input) {
      errors
      brand {
        id
        name
        key
        termsOfUseUrl
        privacyPolicyUrl
        logoImageSrc
        logoHorizontalSrc
        supportUrl
      }
    }
  }
`
