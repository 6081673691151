import { gql } from "@apollo/client"
import { LOGIN_ADMIN_USER_FIELDS } from "contexts/admin-authentication"

const LOGIN_WITH_GOOGLE_MUTATION = gql`
  mutation LoginWithGoogle($input: LoginWithGoogleInput!) {
    loginWithGoogle(input: $input) {
      user {
        ${LOGIN_ADMIN_USER_FIELDS}
      }
    }
  }
`

export default LOGIN_WITH_GOOGLE_MUTATION

export { LOGIN_WITH_GOOGLE_MUTATION }
