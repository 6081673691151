import React from "react"
import { DownloadIcon } from "@heroicons/react/outline"

import ActivationDemo from "components/activation-demo"
import DownloadButton from "components/activation-demo/download-button"
import Button from "components/button"
import { useMutation } from "@apollo/client"
import DownloadLink from "./qr-code-download-link"

import { GENERATE_ACTIVATION_LEGACY_QR_CODE_MUTATION } from "./queries"

const QrCodes = ({ activation, refetchActivation }) => {
  const generateFilename = (activationName, isLegacy = false) =>
    `${isLegacy && "LEGACY__"}${activationName}`
      .replace(/[^a-z0-9]/gi, "_")
      .toLowerCase()

  const {
    name,
    assetSvgUrl,
    kind,
    batchTagDemoSvgUrl,
    batchTagTestSvgUrl,
    qrCodeSvgUrl, // legacy QR code
  } = activation

  const qrCodes = [
    {
      name: "Production",
      qr: (
        <img
          alt={`${name} QR Code URL`}
          className="flex-shrink-0 mx-auto h-24"
          src={assetSvgUrl}
        />
      ),
      downloadButton: (
        <DownloadLink
          assetUrl={assetSvgUrl}
          filename={generateFilename(name)}
        />
      ),
      url: activation.url,
    },
    {
      name: "Test",
      detail: (
        <>
          This QR code will <br /> bypass analytics
          <br /> and supports <br /> <u>real purchases</u>.
        </>
      ),
      qr:
        kind === "batch_tag" ? (
          <img
            alt={`${name} Test QR Code URL`}
            className="flex-shrink-0 mx-auto h-24"
            src={batchTagTestSvgUrl}
          />
        ) : (
          <ActivationDemo
            activation={activation}
            hideDemoUrl
            hideHeader
            kind="test"
            qrCodeSvgId={`test__${qrCodeSvgUrl}`}
            size={24}
          />
        ),
      downloadButton:
        kind === "batch_tag" ? (
          <DownloadLink
            assetUrl={batchTagTestSvgUrl}
            filename={generateFilename(name)}
          />
        ) : (
          <DownloadButton
            activationName={name}
            kind="test"
            qrCodeSvgId={`test__${qrCodeSvgUrl}`}
          />
        ),
      url: activation.testUrl,
    },
    {
      name: "Demo",
      detail: (
        <>
          This QR code will <br /> bypass analytics and
          <br /> <u>bypass purchases</u>.
        </>
      ),
      qr:
        kind === "batch_tag" ? (
          <img
            alt={`${name} Demo QR Code URL`}
            className="flex-shrink-0 mx-auto h-24"
            src={batchTagDemoSvgUrl}
          />
        ) : (
          <ActivationDemo
            activation={activation}
            hideDemoUrl
            hideHeader
            qrCodeSvgId={`demo__${qrCodeSvgUrl}`}
            size={24}
          />
        ),
      downloadButton:
        kind === "batch_tag" ? (
          <DownloadLink
            assetUrl={batchTagDemoSvgUrl}
            filename={generateFilename(name)}
          />
        ) : (
          <DownloadButton
            activationName={name}
            kind="demo"
            qrCodeSvgId={`demo__${qrCodeSvgUrl}`}
          />
        ),
      url: activation.demoUrl,
    },
  ]

  const [executeMutation] = useMutation(
    GENERATE_ACTIVATION_LEGACY_QR_CODE_MUTATION,
    {
      onCompleted: () => {
        refetchActivation()
      },
    }
  )

  // conditionally add legacy QR code
  if (qrCodeSvgUrl) {
    qrCodes.push({
      name: "Legacy",
      detail: (
        <>
          This points to
          <br />
          production but is in
          <br />
          the old QR code style.
        </>
      ),
      qr: (
        <img
          alt={`${name} Legacy QR Code URL`}
          className="flex-shrink-0 h-24 w-24"
          src={qrCodeSvgUrl}
        />
      ),
      downloadButton: (
        <a download={generateFilename(name, true)} href={qrCodeSvgUrl}>
          <button
            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="button"
          >
            <span className="sr-only">Download QR code.</span>
            <DownloadIcon className="w-4 h-4" />
          </button>
        </a>
      ),
      url: activation.url,
    })
  } else {
    // push the legacy QR code placeholder
    qrCodes.push({
      name: "Legacy",
      qr: <p>Not generated</p>,
      downloadButton: (
        <Button
          onClick={() => {
            executeMutation({
              variables: {
                input: {
                  activationId: activation.id,
                },
              },
            })
          }}
          slim
        >
          Generate
        </Button>
      ),
      url: null,
    })
  }

  return (
    <div>
      <div className="col-span-8 border-t my-4 pt-6">
        <h2 className="text-xl tracking-tight">QR Codes</h2>
        <p className="text-sm">
          Please scan the &quot;test&quot; QR code when QAing. They are
          functionally the same as the production QR code, but allow us to omit
          those scans from analytics.
        </p>
      </div>
      <div className="col-span-8 my-4 pt-6">
        <table>
          <thead>
            <tr>
              <th className="text-right px-4 py-2">Name</th>
              <th className="text-left px-4 py-2">QR Code</th>
              <th className="text-left px-4 py-2">URL</th>
              <th className="text-left px-4 py-2">Download</th>
            </tr>
          </thead>
          <tbody>
            {qrCodes.map(
              ({ name: qrCodeName, detail, qr, url, downloadButton }) => (
                <tr key={qrCodeName} className="border-t">
                  <td className="text-right px-4 py-2">
                    {qrCodeName}
                    {detail && (
                      <div className="text-xs text-gray-600">{detail}</div>
                    )}
                  </td>
                  <td className="text-left px-4 py-2">{qr}</td>
                  <td className="text-left px-4 py-2">{url}</td>
                  <td className="text-left px-4 py-2">{downloadButton}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default QrCodes
