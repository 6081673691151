import React from "react"
import { MEASUREMENTS } from "components/containers/iphone-11-pro-max"
import Card from "./card"

const Cards = ({ cardExperienceCards }) => {
  const paddedCards = Array.from({ ...cardExperienceCards, length: 4 })

  // TODO: make a safe default here
  const defaultForegroundColor = "white"

  return (
    <div
      className="grid-cols-2 grid-rows-2 grid"
      style={{
        gap: MEASUREMENTS.gridCards.gap,
        gridGap: MEASUREMENTS.gridCards.gap,
      }}
    >
      {paddedCards.map((cardExperienceCard) => (
        <Card
          key={cardExperienceCard?.id}
          cardExperienceCard={cardExperienceCard}
          defaultForegroundColor={defaultForegroundColor}
        />
      ))}
    </div>
  )
}

export default Cards
