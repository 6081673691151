import React from "react"
import { useEditor } from "contexts/experience-v2-editor"
import { PrimaryButton, SecondaryButton } from "components/button"
import { sortBy } from "lodash"
import useDisclosure from "hooks/use-disclosure"
import NewActivationModal from "./new-activation-modal"
import ActivationRow from "./activation-row"
import CreateBatchTagModal from "./create-batch-tag-modal"

const Activations = () => {
  const { currentCardExperience, refetchCardExperience } = useEditor()
  const { activations } = currentCardExperience

  const {
    isOpen: qrModalIsOpen,
    onOpen: onQrModalOpen,
    onClose: onQrModalClose,
  } = useDisclosure()

  const {
    isOpen: batchTagModalIsOpen,
    onOpen: onBatchTagModalOpen,
    onClose: onBatchTagModalClose,
  } = useDisclosure()

  return (
    <>
      <div className="flex flex-row-reverse justify-start items-center w-full mb-2 px-1 mt-1">
        <PrimaryButton className="ml-4" onClick={onBatchTagModalOpen} slim>
          New Batch Tag
        </PrimaryButton>
        <SecondaryButton onClick={onQrModalOpen} slim>
          New QR Code
        </SecondaryButton>
      </div>
      <div className="min-w-full divide-y divide-gray-200">
        <div className="bg-white divide-y divide-gray-200">
          {sortBy(activations, ["createdAt"])
            .reverse()
            .map((activation) => (
              <ActivationRow
                key={activation.id}
                activation={activation}
                cardExperience={currentCardExperience}
                refetchCardExperience={refetchCardExperience}
              />
            ))}
        </div>
      </div>
      <NewActivationModal
        cardExperience={currentCardExperience}
        cardExperienceRefetch={refetchCardExperience}
        onClose={onQrModalClose}
        visible={qrModalIsOpen}
      />
      <CreateBatchTagModal
        cardExperience={currentCardExperience}
        onClose={onBatchTagModalClose}
        onSuccess={() => {
          refetchCardExperience()
          onBatchTagModalClose()
        }}
        show={batchTagModalIsOpen}
      />
    </>
  )
}

export default Activations
