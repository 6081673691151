import React from "react"
import { useEditor } from "contexts/experience-editor"

import { SidebarExperienceSection } from "components/sidebar"

import SECTION_COPY from "./section-copy"

const SectionNavItem = ({ experienceSection }) => {
  const { experienceSectionDetailsId, experienceSectionDetailsType } =
    experienceSection
  const { currentSection, changeSection } = useEditor()
  const { title, subtitle, icon, toggle } =
    SECTION_COPY[experienceSectionDetailsType]

  return (
    <SidebarExperienceSection
      icon={icon}
      isCurrent={
        currentSection?.experienceSectionDetailsId ===
        experienceSectionDetailsId
      }
      onClick={() => changeSection(experienceSection.id)}
      subtitle={subtitle}
      title={title}
      toggle={toggle}
    />
  )
}

export default SectionNavItem
