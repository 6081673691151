import React, { useState, useEffect } from "react"
import classnames from "classnames"
import { RadioGroup } from "@headlessui/react"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline"

const CollapsableRadioSelect = ({
  children,
  defaultSelectedIndex = 0,
  expandAll = false,
}) => {
  const [selected, setSelected] = useState(children[0])

  useEffect(() => {
    setSelected(children[defaultSelectedIndex])
  }, [children, defaultSelectedIndex])

  return (
    <RadioGroup onChange={setSelected} value={selected}>
      <div className="relative bg-white rounded-md -space-y-px">
        {children.map((item, itemIdx) => (
          <RadioGroup.Option
            key={item.name}
            className={({ checked }) =>
              classnames(
                itemIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
                itemIdx === children.length - 1
                  ? "rounded-bl-md rounded-br-md"
                  : "",
                checked
                  ? "bg-indigo-50 border-indigo-200 z-10"
                  : "border-gray-200",
                "relative border p-4 cursor-pointer focus:outline-none"
              )
            }
            value={item}
          >
            {({ active, checked }) => (
              <>
                <div className="flex w-full justify-between items-center">
                  {/* radio button icon */}
                  <span
                    aria-hidden="true"
                    className={classnames(
                      checked
                        ? "bg-indigo-600 border-transparent"
                        : "bg-white border-gray-300",
                      active ? "ring-2 ring-offset-2 ring-indigo-500" : "",
                      "h-4 w-4 rounded-full border flex items-center justify-center flex-shrink-0"
                    )}
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <RadioGroup.Label
                    as="span"
                    className="ml-3 font-medium text-black text-lg leading-7 flex-grow"
                  >
                    {item.title}
                  </RadioGroup.Label>
                  <span>
                    {checked ? (
                      <ChevronUpIcon className="h-4 w-4 ml-2" />
                    ) : (
                      <ChevronDownIcon className="h-4 w-4 ml-2" />
                    )}
                  </span>
                </div>

                <div className="mr-4 ml-7">
                  <RadioGroup.Description className="text-sm leading-4">
                    <span className="text-gray-500 leading-5">
                      {item.subtitle}
                    </span>
                  </RadioGroup.Description>
                  {(checked || expandAll) && (
                    <div className="mt-2">{item.form}</div>
                  )}
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

export default CollapsableRadioSelect
