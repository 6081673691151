import React from "react"
import TabNavLink from "./tab-nav-link"

const TabNavLinks = ({ children }) => (
  <div className="w-full border-b mx-auto px-4">
    <div className="flex justify-between">
      <div className="flex">
        <nav className="-mb-px flex">{children}</nav>
      </div>
    </div>
  </div>
)

export default TabNavLinks

export { TabNavLinks, TabNavLink }
