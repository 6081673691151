import gql from "graphql-tag"

const GET_BRAND = gql`
  query GetBrand($brandKey: String!) {
    brand(brandKey: $brandKey) {
      id
      name
      key
      activations {
        id
        testUrl
        url
        name
        kind
        hidden
        appleAppClipActive
        fallbackUrl
        cardExperience {
          id
          name
        }
        category
        destination
      }
    }
  }
`

const UPDATE_ACTIVATION = gql`
  mutation UpdateQRCodeActivation($input: UpdateQRCodeActivationInput!) {
    updateQrCodeActivation(input: $input) {
      errors
      activation {
        id
      }
    }
  }
`

export { GET_BRAND, UPDATE_ACTIVATION }
