import React from "react"
import GoogleLoginButton from "components/admin-google-login-button"
import PasswordLoginForm from "components/admin-password-login-form"
import { useAdminAuthentication } from "contexts/admin-authentication"
import Logo from "assets/images/logo.png"
import { isDevEnv } from "utils/app-env"

const Login = () => {
  const { logAdminUserIn } = useAdminAuthentication()

  return (
    <div className="h-full bg-gray-900 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 flex flex-col items-center">
            <a
              aria-label="Home"
              className="mb-10"
              href="https://www.getbatch.com"
            >
              <img
                alt="Batch logo"
                className="mx-auto w-10 h-10 w-auto"
                src={Logo}
              />
            </a>
            {isDevEnv() && <PasswordLoginForm onSuccess={logAdminUserIn} />}
            <GoogleLoginButton admin onSuccess={logAdminUserIn} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
