import React, { useState } from "react"
import GetQueryLoader from "components/get-query-loader"
import useDisclosure from "hooks/use-disclosure"
import BrandUserModal from "./brand-user-modal"
import { GET_BRAND_USERS } from "./queries"
import List from "./list"
import PlatformAccessToggle from "./platform-access-toggle"

const BrandUsers = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [userToEdit, setUserToEdit] = useState({})

  return (
    <GetQueryLoader fetchPolicy="network-only" query={GET_BRAND_USERS}>
      {({ brand }, refetchBrandUsers) => (
        <main>
          <PlatformAccessToggle
            brand={brand}
            refetchBrand={refetchBrandUsers}
          />
          <List
            brand={brand}
            onOpenBrandUserModal={(userInfo = {}) => {
              setUserToEdit(userInfo)
              onOpen()
            }}
            refetchBrandUsers={refetchBrandUsers}
          />
          <BrandUserModal
            onClose={onClose}
            refetchBrandUsers={refetchBrandUsers}
            userInfo={userToEdit}
            visible={isOpen}
          />
        </main>
      )}
    </GetQueryLoader>
  )
}
export default BrandUsers
