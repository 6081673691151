import React from "react"
import Card from "components/expanded-card-preview"
import Iphone11ProMax from "components/containers/iphone-11-pro-max"
import PreviewWorkspace from "components/preview-workspace"
import Footer from "components/expanded-card-preview/footer"
import Header from "components/expanded-card-preview/header"

const CardVariationPreview = ({
  cardVariation,
  brand,
  currentCardVariationBackground,
}) => {
  const cardDetails = {
    expandedBackgroundColor: "DDD",
    expandedForegroundColor: "222",
    title: cardVariation.title,
    subtitle: cardVariation.subtitle,
    cardVariations: [cardVariation],
  }
  return (
    <PreviewWorkspace colSpan={2}>
      <Iphone11ProMax backgroundColor={cardDetails.expandedBackgroundColor}>
        <Header
          accentColor="#FFFFFF"
          imageSrc={brand?.logoHorizontalSrc}
          price={cardVariation.price}
          textColor="#FFFFFF"
        />
        <Card
          card={cardDetails}
          cardVariation={cardVariation}
          cardVariationBackground={currentCardVariationBackground}
        />
        <Footer
          accentColor="#440044"
          setGridView={null}
          textColor="#ffffff"
          textOverAccentColor="#FFFFFF"
        />
      </Iphone11ProMax>
    </PreviewWorkspace>
  )
}

export default CardVariationPreview
