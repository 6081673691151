import React, { useState } from "react"
import { PrimaryButton } from "components/button"
import NewLinkModal from "components/brand/new-link-modal"
import { sortBy } from "lodash"
import ActivationRow from "./activation-row"

const List = ({ experience, experienceRefetch }) => {
  const { activations } = experience
  const [modalVisible, setModalVisible] = useState(false)

  const showModal = () => setModalVisible(true)
  const hideModal = () => setModalVisible(false)

  return (
    <>
      <div className="flex flex-row justify-between items-center w-full mb-2 px-1 mt-10">
        <h3 className="text-lg font-bold leading-tight text-gray-900">
          QR Codes
        </h3>
        <PrimaryButton onClick={showModal} slim>
          New QR Code
        </PrimaryButton>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-white">
          <tr>
            <th
              className="px-2 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
              scope="col"
            >
              Name
            </th>
            <th
              className="px-2 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
              scope="col"
            >
              AppStore
            </th>
            <th
              className="px-2 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
              scope="col"
            >
              Link
            </th>
            <th
              className="px-2 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
              scope="col"
            >
              Created
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sortBy(activations, ["createdAt"])
            .reverse()
            .map((activation) => (
              <ActivationRow
                key={activation.id}
                activation={activation}
                experience={experience}
              />
            ))}
        </tbody>
      </table>
      <NewLinkModal
        experience={experience}
        experienceRefetch={experienceRefetch}
        onClose={hideModal}
        visible={modalVisible}
      />
    </>
  )
}

export default List
