import gql from "graphql-tag"

const CREATE_ACTIVATION_MUTATION = gql`
  mutation CreateActivationForCardExperience(
    $input: CreateActivationForCardExperienceInput!
  ) {
    createActivationForCardExperience(input: $input) {
      errors
      activation {
        id
      }
    }
  }
`

const UPDATE_ACTIVATION = gql`
  mutation UpdateQRCodeActivation($input: UpdateQRCodeActivationInput!) {
    updateQrCodeActivation(input: $input) {
      errors
      activation {
        id
      }
    }
  }
`

const UPDATE_BATCH_TAG_ACTIVATION = gql`
  mutation UpdateBatchTagActivation($input: UpdateBatchTagActivationInput!) {
    updateBatchTagActivation(input: $input) {
      errors
      activation {
        name
        category
        id
      }
    }
  }
`

const MIGRATE_QR_CODE_TO_BATCH_TAG = gql`
  mutation MigrateQRCodeToBatchTag($input: MigrateQRCodeToBatchTagInput!) {
    migrateQrCodeToBatchTag(input: $input) {
      errors
      activation {
        id
      }
    }
  }
`

const CREATE_BATCH_TAG = gql`
  mutation CreateBatchTagForCardExperience(
    $input: CreateBatchTagForCardExperienceInput!
  ) {
    createBatchTagForCardExperience(input: $input) {
      errors
      activation {
        id
      }
    }
  }
`
export {
  CREATE_ACTIVATION_MUTATION,
  UPDATE_BATCH_TAG_ACTIVATION,
  UPDATE_ACTIVATION,
  MIGRATE_QR_CODE_TO_BATCH_TAG,
  CREATE_BATCH_TAG,
}
