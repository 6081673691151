import React from "react"
import CloseableConfigSection from "components/closeable-config-section"

import FormComponent from "./form"

const DiscountSection = ({ experienceSectionDetails }) => (
  <CloseableConfigSection
    subtitle="Customize the discount you offer to customers."
    title="Discount Section"
  >
    <FormComponent experienceSectionDetails={experienceSectionDetails} />
  </CloseableConfigSection>
)

export default DiscountSection
