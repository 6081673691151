import React, { useState } from "react"
import { Form } from "formik"
import * as Yup from "yup"
import { useToasts } from "react-toast-notifications"
import { useHistory } from "react-router-dom"
import { PlusCircleIcon } from "@heroicons/react/outline"

import BatchForm from "components/batch-form"
import SubmitButton from "components/submit-button"
import Button from "components/button"
import FormikInput from "components/forms/formik-input"
import { ModalFooter, ModalBody } from "components/small-form-modal"
import Select from "components/forms/select"

import { CREATE_BRAND_MUTATION } from "./queries"

const FormStep = ({ domainValues, close }) => {
  const { addToast } = useToasts()
  const history = useHistory()
  const {
    scrapeBrandHomepage: {
      homepage,
      logoImageSrc, // "https://cdn.shopify.com/s/files/1/0370/0215/0024/files/favicon_32x32.png?v=1602082153"
      shopifyDomain, // "dirtylabs"
      siteName, // "Dirty Labs"
    },
  } = domainValues

  const [platform, setPlatform] = useState("Shopify")

  // TODO: use conditional rendering once we support more than just Shopify
  // const [platform, setPlatform] = useState(shopifyDomain ? "Shopify" : "Other")

  const formSchema = Yup.object().shape({
    siteName: Yup.string().required("Required"),
    shopifyDomain: Yup.string().required("Required"),
    key: Yup.string().required("Required"),
    homepage: Yup.string().required("Required"),
  })

  const onCompleted = (data) => {
    const {
      createBrand: { errors, brand },
    } = data

    if (errors[0]) {
      addToast(errors[0], { appearance: "error" })
    } else {
      history.push(`/brands/${brand.key}`)
      addToast("Saved Successfully", { appearance: "success" })
    }
  }

  const getBrandKey = () =>
    shopifyDomain
      ? shopifyDomain.replaceAll("-", "")?.substr(0, 8)
      : siteName.replace(/\W/g, "").toLowerCase().substr(0, 8)

  return (
    <BatchForm
      generateValues={(values) => ({
        shopifyDomain:
          shopifyDomain && shopifyDomain.replace(".myshopify.com", ""),
        ...values,
      })}
      initialValues={{
        siteName: siteName || "",
        key: getBrandKey(),
        homepage: homepage || "",
        shopifyDomain: shopifyDomain || null,
        logoImageSrc: logoImageSrc || "",
      }}
      mutation={CREATE_BRAND_MUTATION}
      onCompleted={onCompleted}
      useBrandKey={false}
      validationSchema={formSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <ModalBody
            description="Add details for the new brand."
            icon={
              logoImageSrc ? (
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full ring-2 ring-black sm:mx-0 sm:h-10 sm:w-10">
                  <img alt="" className="w-8 h-8" src={logoImageSrc} />
                </div>
              ) : (
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                  <PlusCircleIcon className="h-6 w-6 text-blue-600" />
                </div>
              )
            }
            title={siteName ? `Add ${siteName}` : "Add a New Brand"}
          >
            <div className="w-72">
              <FormikInput
                autoFocus
                label="Brand Name"
                name="siteName"
                placeholder="Wandering Bear"
                type="text"
              />
            </div>
            <div className="w-72 mt-4">
              <FormikInput
                label="Short Link Prefix"
                name="key"
                placeholder="wandb"
                prefix="https://batch.link/"
                type="text"
              />
            </div>
            <div className="w-72 mt-4">
              <Select
                defaultValue={platform}
                disabled // TODO: remove this flag once we support more than just Shopify
                name="platform"
                onChange={() => setPlatform("Shopify")}
                // TODO: use this live onChange once we support more than just shopify
                // onChange={(value) => setPlatform(value)}
                // TODO: add other options here
                // options={{ Shopify: "Shopify", Other: "Other" }}
                options={{ Shopify: "Shopify" }}
                type="text"
              />
            </div>
            {platform === "Shopify" && (
              <div className="w-72 mt-4">
                <FormikInput
                  label="Shopify Domain"
                  name="shopifyDomain"
                  placeholder="wanderingbear"
                  suffix=".myshopify.com"
                  type="text"
                />
              </div>
            )}
            <div className="w-72 mt-4">
              <FormikInput
                label="Homepage"
                name="homepage"
                placeholder="https://wanderingbearcoffee.com"
                type="text"
              />
            </div>
            <FormikInput name="logoImageSrc" noLabel type="hidden" />
          </ModalBody>
          <ModalFooter>
            <SubmitButton submitting={isSubmitting} />
            <Button className="mr-2" onClick={close}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      )}
    </BatchForm>
  )
}

export default FormStep
