import React from "react"
import Loader from "react-loader-spinner"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"

const GetQueryLoader = ({
  query,
  queryVariables = {},
  loader,
  on404,
  children,
  fetchPolicy,
  useBrandKey = true,
}) => {
  const { brandKey } = useParams()
  const { loading, error, data, refetch } = useQuery(query, {
    errorPolicy: "all",
    variables: {
      ...queryVariables,
      brandKey: useBrandKey ? brandKey : undefined,
    },
    fetchPolicy: fetchPolicy || "cache-first",
  })

  if (error?.graphQLErrors?.[0]?.extensions?.code === 404) {
    if (on404) return on404()
    return "Not Found"
  }

  if (loading) {
    return (
      loader || (
        <div className="h-100 w-100 flex items-center justify-center">
          <div className="p-8">
            <Loader color="#e5e5e5" height={100} type="Puff" width={100} />
          </div>
        </div>
      )
    )
  }
  if (error) {
    return <p>{error.message}</p>
  }

  return <>{children(data, refetch)}</>
}

export default GetQueryLoader
