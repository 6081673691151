import React from "react"
import FormikInput from "components/forms/formik-input"
import TextArea from "components/forms/textarea"

const ModalContentForm = ({ label, namePrefix }) => (
  <div className="border-2 rounded-lg p-4 mb-4">
    <p className="text-md font-semibold mb-2">{label}</p>
    <div className="mb-2">
      <FormikInput
        label="Modal Title"
        name={`${namePrefix}Title`}
        type="text"
        wrapperProps={{ className: "mb-4" }}
      />
    </div>
    <div className="mb-2">
      <TextArea
        label="Modal Body"
        name={`${namePrefix}Body`}
        type="text"
        wrapperProps={{ className: "mb-4" }}
      />
    </div>
    <div className="mb-2">
      <FormikInput
        label="Modal Button Text"
        name={`${namePrefix}Button`}
        type="text"
        wrapperProps={{ className: "mb-4" }}
      />
    </div>
  </div>
)

export default ModalContentForm
