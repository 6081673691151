import gql from "graphql-tag"

const GET_BRAND = gql`
  query GetBrand($brandKey: String!) {
    brand(brandKey: $brandKey) {
      id
      name
      sharePageSecret
    }
  }
`

export default GET_BRAND
