import React from "react"
import { MEASUREMENTS as Iphone11ProMaxMeasurements } from "components/containers/iphone-11-pro-max"
import { H1, H2 } from "components/containers/iphone-fonts"
import DiscountBadge from "components/discount-badge"
import Background from "./background"
import QuantitySelector from "./quantity-selector"
import VariationSelector from "./variation-selector"

const Card = ({
  card,
  cardVariation,
  cardVariationBackground,
  cardVariationCard,
}) => {
  const {
    expandedBackgroundColor: backgroundColor,
    expandedForegroundColor: foregroundColor,
    cardVariations,
  } = card
  const { title, subtitle } = cardVariation || {}

  const firstCardVariationBackground =
    cardVariation?.cardVariationBackgrounds.find(
      (background) => background.ordinal === 1
    )

  const displayedCardVariationBackground =
    cardVariationBackground || firstCardVariationBackground

  return (
    <div
      className="w-full overflow-hidden relative bg-gray-100"
      style={{
        backgroundColor: `#${backgroundColor}`,
        borderRadius: Iphone11ProMaxMeasurements.card.borderRadius,
        height: "643px",
      }}
    >
      <div className="absolute inset-0 z-0" id="cardBackground">
        <Background
          currentCardVariationBackground={displayedCardVariationBackground}
        />
      </div>
      <div
        className="absolute inset-0 z-10 flex flex-col justify-between"
        id="cardContent"
        style={{
          color: `#${foregroundColor}`,
          paddingTop: Iphone11ProMaxMeasurements.card.paddingTop,
          paddingBottom: Iphone11ProMaxMeasurements.card.paddingBottom,
          paddingLeft: Iphone11ProMaxMeasurements.card.paddingX,
          paddingRight: Iphone11ProMaxMeasurements.card.paddingX,
        }}
      >
        <div className="flex flex-col items-center justify-center z-1">
          <h3
            style={{ color: `#${foregroundColor}`, textAlign: "center", ...H1 }}
          >
            {title}
          </h3>
          <p style={{ color: `#${foregroundColor}`, ...H2 }}>{subtitle}</p>
          {cardVariationCard && (
            <DiscountBadge
              badgeSvg={cardVariationCard.discountBadgeSvg}
              badgeTextColor={cardVariationCard.discountBadgeTextColor}
              displayValue={cardVariationCard.discountDisplayValue}
            />
          )}
        </div>
        <div
          className="grid grid-cols-2"
          style={{
            gap: Iphone11ProMaxMeasurements.cardSelector.gap,
            gridGap: Iphone11ProMaxMeasurements.cardSelector.gap,
            paddingLeft: Iphone11ProMaxMeasurements.cardSelector.gap,
            paddingRight: Iphone11ProMaxMeasurements.cardSelector.gap,
          }}
        >
          {cardVariations.length > 0 && cardVariation !== undefined && (
            <VariationSelector
              color={`#${foregroundColor}`}
              currentVariation={cardVariation}
              variations={cardVariations}
            />
          )}
          <QuantitySelector color={`#${foregroundColor}`} />
        </div>
      </div>
    </div>
  )
}

export default Card
