import gql from "graphql-tag"

const GET_ACTIVATION = gql`
  query GetActivation($id: ID!, $brandKey: String!) {
    activation(id: $id, brandKey: $brandKey) {
      id
      cardExperienceId
      name
      category
      availableCategories
      url
      demoUrl
      testUrl
      assetSvgUrl
      qrCodeSvgUrl
      batchTagSvgUrl
      batchTagDemoSvgUrl
      batchTagTestSvgUrl
      kind
      showLaunchCard
      primaryCardId
      fallbackUrl
      destination
      hidden
      cardExperience {
        id
        name
        internalNickname
        activations {
          id
          name
          url
        }
        launchCard {
          id
        }
        cardExperienceCards {
          card {
            id
            name
            linkOverrideUrl
          }
          ordinal
        }
      }
      brand {
        name
        key
        cardExperiences {
          id
          name
          internalNickname
          activations {
            id
            name
            url
          }
          launchCard {
            id
          }
          cardExperienceCards {
            card {
              id
              name
              linkOverrideUrl
            }
            ordinal
          }
        }
        appleAppClipTitle
        appleAppClipSubtitle
        appleAppClipAction
        appleAppClipBusinessCategory
        appleAppClipImage {
          id
          imageSrc
        }
      }
      shareActivation {
        id
      }
      appleAppClipAllActions
      appleAppClipAllBusinessCategories
      appleAppClipAppleId
      appleAppClipActive
      appleAppClipSubmitterAdminUser {
        id
        fullName
      }
      appleAppClipSubmittedAt
      appleAppClipTitle
      appleAppClipSubtitle
      appleAppClipAction
      appleAppClipBusinessCategory
      appleAppClipImage {
        id
        imageSrc
      }
    }
  }
`

export default GET_ACTIVATION

export { GET_ACTIVATION }
