import React from "react"
import GetQueryLoader from "components/get-query-loader"
import SmallFormModal from "components/small-form-modal"
import { GET_MODAL_PRODUCT_VARIANTS } from "./queries"
import SelectProductVariantsModalInner from "./select-product-variants-modal-inner"

const SelectProductVariantsModal = ({
  visible,
  onClose,
  onComplete,
  experienceSectionDetailsId,
}) => {
  if (!visible) {
    return null
  }
  return (
    <SmallFormModal onClose={onClose} visible={visible}>
      {({ close }) => (
        <GetQueryLoader
          fetchPolicy="network-only"
          query={GET_MODAL_PRODUCT_VARIANTS}
          queryVariables={{
            id: experienceSectionDetailsId,
          }}
        >
          {(
            { experienceSectionProductVariantCatalogDetail: section },
            refetch
          ) => {
            const allProductVariants = section.productVariants.concat(
              section.excludedProductVariants
            )
            if (!allProductVariants.length > 0) return <p>No products yet!</p>
            return (
              <main>
                <SelectProductVariantsModalInner
                  close={close}
                  excludedVariants={section.excludedProductVariants}
                  experienceSectionDetailsId={experienceSectionDetailsId}
                  onClose={onClose}
                  onComplete={onComplete}
                  productsRefetch={refetch}
                  variants={section.productVariants}
                />
              </main>
            )
          }}
        </GetQueryLoader>
      )}
    </SmallFormModal>
  )
}

export default SelectProductVariantsModal
