import gql from "graphql-tag"

export const UPDATE_BLANK_EXPERIENCE_WITH_PRODUCT_VARIANT = gql`
  mutation UpdateBlankExperienceWithProductVariant(
    $input: UpdateBlankExperienceWithProductVariantInput!
  ) {
    updateBlankExperienceWithProductVariant(input: $input) {
      errors
      experience {
        id
      }
    }
  }
`
export const GET_PRODUCT_VARIANTS = gql`
  query GetBrand {
    brand {
      productVariants {
        id
        sku
        price
        platformId
        imageSrcLarge
        standaloneTitle
        subtitle
        product {
          title
          platformShoppableUrl
        }
      }
    }
  }
`
