import React, { useEffect, useState } from "react"
import { useMutation } from "@apollo/client"
import { Link, Redirect, useLocation } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { Form } from "formik"
import * as Yup from "yup"

import BatchForm from "components/batch-form"
import FormikInput from "components/forms/formik-input"
import ComboboxSelector from "components/forms/combobox-selector"
import Select from "components/forms/select"
import Switch from "components/forms/switch"
import SubmitButton from "components/submit-button"
import RedButtonWithConfirmation from "components/button/red-button-with-confirmation"

import { UPDATE_ACTIVATION, DELETE_ACTIVATION } from "./queries"
import QrCodes from "./qr-codes"

const GridForm = ({
  activation,
  destination,
  refetchActivation,
  setDestination,
}) => {
  const { addToast } = useToasts()
  const location = useLocation()
  const [redirectUrl, setRedirectUrl] = useState(undefined)
  const formSchema = Yup.object().shape({
    cardExperienceId: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    destination: Yup.string().required("Required"),
  })

  const {
    id,
    name,
    cardExperience,
    cardExperienceId,
    brand: { key: brandKey, cardExperiences },
    showLaunchCard,
    category,
    availableCategories,
    fallbackUrl,
    primaryCardId,
    hidden,
  } = activation

  const [chosenExperienceId, setChosenExperienceId] = useState(cardExperienceId)
  const [chosenExperience, setChosenExperience] = useState(cardExperience)

  useEffect(() => {
    setChosenExperienceId(activation.cardExperienceId)
  }, [activation])

  useEffect(() => {
    const newExperience = cardExperiences.find(
      (ce) => ce.id === chosenExperienceId
    )
    setChosenExperience(newExperience)
  }, [cardExperiences, chosenExperienceId])

  const experienceOptions = cardExperiences.map((exp) => ({
    id: exp.id,
    value: exp.internalNickname
      ? `${exp.name} - ${exp.internalNickname}`
      : exp.name,
  }))

  const basePathname = location.pathname.split("/").slice(0, 3).join("/")
  const generateExperiencePathname = (ce) =>
    `${basePathname}/experiences/${ce.id}`

  const generateAvailableCardIds = (ce) =>
    ce?.cardExperienceCards.reduce(
      (acc, cec) => {
        if (!cec.card.linkOverrideUrl) {
          acc[cec.card.id] = `${cec.ordinal} - ${cec.card.name}`
        }
        return acc
      },
      { null: "Please choose a card" }
    )

  const generateShareActivationOptions = (ce) =>
    ce?.activations.map((act) => ({
      id: act.id,
      value: `${act.name} (${act.url})`,
    }))

  const [deleteActivationMutation] = useMutation(DELETE_ACTIVATION, {
    variables: {
      input: {
        activationId: activation.id,
        brandKey,
      },
    },
    onCompleted: () => {
      addToast("Successfully deleted activation", { appearance: "success" })
      setRedirectUrl(`/brands/${brandKey}/activations`)
    },
    onError: (error) => {
      addToast(`Could not delete activation: ${error.message}`, {
        appearance: "error",
      })
    },
  })

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />
  }

  return (
    <BatchForm
      enableReinitialize
      formSchema={formSchema}
      generateValues={(values) => ({
        ...values,
        category: values.category === "null" ? null : values.category,
        primaryCardId:
          values.destination === "card" ? values.primaryCardId : null,
      })}
      initialValues={{
        activationId: id,
        cardExperienceId: chosenExperienceId,
        shareActivationId: activation.shareActivation?.id || activation.id,
        name,
        showLaunchCard,
        primaryCardId,
        category,
        fallbackUrl,
        destination,
        hidden,
      }}
      mutation={UPDATE_ACTIVATION}
      onSuccess={refetchActivation}
    >
      {({ isSubmitting, values }) => (
        <div className="mb-8">
          <Form>
            <div className="grid grid-cols-8">
              <div className="col-span-8 my-4">
                <h2 className="text-xl tracking-tight">General</h2>
              </div>
              <div className="col-span-2 mb-4">
                <Select
                  label="Destination"
                  name="destination"
                  onChange={setDestination}
                  options={{
                    external_url: "External URL",
                    grid: "Grid",
                    card: "Card",
                  }}
                />
              </div>
              <div className="col-span-1" />
              <div className="col-span-1 mb-4">
                <Switch label="Hidden on Platform" name="hidden" />
              </div>
              <div className="col-span-4" />
              <div className="col-span-4 mb-4">
                <FormikInput label="Title" name="name" type="text" />
              </div>
              <div className="col-span-4" />

              <div className="col-span-2 mb-4">
                <FormikInput
                  label="Fallback Url"
                  name="fallbackUrl"
                  type="text"
                />
              </div>
              <div className="col-span-6" />

              <div className="col-span-4 mb-4">
                <p className="text-sm font-semibold text-gray-900">
                  Current Experience Name and ID
                </p>
                {chosenExperience ? (
                  <>
                    <Link
                      className="text-sm text-blue-600"
                      rel="noopener noreferrer"
                      target="_blank"
                      to={generateExperiencePathname(chosenExperience)}
                    >
                      {`${chosenExperience.name} - ${chosenExperience.internalNickname}`}
                    </Link>
                    <p className="text-sm text-gray-600">
                      {chosenExperienceId}
                    </p>
                  </>
                ) : (
                  <p className="text-sm text-gray-600">
                    No experience selected
                  </p>
                )}
              </div>
              <div className="col-span-4" />

              <div className="col-span-4 mb-4">
                {chosenExperience && (
                  <>
                    <Switch
                      hint="Launch card must be configured in the card experience. Please save after toggling."
                      label="Show Launch Card"
                      name="showLaunchCard"
                    />
                    {values.showLaunchCard && !chosenExperience.launchCard && (
                      <p className="text-sm text-red-600">
                        Launch card has not been configured in the card
                        experience.
                      </p>
                    )}
                  </>
                )}
              </div>
              <div className="col-span-4" />
              {chosenExperience && destination === "card" && (
                <>
                  <div className="col-span-4 mb-4">
                    <Select
                      hint="External URL cards may not be selected"
                      label="Primary Card"
                      name="primaryCardId"
                      options={generateAvailableCardIds(chosenExperience)}
                    />
                  </div>
                  <div className="col-span-4" />
                </>
              )}
              <div className="col-span-2 mb-4">
                <Select
                  label="Category (Internal)"
                  name="category"
                  options={{
                    null: "(None)",
                    ...availableCategories,
                  }}
                />
              </div>
              <div className="col-span-7" />

              <div className="col-span-4 mb-6">
                <ComboboxSelector
                  label="Point to..."
                  name="cardExperienceId"
                  onChange={(value) => setChosenExperienceId(value)}
                  options={experienceOptions}
                />
              </div>
              <div className="col-span-4" />
              {chosenExperience && (
                <>
                  <div className="col-span-4 mb-6">
                    <ComboboxSelector
                      hint="This will auto-update to itself if the card experience is updated."
                      label="Share activation URL to..."
                      name="shareActivationId"
                      options={generateShareActivationOptions(chosenExperience)}
                    />
                  </div>
                  <div className="col-span-4" />
                </>
              )}

              <div className="col-span-8">
                <RedButtonWithConfirmation
                  onClick={deleteActivationMutation}
                  submitting={isSubmitting}
                >
                  Delete
                </RedButtonWithConfirmation>

                <SubmitButton
                  className="ml-2"
                  cta="Save"
                  submitting={isSubmitting}
                />
              </div>
            </div>
            <QrCodes
              activation={activation}
              refetchActivation={refetchActivation}
            />
          </Form>
        </div>
      )}
    </BatchForm>
  )
}

export default GridForm
