import React from "react"
import Time from "react-time-format"
import BackLink from "components/backlink"
import ActivationAppStoreConnectStatus from "components/activation-app-store-connect-status"
import ActivationAppStoreConnectMessage from "components/activation-app-store-connect-message"
import QRCode from "components/activation-preview"
import NFCTagSeries from "./nfc-tag-series"
import TestQRCode from "./test-qr-code"

const ActivationInformation = ({ activation, refetchActivation }) => {
  const { name, url, createdAt, appStoreConnectStatus } = activation

  return (
    <>
      <header>
        <div className="px-8">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-start">
              <div className="flex flex-col">
                <BackLink />
                <div className="flex flex-row items-center">
                  <h1 className="text-3xl mr-2 font-bold leading-tight text-gray-900">
                    {name}
                  </h1>
                </div>
                <p className="font-normal text-sm leading-tight text-gray-500">
                  Created <Time format="MM/DD/YYYY" value={createdAt} />
                  &nbsp;&bull;&nbsp;
                  <ActivationAppStoreConnectStatus
                    status={appStoreConnectStatus}
                  />
                  &nbsp;
                  <a
                    className="cursor-pointer font-normal text-sm leading-tight text-blue-600 hover:text-blue-800"
                    href={url}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {url}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="flex flex-col px-8">
          <ActivationAppStoreConnectMessage status={appStoreConnectStatus} />
          <div className="flex flex-row">
            <div className="w-1/3">
              <QRCode activation={activation} />
            </div>
            <div className="w-1/3">
              <TestQRCode activation={activation} />
            </div>
            <div className="w-1/3 pl-8">
              <NFCTagSeries
                activation={activation}
                refetchActivation={refetchActivation}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ActivationInformation
