import gql from "graphql-tag"

const UPDATE_ACTIVATION = gql`
  mutation UpdateQRCodeActivation($input: UpdateQRCodeActivationInput!) {
    updateQrCodeActivation(input: $input) {
      errors
      activation {
        id
      }
    }
  }
`
const DELETE_ACTIVATION = gql`
  mutation DeleteActivation($input: DeleteActivationInput!) {
    deleteActivation(input: $input) {
      errors
    }
  }
`

const GENERATE_ACTIVATION_LEGACY_QR_CODE_MUTATION = gql`
  mutation GenerateActivationLegacyQRCode(
    $input: GenerateActivationLegacyQRCodeInput!
  ) {
    generateActivationLegacyQrCode(input: $input) {
      errors
      activation {
        id
      }
    }
  }
`

export {
  UPDATE_ACTIVATION,
  DELETE_ACTIVATION,
  GENERATE_ACTIVATION_LEGACY_QR_CODE_MUTATION,
}
