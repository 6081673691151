import React from "react"

import { UPDATE_BATCH_TAG_ACTIVATION } from "./queries"
import BaseBatchTagModal from "./base-batch-tag-modal"

const EditBatchTagModal = ({
  activation,
  cardExperience,
  onSuccess,
  onClose,
  show,
}) => {
  if (!show) return null

  const { name } = activation

  return (
    <BaseBatchTagModal
      activation={activation}
      cardExperience={cardExperience}
      cta="Save"
      migration={UPDATE_BATCH_TAG_ACTIVATION}
      onClose={onClose}
      onSuccess={onSuccess}
      show={show}
      title={
        <>
          Edit <em>{name}</em>
        </>
      }
    />
  )
}

export default EditBatchTagModal
