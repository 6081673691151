import { useMutation } from "@apollo/client"
import Card from "components/card"
import Row from "components/layout/row"
import Toggle from "components/toggle"
import React from "react"
import { useToasts } from "react-toast-notifications"
import ExperienceDescription from "../../ExperienceDescription"
import { UPDATE_BRAND_MUTATION } from "./queries"

const ExperienceCard = ({
  cardExperience,
  experienceOnCoreApp,
  brand,
  closeDrawer,
  refetchBrands,
}) => {
  const { addToast } = useToasts()
  const [executeMutation] = useMutation(UPDATE_BRAND_MUTATION, {
    onError: () => {
      addToast("Something went wrong, changes did not save!", {
        appearance: "error",
      })
    },
    onCompleted: () => {
      closeDrawer()
      refetchBrands()
      addToast("Saved Successfully", { appearance: "success" })
    },
  })

  const isInCoreApp = experienceOnCoreApp?.id === cardExperience.id

  const handleCheckboxChange = () => {
    executeMutation({
      variables: {
        input: {
          brandId: brand.id,
          name: brand.name,
          homepage: brand.homepage,
          shopifyDomain: brand.shopifyDomain,
          coreAppCardExperienceId: isInCoreApp ? null : cardExperience.id,
        },
      },
    })
  }

  return (
    <Card>
      <Row>
        <ExperienceDescription brand={brand} cardExperience={cardExperience} />
        <Toggle
          data-testid="switch"
          enabled={isInCoreApp}
          setEnabled={handleCheckboxChange}
        />
      </Row>
    </Card>
  )
}

export default ExperienceCard
