import React, { useState } from "react"
import SmallFormModal from "components/small-form-modal"
import DomainStep from "./domain-step"
import FormStep from "./form-step"

const CreateBrandModal = ({ visible, onClose }) => {
  const [step, setStep] = useState("domain")
  const [domainValues, setDomainValues] = useState({})

  const onDomainSuccess = (dvs) => {
    setDomainValues(dvs)
    setStep("form")
  }

  const cleanupClose = () => {
    setDomainValues({})
    setStep("domain")
    onClose()
  }

  return (
    <SmallFormModal onClose={cleanupClose} visible={visible}>
      {({ close }) => {
        if (step === "domain")
          return <DomainStep close={close} onSuccess={onDomainSuccess} />
        if (step === "form")
          return <FormStep close={close} domainValues={domainValues} />
        return null
      }}
    </SmallFormModal>
  )
}

export default CreateBrandModal
