import React from "react"
import { formatCurrency } from "utils/money"

const ProductVariantCatalogSectionPreview = ({ experienceSectionDetails }) => (
  <div className="px-4 bg-white">
    <div className="flex flex-col justify-start items-center text-gray-600 font-semibold text-xs w-full mt-4">
      <div className="w-full h-0.5 bg-black" />
      <h5 className="text-black font-semibold text-xs w-full text-left py-3">
        {experienceSectionDetails.title}
      </h5>
      {experienceSectionDetails.productVariants.map(
        ({
          id,
          imageSrcMedium,
          standaloneTitle,
          subtitle,
          description,
          price,
        }) => (
          <div
            key={id}
            className="border-t border-gray-200 border-1 w-full py-4 flex flex-row items-start justify-start"
          >
            <img
              alt=""
              className="rounded-xl w-14 h-14 mr-3"
              src={imageSrcMedium}
            />
            <div className="flex-grow">
              <p
                className="text-xs text-gray-800 font-bold"
                style={{ fontSize: "0.7rem", lineHeight: "1rem" }}
              >
                {standaloneTitle}
              </p>
              <p
                className="text-xs text-gray-800 font-semibold tracking-tight"
                style={{ fontSize: "0.7rem", lineHeight: "1.2rem" }}
              >
                {subtitle}
              </p>
              <p
                className="text-xs text-gray-500 font-normal"
                style={{ fontSize: "0.65rem", lineHeight: "0.8rem" }}
              >
                {description}
              </p>
              <div className="flex flex-row items-center justify-between pt-2 pr-2">
                <span
                  className="text-xs text-gray-800 font-semibold tracking-tight"
                  style={{ fontSize: "0.7rem", lineHeight: "1.2rem" }}
                >
                  {formatCurrency(price)}
                </span>
                <div>
                  <span className="text-sm text-gray-800 font-semibold tracking-tight px-1">
                    -
                  </span>
                  <span className="text-sm text-gray-800 font-semibold tracking-tight px-1">
                    +
                  </span>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  </div>
)

export default ProductVariantCatalogSectionPreview
