import gql from "graphql-tag"

export const REORDER_CARD_VARIATIONS = gql`
  mutation ReorderCardVariations($input: ReorderCardVariationsInput!) {
    reorderCardVariations(input: $input) {
      errors
      card {
        id
      }
    }
  }
`

export const UPDATE_CARD_VARIATION_DISCOUNT = gql`
  mutation UpdateCardVariationCardDiscount(
    $input: UpdateCardVariationCardDiscountInput!
  ) {
    updateCardVariationCardDiscount(input: $input) {
      errors
      cardVariationCard {
        id
        discount
        discountMethod
      }
    }
  }
`

export const DELETE_CARD_VARIATION = gql`
  mutation DeleteCardVariation($input: DeleteCardVariationInput!) {
    deleteCardVariation(input: $input) {
      errors
      cardVariation {
        id
      }
    }
  }
`

export const GET_AVAILABLE_PRODUCT_VARIANTS = gql`
  query GetAvailableProductVariants($cardId: ID!, $brandKey: String!) {
    availableProductVariants(cardId: $cardId, brandKey: $brandKey) {
      id
      price
      productTitle
      subtitle
      shopifyVariantIdNumber
      platformId
      imageSrcSmall
      sku
      product {
        id
        shopifyProductIdNumber
      }
      cardExperiences {
        id
        internalNickname
      }
    }
  }
`
