import React from "react"
import { Link } from "react-router-dom"

const LinkToNewTab = (props) => {
  const { children } = props
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Link rel="noopener noreferrer" target="_blank" {...props}>
      {children}
      <span className="ml-1 mb-1 text-md text-purple-400">&#8599;</span>
    </Link>
  )
}

export default LinkToNewTab
