import React, { useState } from "react"
import { Form } from "formik"
import BatchForm from "components/batch-form"
import SubmitButton from "components/submit-button"
import ImagePicker from "components/image-picker"
import { UPDATE_EXPERIENCE_BACKGROUND_VIDEO_MUTATION } from "./queries"

const BackgroundVideoForm = ({
  currentExperience,
  refetchExperience,
  setProcessing,
}) => {
  const { backgroundVideoSrc, id: experienceId } = currentExperience
  const [videoUrl, setVideoUrl] = useState(backgroundVideoSrc)

  const onImageUploadComplete = (url) => {
    setVideoUrl(url)
  }

  return (
    <BatchForm
      generateValues={() => ({
        backgroundVideoSrc: videoUrl,
        experienceId,
      })}
      initialValues={{
        backgroundVideoSrc,
      }}
      mutation={UPDATE_EXPERIENCE_BACKGROUND_VIDEO_MUTATION}
      onSuccess={() => {
        refetchExperience()
        setProcessing(true)
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <ImagePicker
            allowedFileTypes={["video/mp4"]}
            imageUrl={videoUrl}
            inputName="backgroundVideoSrc"
            label="Background Video"
            maxFileSize={1024 * 1024 * 100} // 100 MB
            onImageUploadComplete={onImageUploadComplete}
            uploadFolder={`experiences/${experienceId}/background_video`}
            uploadText="Upload a video..."
          />
          <SubmitButton submitting={isSubmitting} />
        </Form>
      )}
    </BatchForm>
  )
}

export default BackgroundVideoForm
