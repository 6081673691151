/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React from "react"
import Table from "components/table"
import { SecondaryButton, RedButtonWithConfirmation } from "components/button"

const CardTable = ({
  addExistingCardToCardExperience,
  deleteCard,
  currentCardExperienceId,
  currentCardOrdinal,
  tableData,
  brandKey,
  isLaunchCard,
}) => (
  <Table
    columns={[
      {
        title: "Background Image",
        filtering: false,
        render: (row) => {
          const { backgroundImgSrc, hidden } = row
          const color = hidden ? "bg-red-100" : "bg-green-100"
          const label = hidden ? "Hidden" : "Visible"
          return (
            <div>
              <img
                alt="background-img"
                className="mx-auto mb-2"
                src={backgroundImgSrc}
                style={{ maxHeight: "100px" }}
              />
              <div className="flex justify-center">
                <span
                  className={`px-2 py-1 mr-1 mb-1 text-gray-800 text-xs font-medium rounded-sm ${color}`}
                >
                  {label}
                </span>
              </div>
            </div>
          )
        },
      },
      {
        title: "ID",
        customFilterAndSearch: (term, row) =>
          row.cardId.toLowerCase().includes(term.toLowerCase()),
        render: (row) => {
          const { cardId } = row
          return (
            <div className="ml-4 pr-6 py-2 items-center font-medium text-gray-900">
              <p>{cardId}</p>
            </div>
          )
        },
      },
      {
        title: "SKUs",
        customFilterAndSearch: (term, row) =>
          row.skus
            .map((sku) => sku.toLowerCase().includes(term.toLowerCase()))
            .includes(true),
        render: (row) => {
          const { skus } = row
          return (
            <ul className="list-disc pl-5 text-sm font-mono">
              {skus.map((sku) => (
                <li key={sku}>{sku}</li>
              ))}
            </ul>
          )
        },
      },
      {
        title: "Actions",
        filtering: false,
        render: (row) => {
          const { cardId } = row
          return (
            <div className="flex-col">
              <SecondaryButton
                className="inline-block"
                onClick={() =>
                  addExistingCardToCardExperience({
                    variables: {
                      input: {
                        cardExperienceId: currentCardExperienceId,
                        cardId,
                        ordinal: currentCardOrdinal,
                        brandKey,
                        isLaunchCard,
                        clone: false,
                      },
                    },
                  })
                }
                type="button"
              >
                Add this card
              </SecondaryButton>

              <SecondaryButton
                className="inline-block mt-1"
                onClick={() =>
                  addExistingCardToCardExperience({
                    variables: {
                      input: {
                        cardExperienceId: currentCardExperienceId,
                        cardId,
                        ordinal: currentCardOrdinal,
                        brandKey,
                        isLaunchCard,
                        clone: true,
                      },
                    },
                  })
                }
                type="button"
              >
                Clone this card
              </SecondaryButton>

              <RedButtonWithConfirmation
                className="inline-block mt-1"
                onClick={() =>
                  deleteCard({
                    variables: {
                      input: {
                        brandKey,
                        cardId,
                      },
                    },
                  })
                }
                type="button"
              >
                Delete Card
              </RedButtonWithConfirmation>
            </div>
          )
        },
      },
    ]}
    data={tableData}
  />
)

export default CardTable
