import { useCallback, useEffect } from "react"

const useTriggerClick = (
  triggerRef,
  menuRef,
  visible,
  onTriggerClick,
  onOutsideClick
) => {
  const handleDocumentClick = useCallback(
    (event) => {
      if (menuRef?.current && !menuRef.current.contains(event.target)) {
        onOutsideClick(event)
      }
      // else no op
    },
    [menuRef, onOutsideClick]
  )

  const handleTriggerClick = useCallback(
    (event) => {
      if (triggerRef?.current && triggerRef.current.contains(event.target)) {
        onTriggerClick(event)
      }
      // else no op
    },
    [triggerRef, onTriggerClick]
  )

  useEffect(() => {
    document.addEventListener("mouseup", handleTriggerClick)

    if (visible) {
      document.addEventListener("mouseup", handleDocumentClick)
    } else {
      document.removeEventListener("mouseup", handleDocumentClick)
    }

    return () => {
      document.removeEventListener("mouseup", handleTriggerClick)
      document.removeEventListener("mouseup", handleDocumentClick)
    }
  }, [visible, triggerRef, menuRef, handleTriggerClick, handleDocumentClick])
}

export default useTriggerClick
