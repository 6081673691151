import React from "react"
import { useField } from "formik"
import Toggle from "components/toggle"
import Error from "./error"
import Label from "./label"
import HintText from "./input/hint-text"

const Switch = ({
  noLabel,
  label,
  name,
  hint,
  wrapperProps,
  onChange: passedOnChange = null,
  ...switchProps
}) => {
  const [field, { touched, error }, { setValue, setTouched }] = useField(name)

  const onChange = (newValue) => {
    setTouched()
    setValue(newValue)
    if (passedOnChange !== null) {
      passedOnChange(newValue)
    }
  }

  return (
    <div className="flex flex-col gap-px" {...wrapperProps}>
      <Label displayName={label || name} inputName={name} noLabel={noLabel} />
      <Toggle
        data-testid="switch"
        enabled={field.value}
        setEnabled={onChange}
        {...switchProps}
      />
      <Error error={error} touched={touched} />
      <HintText hint={hint} />
    </div>
  )
}

export default Switch
