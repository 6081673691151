import React, { useState } from "react"
import { Form } from "formik"
import * as Yup from "yup"

import BatchForm from "components/batch-form"
import FormikInput from "components/forms/formik-input"
import SubmitButton from "components/submit-button"
import ImagePicker from "components/image-picker"
import { useParams } from "react-router-dom"
import { SAVE_BRAND_GENERAL_SETTINGS } from "./queries"

const GeneralSettingsForm = ({ brand }) => {
  const {
    id,
    name: brandName,
    key,
    logoImageSrc: initialLogoImageSrc,
    logoHorizontalSrc: initialLogoHorizontalSrc,
    termsOfUseUrl,
    privacyPolicyUrl,
    supportUrl,
  } = brand
  const { brandKey } = useParams()
  const [logoImageSrc, setLogoImageSrc] = useState(initialLogoImageSrc)
  const [logoHorizontalSrc, setLogoHorizontalSrc] = useState(
    initialLogoHorizontalSrc
  )

  const formSchema = Yup.object().shape({
    brandName: Yup.string().required("Required"),
    key: Yup.string().required("Required"),
    termsOfUseUrl: Yup.string(),
    privacyPolicyUrl: Yup.string(),
    supportUrl: Yup.string(),
  })

  return (
    <BatchForm
      additionalValues={{
        logoImageSrc,
        logoHorizontalSrc,
      }}
      formSchema={formSchema}
      initialValues={{
        brandName: brandName || "",
        key: key || "",
        termsOfUseUrl: termsOfUseUrl || "",
        privacyPolicyUrl: privacyPolicyUrl || "",
        supportUrl: supportUrl || "",
      }}
      mutation={SAVE_BRAND_GENERAL_SETTINGS}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white py-6 px-2">
              <div className="mt-6 grid grid-cols-4 gap-6">
                <div className="col-span-4 sm:col-span-3 md:col-span-2">
                  <FormikInput
                    label="Brand Name"
                    name="brandName"
                    placeholder="Kosterina"
                    type="text"
                  />
                </div>
                <div className="hidden md:block md:col-span-2" />

                <div className="col-span-4 sm:col-span-2 md:col-span-1">
                  <FormikInput
                    disabled
                    label="Key"
                    name="key"
                    placeholder="kosterina"
                    prefix="batch.link/"
                    type="text"
                  />
                </div>
                <div className="hidden sm:block sm:col-span-3" />

                <div className="col-span-4 sm:col-span-2">
                  <FormikInput
                    label="Terms of Use URL"
                    name="termsOfUseUrl"
                    placeholder="http://brand.com/terms-of-service"
                    type="text"
                  />
                </div>
                <div className="hidden md:block md:col-span-2" />

                <div className="col-span-4 sm:col-span-2">
                  <FormikInput
                    label="Privacy Policy URL"
                    name="privacyPolicyUrl"
                    placeholder="http://brand.com/privacy-policy"
                    type="text"
                  />
                </div>
                <div className="hidden md:block md:col-span-2" />

                <div className="col-span-4 sm:col-span-2">
                  <FormikInput
                    label="Support URL"
                    name="supportUrl"
                    placeholder="http://brand.com/support"
                    type="text"
                  />
                </div>
                <div className="hidden md:block md:col-span-2" />
                <div className="col-span-4 sm:col-span-2">
                  <ImagePicker
                    brandKey={brandKey}
                    imageUrl={logoImageSrc}
                    inputName="logoImageSrc"
                    label="Favicon Logo"
                    onImageUploadComplete={setLogoImageSrc}
                    preview
                    uploadFolder={`brand/${id}/logo`}
                  />
                </div>
                <div className="hidden md:block md:col-span-2" />
                <div className="col-span-4 sm:col-span-2">
                  <ImagePicker
                    brandKey={brandKey}
                    imageUrl={logoHorizontalSrc}
                    inputName="logoHorizontalSrc"
                    label="Horizontal Logo"
                    onImageUploadComplete={setLogoHorizontalSrc}
                    preview
                    uploadFolder={`brand/${id}/horizontal-logo`}
                  />
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-left sm:px-6">
              <SubmitButton submitting={isSubmitting} />
            </div>
          </div>
        </Form>
      )}
    </BatchForm>
  )
}

export default GeneralSettingsForm
