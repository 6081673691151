import Center from "components/layout/center"
import React from "react"

const DiscountBadge = ({ displayValue, badgeSvg, badgeTextColor }) => {
  if (!displayValue) {
    return null
  }

  return (
    <Center
      className="relative self-end  mr-4 transform rotate-15"
      data-testid="badge"
      style={{ width: 72, height: 72 }}
    >
      <img alt="discount-sticker" data-testid="dark-sticker" src={badgeSvg} />

      <div
        className="absolute font-bold text-center"
        style={{
          color: badgeTextColor,
        }}
      >
        {displayValue === "100%" ? (
          <p>Free</p>
        ) : (
          <>
            <p>{displayValue}</p>
            <p className="text-xs">OFF</p>
          </>
        )}
      </div>
    </Center>
  )
}

export default DiscountBadge
