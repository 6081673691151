import React from "react"

const Counter = ({ limit, value }) => {
  if (!limit) return null

  return (
    <span className="text-gray-400" style={{ fontSize: "0.6rem" }}>
      {value?.length || 0}/{limit}
    </span>
  )
}

export default Counter
