import gql from "graphql-tag"

const GET_BRAND = gql`
  query GetBrand($brandKey: String!) {
    brand(brandKey: $brandKey) {
      id
      name
      key
      affiliates {
        id
        fullName
        firstName
        lastName
        email
        phoneNumber
        activationUrl
        testUrl
        activationId
      }
      activations {
        id
        name
      }
    }
  }
`

const CREATE_AFFILIATE_MUTATION = gql`
  mutation CreateOrUpdateAffiliate($input: CreateOrUpdateAffiliateInput!) {
    createOrUpdateAffiliate(input: $input) {
      affiliate {
        id
      }
      errors
    }
  }
`

const DELETE_AFFILIATE = gql`
  mutation DeleteAffiliate($input: DeleteAffiliateInput!) {
    deleteAffiliate(input: $input) {
      errors
    }
  }
`

export default GET_BRAND

export { GET_BRAND, CREATE_AFFILIATE_MUTATION, DELETE_AFFILIATE }
