import React from "react"
import useDisclosure from "hooks/use-disclosure"
import List from "./list"
import CreateBrandModal from "./create-brand-modal"

const BrandIndex = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      {/* TODO: Remove create brand modal and add prop isDrawerOpen to use the new side drawer form */}
      <List onAddBrand={onOpen} />
      <CreateBrandModal onClose={onClose} visible={isOpen} />
    </>
  )
}

export default BrandIndex
