import React, { useState, useEffect } from "react"
import {
  TrashIcon,
  SelectorIcon,
  PencilAltIcon,
} from "@heroicons/react/outline"
import { formatCurrency } from "utils/money"
import classNames from "classnames"
import LinkToNewTab from "components/link-to-new-tab"
import Badge from "components/badge"
import Row from "components/layout/row"
import useDisclosure from "hooks/use-disclosure"
import EditDiscountDrawer from "./edit-discount-drawer"

const getBadgeInfo = (cardVariationCard) => {
  if (!cardVariationCard.discount || !cardVariationCard.discountMethod) {
    return {
      color: "gray",
      message: "No discount",
    }
  }
  if (cardVariationCard.discountMethod === "percentage") {
    return {
      color: "blue",
      message: `${cardVariationCard.discount}% OFF`,
    }
  }
  return {
    color: "green",
    message: `${formatCurrency(cardVariationCard.discount * 100)} OFF`,
  }
}

const CardVariationDetail = ({
  currentCardId,
  variation,
  deleteCardVariationMutation,
  innerRef,
  dragHandleProps,
  draggableProps,
  innerClassName,
  brandKey,
  showDeletedAlert,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currentCardVariationCard, setCurrentCardVariationCard] = useState({})
  useEffect(() => {
    const newCardVariationCard = variation.cardVariationCards.find(
      (cvc) =>
        cvc.cardVariation.id === variation.id && cvc.card.id === currentCardId
    )
    setCurrentCardVariationCard(newCardVariationCard)
  }, [currentCardId, variation])
  const badgeInfo = getBadgeInfo(currentCardVariationCard)

  return (
    <>
      <li
        key={variation.id}
        ref={innerRef}
        className={classNames(
          "relative border w-full rounded-sm p-4 my-1 flex flex-row justify-between items-center font-medium",
          innerClassName,
          { "border-red-500 border-2 border-sky": showDeletedAlert }
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...draggableProps}
      >
        <Row>
          <img
            alt=""
            className="w-12 h-12 mr-4"
            src={variation.productVariant.imageSrcSmall}
          />
          <div className="flex flex-col">
            <div className="flex flex-row items-center">
              <span className="flex items-center mr-2 text-sm font-semibold">
                {variation.productVariant.productTitle}
                <LinkToNewTab
                  to={`../product-variants/${variation.productVariant.id}`}
                />
              </span>
              <button className="cursor-pointer" onClick={onOpen} type="button">
                <Badge
                  color={badgeInfo.color}
                  message={
                    <Row>
                      <span>{badgeInfo.message}</span>
                      <PencilAltIcon className="w-4 h-4 ml-1" />
                    </Row>
                  }
                />
              </button>
            </div>
            <Row>
              <span className="text-xs">
                {variation.productVariant.shopifyVariantIdNumber}
              </span>
              <span className="px-1 text-xs text-gray-400">&bull;</span>
              <span className="text-xs">{variation.variationValue}</span>
              <span className="px-1 text-xs text-gray-400">&bull;</span>
              <span className="text-xs">
                {formatCurrency(variation.productVariant.price)}
              </span>
              <span className="px-1 text-xs text-gray-400">&bull;</span>

              <span className="text-xs">{variation.productVariant.sku}</span>
            </Row>
          </div>
        </Row>
        <Row className="ml-8">
          <button
            onClick={() => {
              deleteCardVariationMutation({
                variables: {
                  input: {
                    cardId: currentCardId,
                    cardVariationId: variation.id,
                    brandKey,
                  },
                },
              })
            }}
            type="button"
          >
            <TrashIcon className="w-4 h-4 ml-4 text-gray-400 hover:text-gray-600" />
          </button>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <button type="button" {...dragHandleProps}>
            <SelectorIcon className="w-4 h-4 ml-4 text-gray-400 hover:text-gray-600 cursor-drag" />
          </button>
        </Row>
      </li>
      <EditDiscountDrawer
        cardVariationCard={currentCardVariationCard}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

export default CardVariationDetail
