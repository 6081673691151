import React from "react"
// TODO: Remove the form component and use new-form instead, while renaming it to form
import BrandForm from "./form"

const BrandInformation = ({ brand, refetchBrand }) => {
  const { logoImageSrc } = brand

  return (
    <>
      <header>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <img
              alt={`${brand.name} logo`}
              className="h-6 w-6 bg-white rounded-full ring-2 ring-gray-900 mr-4"
              src={logoImageSrc}
            />
            <div className="flex flex-col">
              <div className="flex flex-row items-center">
                <h1 className="text-3xl mr-2 font-extrabold leading-tight text-gray-900">
                  {brand.name}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="pt-8">
        <BrandForm brand={brand} refetchBrand={refetchBrand} />
      </main>
    </>
  )
}
export default BrandInformation
