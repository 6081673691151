import React, { useState } from "react"
import CollapsableRadioSelect from "components/collapsable-radio-select"
import { useEditor } from "contexts/experience-editor"

import BackgroundImageForm from "components/background-form/background-image-form"
import BackgroundVideoForm from "components/background-form/background-video-form"

import PollExperience from "./poll-experience"

const BackgroundForm = () => {
  const { currentExperience, refetchExperience } = useEditor()
  const [processing, setProcessing] = useState(false)

  const getDefaultSelectedIndex = () => {
    const { backgroundVideoSrc } = currentExperience
    const hasVideo = backgroundVideoSrc !== null && backgroundVideoSrc !== ""
    return hasVideo ? 0 : 1
  }

  return (
    <>
      <CollapsableRadioSelect defaultSelectedIndex={getDefaultSelectedIndex()}>
        {[
          {
            form: (
              <BackgroundVideoForm
                currentExperience={currentExperience}
                refetchExperience={refetchExperience}
                setProcessing={setProcessing}
              />
            ),
            title: "Video Background",
            subtitle:
              "Upload a video file to automatically play in the background, seamlessly.",
          },
          {
            form: (
              <BackgroundImageForm
                currentExperience={currentExperience}
                refetchExperience={refetchExperience}
              />
            ),
            title: "Image Background",
            subtitle:
              "Upload a static image that loads instantly showcasing your product.",
          },
        ]}
      </CollapsableRadioSelect>
      {processing && (
        <PollExperience
          currentExperience={currentExperience}
          refetchExperience={refetchExperience}
          setProcessing={setProcessing}
        />
      )}
    </>
  )
}
export default BackgroundForm
