import React from "react"
import { useMutation } from "@apollo/client"
import { TrashIcon } from "@heroicons/react/outline"
import { useToasts } from "react-toast-notifications"
import { RedButtonWithConfirmation } from "components/button"

import { useParams } from "react-router-dom"
import { DELETE_CARD_FROM_CARD_EXPERIENCE } from "./queries"

const DeleteCardButton = ({
  currentCard,
  currentCardExperience,
  isLaunchCard,
  refetchCardExperience,
}) => {
  const { addToast } = useToasts()
  const { brandKey } = useParams()

  const [deleteCardFromExperience, { loading }] = useMutation(
    DELETE_CARD_FROM_CARD_EXPERIENCE,
    {
      variables: {
        input: {
          cardId: currentCard.id,
          cardExperienceId: currentCardExperience.id,
          brandKey,
          isLaunchCard,
        },
      },
      onError: (e) => {
        addToast(`Error occurred while removing: ${e.message}`, {
          appearance: "error",
        })
        refetchCardExperience()
      },
      onCompleted: () => {
        addToast("Card successfully removed", {
          appearance: "success",
        })
        refetchCardExperience()
      },
    }
  )

  return (
    <RedButtonWithConfirmation
      confirmationHeader="Are you sure you want to remove this card?"
      icon={
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
          <TrashIcon className="h-6 w-6 text-gray-600" />
        </div>
      }
      onClick={deleteCardFromExperience}
      submitting={loading}
    >
      Remove Card from Experience
    </RedButtonWithConfirmation>
  )
}

export default DeleteCardButton
