import React from "react"
import { Form } from "formik"
import * as Yup from "yup"
import { DeviceMobileIcon } from "@heroicons/react/solid"
import Button from "components/button"
import BatchForm from "components/batch-form"
import SubmitButton from "components/submit-button"
import FormikInput from "components/forms/formik-input"
import SmallFormModal, {
  ModalFooter,
  ModalBody,
} from "components/small-form-modal"
import { CREATE_BLANK_EXPERIENCE_MUTATION } from "./queries"

const CreateExperienceModal = ({ visible, onClose, refetchExperiences }) => {
  const formSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  })

  return (
    <SmallFormModal onClose={onClose} visible={visible}>
      {({ close }) => (
        <BatchForm
          initialValues={{
            name: "",
          }}
          mutation={CREATE_BLANK_EXPERIENCE_MUTATION}
          onSuccess={(data) => {
            refetchExperiences()
            window.location = `old-experiences/${data.createBlankExperience.experience.id}/editor`
          }}
          validationSchema={formSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody
                icon={
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <DeviceMobileIcon className="w-6 h-6 text-blue-600" />
                  </div>
                }
                title="Create a New Experience"
              >
                <FormikInput
                  autoFocus
                  name="name"
                  placeholder="64 oz Vanilla Coffee"
                  type="text"
                />
              </ModalBody>
              <ModalFooter>
                <SubmitButton submitting={isSubmitting} />
                <Button className="mr-2" onClick={close}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </BatchForm>
      )}
    </SmallFormModal>
  )
}

export default CreateExperienceModal
