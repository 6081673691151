export const APP_CLIP_CARD_SECTION = {
  id: "AppClipCard",
  experienceSectionDetailsType: "AppClipCard",
  experienceSectionId: "AppClipCard",
  experienceSectionDetails: null,
}

export const APP_CLIP_BACKGROUND_SECTION = {
  id: "AppClipCardBackground",
  experienceSectionDetailsType: "AppClipCardBackground",
  experienceSectionId: "AppClipCardBackground",
  experienceSectionDetails: null,
}

// export const APP_CLIP_TRAY_SECTION = {
//   id: "AppClipCardPaymentTray",
//   experienceSectionDetailsType: "AppClipCardPaymentTray",
//   experienceSectionId: "AppClipCardPaymentTray",
//   experienceSectionDetails: null,
// }
