import React from "react"
import { Link, useHistory } from "react-router-dom"
import { useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"

import ActivationDemo from "components/activation-demo"
import Badge from "components/badge"
import IndexTable from "components/index-table"
import Toggle from "components/toggle"

import { UPDATE_ACTIVATION } from "./queries"

const createTableData = (brand) => {
  const { activations } = brand
  return activations.map((activation) => {
    const {
      id: activationId,
      url: activationUrl,
      name: activationName,
      testUrl,
      kind,
      appleAppClipActive,
      hidden,
      cardExperience,
      destination,
      fallbackUrl,
    } = activation

    const cardExperienceName = cardExperience
      ? cardExperience.name
      : "Undefined Card Experience"
    const cardExperienceId = cardExperience ? cardExperience.id : ""
    const cardExperienceUrl = `experiences/${cardExperienceId}`
    const destinationName =
      destination === "external_url" ? "External URL" : cardExperienceName
    const destinationUrl =
      destination === "external_url" ? fallbackUrl : cardExperienceUrl
    const destinationHint =
      destination === "external_url" ? fallbackUrl : cardExperienceId
    const activationEditUrl = `activations/${activationId}`

    return {
      activation,
      activationId,
      activationName,
      activationUrl,
      appleAppClipActive,
      destination,
      destinationHint,
      destinationName,
      destinationUrl,
      activationEditUrl,
      testUrl,
      kind,
      hidden,
    }
  })
}

const ActivationsList = ({ brand, refetchBrand }) => {
  const history = useHistory()
  const { addToast } = useToasts()

  const [updateActivationMutation] = useMutation(UPDATE_ACTIVATION, {
    onCompleted: () => {
      addToast("Successfully updated activation", { appearance: "success" })
      refetchBrand()
    },
    onError: (error) => {
      addToast(`Could not delete activation: ${error.message}`, {
        appearance: "error",
      })
    },
  })

  const handleVisibilityToggleChange = ({
    activationId,
    destination,
    hidden,
  }) => {
    updateActivationMutation({
      variables: {
        input: {
          activationId,
          brandKey: brand.key,
          destination,
          hidden,
        },
      },
    })
  }
  const activeBadge = <Badge color="green" message="Active" uppercase />
  const inactiveBadge = <Badge color="red" message="Inactive" uppercase />

  const visibleBadge = <Badge color="green" message="Visible" uppercase />
  const hiddenBadge = <Badge color="red" message="Hidden" uppercase />

  const tableData = createTableData(brand)

  return (
    <IndexTable
      columns={[
        {
          title: "Test QR Code",
          field: "internalQrCode",
          filtering: false,
          sorting: false,
          align: "center",
          cellStyle: { whiteSpace: "nowrap", width: "15%" },
          render: ({ activation }) => (
            <ActivationDemo
              activation={activation}
              hideDemoUrl
              hideHeader
              kind="test"
              size={24}
            />
          ),
        },
        {
          title: "Name",
          field: "activationName",
          cellStyle: { whiteSpace: "nowrap", width: "15%" },
          customFilterAndSearch: (term, row) =>
            row.activationName.toLowerCase().includes(term.toLowerCase()),
          customSort: (a, b) =>
            a.activationName.localeCompare(b.activationName),
          defaultSort: "asc",
        },
        {
          title: "URL",
          field: "activationUrl",
          cellStyle: { whiteSpace: "nowrap", width: "20%" },
          customFilterAndSearch: (term, row) =>
            row.activationUrl.toLowerCase().includes(term.toLowerCase()),
          customSort: (a, b) => a.activationUrl.localeCompare(b.activatioUrl),
        },
        {
          title: "Points to...",
          field: "pointsTo",
          cellStyle: { whiteSpace: "nowrap", width: "20%" },
          customFilterAndSearch: (term, row) =>
            row.destinationName.toLowerCase().includes(term.toLowerCase()) ||
            row.destinationUrl.toLowerCase().includes(term.toLowerCase()) ||
            row.destinationHint.toLowerCase().includes(term.toLowerCase()),
          customSort: (a, b) =>
            a.destinationName.localeCompare(b.destinationName),
          render: ({
            destination,
            destinationHint,
            destinationName,
            destinationUrl,
          }) => (
            <>
              {destination === "external_url" ? (
                <a
                  className="text-blue-600"
                  href={destinationUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {destinationName}
                </a>
              ) : (
                <Link
                  className="text-blue-600"
                  rel="noopener noreferrer"
                  target="_blank"
                  to={destinationUrl}
                >
                  <p>{destinationName}</p>
                </Link>
              )}
              <p className="text-sm text-gray-600">{destinationHint}</p>
            </>
          ),
        },
        {
          title: "App Clip Status",
          field: "appleAppClipActive",
          cellStyle: { whiteSpace: "nowrap", width: "10%" },
          filtering: false,
          render: ({ appleAppClipActive }) =>
            appleAppClipActive ? activeBadge : inactiveBadge,
        },
        {
          title: "Hidden on Platform",
          field: "hidden",
          disableClick: true,
          cellStyle: { whiteSpace: "nowrap", width: "10%" },
          filtering: true,
          render: ({ activationId, destination, hidden }) => (
            <div>
              <div className="flex justify-center">
                {hidden ? hiddenBadge : visibleBadge}
              </div>
              <div className="flex justify-center">
                <Toggle
                  enabled={hidden}
                  setEnabled={() => {
                    handleVisibilityToggleChange({
                      activationId,
                      destination,
                      hidden: !hidden,
                    })
                  }}
                />
              </div>
            </div>
          ),
        },
        {
          title: "Type",
          field: "kind",
          filtering: false,
          sorting: false,
          align: "center",
          cellStyle: { whiteSpace: "nowrap", width: "10%" },
          render: ({ kind }) => kind.replace("_", " "),
        },
      ]}
      data={tableData}
      onRowClick={(_, rowData) => {
        history.push(rowData.activationEditUrl)
      }}
      title="QR Codes"
    />
  )
}

export default ActivationsList
