import React, { useState } from "react"
import useDisclosure from "hooks/use-disclosure"
import GetQueryLoader from "components/get-query-loader"
import List from "./list"
import SelectExperienceDrawer from "./select-experience-drawer"

import GET_BRANDS from "./queries"

const CoreAppIndex = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedBrand, setSelectedBrand] = useState()

  const handleBrandClick = (brand) => {
    setSelectedBrand(brand)
    onOpen()
  }

  return (
    <GetQueryLoader query={GET_BRANDS} useBrandKey={false}>
      {({ brands }, refetch) => (
        <>
          <List brands={brands} onBrandClick={handleBrandClick} />
          <SelectExperienceDrawer
            isOpen={isOpen}
            onClose={onClose}
            refetchBrands={refetch}
            selectedBrand={selectedBrand}
          />
        </>
      )}
    </GetQueryLoader>
  )
}

export default CoreAppIndex
