import React, { useEffect } from "react"

import { useField } from "formik"

export const allModes = ["buy", "gift"]

const ModeSelector = () => {
  const [availableModesField, _x, _y] = useField("availableModes") // this could probably just be Field, not useField

  const [defaultModeField, _z, { setValue: setDefaultModeValue }] =
    useField("defaultMode")

  useEffect(() => {
    if (!availableModesField.value || availableModesField.value.length === 0) {
      return
    }
    if (!availableModesField.value.includes(defaultModeField.value)) {
      setDefaultModeValue(availableModesField.value[0])
    }
  }, [availableModesField.value, defaultModeField.value, setDefaultModeValue])

  return (
    <div>
      {/* multiselect for available options */}
      <div className="mb-2">
        <label htmlFor="availableModes">
          <span className="block text-xs font-medium text-black capitalize font-bold">
            Available Modes
          </span>
          {allModes.sort().map((mode) => (
            <label
              key={mode}
              className="mr-4"
              htmlFor={`availableModes-${mode}`}
            >
              <input
                {...availableModesField}
                checked={availableModesField.value?.includes(mode)}
                className="mr-2 leading-tight"
                id={`availableModes-${mode}`}
                type="checkbox"
                value={mode}
              />
              <span>{mode}</span>
            </label>
          ))}
        </label>
      </div>
      <div className="mb-2">
        <span className="block text-xs font-medium text-black capitalize font-bold">
          Default Mode
        </span>
        <div className="form-check">
          {availableModesField.value?.sort().map((mode) => (
            <label
              key={mode}
              className="mr-4"
              htmlFor={`availableModes__${mode}`}
            >
              <input
                className="form-check-input mr-2 leading-tight"
                {...defaultModeField}
                checked={mode === defaultModeField.value}
                id={`availableModes__${mode}`}
                name="defaultMode"
                type="radio"
                value={mode}
              />
              {mode}
            </label>
          ))}
          {availableModesField.value?.length === 0 && (
            <p className="text-xs text-red-600 mt-2">
              At least one mode must be selected.
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default ModeSelector
