import Center from "components/layout/center"
import React from "react"
import { isDevEnv } from "utils/app-env"

const DevelopmentWarning = () => {
  if (isDevEnv()) {
    return (
      <Center className="bg-red-500 h-8 w-full text-xs z-50 text-white">
        This is a development site.
        <a className="underline mx-1" href="https://getbatch.com/">
          Go to Batch in production
        </a>{" "}
        →
      </Center>
    )
  }
  return null
}

export default DevelopmentWarning
