import React from "react"
import ToggleWithIcon from "components/toggle-with-icon"
import { Label } from "components/forms/formik-input"
import FormikInput from "./formik-input"

const AgeVerification = ({ ageToggle, setAgeToggle }) => {
  const toggle = () => setAgeToggle((prevState) => !prevState)

  return (
    <>
      <Label displayName="Enforce a minimum purchasing age?" />
      <ToggleWithIcon active={ageToggle} onToggle={toggle} />
      {ageToggle && (
        <div className="w-24">
          <FormikInput
            label="Minimum Age"
            name="ageVerification"
            placeholder="21"
            type="number"
          />
        </div>
      )}
    </>
  )
}

export default AgeVerification
