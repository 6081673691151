import React, { useState } from "react"
import Time from "react-time-format"
import { useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import useDisclosure from "hooks/use-disclosure"
import { useMutation } from "@apollo/client"
import { DownloadIcon } from "@heroicons/react/outline"

import ActivationDemo from "components/activation-demo"
import Badge from "components/badge"
import Button from "components/button"
import Input from "components/forms/input"
import LinkToNewTab from "components/link-to-new-tab"
import SubmitButton from "components/submit-button"

import EditBatchTagModal from "./edit-batch-tag-modal"
import MigrateQRCodeModal from "./migrate-qr-code-modal"
import { UPDATE_ACTIVATION } from "./queries"

const generateFilename = (activationName, experienceName) =>
  `${experienceName}--${activationName}`
    .replace(/[^a-z0-9]/gi, "_")
    .toLowerCase()

const ActivationRow = ({
  activation,
  cardExperience,
  refetchCardExperience,
}) => {
  const { brandKey } = useParams()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [activationName, setActivationName] = useState(activation.name)
  const { addToast } = useToasts()
  const [updateActivationMutation] = useMutation(UPDATE_ACTIVATION, {
    variables: {
      input: {
        activationId: activation.id,
        name: activationName,
        brandKey,
      },
    },
    onCompleted: () => {
      setIsSubmitting(false)
      addToast("Successfully updated activation", { appearance: "success" })
      refetchCardExperience()
    },
    onError: (error) => {
      setIsSubmitting(false)
      addToast(`Could not update activation: ${error.message}`, {
        appearance: "error",
      })
    },
  })
  const { url, createdAt, category, batchTagSvgUrl, qrCodeSvgUrl, kind } =
    activation

  const activationEditUrl = `/brands/${brandKey}/activations/${activation.id}`

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    updateActivationMutation()
  }

  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure()

  const {
    isOpen: isMigrateOpen,
    onOpen: onMigrateOpen,
    onClose: onMigrateClose,
  } = useDisclosure()

  const isBatchTag = kind === "batch_tag"

  return (
    <div className="flex items-center py-4">
      <div className="pr-4 w-24 m-3">
        <ActivationDemo
          activation={activation}
          hideDemoUrl
          hideHeader
          kind="test"
          size={24}
        />
      </div>

      <div className="flex flex-col justify-between items-start flex-grow">
        <div className="flex w-full justify-between">
          <div>
            <p className="uppercase text-gray-500 text-sm">
              {isBatchTag ? "Batch Tag" : "QR Code"}
              <LinkToNewTab className="ml-3" to={activationEditUrl}>
                {activation.id}
              </LinkToNewTab>
            </p>
            <p className="text-sm text-gray-800 mt-1">
              Created <Time format="MM/DD/YYYY" value={createdAt} /> &bull;{" "}
              {url}
            </p>
            <p className="text-gray-800 text-sm mt-1">
              <strong>Category</strong>: {category || <em>none</em>}
            </p>
          </div>
          <div className="">
            <p className="text-right uppercase text-gray-500 text-xs">
              App Clip
            </p>
            {activation.appleAppClipActive ? (
              <Badge color="green" message="Active" uppercase />
            ) : (
              <Badge color="red" message="Inactive" uppercase />
            )}
          </div>
        </div>
        <div className="flex flex-row items-end justify-between w-full mt-1">
          <Input
            label="Name (Internal)"
            name="name"
            onChange={(e) => setActivationName(e.target.value)}
            value={activationName}
          />

          <div className="grid gap-2 grid-flow-col">
            {isBatchTag && (
              <Button onClick={onEditOpen} order={null} slim>
                Edit
              </Button>
            )}
            {!isBatchTag && (
              <Button onClick={onMigrateOpen} order={null} slim>
                Migrate
              </Button>
            )}
            <a
              alt="QR Code"
              className="h-full mt-0.5"
              download={generateFilename(activation.name, cardExperience.name)}
              href={batchTagSvgUrl || qrCodeSvgUrl}
            >
              <button
                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="button"
              >
                <span className="sr-only">
                  Download {isBatchTag ? "Batch Tag" : "QR Code"}.
                </span>
                <DownloadIcon className="w-4 h-4" />
              </button>
            </a>
          </div>
        </div>
        <form className="flex mt-4" onSubmit={handleSubmit}>
          <SubmitButton
            className="rounded"
            cta="Save"
            slim
            submitting={isSubmitting}
          />
        </form>
      </div>
      <EditBatchTagModal
        activation={activation}
        cardExperience={cardExperience}
        onClose={onEditClose}
        onSuccess={(values) => {
          onEditClose()
          refetchCardExperience()
          const {
            updateBatchTagActivation: { activation: editedActivation },
          } = values
          setActivationName(editedActivation.name)
        }}
        show={isEditOpen}
      />
      <MigrateQRCodeModal
        activation={activation}
        cardExperience={cardExperience}
        onClose={onMigrateClose}
        onSuccess={() => {
          onMigrateClose()
          refetchCardExperience()
        }}
        show={isMigrateOpen}
      />
    </div>
  )
}

export default ActivationRow
