import React from "react"

const SidebarGroup = ({ header, children }) => (
  <>
    {header}
    <ol className="divide-y divide-solid divide-gray-200">{children}</ol>
  </>
)

export default SidebarGroup
