import React from "react"
import Time from "react-time-format"
import { useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import { RedButton } from "components/button"
import Badge from "components/badge"
import { RERUN_FAILED_SYSTEM_CHECK } from "../queries"

const SingleSystemCheck = ({ systemCheck }) => {
  const { addToast } = useToasts()

  const systemCheckType = () =>
    systemCheck.checkType.split("::").slice(-1).toString()

  const [executeMigration, _] = useMutation(RERUN_FAILED_SYSTEM_CHECK, {
    onError: () => {
      addToast("Something went wrong, the job has not been queued again!", {
        appearance: "error",
      })
    },
    onCompleted: () => {
      addToast("Retry queued successfully.", { appearance: "success" })
    },
  })

  const retrySystemCheck = async () => {
    await executeMigration({
      variables: {
        input: {
          systemCheckId: systemCheck.id,
        },
      },
    })
  }

  const badgeColor = () => {
    switch (systemCheck.status) {
      case "passed":
        return "green"
      case "retrying":
        return "blue"
      case "failed":
        return "red"
      case "queued":
        return "yellow"
      default:
        return "grey"
    }
  }

  const description = () => {
    switch (systemCheckType()) {
      case "ProductVariantShippingRatesJob":
        return "This check will run this product variant against 5 different addresses in the US to ensure that shipping rates are returning for every address."
      case "ProductVariantPriceCheckJob":
        return "This check will query this product variant on shopify and check if it matches with the price in the platform."
      default:
        return "No description"
    }
  }

  return (
    <li>
      <div className="block hover:bg-gray-50">
        <div className="px-4 py-4 sm:px-6">
          <div className="flex items-center justify-between">
            <p className="font-medium truncate text-md">{systemCheckType()}</p>
            <div className="flex flex-shrink-0 ml-2">
              {systemCheck.status === "failed" && (
                <RedButton onClick={() => retrySystemCheck()}>Retry</RedButton>
              )}
            </div>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <div className="sm:flex">
              <Badge
                className="ml-2"
                color={badgeColor(systemCheck)}
                message={systemCheck.status}
                uppercase
              />
              <p className="flex items-center ml-2 text-sm text-gray-500">
                {systemCheck.status === "passed" && (
                  <span>Last checked passed at </span>
                )}
                {systemCheck.status !== "passed" && (
                  <span>Last checked {systemCheck.status} at </span>
                )}
                <Time
                  className="ml-1"
                  format="MM/DD/YYYY HH:MM"
                  value={systemCheck.lastStatusAt}
                />
              </p>
            </div>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <p className="flex items-center text-sm text-gray-500">
              <div className="sm:flex">{description()}</div>
            </p>
          </div>
        </div>
      </div>
    </li>
  )
}

export default SingleSystemCheck
