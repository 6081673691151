import React from "react"
import { useParams } from "react-router-dom"
import GetQueryLoader from "components/get-query-loader"
import ActivationInformation from "./activation-information"
import { GET_ACTIVATION } from "./queries"

const ActivationShow = () => {
  const { activationId } = useParams()

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <GetQueryLoader
            query={GET_ACTIVATION}
            queryVariables={{
              id: activationId,
            }}
          >
            {({ activation }, refetch) => (
              <ActivationInformation
                activation={activation}
                refetchActivation={refetch}
              />
            )}
          </GetQueryLoader>
        </div>
      </div>
    </div>
  )
}

export default ActivationShow
