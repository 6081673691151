import React from "react"
import classnames from "classnames"
import Button from "./button"
import { getSlimStyles } from "./utils"

const DefaultButton = ({
  slim = false,
  className,
  onClick,
  order = 1,
  type = "button",
  children,
  submitting = false,
  disabled = false,
  rounding = "all",
}) => (
  <Button
    buttonClassName={classnames(
      "bg-white border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 hover:bg-gray-100 text-gray-700",
      getSlimStyles(slim)
    )}
    className={className}
    disabled={disabled}
    onClick={onClick}
    order={order}
    rounding={rounding}
    submitting={submitting}
    type={type}
  >
    {children}
  </Button>
)

export default DefaultButton
