import gql from "graphql-tag"

const CREATE_OR_UPDATE_ACTIVATION_APPLE_APP_CLIP = gql`
  mutation CreateOrUpdateActivationAppleAppClip(
    $input: CreateOrUpdateActivationAppleAppClipInput!
  ) {
    createOrUpdateActivationAppleAppClip(input: $input) {
      errors
      activation {
        id
      }
    }
  }
`

const RESET_ACTIVATION_APPLE_APP_CLIP = gql`
  mutation ResetActivationAppleAppClip(
    $input: ResetActivationAppleAppClipInput!
  ) {
    resetActivationAppleAppClip(input: $input) {
      errors
      activation {
        id
      }
    }
  }
`

const SYNC_ACTIVATION_APPLE_APP_CLIP = gql`
  mutation SyncActivationAppleAppClip(
    $input: SyncActivationAppleAppClipInput!
  ) {
    syncActivationAppleAppClip(input: $input) {
      errors
      activation {
        id
      }
    }
  }
`

const UPDATE_ACTIVATION = gql`
  mutation UpdateQRCodeActivation($input: UpdateQRCodeActivationInput!) {
    updateQrCodeActivation(input: $input) {
      errors
      activation {
        id
      }
    }
  }
`

const UPDATE_ACTIVATION_APPLE_APP_CLIP_STATUS = gql`
  mutation UpdateActivationAppleAppClipStatus(
    $input: UpdateActivationAppleAppClipStatusInput!
  ) {
    updateActivationAppleAppClipStatus(input: $input) {
      errors
      activation {
        id
      }
    }
  }
`

export {
  CREATE_OR_UPDATE_ACTIVATION_APPLE_APP_CLIP,
  RESET_ACTIVATION_APPLE_APP_CLIP,
  SYNC_ACTIVATION_APPLE_APP_CLIP,
  UPDATE_ACTIVATION,
  UPDATE_ACTIVATION_APPLE_APP_CLIP_STATUS,
}
