import React from "react"
import { Form } from "formik"
import * as Yup from "yup"
import Button from "components/button"
import BatchForm from "components/batch-form"
import SubmitButton from "components/submit-button"
import FormikInput from "components/forms/formik-input"
import SmallFormModal, {
  ModalFooter,
  ModalBody,
} from "components/small-form-modal"

import { useToasts } from "react-toast-notifications"
import ComboboxSelector from "components/forms/combobox-selector"
import { CREATE_AFFILIATE_MUTATION } from "./queries"

const AffiliateModal = ({
  activations,
  visible,
  onClose,
  refetchAffiliates,
  affiliateInfo,
}) => {
  const { addToast } = useToasts()

  const formSchema = Yup.object().shape({
    email: Yup.string().required("Required").email(),
    activationId: Yup.string().required("Required"),
    firstName: Yup.string().nullable(),
    lastName: Yup.string().nullable(),
    phoneNumber: Yup.string().nullable(),
  })

  const onCompleted = (data) => {
    const {
      createOrUpdateAffiliate: { errors },
    } = data

    if (errors[0]) {
      addToast(errors[0], { appearance: "error" })
    } else {
      addToast("Saved Successfully", { appearance: "success" })
      refetchAffiliates()
      onClose()
    }
  }

  const activationOptions = activations.map((activation) => ({
    id: activation.id,
    value: activation.name,
  }))

  return (
    <SmallFormModal onClose={onClose} overflowVisible visible={visible}>
      {({ close }) => (
        <BatchForm
          initialValues={{
            affiliateId: affiliateInfo.id || null,
            email: affiliateInfo.email || "",
            phoneNumber: affiliateInfo.phoneNumber || "",
            firstName: affiliateInfo.firstName || "",
            lastName: affiliateInfo.lastName || "",
            activationId: affiliateInfo.activationId,
          }}
          mutation={CREATE_AFFILIATE_MUTATION}
          onCompleted={onCompleted}
          validationSchema={formSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody
                title={`${affiliateInfo.id ? "Edit" : "Add a new"} Affiliate`}
              >
                <FormikInput
                  label="First Name"
                  name="firstName"
                  placeholder="John"
                  type="text"
                  wrapperProps={{ className: "mb-2" }}
                />
                <FormikInput
                  label="Last Name"
                  name="lastName"
                  placeholder="Doe"
                  type="text"
                  wrapperProps={{ className: "mb-2" }}
                />

                <FormikInput
                  label="Email"
                  name="email"
                  placeholder="Email id"
                  type="text"
                  wrapperProps={{ className: "mb-2" }}
                />

                <div className="mb-2">
                  <ComboboxSelector
                    disableAutocomplete
                    label="Select Activation"
                    name="activationId"
                    options={activationOptions}
                  />
                </div>

                <FormikInput
                  label="Phone Number"
                  name="phoneNumber"
                  placeholder="XXX-XXX-XXXX"
                  type="text"
                  wrapperProps={{ className: "mb-2" }}
                />
              </ModalBody>
              <ModalFooter>
                <SubmitButton
                  cta={affiliateInfo.id ? "Update" : "Create"}
                  submitting={isSubmitting}
                />
                <Button className="mr-2" onClick={close}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </BatchForm>
      )}
    </SmallFormModal>
  )
}

export default AffiliateModal
