import gql from "graphql-tag"

const GET_BRAND = gql`
  query GetBrand {
    brand {
      name
      publicRefreshToken
      logoImageSrc
      calendlyScheduled
      shopifyMerchantInstallLink
      stripeInstallLink
      dropboxFileRequestLink
      shopifyConnected
      stripeConnected
      metabaseUrl
      sharePageSecret
    }
  }
`

export { GET_BRAND }

export default GET_BRAND
