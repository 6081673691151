import React from "react"
import iPhone11ProMaxFrameDark from "assets/images/iphone-11-pro-max/frame-darkmode.svg"

export const MEASUREMENTS = {
  root: {
    height: 896,
    width: 414,
    borderRadius: 40,
  },
  safeZone: {
    height: 896 - 44 - 34, // minus status bar minus bottom pad,
    width: 414,
    topOffset: 44,
  },
  header: {
    paddingX: 24,
    paddingTop: 8,
    paddingBottom: 22,
    height: 84,
  },
  logo: {
    height: 54,
    width: 180,
  },
  gridHeader: {
    paddingX: 24,
    paddingTop: 8,
    paddingBottom: 20,
    height: 124,
  },
  card: {
    borderRadius: 24,
    paddingTop: 38,
    paddingBottom: 16,
  },
  gridCards: {
    gap: 16,
    height: 249,
    width: 199,
    padding: 16,
    paddingBottom: 8,
    borderRadius: 24,
  },
  cardSelector: {
    borderRadius: 12,
    gap: 16,
    paddingX: 14,
    paddingY: 22,
  },
  footer: {
    gap: 16,
    height: 91,
    barHeight: 48,
    buttonBorderRadius: 26,
  },
  gridFooter: {
    paddingY: 24,
    paddingX: 24,
    marginY: 16,
    marginX: 16,
    borderRadius: 24,
  },
}

// https://www.figma.com/file/OtzwpY5T7v8ZmmymtHUgc7/iPhone-12-Mockups-(Community)?node-id=4%3A884
const Iphone11ProMax = ({ children, backgroundColor }) => (
  <div
    className="shadow-lg ring-4 ring-black"
    style={{
      position: "relative",
      backgroundColor,
      height: MEASUREMENTS.root.height, // hide bottom overhang
      width: MEASUREMENTS.root.width,
      overflowY: "hidden",
      borderRadius: MEASUREMENTS.root.borderRadius,
    }}
  >
    <img
      alt=""
      src={iPhone11ProMaxFrameDark}
      style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
    />
    <div
      style={{
        position: "absolute",
        height: MEASUREMENTS.safeZone.height,
        width: MEASUREMENTS.safeZone.width,
        top: MEASUREMENTS.safeZone.topOffset, // status bar offset
        left: 0,
        right: 0,
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  </div>
)

export default Iphone11ProMax
