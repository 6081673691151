import React from "react"
import { useParams } from "react-router-dom"

import { ApolloProvider } from "@apollo/client"
import client from "config/apollo-public"
import GetQueryLoader from "components/get-query-loader"

import OnboardingIndex from "./onboarding-index"
import { GET_BRAND } from "./queries"

const Onboarding = () => {
  const { key: brandPublicRefreshToken } = useParams()
  return (
    <ApolloProvider client={client}>
      <GetQueryLoader
        fetchPolicy="network-only"
        query={GET_BRAND}
        queryVariables={{ brandPublicRefreshToken }}
        useBrandKey={false}
      >
        {({ brand }, refetchBrand) => (
          <OnboardingIndex brand={brand} refetchBrand={refetchBrand} />
        )}
      </GetQueryLoader>
    </ApolloProvider>
  )
}

export default Onboarding
