import React from "react"
import { useParams } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import { useMutation } from "@apollo/client"
import { TrashIcon } from "@heroicons/react/outline"

import { RedButtonWithConfirmation } from "components/button"

import { DELETE_BACKGROUND_FROM_CARD_VARIATION_MUTATION } from "./queries"

const DeleteBackgroundButton = ({
  cardVariationBackgroundId,
  cardVariationId,
  disabled = false,
  onDelete,
  productVariantRefetch,
}) => {
  const { addToast } = useToasts()
  const { brandKey } = useParams()

  const [deleteBackgroundFromCardVariation, { loading }] = useMutation(
    DELETE_BACKGROUND_FROM_CARD_VARIATION_MUTATION,
    {
      variables: {
        input: {
          cardVariationBackgroundId,
          cardVariationId,
          brandKey,
        },
      },
      onError: (e) => {
        addToast(`Error occurred while removing: ${e.message}`, {
          appearance: "error",
        })
        productVariantRefetch()
      },
      onCompleted: () => {
        addToast("Background successfully removed", {
          appearance: "success",
        })
        onDelete()
        productVariantRefetch()
      },
    }
  )

  return (
    <RedButtonWithConfirmation
      confirmationHeader="Are you sure you want to remove this background?"
      disabled={disabled}
      icon={
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
          <TrashIcon className="h-6 w-6 text-gray-600" />
        </div>
      }
      onClick={deleteBackgroundFromCardVariation}
      submitting={loading}
    >
      Remove Background
    </RedButtonWithConfirmation>
  )
}

export default DeleteBackgroundButton
