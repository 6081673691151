import React from "react"
import Time from "react-time-format"
import TableButton from "components/table-button"

const ActivationRow = ({ nfcTagSeries }) => {
  const { quantity, seriesRangeString, generatedAt, presignedUrl } =
    nfcTagSeries

  return (
    <tr className="hover:bg-gray-100 cursor-pointer">
      <td className="pl-6 py-4 whitespace-nowrap">
        <Time format="MM/DD/YYYY hh:mm" value={generatedAt} />
      </td>
      <td className="px-6 py-4 whitespace-nowrap">{quantity}</td>
      <td className="px-6 py-4 whitespace-nowrap">{seriesRangeString}</td>
      <td className="px-6 py-4 whitespace-nowrap">
        {presignedUrl ? (
          <a href={presignedUrl} rel="noreferrer" target="_blank">
            <TableButton>Download</TableButton>
          </a>
        ) : (
          <p className="text-gray-500 text-sm">Generating...</p>
        )}
      </td>
    </tr>
  )
}

export default ActivationRow
