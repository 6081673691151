import DefaultButton from "./default-button"
import PrimaryButton from "./primary-button"
import SecondaryButton from "./secondary-button"
import WhiteButton from "./white-button"
import RedButton from "./red-button"
import RedButtonWithConfirmation from "./red-button-with-confirmation"

export default DefaultButton

export {
  DefaultButton as Button,
  PrimaryButton,
  SecondaryButton,
  WhiteButton,
  RedButton,
  RedButtonWithConfirmation,
}
