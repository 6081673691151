import gql from "graphql-tag"

export const GET_BRAND_PRODUCT_VARIANTS = gql`
  query GetBrandProducts($brandKey: String!) {
    brand(brandKey: $brandKey) {
      productVariants {
        id
        productTitle
        subtitle
        product {
          id
          shopifyProductIdNumber
        }
        deletedFromShopify
        platformId
        price
        sku
        cardExperiences {
          id
          internalNickname
        }
      }
    }
  }
`

export default GET_BRAND_PRODUCT_VARIANTS
