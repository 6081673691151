import React from "react"
import { sortBy } from "lodash"
import IndexTable from "components/index-table"

import BrandColumn from "./brand-column"
import Badge from "./badge"
import ExperienceDescription from "../ExperienceDescription"

const BrandList = ({ onBrandClick, brands }) => {
  const prepareData = (unsortedBrands) =>
    sortBy(unsortedBrands, ["name"]).map((brand) => ({
      ...brand,
    }))

  const customSearch = (term, rowData) => {
    if (rowData.coreAppCardExperience) {
      return term.toLowerCase() === "live"
    }
    return term.toLowerCase() === "hidden"
  }

  return (
    <IndexTable
      columns={[
        {
          title: "Name",
          field: "name",
          render: (row) => <BrandColumn brand={row} />,
        },
        {
          title: "Live on Core App",
          field: "coreAppCardExperience",
          render: (row) => <Badge brand={row} />,
          customFilterAndSearch: customSearch,
        },
        {
          title: "Experience",
          disableClick: true,
          filtering: false,
          render: (brand) => (
            <ExperienceDescription
              brand={brand}
              cardExperience={brand.coreAppCardExperience}
            />
          ),
        },
      ]}
      data={prepareData(brands)}
      onRowClick={(_, rowData) => {
        onBrandClick(rowData)
      }}
      title="Core App Brands"
    />
  )
}

export default BrandList
