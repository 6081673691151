import React, { useState } from "react"

import DefaultButton from "./default-button"
import RedButton from "./red-button"
import SmallFormModal, { ModalFooter, ModalBody } from "../small-form-modal"

const RedButtonWithConfirmation = ({
  children,
  className,
  onClick,
  confirmationHeader = "Are you sure?",
  confirmationBody = null,
  icon = null,
  submitting = false,
  disabled = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <SmallFormModal
        onClose={() => setIsModalOpen(false)}
        visible={isModalOpen}
      >
        {({ close }) => (
          <>
            <ModalBody icon={icon} title={confirmationHeader}>
              {confirmationBody}
            </ModalBody>
            <ModalFooter>
              <RedButton
                onClick={() => {
                  onClick()
                  close()
                }}
                submitting={submitting}
              >
                Confirm
              </RedButton>
              <DefaultButton className="mr-2" onClick={close}>
                Cancel
              </DefaultButton>
            </ModalFooter>
          </>
        )}
      </SmallFormModal>
      <RedButton
        className={className}
        disabled={disabled}
        onClick={() => setIsModalOpen(true)}
      >
        {children}
      </RedButton>
    </>
  )
}

export default RedButtonWithConfirmation
