import React from "react"
import { NavLink } from "react-router-dom"

const TabNavLink = ({ to, children, first = false, exact = false }) => (
  <NavLink
    activeClassName="border-purple-500 text-purple-600 hover:text-purple-700"
    className={`border-transparent text-gray-500 hover:text-gray-700 hover:text-gray-700 cursor-pointer whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
      !first && "ml-8"
    }`}
    exact={exact}
    to={to}
  >
    {children}
  </NavLink>
)

export default TabNavLink
