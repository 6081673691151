import React from "react"
import { useMutation } from "@apollo/client"
import { TrashIcon } from "@heroicons/react/outline"
import { useToasts } from "react-toast-notifications"
import { RedButtonWithConfirmation } from "components/button"
import { useParams } from "react-router-dom"

import { DELETE_AFFILIATE } from "./queries"

const DeleteAffiliateButton = ({ affiliateId, refetchBrandUsers }) => {
  const { addToast } = useToasts()
  const { brandKey } = useParams()

  const [deleteAffiliate, { loading }] = useMutation(DELETE_AFFILIATE, {
    variables: {
      input: {
        affiliateId,
        brandKey,
      },
    },
    onError: (e) => {
      addToast(`Error occurred while removing: ${e.message}`, {
        appearance: "error",
      })
      refetchBrandUsers()
    },
    onCompleted: () => {
      addToast("Affiliate removed successfully.", {
        appearance: "success",
      })
      refetchBrandUsers()
    },
  })

  return (
    <RedButtonWithConfirmation
      confirmationHeader="Are you sure you want to remove this affiliate?"
      icon={
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
          <TrashIcon className="h-6 w-6 text-gray-600" />
        </div>
      }
      onClick={deleteAffiliate}
      submitting={loading}
    >
      Delete
    </RedButtonWithConfirmation>
  )
}

export default DeleteAffiliateButton
