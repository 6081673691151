import gql from "graphql-tag"

export const DELETE_CARD_FROM_CARD_EXPERIENCE = gql`
  mutation DeleteCardFromCardExperience(
    $input: DeleteCardFromCardExperienceInput!
  ) {
    deleteCardFromCardExperience(input: $input) {
      errors
      card {
        id
      }
    }
  }
`

export default DELETE_CARD_FROM_CARD_EXPERIENCE
