import React, { useState, useRef } from "react"
import {
  Editor,
  EditorState,
  RichUtils,
  ContentState,
  convertFromHTML,
  Modifier,
} from "draft-js"
import { convertToHTML } from "draft-convert"
import EditorButton from "./editor-button"
import Label from "../label"
import HintText from "../input/hint-text"
import { formatHtmlContent } from "./utils"
import "draft-js/dist/Draft.css"
import "./styles.css"

const MAX_LENGTH = 400

const RichTextInput = ({
  initialValue,
  label,
  hint,
  setExtendedDescriptionHtml,
}) => {
  const EditorRef = useRef(null)

  const getInitialEditorState = () => {
    if (initialValue) {
      const blocksFromHTML = convertFromHTML(initialValue)
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      return EditorState.createWithContent(state)
    }
    return EditorState.createEmpty()
  }

  const [editorState, setEditorState] = useState(getInitialEditorState())

  const handleKeyCommand = (command, passedEditorState) => {
    const newState = RichUtils.handleKeyCommand(passedEditorState, command)

    if (newState) {
      setEditorState(newState)

      /* Will refocus on the editor so you can keep typing
      after selecting bold or itallic */
      EditorRef?.current?.focus()

      return "handled"
    }

    return "not-handled"
  }

  const onChange = (newEditorState) => {
    const currentContent = newEditorState.getCurrentContent()

    // convertToHTML by default wraps each line of the content in a <p> tag,
    // while we want to return the content without any wrapper tags.

    // We add the <delete-me> tags to the content while converting to HTML...
    const convertedContent = convertToHTML({
      // eslint-disable-next-line consistent-return
      blockToHTML: (block) => {
        if (block.type === "unstyled") {
          return <delete-me />
        }
      },
    })(currentContent)

    // ...and then we remove the <delete-me> tags with formatHtmlContent:
    setExtendedDescriptionHtml(formatHtmlContent(convertedContent))

    // Limits the length of the content
    if (currentContent.getPlainText().length <= MAX_LENGTH) {
      setEditorState(newEditorState)
    }
  }

  const limitLength = () => {
    const currentContent = editorState.getCurrentContent()

    const currentContentLength = currentContent.getPlainText("").length

    if (currentContentLength > MAX_LENGTH - 1) {
      return "handled"
    }
    return "not-handled"
  }

  const truncateToLength = (text) => {
    const newState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text.substring(0, MAX_LENGTH)
    )
    onChange(EditorState.push(editorState, newState, "insert-fragment"))

    return true
  }

  const isStyleActive = (style) =>
    editorState.getCurrentInlineStyle().has(style)

  const handleButtonClick = (e, style) => {
    e.preventDefault()
    onChange(RichUtils.toggleInlineStyle(editorState, style))
  }

  return (
    <div className="my-2">
      <Label displayName={label} />
      <div className="rounded-md shadow-sm border p-2">
        <div className="pb-2">
          <EditorButton
            active={isStyleActive("BOLD")}
            label="Bold"
            onClick={(e) => {
              handleButtonClick(e, "BOLD")
            }}
          />
          <EditorButton
            active={isStyleActive("ITALIC")}
            label="Italic"
            onClick={(e) => {
              handleButtonClick(e, "ITALIC")
            }}
          />
        </div>
        <Editor
          ref={EditorRef}
          editorState={editorState}
          handleBeforeInput={limitLength}
          handleKeyCommand={handleKeyCommand}
          handlePastedText={truncateToLength}
          onChange={onChange}
          placeholder="Write the description here..."
        />
        <div className="flex flex-col">
          <span className="text-gray-400 text-xs self-end">
            {editorState.getCurrentContent().getPlainText("").length}/
            {MAX_LENGTH}
          </span>
        </div>
      </div>
      <HintText hint={hint} />
    </div>
  )
}

export default RichTextInput
