import React, { useState } from "react"
import QRCode from "react-qr-code"
import useDisclosure from "hooks/use-disclosure"

import GetQueryLoader from "components/get-query-loader"
import IndexTable from "components/index-table"

import { PrimaryButton } from "components/button"
import { GET_BRAND } from "./queries"
import AffiliateModal from "./affiliate-modal"
import DeleteAffiliateButton from "./delete-affiliate-button"

const AffiliateIndex = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [affiliateToEdit, setAffiliateToEdit] = useState({})

  const handleOpen = (affiliateInfo) => {
    setAffiliateToEdit(affiliateInfo)
    onOpen()
  }
  const handleClose = () => {
    setAffiliateToEdit({})
    onClose()
  }

  return (
    <>
      <GetQueryLoader fetchPolicy="network-only" query={GET_BRAND}>
        {({ brand }, refetchAffiliates) => (
          <>
            <IndexTable
              columns={[
                {
                  title: "Test QR Code",
                  field: "internalQrCode",
                  filtering: false,
                  sorting: false,
                  align: "center",
                  render: ({ id, testUrl }) => (
                    <div className="items-center">
                      <div className="relative bg-white h-24 w-24 flex items-center mx-auto justify-center">
                        <QRCode
                          fgColor="blue"
                          id={id}
                          size={90}
                          value={testUrl}
                        />
                      </div>
                    </div>
                  ),
                },
                {
                  title: "Name",
                  field: "fullName",
                  customFilterAndSearch: (term, row) =>
                    row.fullName.toLowerCase().includes(term.toLowerCase()),
                  customSort: (a, b) => a.fullName.localeCompare(b.fullName),
                },
                {
                  title: "Email",
                  field: "email",
                  customFilterAndSearch: (term, row) =>
                    row.phoneNumber.toLowerCase().includes(term.toLowerCase()),
                  customSort: (a, b) =>
                    a.phoneNumber.localeCompare(b.phoneNumber),
                },
                {
                  title: "Phone Number",
                  field: "phoneNumber",
                  customFilterAndSearch: (term, row) =>
                    row.email.toLowerCase().includes(term.toLowerCase()),
                  customSort: (a, b) => a.email.localeCompare(b.email),
                },
                {
                  title: "URL",
                  field: "activationUrl",
                  cellStyle: { whiteSpace: "nowrap" },
                  customFilterAndSearch: (term, row) =>
                    row.activationUrl
                      .toLowerCase()
                      .includes(term.toLowerCase()),
                  customSort: (a, b) =>
                    a.activationUrl.localeCompare(b.activationUrl),
                },
                {
                  title: "Actions",
                  cellStyle: { whiteSpace: "nowrap", width: "10%" },
                  disableClick: true,
                  render: (affiliate) => (
                    <>
                      <PrimaryButton
                        className="h-6 w-6 mr-2 text-gray-600"
                        onClick={() => handleOpen(affiliate)}
                      >
                        Edit
                      </PrimaryButton>

                      <DeleteAffiliateButton
                        affiliateId={affiliate.id}
                        refetchBrandUsers={refetchAffiliates}
                      />
                    </>
                  ),
                },
              ]}
              ctaText="Create a New Affiliate"
              data={brand.affiliates}
              onCtaClick={onOpen}
              title="Affiliates"
            />

            <AffiliateModal
              activations={brand.activations}
              affiliateInfo={affiliateToEdit}
              onClose={handleClose}
              refetchAffiliates={refetchAffiliates}
              visible={isOpen}
            />
          </>
        )}
      </GetQueryLoader>
    </>
  )
}

export default AffiliateIndex
