import React, { useState } from "react"

import SmallFormModal from "components/small-form-modal"

import OnboardingIntegrationCard from "./onboarding-integration-card"

const OnboardingIntegrationCardWithModal = ({
  children,
  image,
  imageWidth = 100,
  alt,
  title,
  description,
  buttonText = "Connect",
  connectedText = "Connected",
  disabled,
  isConnected,
  onComplete = () => ({}),
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => setIsModalOpen(true)
  const onModalClose = () => {
    setIsModalOpen(false)
    onComplete()
  }

  return (
    <>
      <SmallFormModal onClose={onModalClose} visible={isModalOpen}>
        {() => children}
      </SmallFormModal>
      <OnboardingIntegrationCard
        alt={alt}
        buttonText={buttonText}
        connectedText={connectedText}
        description={description}
        disabled={disabled}
        image={image}
        imageWidth={imageWidth}
        isConnected={isConnected}
        onConnectClick={showModal}
        title={title}
      />
    </>
  )
}

export default OnboardingIntegrationCardWithModal
