/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React from "react"
import GetQueryLoader from "components/get-query-loader"
import IndexTable from "components/index-table"
import { useHistory } from "react-router-dom"
import formatCurrency from "utils/money"
import CardExperiences, {
  filterCardExperiences,
} from "components/card-experiences"
import { GET_BRAND_PRODUCT_VARIANTS } from "./queries"

const ProductIndex = () => {
  const history = useHistory()
  const filterProductVariants = (pv) => !pv.deletedFromShopify

  /** Handles data and reorders to have deleted from shopify in the beggining of the list */
  const createTableData = (productVariants) =>
    productVariants.filter(filterProductVariants).map((row) => ({
      title: row.productTitle,
      subtitle: row.subtitle,
      sku: row.sku,
      id: row.id,
      price: row.price,
      shopifyId: row.platformId.replace("gid://shopify/ProductVariant/", ""),
      shopifyProductId: row.product.shopifyProductIdNumber,
      cardExperiences: row.cardExperiences,
    }))

  const productVariantData = ({ title, subtitle }) => (
    <div className="flex flex-row items-start justify-start whitespace-nowrap">
      <div className="items-center py-2 font-medium text-gray-900">
        <p>{title}</p>
        <p className="text-sm font-normal text-gray-600">{subtitle}</p>
      </div>
    </div>
  )

  return (
    <GetQueryLoader
      fetchPolicy="network-only"
      query={GET_BRAND_PRODUCT_VARIANTS}
    >
      {({ brand: { productVariants } }) => (
        <IndexTable
          columns={[
            { title: "SKU", field: "sku" },
            {
              title: "Shopify Product Variant ID",
              field: "shopifyId",
              defaultFilter: "",
              customFilterAndSearch: (term, row) =>
                row.shopifyId.includes(term),
            },
            {
              title: "Shopify Product ID",
              field: "shopifyProductId",
              defaultFilter: "",
              customFilterAndSearch: (term, row) =>
                row.shopifyProductId.includes(term),
            },
            {
              title: "Used in Card Experiences",
              field: "cardExperiences",
              render: ({ cardExperiences }) => (
                <CardExperiences cardExperiences={cardExperiences} />
              ),
              customFilterAndSearch: (term, row) =>
                filterCardExperiences(term, row.cardExperiences),
              cellStyle: {
                maxWidth: "250px",
                overflow: "hidden",
                whiteSpace: "nowrap",
              },
            },
            {
              title: "Product Variant",
              customFilterAndSearch: (term, row) =>
                row.title.toLowerCase().includes(term.toLowerCase()) ||
                row.subtitle.toLowerCase().includes(term.toLowerCase()),
              render: productVariantData,
            },
            {
              field: "price",
              title: "Price",
              filtering: false,
              customSort: (a, b) => a.price - b.price,
              render: ({ price }) => <span>{formatCurrency(price)}</span>,
            },
          ]}
          data={createTableData(productVariants)}
          onRowClick={(_, row) => {
            history.push(`product-variants/${row.id}`)
          }}
          title="Product Variant Catalog"
        />
      )}
    </GetQueryLoader>
  )
}

export default ProductIndex
