import React, { useState } from "react"
import { useEditor } from "contexts/experience-editor"
import { useParams } from "react-router-dom"
import { Form } from "formik"
import * as Yup from "yup"
import BatchForm from "components/batch-form"
import SubmitButton from "components/submit-button"
import FormikInput from "components/forms/formik-input"
import ImagePicker from "components/image-picker"
import { UPDATE_APP_CLIP_CARD_MUTATION } from "./queries"

const EditForm = () => {
  const { currentExperience, refetchExperience } = useEditor()
  const { experienceId } = useParams()

  const { appClipCardTitle, appClipCardSubtitle, appClipCardImageSrc } =
    currentExperience

  const [imageUrl, setImageUrl] = useState(appClipCardImageSrc)

  const onImageUploadComplete = (url) => {
    setImageUrl(url)
  }

  const formSchema = Yup.object().shape({
    appClipCardTitle: Yup.string().required("Required"),
    appClipCardSubtitle: Yup.string().required("Required"),
  })

  const additionalValues = {
    experienceId,
    appClipCardImageSrc: imageUrl,
  }

  return (
    <BatchForm
      additionalValues={additionalValues}
      initialValues={{
        appClipCardTitle,
        appClipCardSubtitle,
      }}
      mutation={UPDATE_APP_CLIP_CARD_MUTATION}
      onSuccess={() => refetchExperience()}
      validationSchema={formSchema}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="grid grid-cols-8">
            <div className="col-span-8 sm:col-span-6 mb-4">
              <FormikInput
                autoFocus
                label="Card Title"
                limit={30}
                name="appClipCardTitle"
                placeholder="Get more [Your Product Name]"
                type="text"
              />
            </div>
            <div className="col-span-8 sm:col-span-6 mb-4">
              <FormikInput
                autoFocus
                label="Card Subtitle"
                limit={56}
                name="appClipCardSubtitle"
                placeholder='Tap "Open" to browse and reorder'
                type="text"
              />
            </div>
            <div className="col-span-8 sm:col-span-6 mb-4">
              <ImagePicker
                imageUrl={imageUrl}
                inputName="appClipCardImageSrc"
                label="Card Image"
                onImageUploadComplete={onImageUploadComplete}
                uploadFolder={`experiences/${experienceId}/background_image`}
              />
            </div>
          </div>

          <SubmitButton submitting={isSubmitting} />
        </Form>
      )}
    </BatchForm>
  )
}

export default EditForm
