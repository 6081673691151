import React from "react"
import { useEditor } from "contexts/experience-editor"

import SECTION_TYPES from "../section-types"

const Config = () => {
  const { currentExperience, currentSection, refetchExperience } = useEditor()

  if (!currentExperience) return null
  if (!currentSection) return null

  const {
    experienceSectionDetailsType,
    experienceSectionDetails,
    experienceSectionDetailsId,
  } = currentSection

  return (
    <aside
      className="col-span-4 overflow-y-scroll pt-28 pr-16 bg-gray-100"
      style={{ height: "calc(100vh - 7rem)" }}
    >
      {
        // currentExperience.experienceSections.map(({ experienceSectionDetailsId, experienceSectionDetailsType, experienceSectionDetails }) => (
        React.createElement(
          SECTION_TYPES[experienceSectionDetailsType].editor,
          {
            key: experienceSectionDetailsId,
            experienceSectionDetails,
            onComplete: refetchExperience,
          }
        )
        // ))
      }
      {/* <AppClipCard /> */}
    </aside>
  )
}

export default Config
