import React, { useState } from "react"
import { Link } from "react-router-dom"
import { PlusCircleIcon } from "@heroicons/react/outline"

import Button from "components/button"
import SubmitButton from "components/submit-button"
import { ModalFooter, ModalBody } from "components/small-form-modal"
import BatchForm from "components/batch-form"
import { useEditor } from "contexts/experience-v2-editor"
import ProductSelector from "components/product-selector"
import { Form } from "formik"
import { ADD_PRODUCT_VARIANTS_TO_CARD_MUTATION } from "./queries"

const AddCardVariationForm = ({ close, tableData }) => {
  const [selectedTableRows, setSelectedTableRows] = useState([])
  const { currentCard, refetchCardExperience } = useEditor()

  const onSuccess = () => {
    refetchCardExperience()
    close()
  }

  return (
    <BatchForm
      generateValues={() => {
        const selectedIds = tableData
          .filter((row) => row.selected === true)
          .map((row) => row.id)
        return {
          cardId: currentCard.id,
          productVariantIds: selectedIds,
        }
      }}
      mutation={ADD_PRODUCT_VARIANTS_TO_CARD_MUTATION}
      onSuccess={onSuccess}
    >
      {({ isSubmitting }) => (
        <Form>
          <ModalBody
            description="Select a pre-configured product from the catalog"
            icon={
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <PlusCircleIcon className="h-6 w-6 text-blue-600" />
              </div>
            }
            title="Add a Product Variant"
          >
            <div className="pr-3">
              {tableData.length > 0 ? (
                <>
                  {selectedTableRows.length > 0 && (
                    <ProductSelector
                      paginate={selectedTableRows.length > 10}
                      setSelectedTableRows={setSelectedTableRows}
                      showNumberSelected
                      tableData={selectedTableRows}
                    />
                  )}
                  <div className="my-8" />
                  <ProductSelector
                    paginate
                    setSelectedTableRows={setSelectedTableRows}
                    showHeader
                    tableData={tableData}
                  />
                </>
              ) : (
                <div>
                  No product variants are available. Would you like to{" "}
                  <Link
                    className="text-purple-600 underline"
                    target="_blank"
                    to="../product-variants"
                  >
                    configure more
                  </Link>
                  ?
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <SubmitButton cta="Submit" submitting={isSubmitting} />
            <Button className="mr-2" onClick={close}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      )}
    </BatchForm>
  )
}

export default AddCardVariationForm
