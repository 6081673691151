import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/solid"
import React from "react"

const ActivationAppStoreConnectStatus = ({ status }) =>
  status === "completed" ? (
    <CheckCircleIcon
      aria-hidden="true"
      className="h-5 w-5 inline text-green-400"
    />
  ) : (
    <ExclamationIcon
      aria-hidden="true"
      className="h-5 w-5 inline text-pink-500"
    />
  )

export default ActivationAppStoreConnectStatus
