import React from "react"
import { CheckIcon } from "@heroicons/react/outline"

const Square = ({ gradient, textColor, isSelected, ...rest }) => (
  <button
    className="w-24 h-24 rounded-lg flex items-center justify-center font-bold mb-3 relative"
    style={{
      background: `linear-gradient(45deg, #${gradient[0]}, #${gradient[1]})`,
      color: `#${textColor}`,
    }}
    type="button"
    {...rest}
  >
    <div
      className={`w-5 h-5 rounded-full  absolute top-1 right-1 ${
        isSelected ? "bg-blue-500" : "bg-white"
      }`}
    >
      {isSelected && <CheckIcon className="text-white" />}
    </div>
    COLOR
  </button>
)

export default Square
