import React from "react"
import { NavLink } from "react-router-dom"

const NavLinkOrLink = ({
  children,
  activeClassName,
  className,
  href,
  to,
  openInNewTab = false,
  ...rest
}) => (
  <>
    {href ? (
      <a
        className={className}
        href={href}
        rel={openInNewTab && "noopener noreferrer"}
        target={openInNewTab && "_blank"}
      >
        {children}
      </a>
    ) : (
      <NavLink
        activeClassName={activeClassName || null}
        className={className}
        rel={openInNewTab && "noopener noreferrer"}
        target={openInNewTab && "_blank"}
        to={to}
        {...rest}
      >
        {children}
      </NavLink>
    )}
  </>
)

export default NavLinkOrLink
