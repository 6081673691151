import React from "react"

import GetQueryLoader from "components/get-query-loader"
import { useParams } from "react-router-dom"

import { GET_BRAND } from "./queries"
import Card from "./card"

const QrSheet = () => {
  const { brandSharePageSecret } = useParams()

  return (
    <div className="py-16">
      <GetQueryLoader
        query={GET_BRAND}
        queryVariables={{ brandSharePageSecret }}
        useBrandKey={false}
      >
        {({ brand }) => {
          if (brand === null) return <h1>Not authenticated.</h1>
          const { name: brandName, cardExperiences } = brand
          return (
            <div className="max-w-5xl mx-auto">
              <h1 className="text-3xl mr-2 mb-2 font-bold leading-tight text-gray-900">
                Batch QR codes for {brandName}
              </h1>
              <div className="py-5">
                {cardExperiences.map((nbe) => (
                  <div
                    key={nbe.id}
                    className="p-4 mb-4 border-gray-300 border rounded-lg shadow"
                  >
                    <h2 className="text-xl mr-2 mb-4 font-semibold leading-tight text-gray-900">
                      <span className="font-normal text-gray-400">
                        Experience Name:{" "}
                      </span>
                      {nbe.name}
                    </h2>
                    <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
                      {nbe.activations.map((activation) => (
                        <Card
                          key={activation.id}
                          activation={activation}
                          experience={nbe}
                        />
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          )
        }}
      </GetQueryLoader>
    </div>
  )
}

export default QrSheet
