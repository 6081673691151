import React, { useState } from "react"
import { Form } from "formik"
import * as Yup from "yup"

import SubmitButton from "components/submit-button"
import FormikInput from "components/forms/formik-input"
import BatchForm from "components/batch-form"
import Select from "components/forms/select"
import ImagePicker from "components/image-picker"
import RichTextInput from "components/forms/rich-text-input"
import { useParams } from "react-router-dom"
import { UPDATE_CARD_VARIATION_MUTATION } from "./queries"

const EditProductVariantInformation = ({
  productVariant,
  productVariantRefetch,
}) => {
  const {
    primaryCardVariation: cardVariation,
    featuredImageSrc: thumbnailImageSrc,
  } = productVariant
  const { brandKey } = useParams()

  const {
    title,
    subtitle,
    id: cardVariationId,
    variationValue,
    isSoldOut,
    backInStockNotificationFeatureEnabled,
    extendedDescriptionHtml: passedExtendedDescriptionHtml,
  } = cardVariation

  const formSchema = Yup.object().shape({
    cardVariationId: Yup.string().uuid().required(),
    isSoldOut: Yup.boolean().optional(),
    subtitle: Yup.string().optional().nullable(),
    title: Yup.string().required(),
    variationValue: Yup.string().required(),
  })

  const [thumbnailUrl, setThumbnailUrl] = useState(thumbnailImageSrc)
  const [extendedDescriptionHtml, setExtendedDescriptionHtml] = useState(
    passedExtendedDescriptionHtml
  )

  return (
    <main className="ml-10">
      <BatchForm
        enableReinitialize
        formSchema={formSchema}
        generateValues={(values) => ({
          ...values,
          productVariantFeaturedImageSrc: thumbnailUrl,
          extendedDescriptionHtml:
            extendedDescriptionHtml === "<br>" || extendedDescriptionHtml === ""
              ? null
              : extendedDescriptionHtml,
          isSoldOut: values.isSoldOut === "true",
          backInStockNotificationFeatureEnabled:
            values.backInStockNotificationFeatureEnabled === "true",
        })}
        initialValues={{
          title,
          subtitle,
          cardVariationId,
          variationValue,
          extendedDescriptionHtml: passedExtendedDescriptionHtml || "",
          isSoldOut: isSoldOut ? "true" : "false",
          backInStockNotificationFeatureEnabled:
            backInStockNotificationFeatureEnabled ? "true" : "false",
        }}
        mutation={UPDATE_CARD_VARIATION_MUTATION}
        onSuccess={() => productVariantRefetch()}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="grid grid-cols-8">
              <div className="col-span-8 sm:col-span-6 mb-4">
                <FormikInput
                  autoFocus
                  label="Title"
                  name="title"
                  placeholder="Cold Brew (8oz)"
                  type="text"
                />
              </div>
              <div className="col-span-8 sm:col-span-6 mb-4">
                <FormikInput
                  autoFocus
                  label="Subtitle"
                  name="subtitle"
                  placeholder="12-pack"
                  type="text"
                />
              </div>
              <div className="col-span-8 sm:col-span-6 mb-4">
                <FormikInput
                  autoFocus
                  label="Selector Title"
                  name="variationValue"
                  placeholder="Chocolate (8oz)"
                  type="text"
                />
              </div>
              <ImagePicker
                brandKey={brandKey}
                imageUrl={thumbnailUrl}
                inputName="thumbnailImageSrc"
                label="Thumbnail Image (v1)"
                onImageUploadComplete={(url) => setThumbnailUrl(url)}
                preview
                uploadFolder={`card_variations/${cardVariationId}/thumbnail_image`}
              />
              <div className="col-span-8 sm:col-span-6 mb-4">
                <RichTextInput
                  hint="This adds a 'Read More' button next to a card's title to reveal this text. Max 400 characters."
                  initialValue={extendedDescriptionHtml}
                  label="Extended Description"
                  name="extendedDescriptionHtml"
                  setExtendedDescriptionHtml={setExtendedDescriptionHtml}
                />
              </div>
              <div className="col-span-6 sm:col-span-6 mb-4">
                <Select
                  hint="Flags if a product variant is sold out"
                  label="Sold Out Status"
                  name="isSoldOut"
                  options={{
                    true: "Sold Out",
                    false: "Available",
                  }}
                />
              </div>
              <div className="col-span-6 sm:col-span-6 mb-4">
                <Select
                  hint="If enabled, users will be notified when the product variant is back in stock"
                  label="Back in Stock Notification"
                  name="backInStockNotificationFeatureEnabled"
                  options={{
                    true: "Enabled",
                    false: "Disabled",
                  }}
                />
              </div>
            </div>
            <SubmitButton submitting={isSubmitting} />
          </Form>
        )}
      </BatchForm>
    </main>
  )
}

export default EditProductVariantInformation
