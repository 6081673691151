import React from "react"
import { formatCurrency } from "utils/money"

const FeaturedProductVariantSectionPreview = ({ experienceSectionDetails }) => {
  const variant = experienceSectionDetails.productVariant
  if (!variant) return null

  const { price, standaloneTitle, subtitle } = variant

  return (
    <div className="px-4 bg-transparent">
      <h3 className="font-bold text-xl leading-tight">{standaloneTitle}</h3>
      <h4
        className="font-bold tracking-tight mt-2"
        style={{ fontSize: "0.65rem", lineHeight: "0.65rem" }}
      >
        {subtitle}
      </h4>
      <div className="flex flex-row justify-between items-center">
        <p className="text-lg font-bold tracking-tight mt-2">
          {formatCurrency(price)}
        </p>
        <div className="flex flex-row space-x-2 items-center">
          <div className="bg-gray-200 rounded-full h-6 w-6 flex justify-center items-center text-lg font-semibold">
            –
          </div>
          <div className="bg-gray-200 rounded-full h-6 w-6 flex justify-center items-center text-lg font-semibold">
            +
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeaturedProductVariantSectionPreview
