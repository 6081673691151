import React from "react"
import { useLocation } from "react-router-dom"
import SmallFormModal from "components/small-form-modal"
import GetQueryLoader from "components/get-query-loader"
import { useEditor } from "contexts/experience-v2-editor"
import { GET_AVAILABLE_PRODUCT_VARIANTS } from "../queries"
import AddCardVariationForm from "./add-card-variation-form"

const AddVariationModal = ({ visible, onClose }) => {
  const location = useLocation()
  const { currentCard } = useEditor()
  const cardId = currentCard.id

  // Extract the /brand/{brand_name} from location.pathname
  const urlPrefix = location.pathname.split("/").slice(0, 3).join("/")

  return (
    <SmallFormModal fullWidth onClose={onClose} visible={visible}>
      {() => (
        <GetQueryLoader
          fetchPolicy="network-only"
          query={GET_AVAILABLE_PRODUCT_VARIANTS}
          queryVariables={{
            cardId,
          }}
        >
          {({ availableProductVariants }) => {
            const tableData = availableProductVariants.map((o) => ({
              shopifyId: o.shopifyVariantIdNumber,
              productVariantUrl: `${urlPrefix}/product-variants/${o.id}`,
              shopifyProductId: o.product.shopifyProductIdNumber,
              ...o,
            }))
            return (
              <AddCardVariationForm close={onClose} tableData={tableData} />
            )
          }}
        </GetQueryLoader>
      )}
    </SmallFormModal>
  )
}

export default AddVariationModal
