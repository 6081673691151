import gql from "graphql-tag"

export const UPDATE_CARD = gql`
  mutation UpdateCard($input: UpdateCardInput!) {
    updateCard(input: $input) {
      errors
      card {
        id
      }
    }
  }
`

export default UPDATE_CARD
