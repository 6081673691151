import React from "react"
import Drawer from "components/drawer"
import SingleSystemCheck from "./system-checks/single"

const SystemChecks = ({ isOpen, onClose, systemChecks }) => (
  <Drawer
    className="w-1/3 p-8 overflow-hidden bg-white shadow sm:rounded-md"
    isOpen={isOpen}
    onClose={onClose}
    title="System checks"
  >
    <ul className="divide-y divide-gray-200">
      {systemChecks.map((systemCheck) => (
        <SingleSystemCheck key={systemCheck.id} systemCheck={systemCheck} />
      ))}
    </ul>
  </Drawer>
)

export default SystemChecks
