import React, { useState, useEffect } from "react"
import { ReactComponent as DiscountArrow } from "assets/icons/discount-arrow.svg"

const OrderDiscountSectionPreview = ({ experienceSectionDetails }) => {
  const [showSmsDisclaimer, setShowSmsDisclaimer] = useState(false)

  useEffect(() => {
    const interval = setInterval(
      () => setShowSmsDisclaimer((prev) => !prev),
      5000
    )
    return () => {
      clearInterval(interval)
    }
  }, [])

  const { smsDisclaimer, subtitle, title } = experienceSectionDetails

  return (
    <div className="px-4 bg-white">
      <div
        className="bg-gray-200 flex flex-col px-3 py-3 font-semibold text-xs rounded-lg w-full mt-4"
        style={{ backgroundColor: "#DDDDDD" }}
      >
        <p className="font-bold text-xs text-gray-900">{title}</p>
        <p
          className="font-light text-xs text-gray-900"
          style={{ fontSize: "0.7rem", lineHeight: "1rem" }}
        >
          {subtitle}
        </p>
        <div className="flex flex-row justify-between items-center">
          <div className="rounded-md w-full border font-normal text-gray-400 border-gray-400 bg-gray-300 mt-3 px-2 py-2">
            Enter phone number
          </div>
          <DiscountArrow className="w-6 mt-3 ml-4 text-gray-400" />
        </div>
        {showSmsDisclaimer && (
          <p
            className="text-gray-500 font-light mt-2 px-1"
            style={{ fontSize: "0.5rem", lineHeight: "0.8rem" }}
          >
            {smsDisclaimer}
          </p>
        )}
      </div>
    </div>
  )
}

export default OrderDiscountSectionPreview
