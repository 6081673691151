import React from "react"
import BackLink from "components/backlink"

import Star from "components/star"
import formatCurrency from "utils/money"
import Alert from "components/alert"
import CardExperienceItem from "components/card-experiences/card-experience-item"
import Badge from "components/badge"
import Row from "components/layout/row"
import useDisclosure from "hooks/use-disclosure"
import ShopifyOptions from "./shopify-options"
import SystemChecks from "./system-checks"

const getBadgeInfo = (systemChecks) => {
  if (!systemChecks || systemChecks.length === 0) {
    return {
      color: "gray",
      message: "No checks yet",
    }
  }
  if (systemChecks.find((s) => s.status === "failed")) {
    return {
      color: "red",
      message: `Checks Failed`,
    }
  }
  if (
    systemChecks.find((s) => s.status === "retrying" || s.status === "queued")
  ) {
    return {
      color: "blue",
      message: `Checks in progress`,
    }
  }
  return {
    color: "green",
    message: `All checks passed`,
  }
}

const ProductVariantHeader = ({ productVariant }) => {
  const {
    id,
    productTitle,
    subtitle,
    price,
    sku,
    isStarred,
    shopifyVariantIdNumber,
    selectedOptions,
    product: { platformShoppableUrl },
    deletedFromShopify = true,
    cardExperiences,
    systemChecks,
  } = productVariant

  const { isOpen, onOpen, onClose } = useDisclosure()
  const badgeInfo = getBadgeInfo(systemChecks)

  return (
    <header className="flex flex-row justify-start mb-4">
      <div className="flex items-start justify-center mt-5 mr-2">
        <Star id={id} isStarred={isStarred} />
      </div>
      <div className="flex flex-col w-full">
        <BackLink
          backText="All product variants"
          backTo="../product-variants"
        />
        <div className="flex items-center gap-4">
          <h1 className="text-3xl font-extrabold text-gray-900 leading-tightest tracking-narrow">
            {productTitle}
          </h1>
          <button className="cursor-pointer" onClick={onOpen} type="button">
            <Badge
              color={badgeInfo.color}
              message={
                <Row>
                  <span>{badgeInfo.message}</span>
                </Row>
              }
            />
          </button>
        </div>
        <h2 className="mr-2 text-xl font-normal tracking-wide text-gray-600 leading-tightest">
          {subtitle}
        </h2>
        <span className="mr-2 font-normal tracking-wide text-gray-600 text-md leading-tightest">
          {shopifyVariantIdNumber} &bull; {sku} &bull; {formatCurrency(price)}
        </span>

        <br />
        <b>Used in Card Experiences:</b>
        {cardExperiences.map((cardExperience) => (
          <span
            key={cardExperience.id}
            className="mr-2 font-normal tracking-wide text-gray-600 text-md leading-tightest"
          >
            <CardExperienceItem {...cardExperience} />
          </span>
        ))}

        <ShopifyOptions selectedOptions={selectedOptions} />
        {platformShoppableUrl && (
          <a
            className="mr-2 font-normal tracking-wide text-blue-600 text-md leading-tightest"
            href={platformShoppableUrl}
            rel="noreferrer"
            target="_blank"
          >
            Link to PDP
          </a>
        )}
        {deletedFromShopify && (
          <div className="mt-4">
            <Alert message="This product variant has been removed from shopify" />
          </div>
        )}
      </div>
      {/* TODO: add similar products here */}
      <SystemChecks
        isOpen={isOpen}
        onClose={onClose}
        systemChecks={systemChecks}
      />
    </header>
  )
}

export default ProductVariantHeader
