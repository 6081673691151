import React from "react"
import { MEASUREMENTS as Iphone11ProMaxMeasurements } from "components/containers/iphone-11-pro-max"
import { H2 } from "components/containers/iphone-fonts"
import BrandIcon from "components/brand-icon"

const Header = ({ textColor, imageSrc, gridTitle }) => (
  <div
    className="w-full h-full flex flex-col justify-between items-center"
    style={{
      height: Iphone11ProMaxMeasurements.gridHeader.height,
      minHeight: Iphone11ProMaxMeasurements.gridHeader.height,
      paddingLeft: Iphone11ProMaxMeasurements.gridHeader.paddingX,
      paddingRight: Iphone11ProMaxMeasurements.gridHeader.paddingX, // iphone 11 pro max from figma
      paddingTop: Iphone11ProMaxMeasurements.gridHeader.paddingTop,
      paddingBottom: Iphone11ProMaxMeasurements.gridHeader.paddingBottom,
      color: textColor,
    }}
  >
    <BrandIcon imageSrc={imageSrc} textColor={textColor} />
    <div className="flex flex-col justify-end h-full text-center w-full">
      <p
        style={{
          ...H2,
          color: `#${textColor}`,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {gridTitle}
      </p>
    </div>
  </div>
)
export default Header
