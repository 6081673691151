import gql from "graphql-tag"

const GET_EXPERIENCE = gql`
  query GetExperience($id: ID!) {
    experience(id: $id) {
      id
      name
      backgroundImageSrc

      activations {
        id
        name
        category
        url
        identifier
        qrCodeSvgUrl
        updatedAt
        createdAt
        fallbackUrl
        appStoreConnectStatus
      }
    }
  }
`

export default GET_EXPERIENCE

export { GET_EXPERIENCE }
