import React from "react"
import QRCode from "react-qr-code"
import DownloadButton from "./download-button"

const ActivationDemo = ({
  activation,
  size = 48,
  hideHeader = false,
  hideDemoUrl = false,
  kind = "demo",
  showDownloadButton = false,
  qrCodeSvgId: passedQrCodeSvgId,
}) => {
  let qrUrl
  let color
  let label
  let header

  const { name: activationName } = activation

  switch (kind) {
    case "demo":
      color = "red"
      label = "Test"
      qrUrl = activation.demoUrl
      header = "Test Url"
      break
    case "test":
      color = "blue"
      qrUrl = activation.testUrl
      header = "TEST SCANS ONLY"
      break
    default:
      throw new Error(`Wrong kind ${kind}.`)
  }

  const randomString = Math.random().toString(36).substring(2, 15)

  const qrCodeSvgId = passedQrCodeSvgId || `${qrUrl}?qrcode=${randomString}`

  return (
    <div className="items-center">
      <div
        className={`relative bg-white h-${size} w-${size} flex items-center mx-auto justify-center`}
      >
        <QRCode
          fgColor={color}
          id={qrCodeSvgId}
          size={size * 3.75}
          value={qrUrl}
        />
        {label && (
          <div
            className="absolute uppercase font-extrabold text-red-800 border-black border-2 bg-white"
            style={{
              padding: "1px",
              fontSize: `${size / 3.5}px`,
              transform: "translate(0%, 0%)",
              WebkitTransform: "translate(0%, 0%)",
            }}
          >
            {label}
          </div>
        )}
      </div>
      {!hideDemoUrl && <p className="text-gray-500 text-xs">{qrUrl}</p>}
      {!hideHeader && <h3 className="font-bold text-md">{header}</h3>}
      {showDownloadButton && (
        <DownloadButton
          activationName={activationName}
          kind={kind}
          qrCodeSvgId={qrCodeSvgId}
        />
      )}
    </div>
  )
}

export default ActivationDemo
