/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React from "react"
import Table from "components/table"
import CardActionButton from "components/button/card-action-button"
import Row from "components/layout/row"

const IndexTable = ({
  columns,
  data,
  title,
  onCtaClick,
  ctaText,
  options,
  deletedProductVariantError,
  ...tableProps
}) => {
  const Header = () => (
    <Row className="p-4">
      <div>
        <h1 className="text-2xl font-semibold leading-tight text-gray-900">
          {title}
        </h1>
        {deletedProductVariantError}
      </div>
      <CardActionButton onClick={onCtaClick}>{ctaText}</CardActionButton>
    </Row>
  )

  return (
    <Table
      columns={columns}
      components={{
        Toolbar: Header,
      }}
      data={data}
      options={{
        ...options,
        toolbar: true,
      }}
      {...tableProps}
    />
  )
}

export default IndexTable
