import React, { createContext, useContext, useState, useCallback } from "react"
import { useApolloClient, useMutation } from "@apollo/client"
import { useHistory } from "react-router-dom"

import {
  GET_CURRENT_ADMIN_USER_QUERY,
  LOGIN_ADMIN_USER_FIELDS,
} from "./queries"
import { LOGOUT_ADMIN_USER_MUTATION } from "../../components/admin-password-login-form/queries"

const AdminAuthenticationContext = createContext(undefined)

const AdminAuthenticationProvider = ({ children }) => {
  const [currentAdminUser, setCurrentAdminUser] = useState(null)

  const history = useHistory()
  const client = useApolloClient()

  const logAdminUserIn = (adminUser) => {
    setCurrentAdminUser(adminUser)
    history.push(`/admin/brands`)
  }

  const [logout, _] = useMutation(LOGOUT_ADMIN_USER_MUTATION, {
    variables: { input: {} },
  })

  const logAdminUserOut = useCallback(async () => {
    await logout()
    setCurrentAdminUser(null)
    window.location = "/login"
  }, [logout])

  const authAndLoadAdminUser = async () => {
    const authResponse = await client.query({
      query: GET_CURRENT_ADMIN_USER_QUERY,
    })
    const { currentAdminUser: authResponseCurrentAdminUser } =
      authResponse?.data

    if (!authResponseCurrentAdminUser) {
      window.location = "/login"
      return
    }

    setCurrentAdminUser(authResponseCurrentAdminUser)
  }

  return (
    <AdminAuthenticationContext.Provider
      value={{
        currentAdminUser,
        logAdminUserIn,
        logAdminUserOut,
        authAndLoadAdminUser,
      }}
    >
      {children}
    </AdminAuthenticationContext.Provider>
  )
}

const useAdminAuthentication = () => {
  const context = useContext(AdminAuthenticationContext)

  if (context === undefined) {
    throw new Error(
      "useAdminAuthentication must be used within a AdminAuthenticationProvider"
    )
  }

  return context
}

export {
  useAdminAuthentication,
  AdminAuthenticationProvider,
  LOGIN_ADMIN_USER_FIELDS,
}
