import React from "react"
import VideoBackground from "./video"
import ImageBackground from "./image"
import CutoutBackground from "./cutout"

const Background = ({ currentCardVariationBackground }) => {
  if (!currentCardVariationBackground)
    return <div className="bg-gray-200 flex min-h-full overflow-hidden" />
  const { backgroundType } = currentCardVariationBackground
  switch (backgroundType) {
    case "image":
      // if it’s image, we scale to fill the space and center it
      return (
        <ImageBackground
          currentCardVariationBackground={currentCardVariationBackground}
        />
      )
    case "video":
      return (
        <VideoBackground
          currentCardVariationBackground={currentCardVariationBackground}
        />
      )
    case "cutout":
      // if it’s cutout, we scale to fit the space and center it
      return (
        <CutoutBackground
          currentCardVariationBackground={currentCardVariationBackground}
        />
      )
    default:
      return null
  }
}

export default Background
