import React from "react"
import classnames from "classnames"
import { getSlimStyles } from "./utils"
import Button from "./button"

const SecondaryButton = ({
  slim = false,
  className,
  onClick,
  order = 1,
  type = "button",
  children,
  submitting = false,
  disabled = false,
  rounding = "all",
}) => (
  <Button
    buttonClassName={classnames(
      "border border-gray-300 text-gray-700 bg-white hover:text-gray-500 focus:shadow-outline-purple focus:border-purple-300 active:text-gray-800 active:bg-gray-50",
      getSlimStyles(slim)
    )}
    className={className}
    disabled={disabled}
    onClick={onClick}
    order={order}
    rounding={rounding}
    submitting={submitting}
    type={type}
  >
    {children}
  </Button>
)

export default SecondaryButton
