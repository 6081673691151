import React from "react"
import classNames from "classnames"

const Center = ({ children, className, ...rest }) => (
  <div
    className={classNames("flex justify-center items-center", className)}
    {...rest}
  >
    {children}
  </div>
)

export default Center
