import { useState } from "react"

const useDisclosure = (initialState) => {
  const [isOpen, setIsOpen] = useState(initialState || false)

  return {
    isOpen,
    onClose: () => setIsOpen(false),
    onOpen: () => setIsOpen(true),
  }
}

export default useDisclosure
