import React from "react"

const ImageBackground = ({
  currentCardVariationBackground: { backgroundImageSrc },
}) => (
  <div className="flex min-h-full overflow-hidden">
    <img
      alt="Default variant."
      className="min-w-full min-h-full object-cover"
      src={backgroundImageSrc}
    />
  </div>
)

export default ImageBackground
