import React from "react"
import { useEditor } from "contexts/experience-editor"
import { Form } from "formik"
import * as Yup from "yup"

import BatchForm from "components/batch-form"
import FormikInput from "components/forms/formik-input"
import SubmitButton from "components/submit-button"
import ColorSelector from "components/forms/color-selector"
import { UPDATE_EXPERIENCE_CONFIGURATION } from "./queries"

const Settings = () => {
  const { experienceId, currentExperience, refetchExperience } = useEditor()

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  })
  return (
    <BatchForm
      additionalValues={{
        experienceId,
      }}
      formSchema={formSchema}
      initialValues={{
        name: currentExperience.name,
        themeBackgroundColor: currentExperience.themeBackgroundColor,
        themeForegroundColor: currentExperience.themeForegroundColor,
        themeAccentColor: currentExperience.themeAccentColor,
        themeForegroundOverAccentColor:
          currentExperience.themeForegroundOverAccentColor,
      }}
      mutation={UPDATE_EXPERIENCE_CONFIGURATION}
      onSuccess={() => refetchExperience()}
    >
      {({ values, isSubmitting }) => (
        <div className="px-10 pt-10">
          <h2 className="text-xl mr-2 mb-4 font-extrabold leading-tightest tracking-narrow text-gray-900">
            Settings
          </h2>
          <Form>
            <div className="grid grid-cols-8">
              <div className="col-span-8 sm:col-span-6 mb-4">
                <FormikInput
                  autoFocus
                  name="name"
                  placeholder="Reorder — [Your Product Name]"
                  type="text"
                />
              </div>
              <div className="col-span-8 mb-4" />
              <div className="col-span-4 mb-4 md:col-span-2 mr-4">
                <ColorSelector
                  color={values.themeBackgroundColor}
                  label="Background Color"
                  name="themeBackgroundColor"
                  placeholder="FFFFFF"
                />
              </div>
              <div className="col-span-4 mb-4 md:col-span-2 mr-4">
                <ColorSelector
                  color={values.themeForegroundColor}
                  label="Foreground Color"
                  name="themeForegroundColor"
                />
              </div>
              <div className="col-span-8 mb-4" />
              <div className="col-span-4 mb-4 md:col-span-2 mr-4">
                <ColorSelector
                  color={values.themeAccentColor}
                  label="Accent Color"
                  name="themeAccentColor"
                />
              </div>
              <div className="col-span-4 mb-4 md:col-span-2 mr-4">
                <ColorSelector
                  color={values.themeForegroundOverAccentColor}
                  label="Foreground Over Accent Color"
                  name="themeForegroundOverAccentColor"
                  placeholder="FFFFFF"
                />
              </div>
            </div>
            <SubmitButton submitting={isSubmitting} />
          </Form>
        </div>
      )}
    </BatchForm>
  )
}

export default Settings
