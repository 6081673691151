import React from "react"

const completeLabel = {
  label: "Enabled",
  color: "bg-green-100",
}

const incompleteLabel = {
  label: "Disabled",
  color: "bg-red-100",
}

const StatusColumn = ({ brand }) => {
  const { platformEnabled } = brand

  const { color, label } = platformEnabled ? completeLabel : incompleteLabel

  return (
    <span
      className={`px-2 py-1 mr-1 mb-1 text-gray-800 text-xs font-medium rounded-sm ${color}`}
    >
      {label}
    </span>
  )
}

export default StatusColumn
