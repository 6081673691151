import React from "react"

const CardActionButton = ({ children, onClick, ...rest }) => (
  <button
    className="text-blue-500 text-sm"
    onClick={onClick}
    type="button"
    {...rest}
  >
    {children}
  </button>
)

export default CardActionButton
