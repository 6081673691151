import React from "react"

import { Switch, Route } from "react-router-dom"

import Padding from "components/layout/padding"
import ActivationIndex from "./activation-index"
import AffiliateIndex from "./affiliate-index"
import BrandUsersIndex from "./brand-users-index"
import Dash from "./dash"
import ProductVariantShow from "./product-variant-show"
import ProductVariantIndex from "./product-variant-index/index"
import OldExperienceIndex from "./old-experience-index"
import OldExperienceEdit from "./old-experience-edit"
import ExperienceIndex from "./experience-index"
import ExperienceEdit from "./experience-edit"
import ActivationEdit from "./activation-edit"
import Settings from "./settings"
import NewAdminBrandShow from "./new-admin-brand-show"
import BrandShow from "./brand-show"

const BrandRouter = () => (
  <Switch>
    <Route
      component={OldExperienceEdit}
      path="/brands/:brandKey/old-experiences/:experienceId"
    />
    <Route
      component={ExperienceEdit}
      exact
      path="/brands/:brandKey/experiences/:cardExperienceId"
    />
    <Route
      component={ProductVariantShow}
      path="/brands/:brandKey/product-variants/:productVariantId"
    />
    <Padding>
      <Route component={BrandShow} exact path="/brands/:brandKey" />
      <Route
        component={NewAdminBrandShow}
        exact
        path="/brands/:brandKey/new-builder"
      />
      <Route component={Dash} path="/brands/:brandKey/dashboard" />
      <Route
        component={OldExperienceIndex}
        exact
        path="/brands/:brandKey/old-experiences"
      />
      <Route
        component={ExperienceIndex}
        exact
        path="/brands/:brandKey/experiences"
      />
      {/* HEADS UP! Keeping url as 'products' but everything in here is product variants */}
      {/* <Route path={`/brands/product-variants/:productId`}>
          <ProductShow />
        </Route> */}
      <Route
        component={ProductVariantIndex}
        exact
        path="/brands/:brandKey/product-variants"
      />

      <Route
        component={ActivationEdit}
        path="/brands/:brandKey/activations/:activationId"
      />
      <Route
        component={ActivationIndex}
        exact
        path="/brands/:brandKey/activations"
      />
      <Route component={AffiliateIndex} path="/brands/:brandKey/affiliates" />
      <Route component={BrandUsersIndex} path="/brands/:brandKey/users" />
      <Route component={Settings} path="/brands/:brandKey/settings" />
    </Padding>
  </Switch>
)

export default BrandRouter
