import React from "react"
import * as Yup from "yup"
import ToggleForm from "components/toggle-form"
import FormikInput from "components/forms/formik-input"
import BatchForm from "components/batch-form"
import { UPDATE_BRAND_LEGAL_MUTATION } from "./queries"

const formSchema = Yup.object().shape({
  termsOfUseUrl: Yup.string().required("Required"),
  privacyPolicyUrl: Yup.string().required("Required"),
  supportUrl: Yup.string().required("Required"),
})

const LegalForm = ({
  refetchBrand,
  termsOfUseUrl,
  privacyPolicyUrl,
  supportUrl,
}) => (
  <BatchForm
    enableReinitialize
    formSchema={formSchema}
    initialValues={{
      termsOfUseUrl,
      privacyPolicyUrl,
      supportUrl,
    }}
    mutation={UPDATE_BRAND_LEGAL_MUTATION}
    onSuccess={refetchBrand}
  >
    {({ isSubmitting }) => (
      <ToggleForm isSubmitting={isSubmitting} title="Legal">
        {({ isOpen }) => (
          <>
            <FormikInput
              isTextOnly={!isOpen}
              label="Terms of Use URL"
              name="termsOfUseUrl"
              placeholder="https://brand.com/terms.html"
              type="text"
            />
            <FormikInput
              isTextOnly={!isOpen}
              label="Privacy Policy URL"
              name="privacyPolicyUrl"
              placeholder="https://brand.com/privacy.html"
              type="text"
            />
            <FormikInput
              isTextOnly={!isOpen}
              label="Support URL"
              name="supportUrl"
              placeholder="https://brand.com/support.html"
              type="text"
            />
          </>
        )}
      </ToggleForm>
    )}
  </BatchForm>
)

export default LegalForm
