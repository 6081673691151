import gql from "graphql-tag"

// brand is inferred from current user
const GET_EXPERIENCES = gql`
  query GetExperiences($brandKey: String!) {
    brand(brandKey: $brandKey) {
      id
      experiences {
        id
        name
        backgroundImageSrc
        cartProductVariantTitles
      }
    }
  }
`

const CREATE_BLANK_EXPERIENCE_MUTATION = gql`
  mutation CreateBlankExperience($input: CreateBlankExperienceInput!) {
    createBlankExperience(input: $input) {
      experience {
        id
        name
      }
      errors
    }
  }
`

export { GET_EXPERIENCES, CREATE_BLANK_EXPERIENCE_MUTATION }
