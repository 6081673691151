import gql from "graphql-tag"

const CREATE_NEXT_NFC_TAG_SERIES = gql`
  mutation CreateNextNfcTagSeries($input: CreateNextNfcTagSeriesInput!) {
    createNextNfcTagSeries(input: $input) {
      errors
      tagSeries {
        id
      }
    }
  }
`

export default CREATE_NEXT_NFC_TAG_SERIES

export { CREATE_NEXT_NFC_TAG_SERIES }
