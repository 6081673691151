import gql from "graphql-tag"

const GET_EXPERIENCE = gql`
  query GetExperience($id: ID!, $brandKey: String!) {
    experience(id: $id, brandKey: $brandKey) {
      id
      name
      backgroundImageSrc
      backgroundVideoSrc
      appClipCardImageSrc
      appClipCardTitle
      appClipCardSubtitle
      themeBackgroundColor
      themeForegroundColor
      themeAccentColor
      themeForegroundOverAccentColor
      hasExperienceSections

      brand {
        id
        name
        logoImageSrc
      }

      experienceSections {
        id
        position
        experienceSectionDetailsType
        experienceSectionDetailsId

        experienceSectionDetails {
          __typename
          ... on DashboardExperienceSectionFeaturedProductVariantDetailType {
            id
            productVariant {
              id
              sku
              standaloneTitle
              subtitle
              price
              imageSrcSmall
            }
          }
          ... on DashboardExperienceSectionFeaturedContentHeaderDetailType {
            id
            title
            description
          }
          ... on DashboardExperienceSectionOrderDiscountDetailType {
            id
            discountMethod
            discountMagnitude
            subtitle
            discountStrategy
            title
            smsDisclaimer
          }
          ... on DashboardExperienceSectionLocationOrderDiscountDetailType {
            discountMagnitude
            discountMethod
            discountStrategy
            latitude
            longitude
            timeLimit
            radius
            id
            subtitle
            title
          }
          ... on DashboardExperienceSectionProductVariantCatalogDetailType {
            id
            title
            productVariants {
              id
              sku
              description
              imageSrcSmall
              imageSrcMedium
              standaloneTitle
              subtitle
              price
              product {
                ageVerification
              }
            }
          }
        }
      }
    }
  }
`
export { GET_EXPERIENCE }
export default GET_EXPERIENCE
