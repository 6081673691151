import React from "react"
import ProductVariantRow from "./product-variant-row"

const ProductVariantGallery = ({ productVariants, chooseProductVariant }) => (
  <>
    <h2 className="font-medium text-2xl mb-4 text-center text-orange-700 underline">
      This experience is not yet configured
    </h2>
    <h3 className="font-medium text-lg my-4">
      {productVariants.length > 0
        ? "Choose a product to get started:"
        : "Products are not available, connect Shopify first."}
    </h3>
    <table className="max-w-full text-left divide-y divide-gray-200 mb-32">
      <thead>
        <th
          className="px-2 py-3 w-20 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
          scope="col"
        >
          Image
        </th>
        <th
          className="px-2 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
          scope="col"
        >
          Product
        </th>
        <th
          className="px-2 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
          scope="col"
        >
          SKU
        </th>
        <th
          className="px-2 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
          scope="col"
        >
          Shopify Variant ID
        </th>
        <th
          className="px-2 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
          scope="col"
        >
          Price
        </th>

        <th
          className="px-2 py-3 text-left text-xs font-medium text-gray-800 uppercase tracking-wider"
          scope="col"
        >
          Action
        </th>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {productVariants.map((pv) => (
          <ProductVariantRow
            key={pv.id}
            chooseProductVariant={chooseProductVariant}
            productVariant={pv}
          />
        ))}
      </tbody>
    </table>
  </>
)

export default ProductVariantGallery
