import React from "react"

// Helper component to render only image without input component
const ImageOnly = ({ url, isImageOnly, alt, children }) => {
  if (isImageOnly) {
    return (
      <div className="col-span-12 overflow-hidden truncate ">
        {url ? (
          <img
            alt={alt}
            className="max-h-32 bg-gray-100"
            data-testid="image-only"
            src={url}
          />
        ) : (
          <span>Not Provided</span>
        )}
      </div>
    )
  }

  return children
}

export default ImageOnly
