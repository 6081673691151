import gql from "graphql-tag"

export const CREATE_BRAND_MUTATION = gql`
  mutation CreateBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      errors
      brand {
        id
        key
      }
    }
  }
`

export const SCRAPE_BRAND_HOMEPAGE_MUTATION = gql`
  mutation ScrapeBrandHomepage($input: ScrapeBrandHomepageInput!) {
    scrapeBrandHomepage(input: $input) {
      errors
      homepage
      siteName
      logoImageSrc
      shopifyDomain
    }
  }
`
