import React from "react"
import { useEditor } from "contexts/experience-v2-editor"
import Toggle from "components/toggle"
import ActivationDemo from "components/activation-demo"
import PreviewWorkspace from "components/preview-workspace"
import ExperiencePreview from "./experience-preview"

const Workspace = () => {
  const { isGridView, setGridView, currentCardExperience } = useEditor()

  if (!currentCardExperience) return null

  const { activations } = currentCardExperience

  const extras = (
    <>
      <div className="mt-4 mb-2 flex items-center">
        <p className="text-xs text-gray-700 dark:text-gray-300 mx-2">
          Product View
        </p>
        <Toggle enabled={isGridView} setEnabled={setGridView} />
        <p className="text-xs text-gray-700 dark:text-gray-300 mx-2">
          Grid View
        </p>
      </div>
      {activations.length > 0 && (
        <ActivationDemo activation={activations[0]} hideHeader size={32} />
      )}
    </>
  )

  return (
    <PreviewWorkspace colSpan={5} extras={extras}>
      <ExperiencePreview />
    </PreviewWorkspace>
  )
}

export default Workspace
