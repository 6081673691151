import React from "react"
import { Card } from "@material-ui/core"

import { SecondaryButton } from "components/button"
import Row from "components/layout/row"

const OnboardingBookmarkCard = ({ title, description, disabled, url }) => {
  const getButton = () => (
    <SecondaryButton
      buttonClassName={{}}
      className="rounded-lg flex p-1"
      onClick={() => window.open(url, "_blank")}
    >
      Open in new tab
    </SecondaryButton>
  )

  return (
    <Card className="border p-4 relative">
      <Row>
        <div className="flex-1 ml-4">
          <p className="font-medium text-indigo-700">{title}</p>
          <span className="text-gray-500 text-sm">{description}</span>
        </div>
        {getButton()}
      </Row>
      {disabled && (
        <div className="w-full h-full bg-gray-100 absolute top-0 left-0 bg-opacity-50" />
      )}
    </Card>
  )
}

export default OnboardingBookmarkCard
