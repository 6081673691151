import React from "react"
import { PlusCircleIcon } from "@heroicons/react/outline"

import Button from "components/button"
import SubmitButton from "components/submit-button"
import { ModalFooter, ModalBody } from "components/small-form-modal"
import FormikInput from "components/forms/formik-input"
import BatchForm from "components/batch-form"
import * as Yup from "yup"
import { Form } from "formik"
import { SCRAPE_BRAND_HOMEPAGE_MUTATION } from "./queries"

const DomainStep = ({ onSuccess, close }) => {
  const formSchema = Yup.object().shape({
    homepage: Yup.string().required("Required"),
  })

  return (
    <BatchForm
      formSchema={formSchema}
      initialValues={{
        homepage: "",
      }}
      mutation={SCRAPE_BRAND_HOMEPAGE_MUTATION}
      onSuccess={onSuccess}
      useBrandKey={false}
    >
      {({ isSubmitting }) => (
        <Form>
          <ModalBody
            description="Set up a new brand for demos."
            icon={
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <PlusCircleIcon className="h-6 w-6 text-blue-600" />
              </div>
            }
            title="Add a New Brand"
          >
            <div className="w-72">
              <FormikInput
                autoFocus
                name="homepage"
                placeholder="https://mystore.com"
                type="text"
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <SubmitButton cta="Next" submitting={isSubmitting} />
            <Button className="mr-2" onClick={close}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      )}
    </BatchForm>
  )
}

export default DomainStep
