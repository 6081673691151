import React from "react"
import { useParams } from "react-router-dom"

import GetQueryLoader from "components/get-query-loader"
import ActivationTabs from "./tabs"
import { GET_ACTIVATION } from "./queries"

const ActivationEdit = () => {
  const { activationId } = useParams()
  return (
    <GetQueryLoader
      query={GET_ACTIVATION}
      queryVariables={{ id: activationId }}
    >
      {({ activation }, refetch) => {
        const { name, url } = activation
        return (
          <div className="relative">
            <header>
              <div className="w-full">
                <h3 className="text-sm font-semibold leading-tight text-gray-700 my-1">
                  ACTIVATION
                </h3>
                <h1 className="text-3xl font-bold leading-tight text-gray-900">
                  {name}
                </h1>
                <p className="text-xs font-semibold mt-4 uppercase leading-tight text-gray-700">
                  Production URL
                </p>
                <p className="text-md font-bold">{url}</p>
              </div>
            </header>
            <ActivationTabs
              activation={activation}
              refetchActivation={refetch}
            />
          </div>
        )
      }}
    </GetQueryLoader>
  )
}

export default ActivationEdit
