import React, { useState, useRef, useEffect, useMemo } from "react"
import ReactHlsPlayer from "react-hls-player"
import TopNav from "./top-nav"

const FeaturedImageVideoBackground = ({
  backgroundImageSrc,
  backgroundVideoSrc,
  children,
}) => {
  const playerRef = useRef(null)
  const [videoLoaded, setVideoLoaded] = useState(false)

  useEffect(() => {
    const onVideoPause = () => {
      playerRef?.current?.play()
    }
    const onVideoPlaying = () => {
      setVideoLoaded(true)
    }

    if (playerRef && playerRef.current) {
      playerRef.current.addEventListener("pause", onVideoPause)
      playerRef.current.addEventListener("playing", onVideoPlaying)

      return () => {
        playerRef?.current?.removeEventListener("pause", onVideoPause)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        playerRef?.current?.removeEventListener("playing", onVideoPlaying)
      }
    }
    return null
  }, [playerRef])

  const videoContainer = useMemo(() => {
    if (!backgroundVideoSrc) return null

    if (backgroundVideoSrc.endsWith(".mp4")) {
      setVideoLoaded(true)
      return (
        <div className="z-0 top-0 absolute right-0 left-0">
          <video
            autoPlay
            loop
            muted
            style={{
              minHeight: "100%",
              minWidth: "100%",
            }}
          >
            <source src={backgroundVideoSrc} type="video/mp4" />
          </video>
        </div>
      )
    }

    return (
      <div className="z-0 top-0 absolute right-0 left-0">
        <ReactHlsPlayer
          autoplay
          controls
          height="600px"
          playerRef={playerRef}
          url={backgroundVideoSrc}
          width="300px"
        />
      </div>
    )
  }, [backgroundVideoSrc])

  return (
    <div
      className="bg-white h-full w-full flex flex-col relative hide-scrollbar"
      style={{
        backgroundImage: `url(${backgroundImageSrc})`,
        backgroundPosition: "top",
        backgroundPositionX: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        // overflowY: 'scroll'
      }}
    >
      <TopNav />
      <div
        className="absolute inset-0 overflow-y-scroll overflow-x-hidden hide-scrollbar"
        id="scrollcontainer"
      >
        <div
          className="z-10 absolute bg-gradient-to-b from-transparent via-white to-white left-0 right-0 bottom-0"
          id="background"
          style={{ height: "400px" }}
        />
        <div
          className="z-10 absolute right-0 left-0 flex flex-col"
          style={{ top: "320px" }}
        >
          {children}
        </div>
      </div>
      <div style={{ opacity: videoLoaded ? 1 : 0.001 }}>{videoContainer}</div>
    </div>
  )
}

export default FeaturedImageVideoBackground

// <div className='z-10 mt-48 px-4 pt-12 w-full h-96 bg-green-200 flex flex-col'>
//   {children}
// </div>
