import gql from "graphql-tag"

export const TOGGLE_STARRED_PRODUCT_VARIANT = gql`
  mutation ToggleProductVariantStar($input: ToggleProductVariantStarInput!) {
    toggleProductVariantStar(input: $input) {
      errors
      productVariant {
        id
        isStarred
      }
    }
  }
`

export default TOGGLE_STARRED_PRODUCT_VARIANT
