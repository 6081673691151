import React, { useState } from "react"
import BatchForm from "components/batch-form"
import * as Yup from "yup"
import { Form } from "formik"
import FormikInput from "components/forms/formik-input"
import Drawer from "components/drawer"

import { UPDATE_BRAND_MUTATION } from "app/brand/brand-show/brand-edit/queries"
import ImagePicker from "components/image-picker"
import SubmitButton from "components/submit-button"
import { useMutation } from "@apollo/client"
import { SCRAPE_BRAND_HOMEPAGE_MUTATION } from "app/admin/brand-index/create-brand-modal/queries"

const formSchema = Yup.object().shape({
  termsOfUseUrl: Yup.string().required("Required"),
  privacyPolicyUrl: Yup.string().required("Required"),
  applicationFeeRate: Yup.number().required("Required"),
  shopifyApiKey: Yup.string().nullable(),
  shopifyApiSecret: Yup.string().nullable(),
  shopifyDomain: Yup.string().nullable(),
  shopifyInstallLink: Yup.string().nullable(),
  homepage: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  logoImageSrc: Yup.string().required("Required"),
  logoHorizontalSrc: Yup.string().required("Required"),
})

const CreateBrandDrawer = ({ isOpen, onClose, refetchBrands }) => {
  const [logoImageSrc, setLogoImageSrc] = useState("")
  const [logoHorizontalSrc, setLogoHorizontalSrc] = useState("")
  const [executeMigration, _] = useMutation(SCRAPE_BRAND_HOMEPAGE_MUTATION, {
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.log("Error", e)
    },
  })

  const getFaviconAndShopify = async (homepage, setFieldValue) => {
    if (homepage) {
      const { data } = await executeMigration({
        variables: {
          input: {
            homepage,
          },
        },
      })
      const { logoImageSrc: logo, shopifyDomain } =
        data?.scrapeBrandHomepage || {}

      setLogoImageSrc(logo)
      setFieldValue("shopifyDomain", shopifyDomain)
    }
  }

  const handleSuccess = () => {
    refetchBrands()
    onClose()
  }

  return (
    <Drawer
      className="w-1/3 p-8"
      isOpen={isOpen}
      onClose={onClose}
      title="New Brand"
    >
      <BatchForm
        additionalValues={{
          logoImageSrc,
          logoHorizontalSrc,
        }}
        enableReinitialize
        formSchema={formSchema}
        initialValues={{
          name: "",
          homepage: "",
          shopifyDomain: "",
          shopifyApiKey: "",
          shopifyApiSecret: "",
          shopifyInstallLink: "",
          termsOfUseUrl: "",
          privacyPolicyUrl: "",
          applicationFeeRate: 1,
        }}
        mutation={UPDATE_BRAND_MUTATION}
        onSuccess={handleSuccess}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="flex flex-col gap-6">
              <FormikInput
                label="Brand Name"
                name="name"
                placeholder="Kosterina"
              />
              <FormikInput
                label="Website URL"
                name="homepage"
                onBlur={() =>
                  getFaviconAndShopify(values.homepage, setFieldValue)
                }
                placeholder="http://kosterina.com"
              />
              <FormikInput
                label="Transaction Fee"
                min="0"
                name="applicationFeeRate"
                placeholder="2"
                step="0.1"
                suffix="%"
                type="number"
              />
              <FormikInput
                disabled={false}
                label="Shopify Domain"
                name="shopifyDomain"
                placeholder="test"
                suffix=".myshopify.com"
              />
              {values.shopifyDomain && (
                <>
                  <FormikInput
                    label="Shopify API Key"
                    name="shopifyApiKey"
                    placeholder="43f480b8f0951b3611f7ed6c797dc501"
                  />
                  <FormikInput
                    label="Shopify API Secret"
                    name="shopifyApiSecret"
                    placeholder="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"
                  />
                  <FormikInput
                    label="Shopify Install Link"
                    name="shopifyInstallLink"
                    placeholder="https://test.myshopify.com/admin/oauth/kosterina-app"
                  />
                </>
              )}
              <FormikInput
                label="Terms of Use URL"
                name="termsOfUseUrl"
                placeholder="https://brand.com/terms.html"
              />
              <FormikInput
                label="Privacy Policy URL"
                name="privacyPolicyUrl"
                placeholder="https://brand.com/privacy.html"
              />
              <ImagePicker
                imageUrl={logoImageSrc}
                inputName="logoImageSrc"
                label="Favicon Logo"
                onImageUploadComplete={setLogoImageSrc}
                preview
                uploadFolder="brand/new/logo"
              />
              <ImagePicker
                imageUrl={logoHorizontalSrc}
                inputName="logoHorizontalSrc"
                label="Horizontal Logo"
                onImageUploadComplete={setLogoHorizontalSrc}
                preview
                uploadFolder="brand/new/horizontal-logo"
              />
              <SubmitButton cta="Submit" submitting={isSubmitting} />
            </div>
          </Form>
        )}
      </BatchForm>
    </Drawer>
  )
}

export default CreateBrandDrawer
