import React from "react"
import Select from "./select"

// const colorIsWhite = (color) =>
//   typeof color === "string" && color.toLowerCase() === "ffffff"

// const updatedColor = (oldColor) => {
//   if (colorIsWhite(oldColor)) {
//     return "111111"
//   }
//   return "FFFFFF"
// }

const DarkLightSelector = ({ name, className, label }) => (
  <div className={className}>
    <Select
      label={label}
      name={name}
      options={{
        FFFFFF: "White",
        111111: "Black",
      }}
    />
  </div>
)
export default DarkLightSelector
