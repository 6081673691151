import Row from "components/layout/row"
import React from "react"

const TitleHeader = ({ children, ...rest }) => (
  <header {...rest}>
    <Row className="mb-8">{children}</Row>
  </header>
)

export default TitleHeader
