import { Card as MuiCard } from "@material-ui/core"
import classNames from "classnames"
import React, { forwardRef } from "react"

const Card = forwardRef(({ className, children, ...rest }, ref) => (
  <MuiCard
    ref={ref}
    className={classNames("p-4 my-4", className)}
    data-testid="card-wrapper"
    {...rest}
  >
    {children}
  </MuiCard>
))

Card.displayName = "Card"
export default Card
