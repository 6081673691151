import React, { useState } from "react"
import { MEASUREMENTS } from "components/containers/iphone-11-pro-max"
import { Body } from "components/containers/iphone-fonts"
import useInterval from "use-interval"

const hexToRgba = (hex, alphaStr) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}, ${alphaStr})`
    : ""
}

const Footer = ({
  prePurchaseMessage = "",
  postPurchaseMessage = "",
  gridForegroundColor,
}) => {
  const hasTimeString = postPurchaseMessage.indexOf("{{TIME}}") > 0

  const [beforeTimestampString, afterTimestampString] =
    postPurchaseMessage.split("{{TIME}}")
  const [timestamp, setTimestamp] = useState(10 * 60)
  const [showPrePurchase, setShowPrePurchase] = useState(false)

  const toggleContent = () => setShowPrePurchase((prev) => !prev)

  useInterval(() => {
    if (hasTimeString && !showPrePurchase) {
      setTimestamp((prev) => Math.max(1, prev - 1))
    }
  }, 1000)

  const minuteString = Math.floor(timestamp / 60).toFixed(0)
  const secondString = `0${timestamp % 60}`.slice(-2)

  const timestampString = hasTimeString && `${minuteString}:${secondString}`

  return (
    <div
      className="flex items-center justify-center cursor-pointer"
      onClick={toggleContent}
      role="button"
      style={{
        marginTop: MEASUREMENTS.gridFooter.marginY,
        marginLeft: MEASUREMENTS.gridFooter.marginX,
        marginRight: MEASUREMENTS.gridFooter.marginX,
        paddingTop: MEASUREMENTS.gridFooter.paddingY,
        paddingBottom: MEASUREMENTS.gridFooter.paddingY,
        paddingRight: MEASUREMENTS.gridFooter.paddingX,
        paddingLeft: MEASUREMENTS.gridFooter.paddingX,
        borderRadius: MEASUREMENTS.gridFooter.borderRadius,
        backgroundColor: hexToRgba(gridForegroundColor, "0.1"),
      }}
    >
      <p className="text-center inline">
        {showPrePurchase ? (
          <span
            style={{ color: hexToRgba(gridForegroundColor, "0.6"), ...Body }}
          >
            {prePurchaseMessage}
          </span>
        ) : (
          <span
            style={{ color: hexToRgba(gridForegroundColor, "0.6"), ...Body }}
          >
            {beforeTimestampString}
            <span
              style={{
                color: gridForegroundColor,
                fontWeight: 600,
                minWidth: "5em",
              }}
            >
              {timestampString}
            </span>
            {afterTimestampString}
          </span>
        )}
      </p>
    </div>
  )
}

export default Footer
