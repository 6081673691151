import React from "react"
import ExperienceHeader from "./experience-header"
import EditorTabNavFullWidth from "./editor-tab-nav-fill-width"

const EditorPageTitleBar = () => (
  <nav className="bg-white border-gray-200">
    <ExperienceHeader />
    <EditorTabNavFullWidth />
  </nav>
)

export default EditorPageTitleBar
