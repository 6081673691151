import gql from "graphql-tag"

export const UPDATE_BRAND_MUTATION = gql`
  mutation UpdateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      errors
      brand {
        id
      }
    }
  }
`

export const PRESIGN_UPLOAD_URL_MUTATION = gql`
  mutation PresignPublicAssetUploadUrl(
    $input: PresignPublicAssetUploadUrlInput!
  ) {
    presignPublicAssetUploadUrl(input: $input) {
      url
    }
  }
`
