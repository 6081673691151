import React from "react"
import { useEditor } from "contexts/experience-editor"
import Iphone12Container from "components/containers/legacy-iphone12"
import FeaturedImageVideoBackground from "./featured-image-video-background"
import SECTION_TYPES from "../../section-types"

const Preview = () => {
  const { currentExperience, currentSection } = useEditor()

  return (
    <>
      {currentSection?.experienceSectionId === "AppClipCard" ? (
        React.createElement(SECTION_TYPES.AppClipCard.preview, {
          experienceSectionDetails: currentSection.experienceSectionDetails,
        })
      ) : (
        <Iphone12Container>
          <FeaturedImageVideoBackground
            backgroundImageSrc={currentExperience.backgroundImageSrc}
            backgroundVideoSrc={currentExperience.backgroundVideoSrc}
          >
            {currentExperience.experienceSections.map(
              ({
                experienceSectionDetailsType,
                experienceSectionDetailsId,
                experienceSectionDetails,
              }) =>
                React.createElement(
                  SECTION_TYPES[experienceSectionDetailsType].preview,
                  {
                    key: experienceSectionDetailsId,
                    experienceSectionDetails,
                  }
                )
            )}
          </FeaturedImageVideoBackground>
        </Iphone12Container>
      )}
    </>
  )
}

export default Preview
