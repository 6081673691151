import React, { useState } from "react"
import { Form } from "formik"
import * as Yup from "yup"
import { PencilIcon } from "@heroicons/react/outline"

import BatchForm from "components/batch-form"
import FormikInput from "components/forms/formik-input"
import AgeVerification from "components/forms/age-verification"
import Textarea from "components/forms/textarea"
import SmallFormModal, {
  ModalFooter,
  ModalBody,
} from "components/small-form-modal"
import SubmitButton from "components/submit-button"
import Button from "components/button"
import { UPDATE_PRODUCT_VARIANT_MUTATION } from "./queries"

const EditProductVariant = ({ visible, onClose, variant }) => {
  const { id, standaloneTitle, subtitle, price, description, product } = variant
  const [ageToggle, setAgeToggle] = useState(product?.ageVerification !== null)

  if (!product) return null
  const { ageVerification } = product

  const formSchema = Yup.object().shape({
    productVariantId: Yup.string().required("Required"),
    standaloneTitle: Yup.string().required("Required"),
    subtitle: Yup.string().required("Required"),
    price: Yup.number().required("Required"),
    description: Yup.string().required("Required"),
  })

  if (!visible) {
    return null
  }
  return (
    <SmallFormModal onClose={onClose} visible={visible}>
      {({ close }) => (
        <main>
          <BatchForm
            formSchema={formSchema}
            generateValues={(values) => ({
              ...values,
              price: Math.round(parseFloat(values.price) * 100) || null,
              ageVerification: ageToggle
                ? parseInt(values.ageVerification)
                : null,
            })}
            initialValues={{
              productVariantId: id,
              standaloneTitle,
              subtitle,
              description,
              price: price / 100, // originally in cents, shown in dollars
              ageVerification,
            }}
            mutation={UPDATE_PRODUCT_VARIANT_MUTATION}
            onSuccess={() => onClose()}
          >
            {({ isSubmitting }) => (
              <Form>
                <ModalBody
                  description="Modify the details for a product variant."
                  icon={
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10 text-yellow-700">
                      <PencilIcon className="h-6 w-6" />
                    </div>
                  }
                  inputFields={[
                    <FormikInput
                      key="standaloneTitle"
                      autoFocus
                      label="Catalog Title"
                      name="standaloneTitle"
                      placeholder="Product Variant"
                      type="text"
                    />,
                    <FormikInput
                      key="subtitle"
                      label="Catalog Subtitle"
                      name="subtitle"
                      placeholder="Subtitle"
                      type="text"
                    />,
                    <FormikInput
                      key="price"
                      label="Price in Dollars"
                      name="price"
                      placeholder="15.00"
                      step=".01"
                      type="number"
                    />,
                    <Textarea
                      key="description"
                      label="Product Description"
                      name="description"
                      placeholder="Product Description"
                    />,
                    <AgeVerification
                      key="ageVerification"
                      ageToggle={ageToggle}
                      initialValue={ageVerification}
                      setAgeToggle={setAgeToggle}
                    />,
                  ]}
                  title="Edit a Product Variant"
                >
                  {/* <img
                    src={variant.imageSrcSmall}
                    width={100}
                    alt="Current variant."
                  /> */}
                  {/* TODO: add uppy here */}
                </ModalBody>
                <ModalFooter>
                  <SubmitButton submitting={isSubmitting} />
                  <Button className="mr-2" onClick={close}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </BatchForm>
        </main>
      )}
    </SmallFormModal>
  )
}

export default EditProductVariant
