import React from "react"

// Helper component to render an input component or just the text
const TextOnly = ({ text, isTextOnly, children, color, suffix, prefix }) => {
  if (isTextOnly) {
    return (
      <div className={`flex items-center ${color || ""} overflow-hidden`}>
        <span className="truncate" data-testid="text-only">
          {text ? (
            <>
              {prefix}
              {text}
              {suffix}
            </>
          ) : (
            "Not Provided"
          )}
        </span>
      </div>
    )
  }

  return children
}

export default TextOnly
