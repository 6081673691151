import React, { useState } from "react"
import { useEditor } from "contexts/experience-editor"
import CloseableConfigSection from "components/closeable-config-section"
import GetQueryLoader from "components/get-query-loader"

import BatchForm from "components/batch-form"
import { Form } from "formik"
import SubmitButton from "components/submit-button"
import ProductSelector from "components/product-selector"
import {
  GET_PRODUCT_VARIANTS,
  UPDATE_FEATURED_PRODUCT_VARIANT_SECTION,
} from "./queries"

const FeaturedProductVariantSection = ({ experienceSectionDetails }) => {
  const {
    productVariant: currentProductVariant,
    id: experienceSectionDetailsId,
  } = experienceSectionDetails

  const [selected, setSelected] = useState(currentProductVariant)
  const { refetchExperience } = useEditor()

  return (
    <CloseableConfigSection
      subtitle="Customize the product featured in this experience."
      title="Featured Product"
    >
      <BatchForm
        generateValues={() => ({
          featuredProductVariantId: selected.id,
          experienceSectionFeaturedProductVariantDetailsId:
            experienceSectionDetailsId,
        })}
        mutation={UPDATE_FEATURED_PRODUCT_VARIANT_SECTION}
        onSuccess={() => refetchExperience()}
      >
        {({ isSubmitting }) => (
          <Form>
            <GetQueryLoader
              fetchPolicy="network-only"
              query={GET_PRODUCT_VARIANTS}
              queryVariables={{
                id: experienceSectionDetailsId,
              }}
            >
              {({ brand: { productVariants } }) => (
                <ProductSelector
                  products={productVariants}
                  selected={selected}
                  setSelected={setSelected}
                />
              )}
            </GetQueryLoader>
            <div className="mb-8" />
            <SubmitButton submitting={isSubmitting} />
          </Form>
        )}
      </BatchForm>
    </CloseableConfigSection>
  )
}

export default FeaturedProductVariantSection
