import React, { useEffect } from "react"

import { useMutation } from "@apollo/client"
import { useFormikContext } from "formik"
import { PREVIEW_BATCH_TAG } from "./queries"

const BatchTagPreview = () => {
  const { values } = useFormikContext() // Pull form values

  // remove name key from values object
  const { name: _, ...valuesWithoutName } = values

  const [previewBatchTagMutation, { data }] = useMutation(PREVIEW_BATCH_TAG, {
    variables: {
      input: {
        ...valuesWithoutName,
        batchTagCtaLineTwo:
          values.batchTagCtaLineTwo === "" ? null : values.batchTagCtaLineTwo,
        batchTagCtaLineOne:
          values.batchTagCtaLineOne === "" ? null : values.batchTagCtaLineOne,
      },
    },
  })

  useEffect(() => {
    previewBatchTagMutation()
  }, [previewBatchTagMutation, values])

  const svg = data?.previewBatchTag?.svg

  return (
    <div className="flex flex-col">
      <p className="font-bold text-sm text-center">Live Preview</p>
      <div
        className="flex justify-center"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: svg }}
      />
    </div>
  )
}

export default BatchTagPreview
