import React, { useState } from "react"
import Button from "components/button"
import CloseableConfigSection from "components/closeable-config-section"
import SelectProductVariantsModal from "./select-product-variants-modal"

import FormComponent from "./form"

const ProductVariantCatalogSection = ({
  experienceSectionDetails,
  onComplete,
}) => {
  const [modalVisible, setModalVisible] = useState(false)

  const showModal = () => setModalVisible(true)
  const hideModal = () => setModalVisible(false)

  return (
    <CloseableConfigSection
      ctaButton={<Button onClick={showModal}>Add</Button>}
      subtitle="Add additional products to offer to your customers."
      title="Related Products"
    >
      <FormComponent
        experienceSectionDetails={experienceSectionDetails}
        onComplete={onComplete}
      />
      <SelectProductVariantsModal
        experienceSectionDetailsId={experienceSectionDetails.id}
        onClose={hideModal}
        onComplete={onComplete}
        visible={modalVisible}
      />
    </CloseableConfigSection>
  )
}

export default ProductVariantCatalogSection
