import React from "react"

const CloseableConfigSection = ({
  title,
  subtitle,
  children,
  ctaButton = null,
}) => (
  <section className="px-6 py-6 border-b mb-10 bg-white rounded-lg shadow-lg flex flex-col w-full">
    <div className="flex justify-between">
      <div className="hover:text-purple-500 cursor-pointer">
        <h2 className="text-lg font-bold leading-tight tracking-tight">
          {title}
        </h2>
        <p className="text-gray-500 text-sm">{subtitle}</p>
      </div>
      {ctaButton}
    </div>
    <div className="mt-2 mb-5">{children}</div>
  </section>
)

export default CloseableConfigSection
