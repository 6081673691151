import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { Form } from "formik"
import * as Yup from "yup"
import { PlusIcon } from "@heroicons/react/outline"

import SmallFormModal, {
  ModalFooter,
  ModalBody,
} from "components/small-form-modal"
import BatchForm from "components/batch-form"
import SubmitButton from "components/submit-button"
import Select from "components/forms/select"
import ImagePicker from "components/image-picker"
import imageUploadNote from "components/image-upload-note"
import Button from "components/button"

import { ADD_NEW_BACKGROUND_TO_CARD_VARIATION_MUTATION } from "./queries"

const AddBackgroundModal = ({
  cardVariation,
  productVariantRefetch,
  onClose,
  visible,
}) => {
  const { id: cardVariationId } = cardVariation
  const { brandKey } = useParams()
  const [videoUrl, setVideoUrl] = useState(null)
  const [cutoutUrl, setCutoutUrl] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)

  const onMutationSuccess = () => {
    productVariantRefetch()
    onClose()
  }

  const formSchema = Yup.object().shape({
    cardVariationId: Yup.string().uuid().required(),
    brandKey: Yup.string().required(),
    backgroundType: Yup.string().required(),
    backgroundImageSrc: Yup.string().nullable(),
    backgroundCutoutSrc: Yup.string().nullable(),
    backgroundVideoSrc: Yup.string().nullable(),
  })

  return (
    <SmallFormModal onClose={onClose} visible={visible}>
      {({ close }) => (
        <main>
          <BatchForm
            formSchema={formSchema}
            generateValues={(values) => ({
              ...values,
              backgroundCutoutSrc: cutoutUrl,
              backgroundVideoSrc: videoUrl,
              backgroundImageSrc: imageUrl,
            })}
            initialValues={{
              cardVariationId,
              brandKey,
              backgroundType: "cutout",
            }}
            mutation={ADD_NEW_BACKGROUND_TO_CARD_VARIATION_MUTATION}
            onSuccess={() => onMutationSuccess()}
          >
            {({ isSubmitting }) => (
              <Form>
                <ModalBody
                  description="Add a new background to this card variation."
                  icon={
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10 text-yellow-700">
                      <PlusIcon className="h-6 w-6" />
                    </div>
                  }
                  inputFields={[
                    <Select
                      key="backgroundType"
                      label="Background Type"
                      name="backgroundType"
                      options={{
                        video: "Video",
                        cutout: "Cutout",
                        image: "Image",
                      }}
                    />,
                    <ImagePicker
                      key="backgroundVideoSrc"
                      allowedFileTypes={["video/mp4"]}
                      brandKey={brandKey}
                      imageUrl={videoUrl}
                      inputName="backgroundVideoSrc"
                      label="Background Video"
                      maxFileSize={null} // Defaults to 8MB.
                      onImageUploadComplete={(url) => setVideoUrl(url)}
                      preview
                      uploadFolder={`card_variations/${cardVariationId}/background_video`}
                    />,
                    <ImagePicker
                      key="backgroundCutoutSrc"
                      brandKey={brandKey}
                      imageUrl={cutoutUrl}
                      inputName="backgroundCutoutSrc"
                      label="Cutout Image"
                      note={imageUploadNote({ isExpanded: true })}
                      onImageUploadComplete={(url) => setCutoutUrl(url)}
                      preview
                      uploadFolder={`card_variations/${cardVariationId}/background_cutout`}
                    />,
                    <ImagePicker
                      key="backgroundImageSrc"
                      brandKey={brandKey}
                      imageUrl={imageUrl}
                      inputName="backgroundImageSrc"
                      label="Background Image"
                      note={imageUploadNote({ isExpanded: true })}
                      onImageUploadComplete={(url) => setImageUrl(url)}
                      preview
                      uploadFolder={`card_variations/${cardVariationId}/background_image`}
                    />,
                  ]}
                  title="Add a New Background"
                />
                <ModalFooter>
                  <SubmitButton submitting={isSubmitting} />
                  <Button className="mr-2" onClick={close}>
                    Cancel
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </BatchForm>
        </main>
      )}
    </SmallFormModal>
  )
}

export default AddBackgroundModal
