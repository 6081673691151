import React, { useState } from "react"
import * as Yup from "yup"
import ToggleForm from "components/toggle-form"
import FormikInput from "components/forms/formik-input"
import BatchForm from "components/batch-form"
import ImagePicker from "components/image-picker"
import { UPDATE_BRAND_GENERAL_MUTATION } from "./queries"

const formSchema = Yup.object().shape({
  key: Yup.string().required("Required"),
  homepage: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
})

const GeneralForm = ({
  refetchBrand,
  urlKey,
  homepage,
  name,
  faviconSrc,
  logoHorizontalSrc,
}) => {
  const [logoImageSrc, setLogoImageSrc] = useState(faviconSrc)
  const [horizontalSrc, setHorizontalSrc] = useState(logoHorizontalSrc)

  return (
    <BatchForm
      additionalValues={{
        logoHorizontalSrc,
        logoImageSrc,
      }}
      enableReinitialize
      formSchema={formSchema}
      initialValues={{
        key: urlKey,
        homepage,
        name,
      }}
      mutation={UPDATE_BRAND_GENERAL_MUTATION}
      onSuccess={refetchBrand}
    >
      {({ isSubmitting }) => (
        <ToggleForm isSubmitting={isSubmitting} title="General">
          {({ isOpen }) => (
            <>
              <FormikInput
                isTextOnly={!isOpen}
                label="Brand Name"
                name="name"
                placeholder="Awesomely"
                type="text"
              />
              <FormikInput
                disabled
                isTextOnly={!isOpen}
                label="Unique URL Key"
                name="key"
                placeholder="abcdef"
                type="text"
              />
              <FormikInput
                isTextOnly={!isOpen}
                label="Homepage"
                name="homepage"
                placeholder="https://store.com"
                type="text"
              />
              <ImagePicker
                imageUrl={logoImageSrc}
                inputName="logoImageSrc"
                isImageOnly={!isOpen}
                label="Favicon Logo"
                onImageUploadComplete={setLogoImageSrc}
                preview
                uploadFolder={`brand/${urlKey}/logo`}
              />
              <ImagePicker
                imageUrl={horizontalSrc}
                inputName="logoHorizontalSrc"
                isImageOnly={!isOpen}
                label="Horizontal Logo"
                onImageUploadComplete={setHorizontalSrc}
                preview
                uploadFolder={`brand/${urlKey}/horizontal-logo`}
              />
            </>
          )}
        </ToggleForm>
      )}
    </BatchForm>
  )
}

export default GeneralForm
