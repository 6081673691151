import React from "react"
import { useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"

import Toggle from "components/toggle"

import { SET_PLATFORM_ACCESS } from "./queries"

const PlatformAccessToggle = ({ brand, refetchBrand }) => {
  const { id, platformEnabled } = brand
  const { addToast } = useToasts()

  const [setPlatformAccess] = useMutation(SET_PLATFORM_ACCESS, {
    variables: {
      input: {
        brandId: id,
        platformEnabled: !platformEnabled,
      },
    },
    onError: (e) => {
      addToast(`Error occurred while toggling: ${e.message}`, {
        appearance: "error",
      })
      refetchBrand()
    },
    onCompleted: () => {
      addToast("Platform access successfully toggled", {
        appearance: "success",
      })
      refetchBrand()
    },
  })

  return (
    <div className="flex mb-2 space-x-2 items-center justify-end">
      <h2 className="font-bold">Platform Access Enabled</h2>
      <Toggle
        data-testid="checkbox"
        enabled={platformEnabled}
        setEnabled={() => setPlatformAccess()}
      />
    </div>
  )
}

export default PlatformAccessToggle
