import React, { useState } from "react"
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid"
import { Combobox } from "@headlessui/react"
import classnames from "classnames"
import { useField } from "formik"
import Error from "./error"
import HintText from "./input/hint-text"

// Expecting options to be an array of objects with the following properties:
// - id: the option's unique identifier
// - value: the option's display value
const ComboboxSelector = ({
  label,
  name,
  hint,
  options,
  disableAutocomplete,
  onChange: passedOnChange = null,
}) => {
  const [query, setQuery] = useState("")

  const filteredOptions =
    query === ""
      ? options
      : options.filter(
          (option) =>
            option.value.toLowerCase().includes(query.toLowerCase()) ||
            option.id.toLowerCase().includes(query.toLowerCase())
        )

  const generateSelectedOptionName = (optionId) => {
    const selectedOption = filteredOptions.find(
      (option) => option.id === optionId
    )

    if (!selectedOption) {
      return "Choose an option"
    }

    return `${selectedOption.value} [ID: ${selectedOption.id}]`
  }

  const [field, { touched, error }, { setValue, setTouched }] = useField(name)

  const onChange = (value) => {
    setTouched(true)
    setValue(value)
    if (passedOnChange) {
      passedOnChange(value)
    }
  }

  return (
    <div>
      <Combobox as="div" onChange={onChange} value={field.value}>
        <Combobox.Label className="block text-sm font-medium text-gray-700">
          {label}
        </Combobox.Label>
        <div className="relative mt-1">
          <Combobox.Input
            {...field}
            autoComplete={disableAutocomplete ? "off" : "on"}
            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
            displayValue={(optionId) => generateSelectedOptionName(optionId)}
            name={name}
            onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <SelectorIcon
              aria-hidden="true"
              className="h-5 w-5 text-gray-400"
            />
          </Combobox.Button>

          {filteredOptions.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredOptions.map((option) => (
                <Combobox.Option
                  key={option.id}
                  className={({ active }) =>
                    classnames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                  value={option.id}
                >
                  {({ active, selected }) => (
                    <>
                      <div className="flex flex-col">
                        <span
                          className={classnames(
                            "truncate",
                            selected && "font-semibold"
                          )}
                        >
                          {option.value}
                        </span>
                        <span
                          className={classnames(
                            "truncate text-gray-500",
                            active ? "text-indigo-200" : "text-gray-500"
                          )}
                        >
                          {option.id}
                        </span>
                      </div>

                      {selected && (
                        <span
                          className={classnames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-indigo-600"
                          )}
                        >
                          <CheckIcon aria-hidden="true" className="h-5 w-5" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
      <Error error={error} touched={touched} />
      <HintText hint={hint} />
    </div>
  )
}

export default ComboboxSelector
