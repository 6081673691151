import React from "react"
import { useField } from "formik"
import TextOnly from "components/text-only"
import Label from "./label"
import Error from "./error"
import HintText from "./input/hint-text"

const Select = ({
  name,
  noLabel,
  label,
  placeholder,
  disabled,
  defaultValue = null,
  options = {},
  onChange: passedOnChange = null,
  isTextOnly,
  hint,
  ...wrapperProps
}) => {
  const [field, { touched, error }, { setValue, setTouched }] = useField(name)

  const onChange = (e) => {
    const { value } = e.currentTarget
    setTouched()
    setValue(value)
    if (passedOnChange !== null) {
      passedOnChange(value)
    }
  }

  const inputElement = (
    <div
      className={`flex relative rounded-md shadow-sm border ${
        error ? "border-red-500" : "border-gray-300 "
      } ${disabled ? "bg-gray-100" : ""}`}
    >
      {/* An up arrow and a down arrow vertically stacked. */}
      <svg
        aria-hidden="true"
        className="absolute top-2 right-1 h-5 w-5 text-gray-400"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
          fillRule="evenodd"
        />
      </svg>
      <select
        className={`${
          disabled ? "cursor-not-allowed bg-gray-100" : ""
        } mt-1 block w-full py-1.5 px-3 pr-5 bg-white rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
        defaultValue={defaultValue}
        disabled={disabled}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={field.value}
      >
        {Object.entries(options).map(([optionValue, optionLabel]) => (
          <option key={optionValue} value={optionValue}>
            {optionLabel}
          </option>
        ))}
      </select>
    </div>
  )

  return (
    <div {...wrapperProps}>
      <Label displayName={label || name} inputName={name} noLabel={noLabel} />
      <TextOnly isTextOnly={isTextOnly} text={field.value}>
        {inputElement}
      </TextOnly>
      <Error error={error} touched={touched} />
      <HintText hint={hint} />
    </div>
  )
}

export default Select
