import React from "react"
import { useField } from "formik"

import ColorSelector from "components/forms/color-selector"
import DarkLightSelector from "components/forms/dark-light-selector"
import isColorLight from "utils/is-color-light"

const foregroundColorFromBackgroundLuminance = (value) =>
  isColorLight(value) ? "111111" : "FFFFFF"

const AutoForegroundSelector = ({
  backgroundColor,
  backgroundLabel,
  backgroundName,
  foregroundColor,
  foregroundName,
}) => {
  const [_, _2, { setValue }] = useField(foregroundName)

  const onChange = (_3, value) => {
    if (value.length !== 6) return
    const fgColor = foregroundColorFromBackgroundLuminance(value)

    setValue(fgColor)
  }

  return (
    <div className="flex items-center justify-start mb-4">
      <div>
        <ColorSelector
          color={backgroundColor}
          label={backgroundLabel}
          name={backgroundName}
          onChange={onChange}
        />
      </div>
      <DarkLightSelector
        className="ml-1 w-32"
        foregroundColor={foregroundColor}
        label={`${backgroundLabel} Text`}
        name={foregroundName}
      />
    </div>
  )
}
export default AutoForegroundSelector
