import ProductVariantCatalogSectionPreview from "app/brand/common/product-variant-catalog-section-preview"
import ProductVariantCatalogSectionEditor from "app/brand/common/product-variant-catalog-section-editor"

import FeaturedContentHeaderSectionPreview from "./workspace/experience-preview/featured-content-header-section"
import FeaturedContentHeaderSectionEditor from "./config/featured-content-header-section"

import FeaturedProductVariantSectionPreview from "./workspace/experience-preview/featured-product-variant-section"
import FeaturedProductVariantSectionEditor from "./config/featured-product-variant-section"

import OrderDiscountSectionPreview from "./workspace/experience-preview/order-discount-section"
import OrderDiscountSectionEditor from "./config/order-discount-section"

import LocationOrderDiscountSectionPreview from "./workspace/experience-preview/location-order-discount-section"
import LocationOrderDiscountSectionEditor from "./config/location-order-discount-section"

import AppClipCardPreview from "./workspace/card-preview"
import AppClipCardEditor from "./config/app-clip-card"

import AppClipCardBackgroundEditor from "./config/app-clip-background-section"

/*
When adding a new section, add the backend gql type as a key to SECTION_TYPES.

Each section's preview value should be that section's preview component
(i.e., within the iPhone frame)

Each section's editor value should be that section's editor component
*/

const SECTION_TYPES = {
  ExperienceSectionFeaturedContentHeaderDetail: {
    preview: FeaturedContentHeaderSectionPreview,
    editor: FeaturedContentHeaderSectionEditor,
  },
  ExperienceSectionFeaturedProductVariantDetail: {
    preview: FeaturedProductVariantSectionPreview,
    editor: FeaturedProductVariantSectionEditor,
  },
  ExperienceSectionOrderDiscountDetail: {
    preview: OrderDiscountSectionPreview,
    editor: OrderDiscountSectionEditor,
  },
  ExperienceSectionLocationOrderDiscountDetail: {
    preview: LocationOrderDiscountSectionPreview,
    editor: LocationOrderDiscountSectionEditor,
  },
  ExperienceSectionProductVariantCatalogDetail: {
    preview: ProductVariantCatalogSectionPreview,
    editor: ProductVariantCatalogSectionEditor,
  },
  AppClipCard: {
    editor: AppClipCardEditor,
    preview: AppClipCardPreview,
  },
  AppClipCardBackground: {
    editor: AppClipCardBackgroundEditor,
  },
}

export default SECTION_TYPES
