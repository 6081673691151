import React from "react"

const AdminOnlyWrapper = ({ children, sectionName = "Admin" }) => (
  <div className="border-2 border-dotted border-pink-400 px-2 pb-2 mb-6 w-64 mt-8">
    <div className="text-pink-500 text-xs font-medium mt-1 mb-2">
      {sectionName}
    </div>
    {children}
  </div>
)

export default AdminOnlyWrapper
