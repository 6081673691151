import gql from "graphql-tag"

const GET_BRAND_USERS = gql`
  query GetBrandUsers($brandKey: String!) {
    brand(brandKey: $brandKey) {
      id
      name
      shopifyDomain
      homepage
      platformEnabled
      brandUsers {
        id
        firstName
        lastName
        email
      }
    }
  }
`

const CREATE_BRAND_USER_MUTATION = gql`
  mutation CreateOrUpdateBrandUser($input: CreateOrUpdateBrandUserInput!) {
    createOrUpdateBrandUser(input: $input) {
      brandUser {
        id
      }
      errors
    }
  }
`

const DELETE_BRAND_USER = gql`
  mutation DeleteBrandUser($input: DeleteBrandUserInput!) {
    deleteBrandUser(input: $input) {
      errors
    }
  }
`

const SET_PLATFORM_ACCESS = gql`
  mutation SetPlatformAccess($input: SetPlatformAccessInput!) {
    setPlatformAccess(input: $input) {
      errors
    }
  }
`

export {
  GET_BRAND_USERS,
  DELETE_BRAND_USER,
  CREATE_BRAND_USER_MUTATION,
  SET_PLATFORM_ACCESS,
}
