import React from "react"
import Iphone12 from "assets/images/iphone-12--black.svg"
import Iphone12Inset from "assets/images/iphone-12--black--inset.svg"
import Iphone12Lock from "assets/images/iphone-12--black--lock.png"

// https://www.figma.com/file/OtzwpY5T7v8ZmmymtHUgc7/iPhone-12-Mockups-(Community)?node-id=4%3A884
const Iphone12Container = ({ children, lock, backgroundColor }) => (
  <div
    className="shadow-2xl"
    style={{
      position: "relative",
      width: "300px",
      height: "607px",
      borderRadius: "45px",
    }}
  >
    <img
      alt=""
      src={lock ? Iphone12Lock : Iphone12}
      style={{ position: "absolute" }}
    />
    <div
      style={{
        position: "absolute",
        top: "14px",
        bottom: "14px",
        left: "15px",
        right: "15px",
        padding: "0px",
        paddingTop: "20px",
        overflowY: "hidden",
        borderRadius: "28px",
        backgroundColor,
      }}
    >
      {children}
    </div>
    <img
      alt=""
      className="absolute z-10"
      src={Iphone12Inset}
      style={{ top: "14px", left: "76px" }}
    />
  </div>
)

export default Iphone12Container
