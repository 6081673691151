import React from "react"
import { Form } from "formik"
import * as Yup from "yup"

import BatchForm from "components/batch-form"
import { useEditor } from "contexts/experience-editor"
import SubmitButton from "components/submit-button"
import FormikInput from "components/forms/formik-input"
import Select from "components/forms/select"

import { UPDATE_LOCATION_DISCOUNT_SECTION_MUTATION } from "./queries"

const DiscountSection = ({ experienceSectionDetails }) => {
  const { refetchExperience } = useEditor()
  const {
    id,
    discountMethod,
    discountMagnitude,
    subtitle,
    title,
    latitude,
    longitude,
    timeLimit,
  } = experienceSectionDetails

  const formSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    subtitle: Yup.string().required("Required"),
    discountMethod: Yup.string().required("Required"),
    discountMagnitude: Yup.number().required("Required"),
    latitude: Yup.number().max(90).min(-90).required("Required"),
    longitude: Yup.number().max(180).min(-180).required("Required"),
    timeLimit: Yup.number()
      .positive("Time Limit must be positive or blank.")
      .integer()
      .nullable()
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
  })

  return (
    <BatchForm
      generateValues={(values) => ({
        // Yup doesn't parse strings that look like floats to floats,
        // so we do it manually here.
        experienceSectionLocationOrderDiscountDetailsId: id,
        ...values,
        latitude: parseFloat(values.latitude) || null,
        longitude: parseFloat(values.longitude) || null,
      })}
      initialValues={{
        title,
        subtitle,
        discountMethod,
        discountMagnitude:
          discountMethod === "percentage"
            ? discountMagnitude
            : discountMagnitude / 100,
        latitude,
        longitude,
        // timeLimit is in seconds: allow nulls and show the value in minutes
        timeLimit: timeLimit === null ? null : timeLimit / 60,
      }}
      mutation={UPDATE_LOCATION_DISCOUNT_SECTION_MUTATION}
      onSuccess={() => refetchExperience()}
      validationSchema={formSchema}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <div className="grid grid-cols-8">
            <div className="col-span-6 sm:col-span-4 mb-4">
              <FormikInput
                autoFocus
                label="Title"
                name="title"
                placeholder="Unlock Your Discount"
                type="text"
              />
            </div>
            <div className="col-span-2" />
            <div className="col-span-6 mb-4">
              <FormikInput
                autoFocus
                label="Subtitle"
                name="subtitle"
                placeholder="Get 10% off your order"
                type="text"
              />
            </div>
            <div className="col-span-2" />
            <div className="col-span-6 mb-4">
              <Select
                autoFocus
                label="Discount Type"
                name="discountMethod"
                options={{
                  percent: "Percentage",
                  dollar: "Dollar Amount",
                }}
                placeholder="Percentage"
                type="text"
              />
            </div>
            <div className="col-span-2" />
            <div className="col-span-3 mb-4">
              <FormikInput
                autoFocus
                label="Discount Amount"
                mask={parseInt}
                name="discountMagnitude"
                placeholder={
                  values.discountMethod === "percentage" ? "10" : "5.00"
                }
                suffix="%"
                type="number"
              />
            </div>
            <div className="col-span-2" />
            <div className="col-span-6 mb-4">
              <FormikInput
                autoFocus
                label="Latitude"
                name="latitude"
                placeholder="40.718832"
                step="any"
                type="number"
              />
            </div>
            <div className="col-span-2" />
            <div className="col-span-6 mb-4">
              <FormikInput
                autoFocus
                label="Longitude"
                name="longitude"
                placeholder="-74.002616"
                step="any"
                type="number"
              />
            </div>
            <div className="col-span-2" />
            <div className="col-span-6 mb-4">
              <FormikInput
                autoFocus
                label="Time Limit (in minutes)"
                mask={parseInt}
                name="timeLimit"
                placeholder="5"
                type="number"
              />
            </div>
            <div className="col-span-2" />
          </div>
          <SubmitButton submitting={isSubmitting} />
        </Form>
      )}
    </BatchForm>
  )
}

export default DiscountSection
