import React from "react"

import {
  CollectionIcon,
  ChatIcon,
  StarIcon,
  LocationMarkerIcon,
} from "@heroicons/react/solid"

const SECTION_COPY = {
  ExperienceSectionFeaturedProductVariantDetail: {
    title: "Featured Product",
    subtitle: "Customize the product featured in this experience.",
    icon: <StarIcon className="h-5 w-5" />,
  },
  ExperienceSectionOrderDiscountDetail: {
    title: "Phone Number Discount",
    subtitle: "Customize the discount you offer to customers.",
    icon: <ChatIcon className="h-5 w-5" />,
    // toggle: true, // placeholder, TODO: incorporate these
  },
  ExperienceSectionLocationOrderDiscountDetail: {
    title: "Location Discount",
    subtitle: "Offer a location discount to your customers.",
    icon: <LocationMarkerIcon className="h-5 w-5" />,
  },
  ExperienceSectionProductVariantCatalogDetail: {
    title: "Product Catalog",
    subtitle: "Add additional products to offer to your customers.",
    icon: <CollectionIcon className="h-5 w-5" />,
    // toggle: false, // placeholder, TODO: incorporate these
  },
  ExperienceSectionFeaturedContentHeaderDetail: {
    title: "Featured Content",
    subtitle: "Add a header experience with featured content.",
    icon: <StarIcon className="h-5 w-5" />,
  },
}

export default SECTION_COPY
