import React, { useState, useEffect } from "react"
import { Form } from "formik"
import { PlusCircleIcon } from "@heroicons/react/outline"

import BatchForm from "components/batch-form"
import Button from "components/button"
import SubmitButton from "components/submit-button"
import { ModalFooter, ModalBody } from "components/small-form-modal"

import { UPDATE_MODAL_PRODUCT_VARIANTS } from "./queries"
import ProductVariantSelectItem from "./product-variant-select-item"

const SelectProductVariantsModalInner = ({
  close,
  excludedVariants,
  experienceSectionDetailsId,
  onClose,
  onComplete,
  variants,
}) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    const allVariants = variants
      .map((v) => ({
        originalStatus: true,
        status: true,
        data: v,
      }))
      .concat(
        excludedVariants.map((ev) => ({
          originalStatus: false,
          status: false,
          data: ev,
        }))
      )
    setOptions(allVariants)
  }, [variants, excludedVariants])

  const toggleSelected = (optionToToggle) => {
    setOptions(
      options.map((option) => {
        /* eslint-disable no-param-reassign */
        if (option.data.id === optionToToggle.data.id) {
          // toggle the status
          option.status = !option.status
        }
        return option
      })
    )
  }

  return (
    <BatchForm
      generateValues={() => {
        const allValues = options
          // We only want to pass the toggled values to the mutation.
          .filter((v) => v.status !== v.originalStatus)
        return {
          experienceSectionProductVariantCatalogDetailsId:
            experienceSectionDetailsId,
          include: allValues
            .filter((v) => v.status === true)
            .map((v) => v.data.id),
          exclude: allValues
            .filter((v) => v.status === false)
            .map((v) => v.data.id),
        }
      }}
      initialValues={{
        productUrl: "",
      }}
      mutation={UPDATE_MODAL_PRODUCT_VARIANTS}
      onSuccess={() => {
        onComplete()
        onClose()
      }}
    >
      <Form>
        <ModalBody
          description={
            <>
              Select which variants to include in the product catalog section.
            </>
          }
          icon={
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
              <PlusCircleIcon className="h-6 w-6 text-blue-600" />
            </div>
          }
          title="Manage Product Catalog"
        >
          <div className="col-span-6 sm:col-span-4">
            <fieldset>
              {options.map((option) => (
                <ProductVariantSelectItem
                  key={`${option.data.standaloneTitle}-${option.data.subtitle}-${option.data.price}`}
                  option={option}
                  toggleSelected={toggleSelected}
                />
              ))}
            </fieldset>
          </div>
        </ModalBody>
        <ModalFooter>
          <SubmitButton />
          <Button className="mr-2" onClick={close}>
            Cancel
          </Button>
        </ModalFooter>
      </Form>
    </BatchForm>
  )
}

export default SelectProductVariantsModalInner
