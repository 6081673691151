import React, { useState, useRef } from "react"
import { useMutation } from "@apollo/client"
import AdminOnlyWrapper from "components/admin-only-wrapper"
import { LOGIN_WITH_PASSWORD_MUTATION } from "./queries"

const PasswordLoginForm = ({ onSuccess }) => {
  const [loginError, setLoginError] = useState(null)
  const passwordInput = useRef(null)

  const onFailure = () => {
    setLoginError("Login failed")
  }

  const [loginBackendWithPassword, { loading }] = useMutation(
    LOGIN_WITH_PASSWORD_MUTATION,
    {
      onError: onFailure,
      onCompleted: ({ loginWithPassword }) => onSuccess(loginWithPassword.user),
    }
  )

  const onFormSubmit = async (event) => {
    event.preventDefault()

    await loginBackendWithPassword({
      variables: {
        input: {
          password: passwordInput.current.value,
        },
      },
    })
  }

  const getCopy = () => (loading ? "Loading" : "Sign in")

  return (
    <AdminOnlyWrapper sectionName="Staging / Review Login">
      <form onSubmit={onFormSubmit}>
        <div className="mb-2">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="password"
          >
            Password
            <div className="mt-1">
              <input
                ref={passwordInput}
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                id="password"
                required
                type="password"
              />
            </div>
          </label>
        </div>

        <div>
          <button
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            type="submit"
          >
            {getCopy()}
          </button>
        </div>
      </form>

      {loginError && (
        <p className="mt-2 text-center max-w text-red-700 text-sm">
          {loginError}
        </p>
      )}
    </AdminOnlyWrapper>
  )
}

export default PasswordLoginForm
