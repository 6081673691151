import { ChevronDownIcon } from "@heroicons/react/outline"
import React from "react"
import Selector from "./selector"

// const VariationSelector = ({ variations, currentVariation }) => {
const VariationSelector = ({ currentVariation, color }) => {
  const { variationValue: title } = currentVariation
  // TODO: choose which field populates the dropdown
  // TODO: add variant dropdown from variants prop
  return (
    <Selector borderColor={color}>
      <div className="w-full flex items-center justify-between min-w-0">
        <span className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {title}
        </span>
        <ChevronDownIcon className="w-5 h-5 flex-shrink-0" />
      </div>
    </Selector>
  )
}

export default VariationSelector
