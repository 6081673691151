import React from "react"
import { useEditor } from "contexts/experience-editor"

const TopNav = () => {
  const {
    currentExperience: { brand },
  } = useEditor()

  return (
    <div className="w-full mt-8 px-2">
      <div
        className="flex flex-row items-center rounded-full relative w-28 px-2 py-2"
        id="chip"
      >
        <div
          className="z-0 rounded-full bg-gray-100 opacity-60 absolute top-0 right-0 left-0 bottom-0"
          id="chipBackground"
        />
        <img
          alt={`${brand.name} logo.`}
          className="rounded-full w-6 h-6 z-10 ring-1 ring-gray-500 mr-2 bg-white"
          src={brand.logoImageSrc}
        />
        <div className="flex flex-col z-10">
          <p
            className="font-light text-gray-400 tracking-tight"
            style={{ fontSize: "0.5rem", lineHeight: "0.5rem" }}
          >
            Offered By
          </p>
          <p
            className="text-xs text-black font-medium leading-tight mt-1"
            style={{ fontSize: "0.5rem", lineHeight: "0.5rem" }}
          >
            {brand.name}
          </p>
        </div>
      </div>
    </div>
  )
}

export default TopNav
