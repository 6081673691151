import React from "react"
import { Link, useParams } from "react-router-dom"

const CardExperienceItem = ({ id, internalNickname }) => {
  const { brandKey } = useParams()

  const handleClick = (e) => {
    e.stopPropagation()
    return e
  }

  return (
    <Link
      className="text-sm text-blue-500 block overflow-ellipsis overflow-hidden mb-1"
      onClick={handleClick}
      target="_blank"
      title={internalNickname}
      to={`/brands/${brandKey}/experiences/${id}`}
    >
      {internalNickname || id?.split("-")[0]}
    </Link>
  )
}

export default CardExperienceItem
