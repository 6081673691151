/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React from "react"

import GetQueryLoader from "components/get-query-loader"
import ActivationsList from "./list"
import { GET_BRAND } from "./queries"

const ActivationIndex = () => (
  <GetQueryLoader fetchPolicy="network-only" query={GET_BRAND}>
    {({ brand }, refetchBrand) => (
      <ActivationsList brand={brand} refetchBrand={refetchBrand} />
    )}
  </GetQueryLoader>
)

export default ActivationIndex
