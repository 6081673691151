/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import { useMutation } from "@apollo/client"
import { QrcodeIcon } from "@heroicons/react/solid"
import { UPDATE_BRAND_MUTATION } from "app/admin/core-app-index/select-experience-drawer/experience-card/queries"
import Alert from "components/alert"
import IndexTable from "components/index-table"
import Toggle from "components/toggle"
import React, { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useToasts } from "react-toast-notifications"
import sortAlphabetically from "utils/sort-alphabetically"

import { UPDATE_CARD_EXPERIENCE } from "../queries"

const List = ({ onAddExperience, refetchExperiences, brand }) => {
  const { cardExperiences, coreAppCardExperience, key: brandKey } = brand
  const location = useLocation()

  const [hasDeletedProductVariantError] = useState(
    cardExperiences.find((ce) =>
      ce.productVariants.find((pv) => pv.deletedFromShopify)
    )
  )

  const tableData = cardExperiences
    .map((experience) => {
      const {
        id,
        name,
        logoHorizontalSrc,
        batchForWebEnabled,
        notificationsFeature,
        activations,
        cardExperienceCards,
        internalNickname,
        hidden,
      } = experience
      const linkDestination = `${location.pathname}/${id}`
      const firstCard = cardExperienceCards?.filter(
        (card) => card.ordinal === 1
      )[0]?.card
      const imageSrc =
        firstCard?.collapsedBackgroundImageSrc || logoHorizontalSrc

      const deletedProductVariant =
        experience.productVariants.find((pv) => pv.deletedFromShopify) !==
        undefined

      return {
        id,
        name,
        internalNickname,
        linkDestination,
        activations,
        imageSrc,
        batchForWebEnabled,
        notificationsFeature,
        deletedProductVariant,
        hidden,
      }
    })
    .sort(sortAlphabetically)
    .sort((e) => e.deletedProductVariant)

  const history = useHistory()
  const { addToast } = useToasts()

  const [executeBrandMutation] = useMutation(UPDATE_BRAND_MUTATION, {
    onError: () => {
      addToast("Something went wrong, changes did not save!", {
        appearance: "error",
      })
    },
    onCompleted: () => {
      refetchExperiences()
      addToast("Saved Successfully", { appearance: "success" })
    },
  })

  const [executeCardExperienceMutation] = useMutation(UPDATE_CARD_EXPERIENCE, {
    onError: () => {
      addToast("Something went wrong, changes did not save!", {
        appearance: "error",
      })
    },
    onCompleted: () => {
      refetchExperiences()
      addToast("Saved Successfully", { appearance: "success" })
    },
  })

  const handleCoreAppChange = ({ isInCoreApp, cardExperienceId }) => {
    executeBrandMutation({
      variables: {
        input: {
          brandId: brand.id,
          name: brand.name,
          homepage: brand.homepage,
          shopifyDomain: brand.shopifyDomain,
          coreAppCardExperienceId: isInCoreApp ? null : cardExperienceId,
        },
      },
    })
  }

  const handleVisibilityChange = ({ cardExperienceId, hidden }) => {
    executeCardExperienceMutation({
      variables: {
        input: {
          brandKey,
          cardExperienceId,
          hidden,
        },
      },
    })
  }

  return (
    <IndexTable
      columns={[
        {
          title: "First Card Image",
          field: "firstCardImage",
          filtering: false,
          cellStyle: { whiteSpace: "nowrap", width: "20%" },
          render: ({ imageSrc }) => (
            <div
              alt=""
              className="h-40 bg-cover w-60"
              style={{
                backgroundImage: `url(${imageSrc})`,
                border: "1px solid #e5e5e5",
              }}
            />
          ),
        },
        {
          title: "ID & Names",
          field: "idNames",
          cellStyle: { whiteSpace: "nowrap", width: "20%" },
          customFilterAndSearch: (term, row) =>
            row.id.toLowerCase().includes(term.toLowerCase()) ||
            row.name.toLowerCase().includes(term.toLowerCase()) ||
            (row.internalNickname &&
              row.internalNickname.toLowerCase().includes(term.toLowerCase())),
          render: ({ id, name, internalNickname, deletedProductVariant }) => (
            <>
              <p className="text-xs font-medium leading-6 text-gray-600">
                {id}
              </p>
              {internalNickname && (
                <>
                  <span className="text-xs font-light">Internal Nickname</span>
                  <p>{internalNickname}</p>
                </>
              )}
              <span className="text-xs font-light">iMessage Share Title</span>
              <p>{name}</p>
              {deletedProductVariant && (
                <span className="px-2 py-1 my-4 text-xs font-medium text-gray-800 bg-red-100 rounded-sm">
                  Products Removed
                </span>
              )}
            </>
          ),
        },
        {
          title: "Batch for Web / Android",
          field: "batchForWebEnabled",
          cellStyle: { whiteSpace: "nowrap", width: "15%" },
          filtering: false,
          render: ({ batchForWebEnabled }) => (
            <p>{batchForWebEnabled ? "Enabled" : "Disabled"}</p>
          ),
        },
        {
          title: "Notifications",
          field: "notificationsFeature",
          cellStyle: { whiteSpace: "nowrap", width: "15%" },
          filtering: false,
          render: ({ notificationsFeature }) => (
            <p>{notificationsFeature ? "Enabled" : "Disabled"}</p>
          ),
        },
        {
          title: "Core App",
          cellStyle: { whiteSpace: "nowrap", width: "10%" },
          disableClick: true,
          render: ({ id }) => (
            <Toggle
              data-testid="checkbox"
              enabled={coreAppCardExperience?.id === id}
              setEnabled={() =>
                handleCoreAppChange({
                  isInCoreApp: coreAppCardExperience?.id === id,
                  cardExperienceId: id,
                })
              }
            />
          ),
        },
        {
          title: "Hidden on Platform",
          cellStyle: { whiteSpace: "nowrap", width: "10%" },
          disableClick: true,
          render: ({ id, hidden }) => {
            const color = hidden ? "bg-red-100" : "bg-green-100"
            const label = hidden ? "Hidden" : "Visible"

            return (
              <div>
                <div
                  className={`flex justify-center px-2 py-1 mr-1 mb-1 text-gray-800 text-xs font-medium rounded-sm ${color}`}
                >
                  {label}
                </div>
                <div className="flex justify-center">
                  <Toggle
                    enabled={hidden}
                    setEnabled={() =>
                      handleVisibilityChange({
                        cardExperienceId: id,
                        hidden: !hidden,
                      })
                    }
                  />
                </div>
              </div>
            )
          },
        },
        {
          title: <QrcodeIcon className="w-5 h-5" />,
          field: "qr codes",
          filtering: false,
          align: "center",
          cellStyle: { whiteSpace: "nowrap", width: "10%" },
          render: ({ activations }) => (
            <p className="flex items-center justify-center text-lg truncate">
              <QrcodeIcon className="w-5 h-5" />
              <span className="ml-1">{activations.length}</span>
            </p>
          ),
        },
      ]}
      ctaText="Create New Experience"
      data={tableData}
      deletedProductVariantError={
        hasDeletedProductVariantError && (
          <div className="my-4">
            <Alert message="Some experiences have product variants that has been deleted from shopify and need attention" />
          </div>
        )
      }
      onCtaClick={onAddExperience}
      onRowClick={(_, rowData) => {
        history.push(rowData.linkDestination)
      }}
      title="Experiences"
    />
  )
}

export default List
