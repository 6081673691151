import React from "react"

import { CREATE_BATCH_TAG } from "./queries"
import BaseBatchTagModal from "./base-batch-tag-modal"

const CreateBatchTagModal = ({ cardExperience, onSuccess, onClose, show }) => {
  if (!show) return null

  return (
    <BaseBatchTagModal
      cardExperience={cardExperience}
      cta="Create"
      includeCardExperienceId
      migration={CREATE_BATCH_TAG}
      onClose={onClose}
      onSuccess={onSuccess}
      show={show}
      title={<>Create a new Batch Tag</>}
    />
  )
}

export default CreateBatchTagModal
