import AppNav from "components/app-nav"
import React from "react"

import BrandNav from "components/brand-nav"
import GetQueryLoader from "components/get-query-loader"
import { Helmet } from "react-helmet"
import Container from "components/layout/container"
import BrandRouter from "./brand-router"
import GET_BRAND from "./queries"

const Brand = () => (
  <>
    <AppNav />
    <GetQueryLoader fetchPolicy="network-only" query={GET_BRAND}>
      {({ brand }) => (
        <>
          <Helmet>
            <title>{brand.name}</title>
          </Helmet>
          <BrandNav
            brandName={brand.name}
            sharePageSecret={brand.sharePageSecret}
          />
          <Container>
            <BrandRouter />
          </Container>
        </>
      )}
    </GetQueryLoader>
  </>
)

export default Brand
