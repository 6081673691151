import React from "react"
import ActivationDemo from "components/activation-demo"

const TestQRCode = ({ activation }) => (
  <>
    <div className="ml-8 flex flex-row justify-between items-center w-full mb-3 px-1">
      <h3 className="text-xl font-semibold leading-tight text-gray-900">
        Test QR Code
      </h3>
    </div>
    <div className="ml-8 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <div className="bg-white py-6 px-4 sm:p-6 text-center flex flex-col items-center">
        <ActivationDemo activation={activation} size={48} />
      </div>
    </div>
  </>
)

export default TestQRCode
