import React, { useState, useEffect } from "react"
import { MEASUREMENTS as Iphone11ProMaxMeasurements } from "components/containers/iphone-11-pro-max"

const PreviewWorkspace = ({ children, extras, colSpan }) => {
  const [zoom, setZoom] = useState(1)

  const handleWindowResize = () => {
    const calculatedZoom = Math.min(
      (window.innerHeight -
        130 - // top nav height
        100) / // buffer
        Iphone11ProMaxMeasurements.root.height, // tallest iphone container size
      1.2
    )

    setZoom(calculatedZoom)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize)
    handleWindowResize() // run once

    return () => {
      // Not sure why this line is throwing a linting error...
      // eslint-disable-next-line no-unused-vars
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  return (
    <div
      className={`bg-gray-50 dark:bg-dark-900 flex flex-col items-center col-span-${colSpan} h-full p-4 overflow-y-scroll`}
    >
      <div className="flex flex-row justify-center items-center py-2">
        <p className="text-gray-500 text-xs font-medium">iPhone 11 Pro Max</p>
      </div>
      <div className="flex items-center justify-center" style={{ zoom }}>
        {children}
      </div>
      {extras}
    </div>
  )
}

export default PreviewWorkspace
