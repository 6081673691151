import React from "react"
import GetQueryLoader from "components/get-query-loader"
import BrandEdit from "./brand-edit"
import { GET_BRAND } from "./queries"

const BrandShow = () => (
  <>
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <GetQueryLoader query={GET_BRAND}>
            {({ brand }, refetch) => (
              <BrandEdit brand={brand} refetchBrand={refetch} />
            )}
          </GetQueryLoader>
        </div>
      </div>
    </div>
  </>
)

export default BrandShow
