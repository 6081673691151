import React from "react"

import CloseableConfigSection from "components/closeable-config-section"
import Form from "./form"

const FeaturedProductVariantSection = ({ experienceSectionDetails }) => (
  <CloseableConfigSection
    subtitle="Add a header experience with featured content."
    title="Featured Content"
  >
    <Form experienceSectionDetails={experienceSectionDetails} />
  </CloseableConfigSection>
)

export default FeaturedProductVariantSection
