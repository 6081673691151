import React from "react"
import { useEditor } from "contexts/experience-editor"
import BatchAppStoreIcon from "assets/images/batch-shopify-app-icon-noborder.png"
import { ReactComponent as AppleAppStoreIcon } from "assets/icons/app-store.svg"
import Iphone12Container from "components/containers/legacy-iphone12"

const Preview = () => {
  const { currentExperience } = useEditor()

  const { appClipCardTitle, appClipCardSubtitle, appClipCardImageSrc } =
    currentExperience

  return (
    <Iphone12Container lock>
      <div
        className="bg-gray-100 shadow-md"
        style={{
          position: "absolute",
          bottom: "4px",
          left: "6px",
          right: "6px",
          borderRadius: "32px",
          height: "280px",
          overflow: "hidden",
        }}
      >
        <div className="flex flex-col items-center">
          <div className="bg-white h-40 w-full">
            <img alt="App clip card." src={appClipCardImageSrc} />
          </div>
          <div className="pt-4 pb-4 px-3 flex flex-row justify-between items-start h-18 w-full">
            <div className="flex flex-col">
              <h2
                className="font-semibold text-sm tracking-tight mb-1"
                style={{ lineHeight: 1.1 }}
              >
                {appClipCardTitle}
              </h2>
              <h3
                className="font-medium text-xs tracking-tight"
                style={{ fontSize: "0.6rem", lineHeight: "0.75rem" }}
              >
                {appClipCardSubtitle}
              </h3>
            </div>
            <div>
              <p
                className="font-semibold px-5 py-2 text-white text-xs rounded-full"
                style={{ background: "#3B92F7" }}
              >
                Open
              </p>
            </div>
          </div>
          <div className="px-3 w-full flex flex-row justify-between items-center">
            <div className="w-full border-t py-2 flex justify-between items-center">
              <div className="flex flex-row items-start">
                <img
                  alt="Batch app store icon."
                  className="h-5 w-5 rounded-md border"
                  src={BatchAppStoreIcon}
                />
                <div className="flex flex-col pl-2">
                  <p
                    className="text-xs text-gray-400 font-light tracking-tight"
                    style={{ fontSize: "0.5rem", lineHeight: "0.75rem" }}
                  >
                    Powered By
                  </p>
                  <p
                    className="text-xs text-gray-700 tracking-tight font-medium"
                    style={{ fontSize: "0.65rem", lineHeight: "0.75rem" }}
                  >
                    Batch: Reorder Instantly
                  </p>
                </div>
              </div>
              <div className="flex flex-col">
                {/* }<span className='text-gray-400 border border-gray-400 rounded' style={{
                  fontSize: '8px',
                  lineHeight: '10px',
                  paddingLeft: '2px',
                  paddingRight: '2px',
                  height: '0.8rem',
                  width: '1rem',
                }}>4+</span> */}
                <p
                  className="mr-1 text-xs text-gray-400"
                  style={{ fontSize: "0.5rem", lineHeight: "0.75rem" }}
                >
                  <AppleAppStoreIcon className="h-3 w-3 inline mr-1 text-gray-400" />
                  App Store &rsaquo;
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Iphone12Container>
  )
}

export default Preview
