import React from "react"
import { useField } from "formik"
import TextOnly from "components/text-only"
import Label from "./label"
import Error from "./error"

const Textarea = ({
  name,
  noLabel,
  label,
  placeholder,
  disabled,
  rows = 2,
  isTextOnly,
  wrapperProps,
}) => {
  const [field, { touched, error }, { setValue }] = useField(name)

  return (
    <div {...wrapperProps}>
      <Label displayName={label || name} inputName={name} noLabel={noLabel} />
      <div className="flex relative rounded-md shadow-sm">
        <TextOnly isTextOnly={isTextOnly} text={field.value}>
          <textarea
            className={`${disabled ? "cursor-not-allowed" : ""} ${
              error ? "border-red-500" : "border-gray-300 "
            } form-textarea block w-full rounded-md shadow-sm py-2 px-3 focus:outline-none dark:bg-dark-900 dark:text-dark-200 focus:ring-gray-900 border sm:text-sm`}
            name={name}
            onChange={(e) => setValue(e.currentTarget.value)}
            placeholder={placeholder}
            rows={rows}
            value={field.value}
          />
        </TextOnly>
      </div>
      <Error error={error} touched={touched} />
    </div>
  )
}

export default Textarea
