import React from "react"
import GetQueryLoader from "components/get-query-loader"
import useDisclosure from "hooks/use-disclosure"
import CreateExperienceModal from "./create-experience-modal"
import { GET_EXPERIENCES } from "./queries"
import List from "./list"

const Catalog = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <GetQueryLoader fetchPolicy="network-only" query={GET_EXPERIENCES}>
      {({ brand }, refetchExperiences) => (
        <main>
          <List
            brand={brand}
            onAddExperience={onOpen}
            refetchExperiences={refetchExperiences}
          />
          <CreateExperienceModal
            onClose={onClose}
            refetchExperiences={refetchExperiences}
            visible={isOpen}
          />
        </main>
      )}
    </GetQueryLoader>
  )
}
export default Catalog
