import React, { useState, useEffect } from "react"
import classNames from "classnames"
import { useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { useParams } from "react-router-dom"
import Alert from "components/alert"
import AddVariationModal from "./add-variation-modal"
import CardVariationDetail from "./card-variation-detail"

import { REORDER_CARD_VARIATIONS, DELETE_CARD_VARIATION } from "./queries"

const CardVariationConfigurator = ({ currentCard, refetchCardExperience }) => {
  const { cardVariations: currentCardVariations } = currentCard
  const { brandKey } = useParams()

  const [addModalVisible, setAddModalVisible] = useState(false)

  const showAddModal = () => setAddModalVisible(true)
  const hideAddModal = () => setAddModalVisible(false)

  const [cardVariations, setCardVariations] = useState(currentCardVariations)

  useEffect(() => {
    setCardVariations(currentCardVariations)
  }, [currentCardVariations])

  const { addToast } = useToasts()

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const [reorderCardVariations] = useMutation(REORDER_CARD_VARIATIONS, {
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.log("Error", e)
      addToast("Reorder failed, changes did not save!", {
        appearance: "error",
      })
      refetchCardExperience()
    },
    onCompleted: () => {
      addToast("Successfully reordered variations", {
        appearance: "success",
      })
      refetchCardExperience()
    },
  })

  const [deleteCardVariationMutation] = useMutation(DELETE_CARD_VARIATION, {
    onCompleted: () => {
      addToast("Card variation deleted", {
        appearance: "success",
      })
      refetchCardExperience()
    },
  })

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const reorderedItems = reorder(
      cardVariations,
      result.source.index,
      result.destination.index
    )

    setCardVariations(reorderedItems)

    const reorderedItemIds = reorderedItems.map(
      (cardVariation) => cardVariation.id
    )

    reorderCardVariations({
      variables: {
        input: {
          cardId: currentCard.id,
          cardVariationIds: reorderedItemIds,
          brandKey,
        },
      },
    })
  }

  if (!cardVariations) {
    return <p>No card</p>
  }

  const deletedFromShopify = cardVariations.find(
    (cv) => cv.productVariant.deletedFromShopify
  )

  return (
    <>
      <div className="flex flex-col">
        <h3 className="mb-4 font-semibold text-md">Variations</h3>
        {deletedFromShopify && (
          <div className="my-4">
            <Alert message="One or more product variants have been removed from shopify" />
          </div>
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <div>
            <Droppable droppableId="variants-droppable">
              {(provided, snapshot) => (
                <ul
                  ref={provided.innerRef}
                  className={classNames(
                    "flex justify-start items-start flex-col",
                    snapshot.isDraggingOver ? "bg-white" : "bg-white"
                  )}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...provided.droppableProps}
                >
                  {cardVariations.map((variation, index) => (
                    <Draggable
                      key={variation.id}
                      draggableId={variation.id}
                      index={index}
                    >
                      {(itemProvided, itemSnapshot) => (
                        <CardVariationDetail
                          brandKey={brandKey}
                          currentCardId={currentCard.id}
                          deleteCardVariationMutation={
                            deleteCardVariationMutation
                          }
                          draggableProps={itemProvided.draggableProps}
                          dragHandleProps={itemProvided.dragHandleProps}
                          innerClassName={`bg-white border-gray-200 ${
                            itemSnapshot.isDragging ? "shadow-lg" : ""
                          }`}
                          innerRef={itemProvided.innerRef}
                          showDeletedAlert={
                            variation.productVariant.deletedFromShopify
                          }
                          style={itemProvided.draggableProps.style}
                          variation={variation}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <li className="relative flex-grow block w-full h-16 mt-2 font-medium border border-purple-500 rounded-sm cursor-pointer w-100">
                    <button
                      className="inline-block w-full h-16 p-4 text-left"
                      onClick={showAddModal}
                      type="button"
                    >
                      <span className="text-sm font-semibold text-purple-500">
                        + Add
                      </span>
                    </button>
                  </li>
                </ul>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
      <AddVariationModal onClose={hideAddModal} visible={addModalVisible} />
    </>
  )
}

export default CardVariationConfigurator
