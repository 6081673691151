import gql from "graphql-tag"

const GET_PRODUCT_VARIANT = gql`
  query GetProductVariant($id: ID!, $brandKey: String!) {
    productVariant(id: $id, brandKey: $brandKey) {
      id
      productTitle
      subtitle
      featuredImageSrc
      imageSrcSmall
      imageSrcMedium
      description
      price
      sku
      isStarred
      shopifyVariantIdNumber
      updatedAt
      deletedFromShopify
      systemChecks {
        id
        checkType
        lastStatusAt
        status
      }
      selectedOptions {
        name
        value
      }
      brand {
        logoHorizontalSrc
      }
      product {
        id
        title
        variantCount
        platformShoppableUrl
        shopifyProductIdNumber
      }
      primaryCardVariation {
        id
        title
        subtitle
        variationValue
        extendedDescriptionHtml
        isSoldOut
        backInStockNotificationFeatureEnabled
        price
        cardVariationBackgrounds {
          id
          ordinal
          backgroundType
          backgroundImageSrc
          backgroundCutoutSrc
          backgroundVideoSrc
        }
      }
      cardExperiences {
        id
        internalNickname
      }
    }
  }
`

export { GET_PRODUCT_VARIANT }

export default GET_PRODUCT_VARIANT
