import gql from "graphql-tag"

export const UPDATE_EXPERIENCE_BACKGROUND_IMAGE_MUTATION = gql`
  mutation UpdateExperienceBackgroundImage(
    $input: UpdateExperienceBackgroundImageInput!
  ) {
    updateExperienceBackgroundImage(input: $input) {
      errors
      experience {
        id
      }
    }
  }
`

export const UPDATE_EXPERIENCE_BACKGROUND_VIDEO_MUTATION = gql`
  mutation UpdateExperienceBackgroundVideo(
    $input: UpdateExperienceBackgroundVideoInput!
  ) {
    updateExperienceBackgroundVideo(input: $input) {
      errors
      experience {
        id
      }
    }
  }
`
