import React from "react"
import { useEditor } from "contexts/experience-editor"
import { useToasts } from "react-toast-notifications"
import { useMutation } from "@apollo/client"
import GetQueryLoader from "components/get-query-loader"

import ProductVariantGallery from "./product-variant-gallery"
import {
  UPDATE_BLANK_EXPERIENCE_WITH_PRODUCT_VARIANT,
  GET_PRODUCT_VARIANTS,
} from "./queries"

const BlankExperienceEditor = ({ experienceId }) => {
  const { addToast } = useToasts()

  const { refetchExperience } = useEditor()

  /* eslint-disable no-unused-vars  */
  /* eslint-disable no-shadow  */
  const [executeMigration, _] = useMutation(
    UPDATE_BLANK_EXPERIENCE_WITH_PRODUCT_VARIANT,
    {
      onError: (e) => {
        // eslint-disable-next-line
        console.log("Error", e)
        addToast("Something went wrong, changes did not save!", {
          appearance: "error",
        })
      },
      onCompleted: (_) => {
        addToast("Saved Successfully", { appearance: "success" })
        refetchExperience()
      },
    }
  )

  const chooseProductVariant = (productVariantId) => {
    executeMigration({
      variables: {
        input: {
          experienceId,
          productVariantId,
        },
      },
    })
  }

  return (
    <div className="bg-gray-50 col-span-12 pl-8 pt-8 overflow-y-scroll">
      <GetQueryLoader query={GET_PRODUCT_VARIANTS}>
        {({ brand: { productVariants } }) => (
          <ProductVariantGallery
            chooseProductVariant={chooseProductVariant}
            productVariants={productVariants}
          />
        )}
      </GetQueryLoader>
    </div>
  )
}

export default BlankExperienceEditor
