import AppNav from "components/app-nav"
import Container from "components/layout/container"
import Padding from "components/layout/padding"
import React from "react"

import AdminRouter from "./admin-router"

const Admin = () => (
  <>
    <AppNav />
    <Container>
      <Padding>
        <AdminRouter />
      </Padding>
    </Container>
  </>
)

export default Admin
