import gql from "graphql-tag"

export const ADD_PRODUCT_VARIANTS_TO_CARD_MUTATION = gql`
  mutation AddProductVariantToCardMutation(
    $input: AddProductVariantToCardMutationInput!
  ) {
    addProductVariantToCardMutation(input: $input) {
      errors
      card {
        id
      }
    }
  }
`

export default ADD_PRODUCT_VARIANTS_TO_CARD_MUTATION
