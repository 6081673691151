import gql from "graphql-tag"

export const PREVIEW_BATCH_TAG = gql`
  mutation PreviewBatchTag($input: PreviewBatchTagInput!) {
    previewBatchTag(input: $input) {
      svg
    }
  }
`

export default PREVIEW_BATCH_TAG
