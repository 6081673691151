import React from "react"
import ModalBody from "./modal-body"
import ModalFooter from "./modal-footer"

const SmallFormModal = ({
  children,
  visible,
  onClose,
  fullWidth = false,
  overflowVisible,
}) => {
  const close = () => {
    onClose()
  }

  const overflow = overflowVisible ? "overflow-visible" : "overflow-hidden"

  if (!visible) return null

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto" style={{ zIndex: 99 }}>
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div aria-hidden="true" className="fixed inset-0 transition-opacity">
          <button
            aria-label="Close modal."
            className="cursor-default"
            onClick={close}
            type="button"
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </button>
        </div>

        {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
        <span
          aria-hidden="true"
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
        >
          &#8203;
        </span>
        <div
          aria-labelledby="modal-headline"
          aria-modal="true"
          className={`inline-block align-bottom bg-white rounded-lg text-left ${overflow} shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full`}
          role="dialog"
          style={{ minWidth: fullWidth && "80vw" }}
        >
          {children({ close })}
        </div>
      </div>
    </div>
  )
}

export default SmallFormModal

export { ModalBody, ModalFooter }
