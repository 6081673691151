import React, { useState } from "react"
import { SecondaryButton } from "components/button"
import { PhotographIcon } from "@heroicons/react/solid"

import Label from "components/forms/label"
import ImageOnly from "components/image-only"
import ImageUploadModal from "./image-upload-modal"
import ImagePickerPreview from "./preview"

// TODO: abstract out to assetpicker with image and video picker subclasses
const ImagePicker = ({
  allowedFileTypes,
  onImageUploadComplete,
  imageUrl,
  label,
  inputName,
  note,
  maxFileSize,
  uploadFolder,
  uploadText = "upload an image...",
  preview = false,
  showClearButton = false,
  onClearButtonPressed,
  isImageOnly,
  brandKey,
}) => {
  const [modalVisible, setModalVisible] = useState(false)

  const showModal = () => setModalVisible(true)
  const hideModal = () => setModalVisible(false)

  const getImageFileName = () => {
    const url = decodeURIComponent(imageUrl)
    const filename = url.substring(url.lastIndexOf("/") + 1)

    const regex = /presigned\/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z-(.*)$/gm
    const results = regex.exec(decodeURIComponent(imageUrl))

    const stripUrlParams = (fullName) => fullName.split("?")[0]

    if (results !== null) {
      // This is probably an uppy upload.
      return stripUrlParams(results[1])
    }

    return stripUrlParams(filename) || ""
  }

  return (
    <div className="col-span-8 sm:col-span-6 mb-4">
      <Label displayName={label} inputName={inputName} />
      <ImageOnly alt={label} isImageOnly={isImageOnly} url={imageUrl}>
        {/*
        FormikInput is readonly since the imageUrl value is not mutable within the component.
        Rather, it's passed as a state value from the parent, and is mutable within
        the parent component.
       */}
        <input hidden name={inputName} readOnly value={imageUrl} />
        <div className="flex flex-row border-gray-300 border rounded-md justify-between items-center mt-1 h-9 bg-white">
          <PhotographIcon
            className={`h-5 w-5 ml-2 flex-shrink-0 ${
              imageUrl ? "text-purple-500" : "text-gray-400"
            }`}
          />
          <span
            className={`text-sm flex-grow ml-2 h-5 overflow-hidden ${
              imageUrl ? "text-gray-600" : "text-gray-300"
            }`}
          >
            {imageUrl ? getImageFileName() : uploadText}
          </span>
          <SecondaryButton onClick={showModal} rounding="right" slim>
            Change...
          </SecondaryButton>
        </div>
        <ImagePickerPreview imageUrl={imageUrl} preview={preview} />
        <ImageUploadModal
          allowedFileTypes={allowedFileTypes}
          brandKey={brandKey}
          maxFileSize={maxFileSize}
          note={note}
          onClose={hideModal}
          onImageUploadComplete={onImageUploadComplete}
          uploadFolder={uploadFolder}
          visible={modalVisible}
        />
        {showClearButton && (
          <div className="bg-gray-100 p-1">
            <SecondaryButton
              disabled={imageUrl === null}
              onClick={onClearButtonPressed}
              slim
            >
              <span className="text-xs">Clear</span>
            </SecondaryButton>
          </div>
        )}
      </ImageOnly>
    </div>
  )
}

export default ImagePicker
