import gql from "graphql-tag"

const GET_BRAND = gql`
  query GetBrand {
    brand {
      id
      name
      cardExperiences {
        id
        name
        activations {
          id
          url
          name
          qrCodeSvgUrl
          batchTagSvgUrl
          fallbackUrl
        }
      }
    }
  }
`

export default GET_BRAND

export { GET_BRAND }
