import React from "react"
import classnames from "classnames"
import Button from "./button"
import { getSlimStyles } from "./utils"

const PrimaryButton = ({
  slim = false,
  className,
  onClick,
  order = 1,
  type = "button",
  children,
  submitting = false,
  disabled = false,
  rounding = "all",
  ...rest
}) => (
  <Button
    buttonClassName={classnames(
      disabled
        ? "active:bg-gray-700 bg-gray-600"
        : "active:bg-indigo-700 bg-indigo-600 hover:bg-indigo-700",
      "border border-transparent  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  text-white",
      getSlimStyles(slim)
    )}
    className={className}
    disabled={disabled}
    onClick={onClick}
    order={order}
    rounding={rounding}
    submitting={submitting}
    type={type}
    {...rest}
  >
    {children}
  </Button>
)

export default PrimaryButton
