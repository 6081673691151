import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Form } from "formik"
import * as Yup from "yup"
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DownloadIcon,
} from "@heroicons/react/outline"

import SubmitButton from "components/submit-button"
import BatchForm from "components/batch-form"
import { PrimaryButton } from "components/button"
import Select from "components/forms/select"
import ImagePicker from "components/image-picker"
import imageUploadNote from "components/image-upload-note"

import AddBackgroundModal from "./add-background-modal"
import DeleteBackgroundButton from "./delete-background-button"
import { UPDATE_CARD_VARIATION_BACKGROUND_MUTATION } from "./queries"

const EditProductVariantBackgrounds = ({
  currentBackground,
  currentBackgroundOrdinal,
  setCurrentBackgroundOrdinal,
  productVariant,
  productVariantRefetch,
}) => {
  const { primaryCardVariation: cardVariation } = productVariant
  const { cardVariationBackgrounds, id: cardVariationId } = cardVariation
  const {
    id: cardVariationBackgroundId,
    backgroundVideoSrc,
    backgroundCutoutSrc,
    backgroundImageSrc,
    backgroundType,
    ordinal,
  } = currentBackground

  const { brandKey } = useParams()
  const [modalVisible, setModalVisible] = useState(false)

  const showModal = () => setModalVisible(true)
  const onModalClose = () => {
    setModalVisible(false)
  }

  const [videoUrl, setVideoUrl] = useState(backgroundVideoSrc)
  const [cutoutUrl, setCutoutUrl] = useState(backgroundCutoutSrc)
  const [imageUrl, setImageUrl] = useState(backgroundImageSrc)
  useEffect(() => {
    setVideoUrl(backgroundVideoSrc)
    setCutoutUrl(backgroundCutoutSrc)
    setImageUrl(backgroundImageSrc)
  }, [
    backgroundVideoSrc,
    backgroundCutoutSrc,
    backgroundImageSrc,
    currentBackground,
  ])

  const formSchema = Yup.object().shape({
    cardVariationBackgroundId: Yup.string().uuid().required(),
    cardVariationId: Yup.string().uuid().required(),
    brandKey: Yup.string().required(),
    ordinal: Yup.number().required(),
    backgroundType: Yup.string().required(),
    backgroundImageSrc: Yup.string().nullable(),
    backgroundCutoutSrc: Yup.string().nullable(),
    backgroundVideoSrc: Yup.string().nullable(),
  })

  const totalBackgrounds = cardVariationBackgrounds.length
  const onLeftArrowClick = () => {
    if (currentBackgroundOrdinal === 1) {
      setCurrentBackgroundOrdinal(() => totalBackgrounds)
    } else {
      setCurrentBackgroundOrdinal((currentOrdinal) => currentOrdinal - 1)
    }
  }
  const onRightArrowClick = () => {
    if (currentBackgroundOrdinal === totalBackgrounds) {
      setCurrentBackgroundOrdinal(() => 1)
    } else {
      setCurrentBackgroundOrdinal((currentOrdinal) => currentOrdinal + 1)
    }
  }

  const ordinalOptions = Object.fromEntries(
    [...Array(totalBackgrounds + 1).keys()].slice(1).map((o) => [o, o])
  )

  const onDelete = () => {
    if (currentBackgroundOrdinal === totalBackgrounds) {
      setCurrentBackgroundOrdinal(() => currentBackgroundOrdinal - 1)
    }
  }

  return (
    <main className="ml-10">
      <AddBackgroundModal
        cardVariation={cardVariation}
        onClose={onModalClose}
        productVariantRefetch={productVariantRefetch}
        visible={modalVisible}
      />
      <BatchForm
        enableReinitialize
        formSchema={formSchema}
        generateValues={(values) => ({
          ...values,
          ordinal: parseInt(values.ordinal),
          backgroundCutoutSrc: cutoutUrl,
          backgroundVideoSrc: videoUrl,
          backgroundImageSrc: imageUrl,
        })}
        initialValues={{
          backgroundCutoutSrc,
          backgroundImageSrc,
          backgroundType,
          backgroundVideoSrc,
          brandKey,
          cardVariationBackgroundId,
          cardVariationId,
          ordinal,
        }}
        mutation={UPDATE_CARD_VARIATION_BACKGROUND_MUTATION}
        onSuccess={({
          updateCardVariationBackground: { cardVariationBackground },
        }) => {
          setCurrentBackgroundOrdinal(cardVariationBackground.ordinal)
          productVariantRefetch()
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="grid grid-cols-8">
              <div className="col-span-8 sm:col-span-6 rounded-lg bg-purple-50 p-2">
                <div className="flex justify-between items-center">
                  <div>
                    <h4 className="font-medium text-lg">Backgrounds</h4>
                    <p className="font-small text-xs text-gray-600 mb-2">
                      {totalBackgrounds > 0 &&
                        "Press Save after each background edit"}
                      {totalBackgrounds === 0 &&
                        "No backgrounds yet. Add one to the right"}
                    </p>
                  </div>
                  <PrimaryButton onClick={() => showModal()}>
                    Add New Background
                  </PrimaryButton>
                </div>
                {totalBackgrounds > 0 && (
                  <div>
                    <div className="mb-4">
                      <Select
                        label="Reorder to..."
                        name="ordinal"
                        options={ordinalOptions}
                      />
                    </div>
                    <div className="mb-4">
                      <Select
                        label="Background Type"
                        name="backgroundType"
                        options={{
                          video: "Video",
                          cutout: "Cutout",
                          image: "Image",
                        }}
                      />
                    </div>
                    <div className="mb-4">
                      <ImagePicker
                        allowedFileTypes={["video/mp4"]}
                        brandKey={brandKey}
                        imageUrl={videoUrl}
                        inputName="backgroundVideoSrc"
                        label="Background Video (optional)"
                        maxFileSize={null} // Defaults to 8MB.
                        onImageUploadComplete={(url) => setVideoUrl(url)}
                        preview
                        uploadFolder={`card_variations/${cardVariationId}/background_video`}
                      />
                      {videoUrl && (
                        <div className="flex items-center">
                          <p className="text-sm mr-2">Download video</p>
                          <div className="h-6 w-6 bg-purple-700 rounded flex items-center justify-center">
                            <a
                              download
                              href={videoUrl}
                              rel="noreferrer noopener"
                              target="blank"
                            >
                              <DownloadIcon className="h-4 w-4 text-white" />
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <ImagePicker
                        brandKey={brandKey}
                        imageUrl={cutoutUrl}
                        inputName="backgroundCutoutSrc"
                        label="Cutout Image"
                        note={imageUploadNote({ isExpanded: true })}
                        onImageUploadComplete={(url) => setCutoutUrl(url)}
                        preview
                        uploadFolder={`card_variations/${cardVariationId}/background_cutout`}
                      />
                    </div>
                    <div className="mb-4">
                      <ImagePicker
                        brandKey={brandKey}
                        imageUrl={imageUrl}
                        inputName="backgroundImageSrc"
                        label="Background Image"
                        note={imageUploadNote({ isExpanded: true })}
                        onImageUploadComplete={(url) => setImageUrl(url)}
                        preview
                        uploadFolder={`card_variations/${cardVariationId}/background_image`}
                      />
                    </div>
                    <div className="flex justify-between">
                      <SubmitButton submitting={isSubmitting} />
                      <DeleteBackgroundButton
                        cardVariationBackgroundId={cardVariationBackgroundId}
                        cardVariationId={cardVariationId}
                        disabled={totalBackgrounds === 1}
                        onDelete={onDelete}
                        productVariantRefetch={productVariantRefetch}
                      />
                    </div>
                    <div className="flex justify-center items-center">
                      <button onClick={() => onLeftArrowClick()} type="button">
                        <ChevronLeftIcon className="h-4 w-4 mr-2" />
                      </button>
                      <select
                        className="w-4"
                        onChange={(e) =>
                          setCurrentBackgroundOrdinal(parseInt(e.target.value))
                        }
                        type="text"
                        value={currentBackgroundOrdinal}
                      >
                        {Object.entries(ordinalOptions).map(
                          ([optionValue, optionLabel]) => (
                            <option key={optionValue} value={optionValue}>
                              {optionLabel}
                            </option>
                          )
                        )}
                      </select>
                      <p>/ {totalBackgrounds}</p>
                      <button onClick={() => onRightArrowClick()} type="button">
                        <ChevronRightIcon className="h-4 w-4 ml-2" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Form>
        )}
      </BatchForm>
    </main>
  )
}

export default EditProductVariantBackgrounds
