import React from "react"
import { useEditor } from "contexts/experience-editor"

import SectionNav from "./settings"
import Config from "./config"
import Workspace from "./workspace"
import BlankExperienceEditor from "./blank-experience-editor"

const Editor = () => {
  const { currentExperience } = useEditor()
  return (
    <div className="w-screen grid grid-cols-12 mx-auto h-screen">
      {currentExperience.hasExperienceSections ? (
        <>
          <SectionNav />
          <Workspace />
          <Config />
        </>
      ) : (
        <BlankExperienceEditor experienceId={currentExperience.id} />
      )}
    </div>
  )
}

export default Editor
