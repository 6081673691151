import React from "react"
import { useParams } from "react-router-dom"
import TabNavLinks, { TabNavLink } from "components/tab-nav-links"

const EditorTabNavFullWidth = () => {
  const { experienceId, brandKey } = useParams()

  return (
    <div>
      <TabNavLinks>
        <TabNavLink
          first
          to={`/brands/${brandKey}/old-experiences/${experienceId}/editor`}
        >
          Design Editor
        </TabNavLink>
        <TabNavLink
          to={`/brands/${brandKey}/old-experiences/${experienceId}/qrcodes`}
        >
          QR Codes
        </TabNavLink>
        <TabNavLink
          to={`/brands/${brandKey}/old-experiences/${experienceId}/nfctags`}
        >
          NFC Tags
        </TabNavLink>
        <TabNavLink
          to={`/brands/${brandKey}/old-experiences/${experienceId}/configuration`}
        >
          Configuration
        </TabNavLink>
      </TabNavLinks>
    </div>
  )
}

export default EditorTabNavFullWidth
