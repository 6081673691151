import React from "react"
import useQueryParams from "utils/use-query-params"

import batchLogo from "assets/images/batch-shopify-app-icon.png"
import shopifyLogo from "assets/images/shopify-default-app-logo.png"
import connectionArrow from "assets/images/shopify-connection-arrow.png"
import "assets/css/homepage.css"

const Home = () => {
  const query = useQueryParams()
  const logo = query.get("logo") || shopifyLogo
  const store = query.get("store")

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div aria-hidden="true" className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-100" />
        </div>
        <span
          aria-hidden="true"
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
        >
          &#8203;
        </span>
        <div
          aria-labelledby="modal-headline"
          aria-modal="true"
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
          role="dialog"
        >
          <div className="flex flex-col items-center">
            <div className="flex flex-row mt-8 mb-4">
              <img alt="Logo." className="h-12 w-12 rounded-full" src={logo} />
              <img
                alt=""
                className="h-8 w-20 mx-4 mt-3"
                src={connectionArrow}
              />
              <img
                alt="Batch logo."
                className="h-14 w-14 rounded-full"
                src={batchLogo}
              />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                Shopify Connected Successfully
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  No further steps needed, you can close this tab.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <a
              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm"
              href={`https://${store}/admin`}
              type="button"
            >
              Go back to Shopify
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
