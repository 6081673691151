import gql from "graphql-tag"

export const UPDATE_CARD_VARIATION_MUTATION = gql`
  mutation UpdateCardVariation($input: UpdateCardVariationInput!) {
    updateCardVariation(input: $input) {
      errors
      cardVariation {
        id
      }
    }
  }
`

export const RERUN_FAILED_SYSTEM_CHECK = gql`
  mutation RerunSystemCheck($input: RerunSystemCheckInput!) {
    rerunSystemCheck(input: $input) {
      errors
      systemCheck {
        id
        status
      }
    }
  }
`

export const PRESIGN_UPLOAD_URL_MUTATION = gql`
  mutation PresignPublicAssetUploadUrl(
    $input: PresignPublicAssetUploadUrlInput!
  ) {
    presignPublicAssetUploadUrl(input: $input) {
      url
    }
  }
`
