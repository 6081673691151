import React from "react"

const Badge = ({ color, message, uppercase = false }) => {
  const theme = {
    green: "bg-green-600",
    blue: "bg-blue-500",
    red: "bg-red-600",
    gray: "bg-gray-600",
    indigo: "bg-indigo-600",
  }[color]

  return (
    <span
      className={`px-2 py-1 inline-flex text-white text-sm font-medium rounded-md ${theme} ${
        uppercase ? "uppercase" : ""
      }`}
    >
      {message}
    </span>
  )
}

export default Badge
