import gql from "graphql-tag"

const GET_BRANDS = gql`
  query GetBrands {
    brands {
      id
      name
      shopifyDomain
      homepage
      status
      key
      logoImageSrc
      coreAppCardExperience {
        id
        name
      }
      cardExperiences {
        id
        name
      }
    }
  }
`

export default GET_BRANDS
