import { PlusIcon } from "@heroicons/react/outline"
import React from "react"
import { useEditor } from "contexts/experience-v2-editor"
import { MEASUREMENTS } from "components/containers/iphone-11-pro-max"
import { H2 } from "components/containers/iphone-fonts"
import Background from "./background"

const Card = ({ cardExperienceCard, defaultForegroundColor }) => {
  const { setCurrentCardOrdinal, setGridView } = useEditor()

  if (cardExperienceCard === undefined) {
    // This is just padding
    return (
      <div
        className="w-full rounded-lg px-2 py-3 text-center border-4 flex items-center justify-center"
        style={{
          borderColor: defaultForegroundColor,
          opacity: 0.3,
          color: defaultForegroundColor,
          minHeight: "160px",
        }}
      >
        <PlusIcon className="w-14 h-14 mx-auto" />
      </div>
    )
  }

  const { card, ordinal } = cardExperienceCard
  const {
    collapsedBackgroundColor: backgroundColor,
    collapsedForegroundColor: foregroundColor,
    collapsedTitle: cardTitle,
    collapsedBackgroundGradient: backgroundGradient,
  } = card

  const getBackgroundColor = () => {
    if (backgroundGradient?.length) {
      return {
        color: `#${foregroundColor}`,
        background: `linear-gradient(45deg, #${backgroundGradient[0]}, #${backgroundGradient[1]})`,
      }
    }

    return {
      color: `#${foregroundColor}`,
      backgroundColor: `#${backgroundColor}`,
    }
  }

  return (
    <button
      className="w-full h-full relative flex flex-col justify-end items-center"
      onClick={() => {
        setCurrentCardOrdinal(ordinal)
        setGridView(false)
      }}
      style={{
        height: MEASUREMENTS.gridCards.height,
        minHeight: MEASUREMENTS.gridCards.height,
        width: MEASUREMENTS.gridCards.width,
        minWidth: MEASUREMENTS.gridCards.width,
        paddingBottom: MEASUREMENTS.gridCards.paddingBottom,
        borderRadius: MEASUREMENTS.gridCards.borderRadius,
        ...getBackgroundColor(),
      }}
      type="button"
    >
      <div className="z-10">
        <span
          className="font-semibold z-20 w-full px-4"
          style={{
            ...H2,
          }}
        >
          {cardTitle}
        </span>
      </div>
      <Background currentCard={card} />
    </button>
  )
}

export default Card
