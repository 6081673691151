import gql from "graphql-tag"

export const ADD_EXISTING_CARD_TO_CARD_EXPERIENCE = gql`
  mutation AddExistingCardToCardExperience(
    $input: AddExistingCardToCardExperienceInput!
  ) {
    addExistingCardToCardExperience(input: $input) {
      errors
      card {
        id
      }
    }
  }
`

export const DELETE_CARD = gql`
  mutation DeleteCard($input: DeleteCardInput!) {
    deleteCard(input: $input) {
      errors
    }
  }
`

export const ADD_NEW_CARD_TO_CARD_EXPERIENCE = gql`
  mutation AddNewCardToCardExperience(
    $input: AddNewCardToCardExperienceInput!
  ) {
    addNewCardToCardExperience(input: $input) {
      errors
      card {
        id
      }
    }
  }
`

export const GET_CARDS = gql`
  query GetBrand($brandKey: String!) {
    brand(brandKey: $brandKey) {
      id
      cards {
        id
        hidden
        productVariants {
          id
          sku
        }
        cardVariations {
          id
          productVariant {
            featuredImageSrc
          }
        }
      }
    }
  }
`

export default GET_CARDS
