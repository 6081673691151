import React from "react"
import { useToasts } from "react-toast-notifications"
import { useMutation } from "@apollo/client"
import { Formik } from "formik"
import { useParams } from "react-router-dom"

const BatchForm = ({
  enableReinitialize = false,
  formSchema,
  initialValues = {},
  mutation,
  onSuccess,
  children,
  onSubmit = null,
  onCompleted,
  additionalValues,
  generateValues, // a function that generates the values to submit based on the initial values
  useBrandKey = true,
}) => {
  const { brandKey } = useParams()
  const { addToast } = useToasts()

  const [executeMigration, _] = useMutation(mutation, {
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.log("Error", e)
      addToast("Something went wrong, changes did not save!", {
        appearance: "error",
      })
    },
    // TODO: combine onSuccess and onCompleted
    onCompleted: (data) => {
      if (onSuccess) {
        onSuccess(data)
      }
      if (onCompleted) {
        onCompleted(data)
      } else {
        // Show success toast by default
        addToast("Saved Successfully", { appearance: "success" })
      }
    },
  })

  const getOnSubmit = () => {
    if (onSubmit) {
      return async (values, actions) => {
        actions.setSubmitting(true)

        onSubmit()

        actions.setSubmitting(false)
      }
    }

    return async (values, actions) => {
      actions.setSubmitting(true)

      const input = generateValues
        ? { ...generateValues(values) }
        : { ...additionalValues, ...values }

      await executeMigration({
        variables: {
          input: {
            ...input,
            brandKey: useBrandKey ? brandKey : undefined,
          },
        },
      })

      actions.setSubmitting(false)
    }
  }

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      onSubmit={getOnSubmit()}
      validationSchema={formSchema}
    >
      {children}
    </Formik>
  )
}

export default BatchForm
