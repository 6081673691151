import React from "react"
import NavLinkOrLink from "./nav-link-or-link"

const NavItem = ({ to, href, children, openInNewTab = false, ...rest }) => {
  const { title, icon } = children

  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <NavLinkOrLink
        activeClassName="bg-indigo-200 dark:bg-dark-700 rounded-full"
        className="border-transparent w-full rounded-md text-gray-800 dark:text-white group border-l-2 p-2 flex items-center text-sm font-medium"
        href={href}
        openInNewTab={openInNewTab}
        to={to}
        {...rest}
      >
        {icon}
        <div className="px-2 py-1 font-normal z-50 rounded-md">
          {title}
          {openInNewTab && (
            <span className="ml-1 mb-1 text-md text-purple-400">&#8599;</span>
          )}
        </div>
      </NavLinkOrLink>
    </div>
  )
}

export default NavItem
