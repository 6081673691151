import React from "react"
import CloseableConfigSection from "components/closeable-config-section"

import BackgroundForm from "./background-form"

const AppClipBackgroundSection = () => (
  <CloseableConfigSection
    subtitle="Capture customers’ attention with a dynamic background image or video."
    title="Experience Background"
  >
    <BackgroundForm />
  </CloseableConfigSection>
)

export default AppClipBackgroundSection
