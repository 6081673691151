import React from "react"

const FeaturedProductVariantSectionPreview = ({ experienceSectionDetails }) => {
  const { title, description } = experienceSectionDetails

  return (
    <div className="px-4 bg-transparent">
      <h3 className="font-bold text-xl leading-tight mb-2">{title}</h3>
      <h4
        className="text-xs text-gray-500 font-normal mb-2"
        style={{ fontSize: "0.7rem", lineHeight: "1rem" }}
      >
        {description}
      </h4>
    </div>
  )
}

export default FeaturedProductVariantSectionPreview
