import React from "react"
import { MEASUREMENTS } from "components/containers/iphone-11-pro-max"

const Background = ({ currentCard }) => {
  const { collapsedBackgroundType: type } = currentCard

  switch (type) {
    case "image":
      return (
        <div
          className="absolute inset-0 flex min-h-full overflow-hidden"
          style={{
            borderRadius: MEASUREMENTS.gridCards.borderRadius,
          }}
        >
          <img
            alt="Default variant."
            className="min-w-full min-h-full object-cover z-0"
            src={currentCard.collapsedBackgroundImageSrc}
          />
        </div>
      )
    case "cutout":
      return (
        <div
          className="absolute inset-0 overflow-hidden flex items-center justify-center"
          style={{
            borderRadius: MEASUREMENTS.gridCards.borderRadius,
          }}
        >
          <img alt="" src={currentCard.collapsedBackgroundCutoutSrc} />
        </div>
      )
    case "video":
      return (
        <div
          className="absolute inset-0 overflow-hidden"
          style={{
            borderRadius: MEASUREMENTS.gridCards.borderRadius,
          }}
        >
          <div
            style={{ minWidth: "100%", maxHeight: "90%", overflow: "hidden" }}
          >
            <video autoPlay loop muted>
              <source
                src={currentCard.collapsedBackgroundVideoSrc}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      )
    case "icon":
      return (
        <div
          className="absolute flex justify-center items-center inset-0 flex overflow-hidden"
          style={{
            borderRadius: MEASUREMENTS.gridCards.borderRadius,
          }}
        >
          <img
            alt="Default variant."
            className="w-32 h-32 object-contain z-0"
            src={currentCard.collapsedBackgroundIconSrc}
          />
        </div>
      )

    default:
      return null
  }
}

export default Background
