import React from "react"
import { Form } from "formik"
import * as Yup from "yup"
import { useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"

import BatchForm from "components/batch-form"
import { RedButtonWithConfirmation } from "components/button"
import Select from "components/forms/select"
import FormikInput from "components/forms/formik-input"
import TextArea from "components/forms/textarea"
import GradientSelector from "components/gradient-selector"
import SubmitButton from "components/submit-button"
import { useEditor } from "contexts/experience-v2-editor"

import { DELETE_INCENTIVE, UPDATE_INCENTIVE } from "./queries"

const IncentiveForm = ({ incentive }) => {
  const { addToast } = useToasts()
  const { currentCardExperience, refetchCardExperience: refetch } = useEditor()
  const {
    incentiveType,
    id: incentiveId,
    details,
    textColor,
    backgroundGradient,
    priority,
  } = incentive

  const {
    id: cardExperienceId,
    brand: { key: brandKey },
    gridVersion,
    incentives,
  } = currentCardExperience

  const [deleteIncentive] = useMutation(DELETE_INCENTIVE, {
    variables: {
      input: {
        brandKey,
        cardExperienceId,
        id: incentiveId,
      },
    },
    onError: (e) => {
      // eslint-disable-next-line
      console.log("Error", e)
      addToast("Something went wrong, changes did not save!", {
        appearance: "error",
      })
    },
    onCompleted: () => {
      addToast("Successfully Deleted Incentive", {
        appearance: "success",
      })
      refetch()
    },
  })

  const formSchema = Yup.object().shape({
    details: Yup.object().shape({
      prePurchaseExplainerTitle: Yup.string().required("Required"),
      postPurchaseExplainerTitle: Yup.string().required("Required"),
    }),
  })

  const labels = {
    v1: {
      preTitle: "Pre-purchase Explainer Text",
      postTitle: "Post-purchase Explainer Text",
    },
    v2: {
      preTitle: "Pre-purchase Title",
      postTitle: "Post-purchase Title",
    },
  }

  // Display priorty is 1-indexed.
  const displayPriorityOptions = [...Array(incentives.length).keys()].reduce(
    (acc, i) => {
      acc[i + 1] = (i + 1).toString()
      return acc
    },
    {}
  )

  return (
    <BatchForm
      formSchema={formSchema}
      generateValues={(values) => ({
        id: values.incentiveId,
        cardExperienceId: values.cardExperienceId,
        incentiveType: values.incentiveType,
        backgroundGradient: values.backgroundGradient,
        textColor: values.textColor,
        priority: parseInt(values.priority),
        details: {
          ...values.details,
          timerDurationSeconds: values.timerDurationMins * 60,
          discountPercentage:
            values.details.discountPercentage &&
            parseInt(values.details.discountPercentage),
        },
      })}
      initialValues={{
        incentiveType,
        cardExperienceId,
        incentiveId,
        details,
        textColor,
        backgroundGradient,
        priority,
        timerDurationMins: details.timerDurationSeconds / 60,
      }}
      mutation={UPDATE_INCENTIVE}
      onSuccess={refetch}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className="my-4">
            <FormikInput
              label={labels[gridVersion].preTitle}
              name="details.prePurchaseExplainerTitle"
              type="text"
            />
          </div>
          {gridVersion === "v2" && (
            <div className="my-4">
              <FormikInput
                label="Pre-purchase Subtitle"
                name="details.prePurchaseExplainerSubtitle"
                type="text"
              />
            </div>
          )}
          <div className="my-4">
            <FormikInput
              hint={
                gridVersion === "v1" &&
                "The text {{TIME}} will be replaced with the post-purchase countdown"
              }
              label={labels[gridVersion].postTitle}
              name="details.postPurchaseExplainerTitle"
              type="text"
            />
          </div>

          {gridVersion === "v2" && (
            <div className="my-4">
              <FormikInput
                label="Post-purchase Subtitle"
                name="details.postPurchaseExplainerSubtitle"
                type="text"
              />
            </div>
          )}

          {incentiveType === "sms_discount" && (
            <div className="my-4">
              <TextArea
                label="Legal Text"
                name="details.legalText"
                type="text"
              />
            </div>
          )}

          {gridVersion === "v2" && <GradientSelector />}

          <div className="w-48 my-4">
            <FormikInput
              hint="If it is set to 0, there will be no timer for free shipping after an order is placed"
              label="Free Shipping Timer Minutes"
              max="60"
              min="0"
              name="timerDurationMins"
              placeholder="10"
              type="number"
            />
          </div>

          {incentiveType === "sms_discount" && (
            <div className="w-48 my-4">
              <FormikInput
                label="Discount Percentage"
                max="100"
                min="1"
                name="details.discountPercentage"
                placeholder="15"
                type="number"
              />
            </div>
          )}

          <div className="my-4">
            <Select
              label="Display Priority"
              name="priority"
              options={displayPriorityOptions}
            />
          </div>
          <RedButtonWithConfirmation
            className="inline-block mt-1"
            isSubmitting={isSubmitting}
            onClick={deleteIncentive}
            type="button"
          >
            Delete Incentive
          </RedButtonWithConfirmation>
          <SubmitButton className="ml-2" cta="Save" submitting={isSubmitting} />
        </Form>
      )}
    </BatchForm>
  )
}

export default IncentiveForm
