import { MinusIcon, PlusIcon } from "@heroicons/react/outline"
import React, { useState } from "react"
import Selector from "./selector"

const QuantitySelector = ({ color }) => {
  const [quantity, setQuantity] = useState(1)
  return (
    <Selector borderColor={color}>
      <div className="w-full flex items-center justify-between">
        <button
          disabled={quantity < 1}
          onClick={() =>
            setQuantity((previousQuantity) => previousQuantity - 1)
          }
          type="button"
        >
          <MinusIcon className="w-5" />
        </button>
        <span>{quantity}</span>
        <button
          onClick={() =>
            setQuantity((previousQuantity) => previousQuantity + 1)
          }
          type="button"
        >
          <PlusIcon className="w-6" />
        </button>
      </div>
    </Selector>
  )
}

export default QuantitySelector
