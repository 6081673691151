import React, { useState } from "react"
import { useEditor } from "contexts/experience-v2-editor"
import { useMutation } from "@apollo/client"
import { useToasts } from "react-toast-notifications"
import { Redirect } from "react-router-dom"

import BatchForm from "components/batch-form"
import SubmitButton from "components/submit-button"
import FormikInput from "components/forms/formik-input"
import TextArea from "components/forms/textarea"
import Select from "components/forms/select"
import Switch from "components/forms/switch"
import ImagePicker from "components/image-picker"
import { Form } from "formik"
import { RedButtonWithConfirmation } from "components/button"
import * as Yup from "yup"

import { UPDATE_CARD_EXPERIENCE, DELETE_CARD_EXPERIENCE } from "./queries"

const Settings = () => {
  const { addToast } = useToasts()
  const [redirectUrl, setRedirectUrl] = useState(null)
  const { currentCardExperience, refetchCardExperience: refetch } = useEditor()
  const {
    brand: { key: brandKey },
    gridVersion,
    id,
    name,
    internalNickname,
    notificationsFeature,
    notificationTitle,
    notificationSubtitle,
    notificationBody,
    notificationDelayMins,
    batchForWebEnabled,
    hidden,
  } = currentCardExperience

  const [deleteCardExperience] = useMutation(DELETE_CARD_EXPERIENCE, {
    variables: {
      input: {
        brandKey,
        cardExperienceId: currentCardExperience.id,
      },
    },
    onError: (e) => {
      // eslint-disable-next-line
      console.log("Error", e)
      addToast("Something went wrong, changes did not save!", {
        appearance: "error",
      })
    },
    onCompleted: () => {
      addToast("Successfully Deleted Experience (Grid)", {
        appearance: "success",
      })
      setRedirectUrl(`/brands/${brandKey}/experiences`)
    },
  })

  const { logoHorizontalOverrideSrc: initialLogoHorizontalOverrideSrc } =
    currentCardExperience

  const [logoHorizontalOverrideSrc, setLogoHorizontalOverrideSrc] = useState(
    initialLogoHorizontalOverrideSrc
  )

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    notificationsFeature: Yup.boolean().required(),
    notificationTitle: Yup.string()
      .max(
        30,
        "Title can't be over ${max} characters" // eslint-disable-line no-template-curly-in-string
      )
      .nullable(),
    notificationSubtitle: Yup.string()
      .max(
        50,
        "Subtitle can't be over ${max} characters" // eslint-disable-line no-template-curly-in-string
      )
      .nullable(),
    notificationBody: Yup.string()
      .max(
        120,
        "Body can't be over ${max} characters" // eslint-disable-line no-template-curly-in-string
      )
      .nullable(),
    notificationDelayMins: Yup.number().positive().max(360),
    timerDurationMins: Yup.number().min(0).max(60),
  })

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />
  }

  return (
    <BatchForm
      formSchema={formSchema}
      generateValues={(values) => ({
        ...values,
        logoHorizontalOverrideSrc,
        notificationsFeature: values.notificationsFeature === "true",
        notificationDelayMins: parseInt(values.notificationDelayMins),
        batchForWebEnabled: values.batchForWebEnabled === "true",
      })}
      initialValues={{
        gridVersion,
        cardExperienceId: id,
        name,
        internalNickname,
        notificationsFeature: notificationsFeature ? "true" : "false",
        notificationTitle,
        notificationSubtitle,
        notificationBody,
        notificationDelayMins,
        batchForWebEnabled: batchForWebEnabled ? "true" : "false",
        hidden,
      }}
      mutation={UPDATE_CARD_EXPERIENCE}
      onSuccess={refetch}
    >
      {({ isSubmitting }) => (
        <Form>
          <h2 className="my-4">Experience</h2>
          <div className="my-4">
            <Switch label="Hidden on Platform" name="hidden" />
          </div>
          <div className="my-4">
            <FormikInput label="Name" name="name" type="text" />
            <p className="text-xs text-gray-600 pt-1 pl-1">
              This name is used as the iMessage Card Title.
            </p>
          </div>
          <div className="my-4">
            <FormikInput
              label="Internal Experience Nickname"
              name="internalNickname"
              type="text"
            />
          </div>
          <div className="w-48 my-4">
            <Select
              label="Grid Version"
              name="gridVersion"
              options={{ v1: "V1", v2: "V2" }}
            />
          </div>
          <div className="w-48 my-4">
            <Select
              label="Batch for Web / Android"
              name="batchForWebEnabled"
              options={{ true: "Enabled", false: "Disabled" }}
            />
          </div>

          <div className="w-48 my-4">
            <ImagePicker
              brandKey={currentCardExperience.brand.key}
              imageUrl={logoHorizontalOverrideSrc}
              inputName="logoHorizontalOverrideSrc"
              label="Horizontal Logo Override"
              onClearButtonPressed={() => setLogoHorizontalOverrideSrc(null)}
              onImageUploadComplete={setLogoHorizontalOverrideSrc}
              preview
              showClearButton
              uploadFolder={`brand/${currentCardExperience.brand.id}/experiences/${currentCardExperience.id}/experience-horizontal-logo`}
            />
          </div>

          <hr />

          <h2 className="my-4">Notifications</h2>

          <div className="w-48 my-4">
            <Select
              label="Enabled"
              name="notificationsFeature"
              options={{ true: "True", false: "False" }}
            />
          </div>
          <div className="my-4">
            <FormikInput label="Title" name="notificationTitle" type="text" />
          </div>
          <div className="my-4">
            <FormikInput
              label="Subtitle"
              name="notificationSubtitle"
              type="text"
            />
          </div>
          <div className="my-4">
            <TextArea label="Body" name="notificationBody" />
          </div>
          <div className="w-48 my-4">
            <FormikInput
              label="Minutes After Scan"
              name="notificationDelayMins"
              placeholder="30"
              type="number"
            />
          </div>
          <RedButtonWithConfirmation
            className="inline-block mt-1"
            isSubmitting={isSubmitting}
            onClick={deleteCardExperience}
            type="button"
          >
            Delete Experience
          </RedButtonWithConfirmation>
          <SubmitButton className="ml-2" cta="Save" submitting={isSubmitting} />
        </Form>
      )}
    </BatchForm>
  )
}

export default Settings
