import gql from "graphql-tag"

const CREATE_INCENTIVE = gql`
  mutation CreateIncentive($input: CreateIncentiveInput!) {
    createIncentive(input: $input) {
      incentive {
        id
      }
      errors
    }
  }
`

const DELETE_INCENTIVE = gql`
  mutation DeleteIncentive($input: DeleteIncentiveInput!) {
    deleteIncentive(input: $input) {
      errors
    }
  }
`

const UPDATE_INCENTIVE = gql`
  mutation UpdateIncentive($input: UpdateIncentiveInput!) {
    updateIncentive(input: $input) {
      errors
    }
  }
`

export { CREATE_INCENTIVE, DELETE_INCENTIVE, UPDATE_INCENTIVE }
